import { Box, Button } from '@mui/material';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import HorizontalItemsSelector from '../../../../../../../../components/HorizontalItemsSelector';
import { sortObjectTimeOfDays } from '../../../../../../../../shared/utils/common';
import { checkAvailabilitySchedulePatternWrapperStyles, refreshButton } from './styles';
import moment from 'moment';

export default function CheckAvailabilitySchedulePattern({
  disabledRecheckAvailability = false,
  filtersData = {},
  handleChangeDates = () => {},
  handleChangeTimeOfDay = () => {},
  isFiltersLoading = false,
  isLoading = false,
  recheckAvailability = () => {},
  visitData = {},
  selectedDates = [],
  activeDates = [],
}) {
  return (
    !isFiltersLoading && (
      <Box sx={checkAvailabilitySchedulePatternWrapperStyles}>
        <HorizontalItemsSelector
          disabled={isLoading}
          extendingProperties={{ minWidth: '150px', maxWidth: '150px' }}
          gap="8px"
          handleChange={handleChangeDates}
          options={selectedDates.map(x => ({
            name: x,
            value: moment(x).format('ddd - MMM D'),
          }))}
          selectedDays={activeDates}
          title="Dates to Schedule"
        />
        <HorizontalItemsSelector
          disabled={isLoading}
          extendingProperties={{ minWidth: '112px' }}
          gap="8px"
          handleChange={handleChangeTimeOfDay}
          options={sortObjectTimeOfDays(filtersData).map(({ name }) => ({
            name,
            value: name,
          }))}
          selectedDays={visitData?.timesOfDaysDetails?.map(({ name }) => name) ?? []}
          title="Time of Day"
        />
        <Button
          color="primary"
          variant="contained"
          onClick={recheckAvailability}
          className="re-check-availability-button"
          sx={refreshButton}
          disabled={disabledRecheckAvailability}
          disableElevation
        >
          <RefreshIcon sx={{ minWidth: '20px', minHeight: '20px' }} />
        </Button>
      </Box>
    )
  );
}

CheckAvailabilitySchedulePattern.propTypes = {
  disabledRecheckAvailability: bool,
  filtersData: instanceOf(Object),
  handleChangeDates: func,
  handleChangeTimeOfDay: func,
  isFiltersLoading: bool,
  isLoading: bool,
  recheckAvailability: func,
  visitData: instanceOf(Object),
  selectedDates: instanceOf(Array),
  activeDates: instanceOf(Array),
};

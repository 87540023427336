import { Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { visitInfoTypeDialogs } from '../../constants';
import CareProgramVisitPlaceholderDialog from './CareProgramVisitPlaceholderDialog';
import CareProgramVisitPlaceholderDialogContent from './CareProgramVisitPlaceholderDialogContent';
import AlertContext from '../../../Alert';
import { api } from '../../../../api';

export default function CareProgramVisitPlaceholderDialogWrapper() {
  const { closeDialog, openDialogType, visitInfoData, careProgramData, refetchCareProgramData } =
    useContext(VisitInfoTypeContext);
  const { setAlert } = useContext(AlertContext);
  const [localLoading, setLocalLoading] = useState(false);

  const updateVisit = async (cancel = false) => {
    const currentCareProgram = careProgramData.find(
      careProgram => careProgram.id === visitInfoData.careprogramId,
    );
    if (currentCareProgram) {
      setLocalLoading(true);

      let tempCareProgram = { ...currentCareProgram };

      if (cancel) {
        const newDateChange = { canceled: true };
        let dateChanges = {};
        if (tempCareProgram.dateChanges) {
          const slicedDate = visitInfoData.date.slice(0, 10);
          dateChanges = { ...tempCareProgram.dateChanges };
          if (slicedDate in dateChanges) {
            dateChanges[slicedDate] = { ...dateChanges[slicedDate], ...newDateChange };
          } else {
            dateChanges[slicedDate] = newDateChange;
          }
        }
        tempCareProgram.dateChanges = dateChanges;
        tempCareProgram.oneTimeChange = true;
      } else {
        const slicedDate = visitInfoData.date.slice(0, 10);
        let dateChanges = tempCareProgram.dateChanges[slicedDate];
        if (dateChanges) {
          const { canceled, ...restOfCurrentDateChanges } = dateChanges;
          if (Object.keys(restOfCurrentDateChanges).length === 0) {
            const { [slicedDate]: _, ...restOfTempCareProgramDateChanges } =
              tempCareProgram.dateChanges;
            tempCareProgram.dateChanges = restOfTempCareProgramDateChanges;
          } else {
            tempCareProgram.dateChanges[slicedDate] = restOfCurrentDateChanges;
          }
        }
      }

      const response = await api('PATCH', 'crud', 'careProgram', tempCareProgram);
      setLocalLoading(false);
      if (response.error) {
        setAlert({
          response,
          type: 'error',
        });
      } else {
        refetchCareProgramData();
        closeDialog();
      }
    }
  };

  return (
    <CareProgramVisitPlaceholderDialog
      cancelCallback={closeDialog}
      openDialog={openDialogType === visitInfoTypeDialogs.careProgramVisit}
      updateVisit={updateVisit}
      localLoading={localLoading}
      visitStatus={visitInfoData.status}
    >
      <Box sx={{ position: 'relative' }}>
        <CareProgramVisitPlaceholderDialogContent visitInfoData={visitInfoData} />
      </Box>
    </CareProgramVisitPlaceholderDialog>
  );
}

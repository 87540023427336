import { TabList } from '@mui/lab';
import { Box, Tab, Typography, Button } from '@mui/material';
import { array, func, object } from 'prop-types';
import React, { useContext } from 'react';
import {
  useDownloadClientReportMutation,
  useDownloadUtilizationReportMutation,
  useUpdateSeenClientsMutation,
} from '../../../../../src/api/Reporting/api';
import AlertContext from '../../../../components/Alert';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { ISO_DATE_ONLY_FORMAT } from '../../../../shared/constants';

export default function ReportingTitle({
  handleChange = () => {},
  reportingTabs = [],
  reportingWeekStartDate = moment(),
}) {
  const selectedDateFrom = reportingWeekStartDate.format(ISO_DATE_ONLY_FORMAT);
  const selectedDateTo = reportingWeekStartDate
    .clone()
    .endOf('isoweek')
    .format(ISO_DATE_ONLY_FORMAT);
  const { setAlert } = useContext(AlertContext);
  const [
    downloadClientReport,
    {isLoading: clientIsLoading },
  ] = useDownloadClientReportMutation();
  const [
    downloadUtilizationReport,
    {isLoading: utilIsLoading },
  ] = useDownloadUtilizationReportMutation();
  const [updateSeenClients, { isLoading: seenIsLoading }] =
    useUpdateSeenClientsMutation();

  const generateExcel = (data, fileName) => {
    const encodedCsvData = data;
    const csvData = atob(encodedCsvData);

    const worksheet = XLSX.utils.aoa_to_sheet(CSVToArray(csvData));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');

    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

    const blob = new Blob([s2ab(excelData)], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = `${fileName}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const processDownloadClientReport = () => {
    downloadClientReport()
      .then(response => {
        generateExcel(response.data.csvContent, 'caregiverClientReport');
      })
      .catch(error => {
        console.error('Error generating report:', error);
        const errorMessage = error?.data?.message || 'An unexpected error occurred.';
        setAlert({
          message: errorMessage,
          type: 'error',
        });
      });
  };

  const processDownloadUtilizationReport = () => {
    downloadUtilizationReport({from: selectedDateFrom, to: selectedDateTo})
      .then(response => {
        generateExcel(response.data.csvContent, 'caregiverUtilizationReport');
      })
      .catch(error => {
        console.error('Error generating report:', error);
        const errorMessage = error?.data?.message || 'An unexpected error occurred.';
        setAlert({
          message: errorMessage,
          type: 'error',
        });
      });
  };

  const processUpdateSeenClients = () => {
    updateSeenClients()
      .then(response => {})
      .catch(error => {
        console.error('Error updating seen clients:', error);
        const errorMessage = error?.data?.message || 'An unexpected error occurred.';
        setAlert({
          message: errorMessage,
          type: 'error',
        });
      });
  };

  const s2ab = s => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const CSVToArray = csv => {
    const lines = csv.split('\n');
    return lines.map(line => line.split(','));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        mb: '32px',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Typography variant="h1">Reporting</Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={processUpdateSeenClients}
          sx={{ ml: '28px' }}
          disableElevation
          disabled={seenIsLoading || clientIsLoading || utilIsLoading}
        >
          {seenIsLoading ? 'UPDATING...' : 'UPDATE SEEN CLIENTS'}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={processDownloadClientReport}
          sx={{ ml: '28px' }}
          disableElevation
          disabled={seenIsLoading || clientIsLoading || utilIsLoading}
        >
          {clientIsLoading ? 'Downloading...' : 'CLIENT REPORT'}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={processDownloadUtilizationReport}
          sx={{ ml: '28px' }}
          disableElevation
          disabled={seenIsLoading || clientIsLoading || utilIsLoading}
        >
          {utilIsLoading ? 'Downloading...' : 'UTILIZATION REPORT'}
        </Button>
      </Box>

      <TabList
        onChange={handleChange}
        sx={{ display: 'flex', '& .MuiTabs-flexContainer': { gap: '24px' } }}
      >
        {reportingTabs.map(({ key, title }) => (
          <Tab disableRipple key={`${title}-${key}`} label={title} value={key} />
        ))}
      </TabList>
    </Box>
  );
}

ReportingTitle.propTypes = {
  handleChange: func,
  reportingTabs: array,
  reportingWeekStartDate: object,
};

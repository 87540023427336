import { COLORS } from '../../../../shared/styles/Theme';

export const disabledButtonStyles = {
  '&.Mui-disabled': {
    backgroundColor: 'white',
    color: COLORS.blue[500],
    border: '1px solid rgba(84, 104, 125, 0.5)',
    opacity: 0.6,
  },
};

export const titleHeaderStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '12px',
  borderBottom: `1px solid ${COLORS.blue[20]}`,
};

export const titleWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '& img': { width: '20px', height: '20px' },
};

export const closeButtonStyles = {
  marginRight: '-8px',
  marginTop: '-8px',
  width: '30px',
  height: '30px',
};

export const styledCancelButton = {
  backgroundColor: '#ff402e',
  borderColor: '#ff402e',
  color: '#FFFFFF',
  '&:hover, &:active, &:focus ': {
    backgroundColor: '#ff402e',
    borderColor: '#ff402e',
    color: '#FFFFFF',
    opacity: 0.8,
  },
};

import { Box } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import OptimizerCheck from '../../../../../../../../components/Optimizer/OptimizerCheck';
import moment from 'moment';
import AlertContext from '../../../../../../../../components/Alert';
import { api } from '../../../../../../../../api';
import { v4 as uuidv4 } from 'uuid';

function CheckAvailability({
  setStepDataLoading = () => {},
  submitStep = () => {},
  resetToInitialView = () => {},
  handleCancelFlow = () => {},
  selectedTimeSlots = {},
  activeDates = [],
  visitParams = {},
  getCalendarData = () => {},
  setManualLoading = () => {},
}) {
  const [openCheckOptimizer, setOpenCheckOptimizer] = useState(false);
  const [futureDayOptimizerVisits, setFutureDayOptimizerVisits] = useState([]);
  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    setStepDataLoading(true);
  }, [setStepDataLoading]);

  const saveVisit = async payload => {
    setManualLoading(true);
    const apiData = await api('POST', 'crud', 'visits', payload);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      setManualLoading(false);
      getCalendarData();
    }
  };

  useEffect(() => {
    let visits = [];
    for (const activeDate of activeDates) {
      const trimmedDate = activeDate.substring(0, 10);
      const dateArrivalStart = moment(
        `${trimmedDate} ${selectedTimeSlots?.arrivalStart}`,
        'YYYY-MM-DD HH:mm A',
      ).format('YYYY-MM-DDTHH:mm:ss');
      const dateArrivalEnd = moment(
        `${trimmedDate} ${selectedTimeSlots?.arrivalEnd}`,
        'YYYY-MM-DD HH:mm A',
      ).format('YYYY-MM-DDTHH:mm:ss');

      visits.push({
        ...visitParams,
        id: uuidv4(),
        date: activeDate,
        arrivalStart: dateArrivalStart,
        arrivalEnd: dateArrivalEnd,
        arrivalTime: dateArrivalStart,
      });
    }

    const futureVisits = visits.filter(visit => moment(visit?.date).isAfter(moment(), 'day'));
    const sameDayVisits = visits.filter(visit => moment(visit?.date).isSame(moment(), 'day'));

    if (futureVisits.length > 0) {
      setFutureDayOptimizerVisits(futureVisits);
      setOpenCheckOptimizer(true);
    }
    if (sameDayVisits.length > 0) {
      saveVisit(sameDayVisits);
      if (futureVisits.length === 0) {
        submitStep();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitFunction = data => {
    resetToInitialView();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <OptimizerCheck
        data={{
          added: futureDayOptimizerVisits,
          edited: [],
          deleted: [],
        }}
        dataObjName="visitObj"
        submitFunction={submitFunction}
        cancelFunction={handleCancelFlow}
        open={openCheckOptimizer}
        openToggle={setOpenCheckOptimizer}
        returnAPIData={true}
        cancelConfirmation={true}
      />
    </Box>
  );
}

CheckAvailability.propTypes = {
  setStepDataLoading: func,
  submitStep: func,
  resetToInitialView: func,
  handleCancelFlow: func,
  selectedTimeSlots: instanceOf(Object),
  activeDates: instanceOf(Array),
  visitParams: instanceOf(Object),
  getCalendarData: func,
  setManualLoading: func,
};

export default React.memo(CheckAvailability);

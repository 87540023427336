import { COLORS } from '../../../../../../../../shared/styles/Theme';

export const customAccordionStyles = {
  backgroundColor: COLORS.blue[50],
  '&:before': {
    display: 'none',
  },
  padding: '0 32px',
  margin: '0 0 24px 0',
  '&.MuiPaper-root.MuiAccordion-root': {
    borderRadius: '10px',
  },
  '&.Mui-expanded': {
    margin: '0 0 24px 0',
  },
};

export const customAccordionSummaryStyles = {
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  margin: '12px 0',
  cursor: 'default',
  '&.MuiAccordionSummary-root, &.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: '32px',
  },
  '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
    justifyContent: 'space-between',
    margin: 0,
    paddingRight: '5px',
    cursor: 'default',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
  },
};

export const visitAccordionSummaryTitle = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  cursor: 'pointer',
};

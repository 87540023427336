import { Box, Typography } from '@mui/material';
import { bool, instanceOf, string } from 'prop-types';
import React from 'react';
import { detailsInfoKeysStyles } from '../styles';
import AdditionalInfoAndStatuses from './AdditionalInfoAndStatuses';
import CaregiverInfo from './CaregiverInfo';
import DetailsWrapper from './DetailsWrapper';
import NotAllocatedHeaderComponent from './NotAllocatedHeaderComponent';
import { calculateHourColor, calculateRateColor, getHourString } from '../../utils';
import { sortBy } from 'lodash';

function CustomHeader({
  caregiver = {},
  editMode = false,
  isDateHoliday = {},
  selectedDate = {},
  resourceStatistics = {},
  minHeight = '',
  minExpandedHeight = '',
}) {
  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      {caregiver.title === 'Not Allocated Visits' ? (
        <NotAllocatedHeaderComponent
          caregiver={caregiver}
          editMode={editMode}
          minHeight={minHeight}
        />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            className="CaregiversBox"
            sx={{
              display: 'flex',
              padding: '10px 10px 10px 10px',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <CaregiverInfo caregiver={caregiver} editMode={editMode} />
              <AdditionalInfoAndStatuses
                caregiver={caregiver}
                editMode={editMode}
                isDateHoliday={isDateHoliday}
                selectedDate={selectedDate}
                totalVisits={resourceStatistics?.totalVisits}
              />
            </Box>
            <Box
              className="collapsedHeader"
              sx={{
                ml: '6px',
                mt: '10px',
                minHeight: `${Number(minHeight.split('p')[0]) - 51}px`,
              }}
            >
              <Box sx={{ display: 'flex', mb: '2px' }}>
                <Typography sx={{ ...detailsInfoKeysStyles }}>Primary Teams:</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  {caregiver.primaryTeamsDetails?.length ? (
                    sortBy(caregiver.primaryTeamsDetails ?? [], ['name'])?.map(teamDetail => (
                      <Typography sx={{ fontSize: '12px' }}>{teamDetail?.name}</Typography>
                    ))
                  ) : (
                    <Typography sx={{ fontSize: '12px', fontStyle: 'italic' }}>No Team</Typography>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', mb: '2px' }}>
                <Typography sx={{ ...detailsInfoKeysStyles }}>Scheduled Hours:</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: calculateHourColor(
                        resourceStatistics?.hoursScheduled,
                        resourceStatistics?.minutesScheduled,
                        480,
                        480,
                      ),
                    }}
                  >
                    {getHourString(
                      resourceStatistics?.hoursScheduled,
                      resourceStatistics?.minutesScheduled,
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', mb: '2px' }}>
                <Typography sx={{ ...detailsInfoKeysStyles }}>Billable Hours:</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: calculateHourColor(
                        resourceStatistics?.hoursBooked,
                        resourceStatistics?.minutesBooked,
                        resourceStatistics?.scheduledTotalMinutes * 0.7,
                        resourceStatistics?.scheduledTotalMinutes * 0.5,
                      ),
                    }}
                  >
                    {getHourString(
                      resourceStatistics?.hoursBooked,
                      resourceStatistics?.minutesBooked,
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', mb: '2px' }}>
                <Typography sx={{ ...detailsInfoKeysStyles }}> Utilization %: </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: calculateRateColor(resourceStatistics?.billableRate, 60, 75),
                  }}
                >
                  {resourceStatistics?.billableRate}%
                </Typography>
              </Box>
            </Box>
          </Box>
          <DetailsWrapper
            resourceStatistics={resourceStatistics}
            caregiver={caregiver}
            minExpandedHeight={minExpandedHeight}
          />
        </Box>
      )}
    </Box>
  );
}
export default CustomHeader;

CustomHeader.propTypes = {
  caregiver: instanceOf(Object),
  isDateHoliday: instanceOf(Object),
  selectedDate: instanceOf(Object),
  editMode: bool,
  resourceStatistics: instanceOf(Object),
  minHeight: string,
  minExpandedHeight: string,
};

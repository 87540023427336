export const createOneTimeVisitInitialData = {
  arrivalStart: null,
  arrivalEnd: null,
  isExactTime: false,
  date: null,
  duration: null,
  visitType: {},
  status: 'NotAllocated',
  violatedHardConstraints: [],
  violatedSoftConstraints: [],
  arrivalWindowDuration: 60,
  caregiverId: 'NoCaregiver',
};

export const createOneTimeVisitSteps = ['Visit Parameters', 'Select Availability', 'Optimize'];

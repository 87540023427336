import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { func, instanceOf, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TASKS_TYPES, tasksTypeButtons } from '../../../../../../../shared/constants';
import { getNoData } from '../../../../../../../shared/utils/common';
import ButtonsGroup from '../../../../../../../components/ButtonsGroup';
import CustomTable from '../../../../../../../components/Table';
import { getTasksColumns } from './constants';
import {
  customAccordionStyles,
  customAccordionSummaryStyles,
  tasksManagementTableStyles,
  visitAccordionDetailsContainer,
  visitAccordionSummaryTitle,
  visitTasksManagementWrapper,
} from './styles';
import { COLORS } from '../../../../../../../shared/styles/Theme';
import { v4 as uuidv4 } from 'uuid';

function VisitTasksManagement({
  showedTasksType = '',
  setShowedTasksType = () => {},
  showedTasks = [],
  setShowedTasks = () => {},
  titleInsert = '',
  temporaryVisitData = {},
  configData = {},
  titleColor = '',
}) {
  const apiRef = useGridApiRef();
  const [sortOption, setSortOption] = useState({ field: 'priority', sort: 'asc' });
  const [taskAccordionExpanded, setTaskAccordionExpanded] = useState(false);

  useEffect(() => {
    if (temporaryVisitData?.tasks?.length) {
      if (configData?.categoryTypes) {
        if (showedTasksType === TASKS_TYPES.careProgramTasks) {
          setShowedTasks(
            temporaryVisitData.tasks
              .filter(task => task.careplanId?.length > 0)
              .map(task => {
                const taskDetails = temporaryVisitData?.taskDetails?.find(
                  taskDetails => taskDetails?.id === task.careplanId,
                );
                const linkedCategory = configData.categoryTypes.find(
                  categoryType =>
                    categoryType.id === task.categoryTypes ||
                    categoryType.id === taskDetails?.categoryTypes,
                );
                return {
                  ...taskDetails,
                  ...task,
                  categoryName: linkedCategory ? linkedCategory?.name : 'Unknown Category',
                };
              }),
          );
        } else if (showedTasksType === TASKS_TYPES.visitTasks) {
          setShowedTasks(
            temporaryVisitData.tasks
              .filter(task => !task.careplanId)
              .map(task => {
                const linkedCategory = configData.categoryTypes.find(
                  categoryType => categoryType.id === task.categoryTypes,
                );
                return {
                  ...task,
                  categoryName: linkedCategory ? linkedCategory?.name : 'Unknown Category',
                };
              }),
          );
        }
      }
    } else {
      setShowedTasks([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData, showedTasksType, temporaryVisitData]);

  const updateSorting = useCallback(item => setSortOption(item[0]), []);

  const tasksColumns = useMemo(() => getTasksColumns(showedTasksType), [showedTasksType]);

  const tableToDisplay = useMemo(() => {
    return (
      <CustomTable
        key={uuidv4()}
        apiRef={apiRef}
        columns={tasksColumns}
        customStyles={tasksManagementTableStyles}
        headerHeight={34}
        noRowsOverlay={() => getNoData({ title: 'No tasks yet' })}
        onRowClick={() => {}}
        rows={showedTasks || []}
        withoutPagination
        setSortModelState={updateSorting}
        sortModel={[sortOption]}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, showedTasks, sortOption, tasksColumns]);

  return (
    <Accordion elevation={0} expanded={taskAccordionExpanded} sx={customAccordionStyles}>
      <AccordionSummary
        onClick={() => setTaskAccordionExpanded(prev => !prev)}
        aria-controls="panel1bh-content"
        sx={customAccordionSummaryStyles}
      >
        <Box sx={visitAccordionSummaryTitle}>
          <Typography
            variant="body1"
            sx={{
              color: titleColor ? titleColor : COLORS.blue[700],
              fontWeight: '600',
              fontSize: '14px',
              cursor: 'pointer',
            }}
          >
            {taskAccordionExpanded ? 'Hide' : 'View'} {titleInsert}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={visitAccordionDetailsContainer}>
        <Box sx={visitTasksManagementWrapper}>
          <ButtonsGroup
            buttons={tasksTypeButtons}
            changeSelected={value => setShowedTasksType(value)}
            selected={showedTasksType}
          />
          {tableToDisplay}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

VisitTasksManagement.propTypes = {
  showedTasksType: string,
  setShowedTasksType: func,
  showedTasks: instanceOf(Array),
  setShowedTasks: func,
  titleInsert: string,
  temporaryVisitData: instanceOf(Object),
  configData: instanceOf(Object),
  titleColor: string,
};

export default React.memo(VisitTasksManagement);

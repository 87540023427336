/* eslint-disable react/prop-types */
import { Alert, AlertTitle, Box, Grow } from '@mui/material';
import {
  useCheckDragAndDropPossibilityMutation,
  useUpdateSchedulerMutation,
} from '../../../../api/Scheduler/api';
import moment from 'moment-timezone';
import Header from '../../../../pages/Scheduling/components/EditCalendar/Header';
import {
  dragAndDropSuccessAlertStyles,
  editContentWrapper,
} from '../../../../pages/Scheduling/components/styles';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import AlertContext from '../../../../components/Alert';
import DnDCalendars from '../../../../components/DndCalendar/DndCalendar';
import FullWidthDialog from '../../../../components/FullWidthDialog';
import Loader from '../../../../components/Loader';
import { LOCAL_STORAGE_KEYS, addArrivalEvents } from '../../constants';
import { DragAnnDropContext } from '../../context';
import '../../index.css';
import { calculateCalendarVerticalScrollPosition, getDndEventBoxClass } from '../../utils';
import CustomNavigation from '../CustomNavigation';
import DragAndDropEventComponent from '../DragAndDropEventComponent';
import RescheduleModalBackend from '../RescheduleModalWindows/RescheduleModalBackend';
import TaskChangesModal from '../TaskChangesModalWindows/TaskChangesModals';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { api } from '../../../../api';

const MemoizedDnDCalendars = React.memo(DnDCalendars);
function EditScheduling({
  currentDate,
  dayPropGetter,
  eventsList,
  filteredCaregivers,
  firstVisit,
  isDateHoliday,
  lastVisit,
  notAllocatedEvents,
  onNavigate,
  resourceMap,
  setEditMode,
  setTeamsFiler,
  showDetails,
  slotPropGetter,
  teamsFilter,
  todayDate,
  minHeight,
}) {
  const { setAlert } = useContext(AlertContext);
  const { reFetchCalendarEvents } = useContext(VisitInfoTypeContext);
  const eventWrapperRef = useRef();
  const savedScrollPosition = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.scrollPosition));
  const [draggedEvent, setDraggedEvent] = useState({});
  const [open, setOpen] = useState(false);
  const [updateContainer, setUpdateContainer] = useState(0);
  const [carePlanChanged, setIsCarePlanChanged] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [editModeEvents, setEditModeEvents] = useState([]);
  const [updatedVisitData, setUpdatedVisitData] = useState([]);
  const [savedNewVisits, setSavedNewVisits] = useState(true);
  const [savedExistingVisits, setSavedExistingVisits] = useState(true);
  const [deletedVisits, setDeletedVisits] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [
    checkDragAndDropPossibility,
    { data: checkPossibilityData, isLoading, error: checkDragAndDropPossibilityError },
  ] = useCheckDragAndDropPossibilityMutation();
  const [updateCalendarData, { error: updateSchedulerError, isLoading: isUpdateCalendarLoading }] =
    useUpdateSchedulerMutation();

  useEffect(() => {
    if (updateSchedulerError || checkDragAndDropPossibilityError) {
      const errorData = updateSchedulerError || checkDragAndDropPossibilityError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [updateSchedulerError, checkDragAndDropPossibilityError, setAlert]);

  useEffect(() => {
    const timeHeader = document.querySelector('.rbc-time-header');

    if (timeHeader) {
      timeHeader?.classList.remove('custom-time-header-scheduling');
      timeHeader.style.minHeight = minHeight;
    }
  }, [currentDate, editModeEvents, dayPropGetter, onNavigate, minHeight]);

  const updateStorageScrollPosition = useCallback(timeContent => {
    const scrollPosition = {
      top: timeContent.scrollTop.toFixed(2),
      left: timeContent.scrollLeft.toFixed(2),
    };

    localStorage.setItem(LOCAL_STORAGE_KEYS.scrollPosition, JSON.stringify(scrollPosition));
  }, []);

  useEffect(() => {
    const timeContentEdit = document?.querySelector('.edit-scheduling-calendar  .rbc-time-content');

    if (timeContentEdit && savedScrollPosition) {
      timeContentEdit.scrollLeft = savedScrollPosition.left;
    }

    timeContentEdit?.addEventListener('scroll', () => updateStorageScrollPosition(timeContentEdit));
    return () => {
      timeContentEdit?.removeEventListener('scroll', () =>
        updateStorageScrollPosition(timeContentEdit),
      );
    };
  }, [updateStorageScrollPosition, savedScrollPosition]);

  const getEditModeEvents = useCallback(() => {
    if (!eventsList) return [];
    const eventsWithoutArrival = eventsList?.filter(event => event?.title !== 'arrival');

    return addArrivalEvents({
      eventsList: [...eventsWithoutArrival],
      filteredCaregivers,
      currentDate,
    });
  }, [eventsList, filteredCaregivers, currentDate]);

  useEffect(() => {
    setTimeout(() => {
      const nonDraggableEvents = document?.querySelectorAll('.non-draggable');
      if (nonDraggableEvents.length > 0) {
        nonDraggableEvents.forEach(element => {
          element.style.setProperty('opacity', '0.9', 'important');
        });
      }
    });
    setEditModeEvents(getEditModeEvents());
  }, [getEditModeEvents]);

  useEffect(() => {
    setTimeout(() => {
      const selector = `.rbc-event.dnd-event.arrival${draggedEvent.event?.eventId}`;
      const selectorArrivalTime = `.rbc-event.dnd-event.arrivalTime${draggedEvent?.event?.eventId}`;
      const allArrivalTime = document?.querySelectorAll(`.rbc-event.dnd-event.arrivalTime`);
      const teamClassElements = document?.querySelectorAll(selector);
      const allArrivalElements = document?.querySelectorAll('.arrival');
      const arrivalTimeElement = document?.querySelector(selectorArrivalTime);
      const draggingEvent = document.querySelector('.rbc-addons-dnd-dragged-event');
      draggingEvent?.classList?.toggle('is-dragging', draggedEvent?.event);
      if (Object.keys(draggedEvent).length > 0) {
        allArrivalElements?.forEach(element => {
          element.style.setProperty('display', 'none', 'important');
        });
        arrivalTimeElement?.style?.setProperty('display', 'none', 'important');

        teamClassElements?.forEach(element => {
          element.style.setProperty('display', 'block', 'important');
          element.style.setProperty('left', '0%', 'important');
          element.style.setProperty(
            'background',
            `linear-gradient(
            0deg,
            rgba(43, 119, 255, 0.2),
            rgba(43, 119, 255, 0.2)
          )`,
            'important',
          );
        });
        arrivalTimeElement?.style.setProperty('display', 'block', 'important');
        arrivalTimeElement?.style.setProperty('width', '95%', 'important');
      }
      if (Object.keys(draggedEvent).length === 0) {
        allArrivalElements?.forEach(element => {
          element.style.setProperty('display', 'none', 'important');
        });
        allArrivalTime?.forEach(element => {
          element.style.setProperty('display', 'none', 'important');
        });
      }
    });
  }, [draggedEvent, updateContainer]);

  useEffect(() => {
    if (!draggedEvent?.event) {
      setTimeout(() => {
        const targetNodes = document.querySelectorAll('.rbc-events-container');

        const observers = Array.from(targetNodes)?.map(node => {
          const observer = new MutationObserver(() => {
            setUpdateContainer(prevCounter => prevCounter + 1);
          });

          const config = { attributes: true, childList: true, subtree: true };

          observer.observe(node, config);

          return observer;
        });

        return () => {
          observers.forEach(observer => observer.disconnect());
        };
      });
    }
  }, [draggedEvent]);

  const handleMouseOver = useCallback(
    event => {
      const eventWrapper = eventWrapperRef.current;
      const timeContentEdit = document?.querySelector('.rbc-time-content');
      if (!eventWrapper || !timeContentEdit || !draggedEvent?.event) return;
      const mouseY = event.clientY;
      const mouseX = event.clientX;
      const scrollTopThreshold = 300;
      const scrollBottomThreshold = timeContentEdit.scrollHeight - 1150;
      const scrollLeftThreshold = 200;
      const scrollRightThreshold = timeContentEdit.clientWidth - 100;

      if (mouseX < scrollLeftThreshold) {
        timeContentEdit.scrollLeft -= 30;
      }
      if (mouseX > scrollRightThreshold) {
        timeContentEdit.scrollLeft += 30;
      }

      if (mouseY < scrollTopThreshold) {
        timeContentEdit.scrollTop -= 50;
      }
      if (mouseY > scrollBottomThreshold) {
        timeContentEdit.scrollTop += 50;
      }
    },
    [draggedEvent],
  );

  const onEventSelect = useCallback(event => {
    setDraggedEvent(event);
    const timeContentEdit = document?.querySelector('.rbc-time-content');
    const scrollPosition = {
      top: timeContentEdit.scrollTop.toFixed(2),
      left: timeContentEdit.scrollLeft.toFixed(2),
    };
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.temporaryScrollPosition,
      JSON.stringify(scrollPosition),
    );
  }, []);

  const splitPayloads = payloads => {
    const listOfPayloads = [];
    let numberOfVisits = 0;
    let numberOfPayloads = 0;
    for (let visit in payloads) {
      if (numberOfVisits === 0) {
        listOfPayloads.push([]);
      }
      if (numberOfVisits === 50) {
        numberOfVisits = 0;
        numberOfPayloads++;
        listOfPayloads.push([]);
      }
      listOfPayloads[numberOfPayloads].push(payloads[visit]);
      numberOfVisits++;
    }
    return listOfPayloads;
  };

  const handleVisitsAndLunchBreaksPresentMessage = events => {
    const lunchBreaksPresent =
      events.filter(event => event?.status?.toLowerCase() === 'lunch break')?.length > 0;
    const visitsPresent =
      events.filter(event => event?.status?.toLowerCase() !== 'lunch break')?.length > 0;
    return lunchBreaksPresent && visitsPresent
      ? 'Visits and Lunch Breaks'
      : lunchBreaksPresent
      ? 'Lunch Breaks'
      : 'Visits';
  };

  const addVisits = async addedVisits => {
    const messageEntity = handleVisitsAndLunchBreaksPresentMessage(addedVisits);
    const listOfPayloads = splitPayloads(addedVisits);
    let returnedData = [];
    for (let payload in listOfPayloads) {
      const apiData = await api('POST', 'crud', 'visits', listOfPayloads[payload]);
      if (apiData.error) {
        setAlert({
          apiData,
          type: 'error',
        });
      } else {
        returnedData = [...returnedData, ...apiData.data];
        setAlert({
          message: `${messageEntity} saved successfully`,
          type: 'success',
        });
      }
    }
    setSavedNewVisits(true);
    return returnedData;
  };

  const updateVisits = async editedVisits => {
    const messageEntity = handleVisitsAndLunchBreaksPresentMessage(editedVisits);
    const listOfPayloads = splitPayloads(editedVisits);
    let returnedData = [];
    for (let payload in listOfPayloads) {
      const apiData = await api('PATCH', 'crud', 'visits', listOfPayloads[payload]);
      if (apiData.error) {
        setAlert({
          apiData,
          type: 'error',
        });
      } else {
        returnedData = [...returnedData, ...apiData.data];
        setAlert({
          message: `${messageEntity} updated successfully`,
          type: 'success',
        });
      }
    }
    setSavedExistingVisits(true);
    return returnedData;
  };

  const deleteVisits = async deletedVisits => {
    const messageEntity = handleVisitsAndLunchBreaksPresentMessage(deletedVisits);
    const listOfPayloads = splitPayloads(deletedVisits);
    for (let payload in listOfPayloads) {
      const apiData = await api('DELETE', 'crud', 'visits', listOfPayloads[payload]);
      if (apiData.error) {
        setAlert({
          apiData,
          type: 'error',
        });
      } else {
        setAlert({
          message: `${messageEntity} deleted successfully`,
          type: 'success',
        });
      }
    }
    setDeletedVisits(true);
  };

  useEffect(() => {
    if (savedNewVisits && savedExistingVisits && deletedVisits) {
      setOpen(false);
      setIsSaving(false);
      reFetchCalendarEvents();
    }
    // eslint-disable-next-line
  }, [deletedVisits, savedExistingVisits, savedNewVisits]);

  const submitCalendarData = (
    newTravelTime,
    violatedHardConstraints,
    violatedSoftConstraints,
    deletedVisits,
    addedVisits,
    editedVisits,
  ) => {
    setIsSaving(true);
    const tempData = {};
    tempData.address = updatedVisitData.address;
    tempData.arrivalStart = updatedVisitData.arrivalStart;
    tempData.arrivalEnd = updatedVisitData.arrivalEnd;
    tempData.clientId = updatedVisitData.clientId;
    tempData.date = updatedVisitData.date;
    tempData.caregiverId = updatedVisitData.caregiverId;
    tempData.id = updatedVisitData.careprogramId;
    tempData.arrivalTime = updatedVisitData.arrivalTime;
    tempData.careprogramId = updatedVisitData.careprogramId;
    tempData.createdBy = updatedVisitData.createdBy;
    tempData.duration = updatedVisitData.duration;
    tempData.paddingTime = updatedVisitData.paddingTime;
    tempData.roadTimeInMinutes = newTravelTime;
    tempData.tasks = updatedVisitData.tasks;
    tempData.team = updatedVisitData.team;
    tempData.territory = updatedVisitData.territory;
    tempData.version = updatedVisitData.version;
    tempData.active = updatedVisitData.active;
    tempData.visitType = updatedVisitData.visitType;
    tempData.id = updatedVisitData.id;
    tempData.status = updatedVisitData.status === 'Lunch Break' ? 'Lunch Break' : 'Scheduled';
    tempData.violatedHardConstraints = violatedHardConstraints;
    tempData.violatedSoftConstraints = violatedSoftConstraints;
    tempData.shift = updatedVisitData.shift;
    const isTempDataInEditedVisits = editedVisits.find(
      visit => visit.careprogramId === tempData.careprogramId,
    );
    let newEditedVisits = editedVisits;
    if (!isTempDataInEditedVisits) {
      newEditedVisits = [...editedVisits, tempData];
    }
    if (deletedVisits.length > 0) {
      setDeletedVisits(false);
      deleteVisits(deletedVisits);
    }
    if (addedVisits.length > 0) {
      setSavedNewVisits(false);
      addVisits(addedVisits);
    }
    if (newEditedVisits.length > 0) {
      setSavedExistingVisits(false);
      updateVisits(newEditedVisits);
    }
    setIsCarePlanChanged(checkPossibilityData?.carePlanChange);
  };

  const openMovingSubmitDialog = useCallback(
    checkRequestData => {
      setUpdatedVisitData(checkRequestData);
      checkDragAndDropPossibility({
        caregiverId: checkRequestData.caregiverId,
        visit: checkRequestData,
        action: 'visitCheck',
      });
      setOpen(true);
      setDraggedEvent({});
    },
    [checkDragAndDropPossibility],
  );

  const moveEvent = useCallback(
    ({ event, start, end, resourceId }) => {
      const eventIndex = editModeEvents.findIndex(ev => ev.id === event.id);
      const formattedStartDate = moment(start).format('YYYY-MM-DD');
      const formattedDateNow = moment().format('YYYY-MM-DD');
      if (moment(formattedStartDate).isBefore(moment(formattedDateNow), 'date')) {
        setEditModeEvents(getEditModeEvents());
        return;
      }
      const resource = resourceMap.find(resource => resource.resourceId === resourceId);
      let targetCaregiver = null;
      if (resource) {
        targetCaregiver = resource?.resourceTitle?.props?.caregiver;
      }

      const newStart = moment(start).toDate();
      const newEnd = moment(end).toDate();
      const newArrivalStart = moment(start)
        .subtract(event.timeDifferenceBetweenStartAndArrivalStart, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss');

      let updatedEvent = {};
      if (!targetCaregiver || targetCaregiver?.id === 'NoCaregiver') {
        updatedEvent = {
          ...event,
          caregiverId: 'NoCaregiver',
          resourceId: 'NoCaregiver',
          sourceResource: 'NoCaregiver',
          start: newStart,
          end: newEnd,
          arrivalTime: newArrivalStart,
          status: 'NotAllocated',
        };
      } else {
        updatedEvent = {
          ...event,
          caregiverId: targetCaregiver?.id,
          caregiverName: [targetCaregiver?.firstName, targetCaregiver?.lastName]
            .filter(x => x && x.length > 0)
            .join(' '),
          resourceId: targetCaregiver?.id,
          sourceResource: targetCaregiver?.id,
          start: newStart,
          end: newEnd,
          arrivalTime: newArrivalStart,
          status: event.status === 'Lunch Break' ? 'Lunch Break' : 'Scheduled',
        };
      }

      setEditModeEvents(prevEvents => {
        const updatedEvents = [...prevEvents];
        updatedEvents[eventIndex] = updatedEvent;
        return updatedEvents;
      });
      openMovingSubmitDialog(updatedEvent);
    },
    [editModeEvents, getEditModeEvents, openMovingSubmitDialog, resourceMap],
  );

  const minStartTime = moment().subtract(30, 'minute').toDate();
  const minUTCStartTime = moment(minStartTime).utc().toDate();
  const customToolbarComponent = props => <CustomNavigation isEdit {...props} />;
  const calendarComponent = useMemo(
    () => (
      <MemoizedDnDCalendars
        className="edit-scheduling-calendar"
        customToolbar={customToolbarComponent}
        date={currentDate}
        dayPropGetter={dayPropGetter}
        eventComponent={DragAndDropEventComponent}
        eventDrop={moveEvent}
        eventStyleGetter={getDndEventBoxClass}
        events={editModeEvents}
        firstVisit={firstVisit}
        isDateHoliday={isDateHoliday}
        lastVisit={lastVisit}
        onDragStart={onEventSelect}
        onNavigate={onNavigate}
        resources={resourceMap}
        scrollTime={
          currentDate === null || !savedScrollPosition
            ? minUTCStartTime
            : calculateCalendarVerticalScrollPosition({
                currentDate,
                firstVisit,
                top: savedScrollPosition.top,
              })
        }
        slotPropGetter={slotPropGetter}
        toolbar
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentDate, editModeEvents, dayPropGetter, onEventSelect, moveEvent, onNavigate],
  );

  const closeEventMovingConfirmation = useCallback(() => {
    setOpen(false);
    const temporaryScrollPosition = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.temporaryScrollPosition),
    );
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.scrollPosition,
      JSON.stringify(temporaryScrollPosition),
    );
    setEditModeEvents(getEditModeEvents());
  }, [getEditModeEvents]);

  const handleMouseUp = useCallback(() => {
    if (draggedEvent.event) {
      setDraggedEvent({});
    }
  }, [draggedEvent]);
  const closeEditMode = () => {
    setEditMode(false);
    setDraggedEvent({});
  };
  const memoizedProviderValue = useMemo(
    () => ({
      draggedEvent,
    }),
    [draggedEvent],
  );

  return (
    <DragAnnDropContext.Provider value={memoizedProviderValue}>
      <FullWidthDialog
        cancelButtonName=""
        cancelCallback={() => {}}
        submitButtonName=""
        submitCallback={() => {}}
        title="Edit Mode"
        openDialog
        hideBackButton
        hideSubmitButtons
      >
        <Box sx={editContentWrapper}>
          {isUpdateCalendarLoading && <Loader />}
          <Grow in={showSuccessAlert}>
            <Alert sx={dragAndDropSuccessAlertStyles} severity="success">
              <AlertTitle>Success</AlertTitle>
              Successfully moved visit
            </Alert>
          </Grow>
          <Header
            closeEditMode={closeEditMode}
            isDateHoliday={!!isDateHoliday?.name}
            setTeamsFiler={setTeamsFiler}
            showDetails={showDetails}
            teamsFilter={teamsFilter}
          />
          <Box
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseOver}
            role="presentation"
            ref={eventWrapperRef}
            style={{ width: '100%', position: 'relative' }}
          >
            {calendarComponent}
          </Box>
          <RescheduleModalBackend
            checkPossibilityData={checkPossibilityData}
            closeDialog={closeEventMovingConfirmation}
            disabledSubmit={isLoading || isSaving}
            isLoading={isLoading || isSaving}
            openDialog={open}
            submitDialog={submitCalendarData}
            updatedVisitData={updatedVisitData}
            isSaving={isSaving}
          />
          <TaskChangesModal
            actualVisit={updatedVisitData}
            carePlanChanges={checkPossibilityData?.carePlanChanges}
            openDialog={carePlanChanged}
            setOpenDialog={setIsCarePlanChanged}
            updateVisitData={updateCalendarData}
            isUpdateCalendarLoading={isUpdateCalendarLoading}
          />
        </Box>
      </FullWidthDialog>
    </DragAnnDropContext.Provider>
  );
}

export default EditScheduling;

import moment from 'moment';
import {
  CreateVisitContext,
  EditCareProgramContext,
} from '../../../../../../../../pages/ClientDetails/context';
import { getCreateVisitConfirmButtonName } from '../../../../../../../../pages/ClientDetails/utils';
import {
  UPDATE_PROGRAM_POPUPS,
  createVisitSteps,
} from '../../../../../../../../pages/ClientDetails/views/CareProgram/constants';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { H12_TIME_FORMAT } from '../../../../../../../../shared/constants';
import CustomDialog from '../../../../../../../../components/Dialog';
import UnsavedChangesContent from '../../../../../../../../components/Dialog/UnsavedChangesContent';
import FullWidthDialog from '../../../../../../../../components/FullWidthDialog';
import { checkIsCreateVisitStepConfirmButtonDisabled, getNewVisitsList } from '../../utils';
import { CreateVisitMultistep } from './CreateVisitMultistep';

export default function CreateCareProgramVisitDialog() {
  const {
    editedVisit,
    effectiveDate,
    closeDialog,
    innerDialogType,
    setEditedVisitData,
    setUpdatedCareProgram,
    showProgramVisitsView,
    updatedCareProgram,
    clientId,
  } = useContext(EditCareProgramContext);

  const [step, setStep] = useState(0);
  const [stepDataLoading, setStepDataLoading] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [availabilityScheduling, setAvailabilityScheduling] = useState(null);
  const [alternativeArrivalWindows, setAlternativeArrivalWindows] = useState(null);
  const [availableVisitTasks, setAvailableVisitTasks] = useState([]);
  const [visitData, setVisitData] = useState(null);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState(null);

  useEffect(() => {
    if (editedVisit?.days && !editedVisit.arrivalWindowDuration) {
      const { arrivalStart, arrivalEnd } = editedVisit.days[Object.keys(editedVisit.days)[0]];
      const start = moment(arrivalStart, H12_TIME_FORMAT);
      const end = moment(arrivalEnd, H12_TIME_FORMAT);
      editedVisit.arrivalWindowDuration = moment.duration(end.diff(start)).as('minutes').toString();
      // editedVisit.effectiveDateStart = moment(editedVisit.effectiveDateStart, H12_TIME_FORMAT);
    }
    setVisitData(editedVisit);
  }, [editedVisit, effectiveDate]);

  const clearCreationData = useCallback(() => {
    setStep(0);
    setCompletedSteps([]);
    setAvailabilityScheduling(null);
    setSelectedTimeSlots(null);
    setAvailableVisitTasks([]);
    setEditedVisitData();
    setVisitData();
  }, [setEditedVisitData]);

  const goToNextStep = () => {
    if (step === createVisitSteps.length - 1) {
      const updatedVisitsList = getNewVisitsList({
        selectedTimeSlots,
        updatedCareProgram,
        visitData,
        effectiveDate,
        clientId,
      });
      setUpdatedCareProgram(updatedVisitsList);
      clearCreationData();
      showProgramVisitsView();
      return '';
    }
    if (!completedSteps.includes(step)) {
      setCompletedSteps([...completedSteps, step]);
    }
    setStep(prevState => prevState + 1);
    return '';
  };

  const clearSelectedTimeSlots = useCallback(() => setSelectedTimeSlots(null), []);

  const handleChangesAdded = useCallback(() => {
    const {
      arrivalWindowDuration,
      days,
      duration,
      genderPreference,
      skills,
      timeOfDays,
      visitType,
    } = visitData || {};
    const isChangesAdded =
      step > 0 ||
      !!arrivalWindowDuration ||
      !!days?.length ||
      !!duration ||
      !!genderPreference ||
      !!skills?.length ||
      !!timeOfDays?.length ||
      (visitType && !!Object.keys(visitType)?.length);
    return isChangesAdded;
  }, [step, visitData]);

  const closeVisitCreation = useCallback(() => {
    const isChangesAdded = handleChangesAdded();
    if (isChangesAdded) {
      setOpenConfirmationModal(true);
    } else {
      clearCreationData();
      closeDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeDialog, handleChangesAdded]);

  const submitCancelCreation = useCallback(() => {
    clearCreationData();
    closeDialog();
    setOpenConfirmationModal(false);
  }, [clearCreationData, closeDialog]);

  const changeSelectedSlots = useCallback(
    newSlot => {
      const tempSelectedTimeSlots = {};
      for (const day in visitData?.days) {
        tempSelectedTimeSlots[day] = newSlot;
      }
      setSelectedTimeSlots(tempSelectedTimeSlots);
    },
    [visitData],
  );

  const memoizedProviderValue = useMemo(
    () => ({
      alternativeArrivalWindows,
      availabilityScheduling,
      availableVisitTasks,
      changeSelectedSlots,
      clearSelectedTimeSlots,
      completedSteps,
      selectedTimeSlots,
      setAlternativeArrivalWindows,
      setAvailabilityScheduling,
      setAvailableVisitTasks,
      setCompletedSteps,
      setSelectedTimeSlots,
      setStep,
      setStepDataLoading,
      setVisitData,
      step,
      stepDataLoading,
      visitData,
      handleChangesAdded,
      submitCancelCreation,
    }),
    [
      alternativeArrivalWindows,
      availabilityScheduling,
      availableVisitTasks,
      changeSelectedSlots,
      clearSelectedTimeSlots,
      completedSteps,
      selectedTimeSlots,
      setAlternativeArrivalWindows,
      setAvailableVisitTasks,
      setStepDataLoading,
      step,
      stepDataLoading,
      visitData,
      handleChangesAdded,
      submitCancelCreation,
    ],
  );

  const isStepConfirmationDisabled = useMemo(
    () =>
      !visitData ||
      checkIsCreateVisitStepConfirmButtonDisabled({
        alternativeArrivalWindows,
        selectedTimeSlots,
        step,
        visitData,
      }) ||
      stepDataLoading,
    [alternativeArrivalWindows, selectedTimeSlots, step, stepDataLoading, visitData],
  );

  return (
    <CreateVisitContext.Provider value={memoizedProviderValue}>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenConfirmationModal(false)}
        submitButtonName="confirm"
        submitCallback={submitCancelCreation}
        title="Confirmation"
        openDialog={openConfirmationModal}
      >
        <UnsavedChangesContent />
      </CustomDialog>
      <FullWidthDialog
        backButtonCallback={closeVisitCreation}
        cancelButtonName="cancel"
        cancelCallback={closeVisitCreation}
        disabledSubmit={isStepConfirmationDisabled}
        scrollable={step !== 1}
        submitButtonName={getCreateVisitConfirmButtonName(step)}
        submitCallback={goToNextStep}
        title={editedVisit?.id ? 'Edit a Care Program Visit' : 'Add a Care Program Visit'}
        openDialog={innerDialogType === UPDATE_PROGRAM_POPUPS.addNewVisit}
      >
        <CreateVisitMultistep />
      </FullWidthDialog>
    </CreateVisitContext.Provider>
  );
}

import { Box, Chip, Typography } from '@mui/material';
import hardConstraintsWarning from '../../../../img/icons/error-icon.svg';
import warningIconYellow from '../../../../img/icons/warning-icon.svg';
import moment from 'moment';
import { DragAnnDropContext } from '../../../../pages/Scheduling/context';
import { instanceOf } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { COLORS } from '../../../../shared/styles/Theme';
import {
  checkIfShowArrivalWindow,
  violationTooltipComponent,
} from '../../../../shared/utils/common';
import { EventCardInfo } from '../../../../components/CalendarsEventComponent/components/SchedulingEventsComponents';
import {
  arrivalWindowLabelStyles,
  chipArrivalStartStyle,
  chipArrivalWindowStyle,
  showTeamStyles,
  violatedSoftConstraintsStyles,
  visitEventInfoWrapper,
} from '../../../../components/CalendarsEventComponent/styles';
import ArrivalWindow from './ArrivalWindow';
import Duration from './Duration';
import TravelTime from './TravelTime';
import { eventComponent } from './constant';
import { calendarCellHeightMultiplier } from '../../../../components/CalendarsEventComponent/constant';
import PTOEventComponent from '../../../../components/CalendarsEventComponent/components/PTOEventComponent/PTOEventComponent';
import {
  hardConstraintNotOnDemandReasons,
  hardConstraintReasons,
  softConstraintReasons,
} from '../../../Reporting/views/WeeklySchedulingReport/constants';

export default function DragAndDropEventComponent({ event = {} }) {
  const arrivalStart = moment(event.arrivalStart);
  const arrivalEnd = moment(event.arrivalEnd);
  const { draggedEvent } = useContext(DragAnnDropContext);
  const checkIfShowArrival = checkIfShowArrivalWindow(event?.status);
  const [hovered, setHovered] = useState(false);
  const [violatedHardConstraints, setViolatedHardConstraints] = useState([]);
  const [violatedSoftConstraints, setViolatedSoftConstraints] = useState([]);

  const arrivalWindowHeight =
    moment(arrivalEnd).diff(arrivalStart, 'minutes') * calendarCellHeightMultiplier;

  const arrivalWindowStartPosition =
    moment(event.start).diff(arrivalStart, 'minutes') * calendarCellHeightMultiplier;

  const arrivalEndPosition =
    moment(arrivalEnd).diff(event.start, 'minutes') * calendarCellHeightMultiplier - 10;

  const travelTime = event?.isShadowVisit
    ? Number(event?.shadowTravelTime)
    : Number(event?.roadTimeInMinutes);

  useEffect(() => {
    const tempViolatedSoftConstraints =
      event?.violatedSoftConstraints?.filter(
        constraint =>
          constraint?.length &&
          softConstraintReasons.filter(reason => constraint.toLowerCase().startsWith(reason))
            .length > 0,
      ) ?? [];

    const tempViolatedHardConstraints =
      event?.violatedHardConstraints?.filter(
        constraint =>
          constraint?.length &&
          [
            hardConstraintReasons,
            ...(event?.visitTypeDetails?.name !== 'On Demand'
              ? hardConstraintNotOnDemandReasons
              : []),
          ].filter(reason => constraint.toLowerCase().startsWith(reason)).length > 0,
      ) ?? [];

    setViolatedSoftConstraints(tempViolatedSoftConstraints);
    setViolatedHardConstraints(tempViolatedHardConstraints);
  }, [event]);

  return (
    <Box sx={{ height: '100%', overflow: 'hidden' }}>
      {event.title !== 'arrival' &&
      event.title !== 'Lunch break' &&
      event.title !== 'arrivalTime' &&
      event.type !== 'PTO' ? (
        <Box
          sx={{ height: '100%' }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {event?.travelTime > 0 && moment(event?.end).isAfter(moment()) && (
            <TravelTime travelTime={travelTime} />
          )}
          <Box sx={{ height: '100%' }} className="event-before-box">
            <Box sx={{ p: '5px 14px', height: '100%' }}>
              <Box sx={visitEventInfoWrapper}>
                <EventCardInfo
                  arrivalStart={moment(event.arrivalStart)}
                  arrivalEnd={moment(event.arrivalEnd)}
                  checkIfShowArrival={checkIfShowArrival}
                  eventData={event}
                />
              </Box>
            </Box>
            <Box
              sx={{
                ...showTeamStyles,
                background: hovered && event?.region?.color?.color,
                height: hovered ? '24px' : '3px',
              }}
            >
              {hovered && (
                <Typography
                  sx={{
                    color: event?.region?.color?.fontColor,
                  }}
                >
                  {event?.region?.name}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mt: '-20px',
              }}
            >
              {violatedHardConstraints.length > 0 && (
                <Box sx={violatedSoftConstraintsStyles}>
                  {violationTooltipComponent({
                    violations: violatedHardConstraints,
                    icon: hardConstraintsWarning,
                    status: event.status,
                    violationType: 'hard',
                  })}
                </Box>
              )}
              {violatedSoftConstraints.length > 0 && (
                <Box sx={violatedSoftConstraintsStyles}>
                  {violationTooltipComponent({
                    violations: violatedSoftConstraints,
                    icon: warningIconYellow,
                    status: event.status,
                    violationType: 'soft',
                  })}
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            {hovered && <Duration event={event} />}
            <Box>
              {!!event.arrivalTimeDuration &&
                hovered &&
                checkIfShowArrival &&
                !draggedEvent?.event && (
                  <ArrivalWindow
                    arrivalEnd={arrivalEnd}
                    arrivalStart={arrivalStart}
                    arrivalWindowStartPosition={arrivalWindowStartPosition}
                    arrivalEndPosition={arrivalEndPosition}
                  />
                )}
              {(hovered ||
                (draggedEvent?.event &&
                  document.querySelector('.rbc-addons-dnd-dragged-event') &&
                  event.eventId === draggedEvent?.event?.eventId)) &&
                event.withArrival !== 'withoutArrival' &&
                !!event.arrivalTimeDuration &&
                checkIfShowArrival && (
                  <Box
                    className="arrivalWindow"
                    sx={{
                      height: `${arrivalWindowHeight}px`,
                      border: `2px dashed ${COLORS.blue[700]}`,
                      borderBottom: 'none',
                      position: 'absolute',
                      top: `${-arrivalWindowStartPosition}px`,
                      width: '100%',
                      zIndex: 1,
                    }}
                  />
                )}
            </Box>
            {event.withArrival === 'withoutArrival' && hovered && !draggedEvent?.event && (
              <Box>
                <Chip
                  label={moment(arrivalStart).format('h:mm A')}
                  sx={{
                    ...chipArrivalStartStyle,
                    top: moment(event.start).isAfter(arrivalStart)
                      ? `-${arrivalWindowStartPosition + 10}px`
                      : `${-arrivalWindowStartPosition - 10}px`,
                  }}
                />
                <Box
                  sx={{
                    ...chipArrivalWindowStyle,
                    top: `${-arrivalWindowStartPosition + 15}px`,
                    width: '75px',
                    right: '-80px',
                  }}
                >
                  <Typography sx={arrivalWindowLabelStyles}>Exact Time</Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box sx={{ height: '100%' }}>
          {event.type === 'PTO' ? <PTOEventComponent event={event} /> : eventComponent(event)}
        </Box>
      )}
    </Box>
  );
}

DragAndDropEventComponent.propTypes = {
  event: instanceOf(Object),
};

import { Typography } from '@mui/material';
import moment from 'moment';
import { H24_TIME_FORMAT } from '../../../../../../../shared/constants';
import { COLORS } from '../../../../../../../shared/styles/Theme';

export const dateHandler = dateString => {
  if (dateString.includes('AM') || dateString.includes('PM')) {
    return dateString;
  } else {
    return moment(dateString, H24_TIME_FORMAT).format('hh:mm A');
  }
};

export const getSummaryData = data => {
  const selectedTimeSlotsText = data?.selectedTimeSlots
    ? data.selectedTimeSlots.arrivalStart === data.selectedTimeSlots.arrivalEnd
      ? `${data.selectedTimeSlots.arrivalStart}`
      : `${data.selectedTimeSlots.arrivalStart} - ${data.selectedTimeSlots.arrivalEnd}`
    : '-';

  return {
    client: `${data?.userData?.firstName} ${data?.userData?.lastName}`,
    address: `${data?.userData?.street} ${data?.userData?.city}, ${data?.userData?.state}, ${data?.userData?.zipCode}, ${data?.userData?.country}`,
    duration: data?.visitParams?.duration ?? 0,
    visitType: data?.visitParams?.visitTypeDetails?.name ?? 'Unknown',
    selectedTimeSlotsText,
  };
};

export const getSummaryChangesTypography = (data, key) => {
  return (
    <Typography
      variant="body1"
      sx={{
        color: COLORS.blue[900],
        mb: '16px',
        lineHeight: '20px',
        '& .selected-data-value': {
          fontStyle: data[key] ? 'italic' : 'normal',
        },
      }}
    >
      <span className="selected-data-value">{data[key] ? data[key] : 'N/A'}</span>
    </Typography>
  );
};

import { Box, Button } from '@mui/material';
import { func, instanceOf, number, string } from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import ConstantTableItem from './ArrivalWindowsTableItmes/ConstantTableItem';
import { WebSocketContext } from '../../../../../../../../components/WebsocketProvider/WebsocketProvider';
import AlertContext from '../../../../../../../../components/Alert';
import { api } from '../../../../../../../../api';
import { useGetConfigQuery } from '../../../../../../../../api/Config/api';
import {
  checkAvailabilityWrapperStyles,
  getRecurrentAvailabilityContentWrapperStyles,
  recurrentAvailabilityWrapper,
} from './styles';
import CheckAvailabilitySchedulePattern from './CheckAvailabilitySchedulePattern';
import AvailabilityLoader from '../../../../../../components/AvailabilityLoader';
import AvailabilityWindowsBody from './AvailabilityWindowsBody';

function RecurrentAvailability({
  availabilityScheduling,
  clearSelectedTimeSlots = () => {},
  errorData = '',
  setAvailabilityScheduling = () => {},
  setErrorData = () => {},
  setStepDataLoading = () => {},
  setTemporaryVisitData = () => {},
  setVisitData = () => {},
  temporaryVisitData = {},
  visitData = {},
  visitSummaryHeight = 0,
  setCompletedSteps = () => {},
  selectedDates = [],
  activeDates = [],
  setActiveDates = () => {},
  setSelectedTimeSlots,
}) {
  const [subscribe, unsubscribe] = useContext(WebSocketContext);
  const { setAlert } = useContext(AlertContext);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [availabilityData, setAvailabilityData] = useState(null);
  const [operationId, setOperationId] = useState(null);
  const [lastCheckedDates, setLastCheckedDates] = useState([]);
  const [lastCheckedTimesOfDay, setLastCheckedTimesOfDay] = useState([]);

  useEffect(() => {
    setCompletedSteps([0]);
    setTemporaryVisitData(visitData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkDb = async () => {
    const payload = {
      id: operationId,
    };
    const apiData = await api('GET', 'crud', 'checksData', payload);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      if (apiData?.data?.status === 'finished') {
        setAvailabilityData(apiData?.data?.data);
        setIsLoading(false);
      }
    }
  };

  const getData = async () => {
    let dates = [];
    for (const activeDate of activeDates) {
      dates.push(activeDate);
    }
    const payload = {
      clientId: id,
      dates: dates,
      timeOfDays: temporaryVisitData?.timesOfDaysDetails,
      arrivalWindowDuration: temporaryVisitData?.arrivalWindowDuration,
      visit: temporaryVisitData,
      action: 'startOneTimeAvailabilty',
    };
    const apiData = await api('POST', 'check', 'careProgram', payload);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      setOperationId(apiData?.data?.operationId);
    }
    setSelectedTimeSlots(null);
    setLastCheckedDates(dates);
    setLastCheckedTimesOfDay(temporaryVisitData?.timesOfDays);
  };

  useEffect(() => {
    if (!operationId && temporaryVisitData) {
      getData();
    }
    // eslint-disable-next-line
  }, [operationId, temporaryVisitData]);

  const {
    data: filtersData,
    isLoading: isFiltersLoading,
    error: getFiltersDataError,
  } = useGetConfigQuery(['timeOfDays']);

  useEffect(() => {
    if (getFiltersDataError) {
      const errorRequestData = getFiltersDataError;
      setAlert({
        errorData: errorRequestData,
        type: 'error',
      });
    }
  }, [getFiltersDataError, setAlert]);

  useEffect(() => {
    if (availabilityData) {
      setAvailabilityScheduling(availabilityData);
    }
  }, [setAvailabilityScheduling, availabilityData]);

  useEffect(() => {
    if (!availabilityScheduling) {
      setAvailabilityScheduling(availabilityData);
    }
  }, [availabilityData, availabilityScheduling, setAvailabilityScheduling]);

  useEffect(() => {
    setStepDataLoading(isLoading);
  }, [isLoading, setStepDataLoading]);

  const handleChangeDates = e => {
    if (errorData) {
      setErrorData(null);
    }
    const dateName = e.target.name;
    if (activeDates.includes(dateName)) {
      setActiveDates(activeDates.filter(activeDate => activeDate !== dateName));
    } else {
      setActiveDates([...activeDates, dateName]);
    }
  };

  const handleChangeTimeOfDay = e => {
    if (errorData) {
      setErrorData(null);
    }
    const { name } = e.target;
    let timesOfDaysDetails = [...temporaryVisitData.timesOfDaysDetails];
    const dayNames = timesOfDaysDetails.map(({ name }) => name);
    if (dayNames.includes(name)) {
      timesOfDaysDetails = timesOfDaysDetails.filter(item => item.name !== name);
    } else {
      timesOfDaysDetails.push(filtersData.timeOfDays.find(item => item.name === name));
    }
    setTemporaryVisitData({
      ...temporaryVisitData,
      timesOfDays: timesOfDaysDetails.map(({ id }) => id),
      timesOfDaysDetails: timesOfDaysDetails,
    });
    return false;
  };

  const recheckAvailability = async () => {
    setIsLoading(true);
    clearSelectedTimeSlots();
    setAvailabilityData(null);
    setAvailabilityScheduling(null);
    setOperationId(null);
  };

  const tableHeaderItems = useMemo(() => ['Arrival Window', 'Available'], []);

  const tableContentStyles = useMemo(
    () => getRecurrentAvailabilityContentWrapperStyles(visitSummaryHeight),
    [visitSummaryHeight],
  );

  useEffect(() => {
    subscribe('checkAvailability', messageData => {
      if (messageData.operationId === operationId) {
        setAvailabilityData(messageData.data);
        setIsLoading(false);
      }
    });
    return () => {
      unsubscribe('checkAvailability');
    };
    // eslint-disable-next-line
  }, [subscribe, unsubscribe, operationId]);

  return (
    <Box sx={checkAvailabilityWrapperStyles}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CheckAvailabilitySchedulePattern
          disabledRecheckAvailability={
            !activeDates.length ||
            !temporaryVisitData?.timesOfDays?.length ||
            (JSON.stringify(lastCheckedDates.sort((a, b) => a.localeCompare(b))) ===
              JSON.stringify(activeDates.sort((a, b) => a.localeCompare(b))) &&
              JSON.stringify(lastCheckedTimesOfDay.sort((a, b) => a.localeCompare(b))) ===
                JSON.stringify(
                  temporaryVisitData?.timesOfDays?.sort((a, b) => a.localeCompare(b)),
                )) ||
            isLoading
          }
          filtersData={filtersData}
          handleChangeDates={handleChangeDates}
          handleChangeTimeOfDay={handleChangeTimeOfDay}
          isFiltersLoading={isFiltersLoading}
          isLoading={isLoading}
          recheckAvailability={recheckAvailability}
          visitData={temporaryVisitData ?? {}}
          selectedDates={selectedDates}
          activeDates={activeDates}
        />
        {isLoading ? (
          <>
            <Button
              disabled={!operationId}
              sx={{ width: '15%', marginBottom: '20px', marginX: 'auto' }}
              onClick={checkDb}
            >
              Refresh
            </Button>
            <AvailabilityLoader />
          </>
        ) : (
          <Box sx={recurrentAvailabilityWrapper}>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              {tableHeaderItems.map((item, index) => (
                <ConstantTableItem
                  key={item}
                  isArrivalWindowItem={!index}
                  isHeader
                  name={item}
                  showDivider={!!index && tableHeaderItems.length - 1 !== index}
                />
              ))}
            </Box>
            <Box sx={tableContentStyles}>
              {availabilityScheduling?.timeOfDays && <AvailabilityWindowsBody />}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

RecurrentAvailability.propTypes = {
  availabilityScheduling: instanceOf(Object),
  clearSelectedTimeSlots: func,
  errorData: string,
  setAvailabilityScheduling: func,
  setErrorData: func,
  setStepDataLoading: func,
  setTemporaryVisitData: func,
  setVisitData: func,
  temporaryVisitData: instanceOf(Object),
  visitData: instanceOf(Object),
  visitSummaryHeight: number,
  setCompletedSteps: func,
  selectedDates: instanceOf(Array),
  activeDates: instanceOf(Array),
  setActiveDates: func,
  setSelectedTimeSlots: func,
};

export default RecurrentAvailability;

import { visitStatusesMap } from '../../shared/constants';

export const constraintsIconsTypes = {
  circle: 'circle',
  triangle: 'triangle',
};

export const visitDetailsViewsMap = {
  details: 'Visit Details',
  history: 'History',
};

export const visitInfoTypeDialogs = {
  visitInfoType: 'visitInfoType',
  visitFullDetails: 'visitFullDetails',
  careProgramVisit: 'careProgramVisit',
};

export const visitStatuses = [
  // {
  //   key: 'allocated',
  //   name: 'Allocated',
  // },
  {
    key: 'scheduled',
    name: visitStatusesMap.scheduled,
    enabledIfStatus: [],
  },
  {
    key: 'enRoute',
    name: visitStatusesMap.enRoute,
    enabledIfStatus: [visitStatusesMap.scheduled],
  },
  {
    key: 'onSite',
    name: visitStatusesMap.onSite,
    enabledIfStatus: [visitStatusesMap.scheduled, visitStatusesMap.enRoute],
  },
  {
    key: 'inProgress',
    name: visitStatusesMap.inProgress,
    enabledIfStatus: [
      visitStatusesMap.scheduled,
      visitStatusesMap.enRoute,
      visitStatusesMap.onSite,
    ],
  },
  {
    key: 'completed',
    name: visitStatusesMap.completed,
    enabledIfStatus: [visitStatusesMap.inProgress],
  },
  {
    key: 'verified',
    name: visitStatusesMap.verified,
    enabledIfStatus: [visitStatusesMap.completed],
  },
  {
    key: 'canceled',
    name: visitStatusesMap.canceled,
    enabledIfStatus: [
      visitStatusesMap.scheduled,
      visitStatusesMap.enRoute,
      visitStatusesMap.onSite,
      visitStatusesMap.inProgress,
      visitStatusesMap.completed,
    ],
  },
];

export const onlyCancelAllowsVisitStatuses = [
  // {
  //   key: 'allocated',
  //   name: 'Allocated',
  // },
  {
    key: 'scheduled',
    name: visitStatusesMap.scheduled,
    enabledIfStatus: [],
  },
  {
    key: 'enRoute',
    name: visitStatusesMap.enRoute,
    enabledIfStatus: [],
  },
  {
    key: 'onSite',
    name: visitStatusesMap.onSite,
    enabledIfStatus: [],
  },
  {
    key: 'inProgress',
    name: visitStatusesMap.inProgress,
    enabledIfStatus: [],
  },
  {
    key: 'completed',
    name: visitStatusesMap.completed,
    enabledIfStatus: [],
  },
  {
    key: 'verified',
    name: visitStatusesMap.verified,
    enabledIfStatus: [],
  },
  {
    key: 'canceled',
    name: visitStatusesMap.canceled,
    enabledIfStatus: [visitStatusesMap.scheduled],
  },
];

export const unVerifyVisitStatuses = [
  // {
  //   key: 'allocated',
  //   name: 'Allocated',
  // },
  {
    key: 'scheduled',
    name: visitStatusesMap.scheduled,
    enabledIfStatus: [],
  },
  {
    key: 'enRoute',
    name: visitStatusesMap.enRoute,
    enabledIfStatus: [],
  },
  {
    key: 'onSite',
    name: visitStatusesMap.onSite,
    enabledIfStatus: [],
  },
  {
    key: 'inProgress',
    name: visitStatusesMap.inProgress,
    enabledIfStatus: [],
  },
  {
    key: 'completed',
    name: visitStatusesMap.completed,
    enabledIfStatus: [visitStatusesMap.verified],
  },
  {
    key: 'verified',
    name: visitStatusesMap.verified,
    enabledIfStatus: [],
  },
  {
    key: 'canceled',
    name: visitStatusesMap.canceled,
    enabledIfStatus: [],
  },
];

export const canRestoreCanceledVisitStatuses = [
  {
    key: 'scheduled',
    name: visitStatusesMap.scheduled,
    enabledIfStatus: [],
  },
  {
    key: 'enRoute',
    name: visitStatusesMap.enRoute,
    enabledIfStatus: [],
  },
  {
    key: 'onSite',
    name: visitStatusesMap.onSite,
    enabledIfStatus: [],
  },
  {
    key: 'inProgress',
    name: visitStatusesMap.inProgress,
    enabledIfStatus: [],
  },
  {
    key: 'completed',
    name: visitStatusesMap.completed,
    enabledIfStatus: [],
  },
  {
    key: 'verified',
    name: visitStatusesMap.verified,
    enabledIfStatus: [],
  },
  {
    key: 'canceled',
    name: visitStatusesMap.canceled,
    enabledIfStatus: [],
  },
];

export const notAllocatedVisitStatuses = [
  {
    key: 'notAllocated',
    name: visitStatusesMap.notAllocated,
    enabledIfStatus: [],
  },
];

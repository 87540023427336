import { Typography } from '@mui/material';
import React, { createContext } from 'react';
import { backgroundColorSchemeMap } from '../../shared/constants';
import { COLORS } from '../../shared/styles/Theme';
import { combineTeamsAndTerritory } from '../../shared/utils/common';
import Badge from '../../components/Badge';
import TeamsCell from './components/TeamsCell';
import { sortBy } from 'lodash';

export const CaregiversFilters = createContext({});

export const DEFAULT_SORT_MODAL = { field: 'firstName', sort: 'asc' };

export const CAREGIVERS_STATUSES = [
  { id: 'Active', name: 'Active' },
  { id: 'Inactive', name: 'Inactive' },
];

export const CAREGIVERS_STATUSES_MAP = {
  active: 'Active',
  inactive: 'Inactive',
};

export const INITIAL_CAREGIVERS_FILTERS = {
  caregiverName: '',
  teams: [],
  // phoneNumber: '',
  status: ['Active'],
};

export const CAREGIVER_LOCAL_STORAGE_KEYS = {
  caregiverStatus: 'CAREGIVER_STATUS',
  caregiverTeamsFilter: 'TEAMS_FILTER',
  caregiverSearch: 'CAREGIVER_SEARCH',
  // caregiverPhoneNumber: 'CAREGIVER_PHONE_NUMBER',
  caregiverPageSize: 'CAREGIVER_PAGE_SIZE',
};
const PAGE_SIZE = 20;

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const INITIAL_PAGE_SIZE = { pageSize: PAGE_SIZE, page: 0 };

const comparingTeams = (first, second) => {
  const firstHasPrimaryTeam = first?.length > 0;
  const secondHasPrimaryTeam = second?.length > 0;

  if (!firstHasPrimaryTeam && !secondHasPrimaryTeam) {
    return 0;
  }
  if (!firstHasPrimaryTeam) {
    return -1;
  }
  if (!secondHasPrimaryTeam) {
    return 1;
  }
  return first?.[0]?.name?.localeCompare(second?.[0]?.name);
};

const columns = [
  {
    field: 'firstName',
    headerName: 'First name',
    minWidth: 150,
    maxWidth: 200,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    minWidth: 150,
    maxWidth: 200,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 110,
    renderCell: ({ value }) => (
      <Badge
        colorScheme={
          value?.toLowerCase() === 'active'
            ? backgroundColorSchemeMap.active
            : backgroundColorSchemeMap.inactive
        }
        label={CAREGIVERS_STATUSES_MAP[value?.toLowerCase()]}
      />
    ),
  },
  {
    field: 'territory',
    headerName: 'Territory',
    minWidth: 220,
    renderCell: ({ row }) => {
      return row.territoryName ? (
        <TeamsCell isTerritory territory={row.territoryName} />
      ) : (
        <Typography sx={{ color: COLORS.blue[500], fontStyle: 'italic' }}>No Territory</Typography>
      );
    },
  },
  {
    field: 'primaryTeamsDetails',
    headerName: 'Primary Teams',
    minWidth: 220,
    renderCell: ({ row }) =>
      row?.primaryTeams?.length ? (
        <TeamsCell isPrimaryTeams teams={sortBy(row?.primaryTeamsDetails, ['name'])} />
      ) : (
        <Typography sx={{ color: COLORS.blue[500], fontStyle: 'italic' }}>
          No Primary Teams
        </Typography>
      ),
    sortComparator: (v1, v2) => comparingTeams(v1, v2),
  },
  {
    field: 'secondaryTeams',
    headerName: 'Secondary Teams',
    minWidth: 240,
    sortable: false,
    renderCell: ({ row }) =>
      row?.secondaryTeams?.length ? (
        <TeamsCell isSecondaryTeams teams={sortBy(row?.secondaryTeamsDetails, ['name'])} />
      ) : (
        <Typography sx={{ color: COLORS.blue[500], fontStyle: 'italic' }}>
          No Secondary Teams
        </Typography>
      ),
  },
  {
    field: 'guaranteedHours',
    headerName: 'Guaranteed Hours',
    minWidth: 240,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <Typography
        sx={{
          color: COLORS.blue[500],
          ...(!row?.guaranteedHours && { fontStyle: 'italic' }),
        }}
      >
        {row?.guaranteedHours ?? 0}
      </Typography>
    ),
  },
  // {
  //   field: 'address',
  //   headerName: 'Address',
  //   minWidth: 350,
  //   flex: 1,
  //   sortable: false,
  // },
  // {
  //   field: 'phoneNumber',
  //   headerName: 'Phone Number',
  //   minWidth: 180,
  //   sortable: false,
  //   renderCell: ({ row }) => {
  //     const numericPhoneNumber = row?.phoneNumber?.replace(/\D/g, '');

  //     const formattedPhoneNumber = numericPhoneNumber
  //       ? `(${numericPhoneNumber?.slice(0, 3)}) ${numericPhoneNumber?.slice(
  //           3,
  //           6,
  //         )}-${numericPhoneNumber?.slice(6)}`
  //       : '';
  //     return <Box>{formattedPhoneNumber || ''}</Box>;
  //   },
  // },
];

export { columns, PAGE_SIZE, PAGE_SIZE_OPTIONS };

export const getLocalStorageValues = ({
  locationsList,
  setCaregiversFilters,
  setPaginationModel,
}) => {
  if (locationsList) {
    const allTeamsValue = combineTeamsAndTerritory(
      locationsList.filter(x => x?.name !== 'ProdTestTerritory'),
    );
    setCaregiversFilters(prevValue => ({
      ...prevValue,
      teams: allTeamsValue,
    }));
  }
  const statusInitialValue = localStorage.getItem(CAREGIVER_LOCAL_STORAGE_KEYS.caregiverStatus)
    ? JSON.parse(localStorage.getItem(CAREGIVER_LOCAL_STORAGE_KEYS.caregiverStatus))
    : INITIAL_CAREGIVERS_FILTERS.status;
  setCaregiversFilters(prevValue => ({
    ...prevValue,
    status: statusInitialValue,
  }));
  const searchInitialValue = localStorage.getItem(CAREGIVER_LOCAL_STORAGE_KEYS.caregiverStatus)
    ? JSON.parse(localStorage.getItem(CAREGIVER_LOCAL_STORAGE_KEYS.caregiverSearch))
    : INITIAL_CAREGIVERS_FILTERS.caregiverName;
  setCaregiversFilters(prevValue => ({
    ...prevValue,
    caregiverName: searchInitialValue,
  }));
  // const phoneNumberInitialValue = localStorage.getItem(
  //   CAREGIVER_LOCAL_STORAGE_KEYS.caregiverStatus,
  // )
  //   ? JSON.parse(
  //       localStorage.getItem(CAREGIVER_LOCAL_STORAGE_KEYS.caregiverPhoneNumber),
  //     )
  //   : INITIAL_CAREGIVERS_FILTERS.phoneNumber;
  // setCaregiversFilters((prevValue) => ({
  //   ...prevValue,
  //   phoneNumber: phoneNumberInitialValue,
  // }));
  const pageSize = localStorage.getItem(CAREGIVER_LOCAL_STORAGE_KEYS.caregiverPageSize)
    ? JSON.parse(localStorage.getItem(CAREGIVER_LOCAL_STORAGE_KEYS.caregiverPageSize))
    : INITIAL_PAGE_SIZE;
  setPaginationModel(pageSize);
};

import { Box, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import CareProgramManagement from './components/CareProgramManagementProvider';
import { detailsButtons } from './constants';
import { getCareProgramComponent, getCareProgramRightHeader } from './utils';
import { useGetUserDataByIdQuery } from '../../../../api/Clients/api';
import Loader from '../../../../components/Loader';
import AlertContext from '../../../../components/Alert';

export default function CareProgram() {
  const navigate = useNavigate();
  const { id, tabSubView } = useParams();
  const [careProgramVersion, setCareProgramVersion] = useState('current');
  const { setAlert } = useContext(AlertContext);

  const {
    data: originalClientData,
    isLoading: isDataLoading,
    error: userDataError,
  } = useGetUserDataByIdQuery(id);

  useEffect(() => {
    if (userDataError) {
      const errorData = userDataError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [setAlert, userDataError]);

  return (
    <CareProgramManagement>
      <Stack>
        {isDataLoading && <Loader />}
        {originalClientData ? (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <ButtonsGroup
                buttons={detailsButtons}
                changeSelected={value => navigate(`/clients/${id}/care-program/${value}`)}
                selected={tabSubView}
              />
              {getCareProgramRightHeader(
                tabSubView,
                careProgramVersion,
                setCareProgramVersion,
                originalClientData.status,
              )}
            </Box>
            <Box sx={{ mt: '24px' }}>{getCareProgramComponent(tabSubView, careProgramVersion)}</Box>
          </>
        ) : (
          <></>
        )}
      </Stack>
    </CareProgramManagement>
  );
}

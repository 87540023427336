import {
  useCheckVisitReschedulingQuery,
  useConfirmVisitReschedulingMutation,
  useUpdateVisitDetailsMutation,
} from '../../api/commonApi/api';
import moment from 'moment-timezone';
import { element, func } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { VisitInfoTypeContext } from '../../shared/context';
import AlertContext from '../../components/Alert';
import CustomDialog from '../../components/Dialog';
import UnsavedChangesContent from '../../components/Dialog/UnsavedChangesContent';
import CheckReschedulingTable from './components/CheckReschedulingTable';
import AddTaskProvider from './components/CreateTask';
import VisitDetailsWrapper from './components/VisitDetails';
import VisitInfoTypeDialogWrapper from './components/VisitInfoTypeDialog';
import CareProgramVisitPlaceholderDialogWrapper from './components/CareProgramVisitPlaceholderDialog';
import { visitInfoTypeDialogs } from './constants';
import {
  checkFieldsToRecheckAvailability,
  parseVisitReschedulingData,
  visitDateHasBeenChanged,
} from './utils';

function VisitManagementProvider({
  children = <div />,
  reFetchCalendarEvents = () => {},
  refetchCareProgramData = () => {},
  rawVisits = [],
}) {
  const { setAlert } = useContext(AlertContext);
  const [openDialogType, setOpenDialogType] = useState(null);
  const [openCheckReschedulingDialog, setOpenCheckReschedulingDialog] = useState(false);
  const [editArrivalTime, setEditArrivalTime] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [visitInfoData, setVisitInfoData] = useState(null);
  const [careProgramData, setCareProgramData] = useState([]);
  const [initialVisitData, setInitialVisitData] = useState(null);
  const [temporaryVisitData, setTemporaryVisitData] = useState(null);
  const [checkAvailabilityStatus, setCheckAvailabilityStatus] = useState(null);
  const [visitConfirmationAvailable, setVisitConfirmationAvailable] = useState(false);
  const [isUpdatingVisitStatus, setIsUpdatingVisitStatus] = useState(false);
  const [updateVisitDetails, { isLoading: updatingVisitDetails, error: updateVisitDetailsError }] =
    useUpdateVisitDetailsMutation();

  const reschedulingRequestData = parseVisitReschedulingData({
    initialVisitData,
    temporaryVisitData,
  });
  const {
    data: reschedulingData,
    isLoading: isLoadingReschedulingData,
    isFetching: isFetchingReschedulingData,
    error: checkVisitReschedulingError,
  } = useCheckVisitReschedulingQuery(reschedulingRequestData, {
    refetchOnMountOrArgChange: true,
    skip: !openCheckReschedulingDialog,
  });
  const [confirmVisitRescheduling, { error: confirmVisitReschedulingError }] =
    useConfirmVisitReschedulingMutation();

  useEffect(() => {
    const errorData =
      updateVisitDetailsError || checkVisitReschedulingError || confirmVisitReschedulingError;

    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
      setTemporaryVisitData(visitInfoData);
    }
  }, [
    checkVisitReschedulingError,
    confirmVisitReschedulingError,
    setAlert,
    updateVisitDetailsError,
    visitInfoData,
  ]);

  useEffect(() => {
    if (visitInfoData && openDialogType) {
      setInitialVisitData(visitInfoData);
      setTemporaryVisitData(visitInfoData);
    }
  }, [visitInfoData, openDialogType]);

  const transformAndSetVisitData = useCallback(visitData => {
    setVisitInfoData({
      ...visitData,
      isExactTime:
        'isExactTime' in visitData
          ? visitData.isExactTime
          : visitData.arrivalStart === visitData.arrivalEnd,
    });
  }, []);

  const onOpenCareProgramVisitPlaceholderDialog = useCallback((visitData, careProgramData) => {
    setVisitInfoData(visitData);
    setCareProgramData(careProgramData);
    setOpenDialogType(visitInfoTypeDialogs.careProgramVisit);
  }, []);

  const onOpenVisitInfoTypeDialog = useCallback(
    visitData => {
      transformAndSetVisitData(visitData);
      setOpenDialogType(visitInfoTypeDialogs.visitInfoType);
    },
    [transformAndSetVisitData],
  );

  const openVisitDetailsDialog = useCallback(
    visitData => {
      if (visitData) {
        transformAndSetVisitData(visitData);
      }
      setOpenDialogType(visitInfoTypeDialogs.visitFullDetails);
    },
    [transformAndSetVisitData],
  );

  const handleNewVisitInfoData = useCallback(
    visitData => {
      transformAndSetVisitData(visitData);
    },
    [transformAndSetVisitData],
  );

  const updateTemporaryVisitData = useCallback(
    value => {
      setTemporaryVisitData(prevState => ({ ...prevState, ...value }));
    },
    [setTemporaryVisitData],
  );

  const updateVisitTasksList = useCallback(
    value =>
      setTemporaryVisitData(prevState => ({
        ...prevState,
        tasks: value.tasks,
      })),
    [],
  );

  const isRecheckAvailable = useMemo(
    () => checkFieldsToRecheckAvailability(initialVisitData, temporaryVisitData),
    [initialVisitData, temporaryVisitData],
  );

  const isVisitDataChanged = useMemo(
    () => visitDateHasBeenChanged(initialVisitData, temporaryVisitData),
    [initialVisitData, temporaryVisitData],
  );

  useEffect(() => {
    if (isVisitDataChanged) {
      setVisitConfirmationAvailable(!isRecheckAvailable);
    }
  }, [isRecheckAvailable, isVisitDataChanged]);

  const clearState = useCallback(() => {
    setCheckAvailabilityStatus(null);
    setVisitConfirmationAvailable(false);
    setOpenCheckReschedulingDialog(false);
    setInitialVisitData(null);
    setTemporaryVisitData(null);
    setEditArrivalTime(false);
    setCareProgramData([]);
  }, []);

  const onUpdateVisitDetails = useCallback(
    async data => {
      setIsUpdatingVisitStatus(true);
      const dataToSend = data ? data : temporaryVisitData;
      await updateVisitDetails(dataToSend).then(() => {
        let updatedVisitData = dataToSend;
        if (Array.isArray(dataToSend) && dataToSend.length > 0) {
          updatedVisitData = dataToSend[0];
        }
        setVisitInfoData(updatedVisitData);
      });
      reFetchCalendarEvents();
      setOpenCheckReschedulingDialog(false);
      setOpenConfirmationModal(false);
      setIsUpdatingVisitStatus(false);
    },
    [reFetchCalendarEvents, temporaryVisitData, updateVisitDetails],
  );

  const closeDialog = useCallback(
    (skipCheck = false) => {
      if ((isVisitDataChanged || isRecheckAvailable) && !skipCheck) {
        setOpenConfirmationModal(true);
      } else {
        setOpenDialogType(null);
        setOpenConfirmationModal(false);
        clearState();
      }
    },
    [clearState, isRecheckAvailable, isVisitDataChanged],
  );

  const closeVisitDetailsDialog = useCallback(
    () => setOpenDialogType(visitInfoTypeDialogs.visitInfoType),
    [],
  );

  const recheckAvailability = useCallback(() => setOpenCheckReschedulingDialog(true), []);

  const closeRescheduling = useCallback(() => {
    setOpenCheckReschedulingDialog(false);
    setIsUpdatingVisitStatus(false);
  }, []);

  const closeDateChanging = () => {
    setOpenDialogType(null);
    setOpenConfirmationModal(false);
    clearState();
  };

  const confirmReschedulingVisit = useCallback(() => {
    confirmVisitRescheduling(reschedulingData?.requestId)
      .unwrap()
      .then(() => {
        reFetchCalendarEvents();
        closeRescheduling();
        if (!moment(initialVisitData?.date).isSame(moment(temporaryVisitData?.date))) {
          setOpenDialogType(null);
        }
        setEditArrivalTime(false);
      });
  }, [
    closeRescheduling,
    confirmVisitRescheduling,
    initialVisitData?.date,
    reFetchCalendarEvents,
    reschedulingData?.requestId,
    temporaryVisitData?.date,
  ]);

  const visitInfoLoading = false;
  const memoizedProviderValue = useMemo(
    () => ({
      checkAvailabilityStatus,
      closeDialog,
      closeRescheduling,
      closeVisitDetailsDialog,
      initialVisitData,
      isRecheckAvailable,
      isUpdatingVisitStatus,
      isVisitDataChanged,
      onOpenVisitInfoTypeDialog,
      onOpenCareProgramVisitPlaceholderDialog,
      onUpdateVisitDetails,
      openCheckReschedulingDialog,
      openDialogType,
      openVisitDetailsDialog,
      reFetchCalendarEvents,
      recheckAvailability,
      setCheckAvailabilityStatus,
      temporaryVisitData,
      updateTemporaryVisitData,
      updatingVisitDetails,
      visitConfirmationAvailable,
      visitInfoData,
      visitInfoLoading,
      editArrivalTime,
      setEditArrivalTime,
      handleNewVisitInfoData,
      rawVisits,
      careProgramData,
      refetchCareProgramData,
    }),
    [
      checkAvailabilityStatus,
      closeDialog,
      closeRescheduling,
      closeVisitDetailsDialog,
      initialVisitData,
      isRecheckAvailable,
      isUpdatingVisitStatus,
      isVisitDataChanged,
      onOpenVisitInfoTypeDialog,
      onOpenCareProgramVisitPlaceholderDialog,
      onUpdateVisitDetails,
      openCheckReschedulingDialog,
      openDialogType,
      openVisitDetailsDialog,
      recheckAvailability,
      reFetchCalendarEvents,
      temporaryVisitData,
      updateTemporaryVisitData,
      updatingVisitDetails,
      visitConfirmationAvailable,
      visitInfoData,
      visitInfoLoading,
      editArrivalTime,
      setEditArrivalTime,
      handleNewVisitInfoData,
      rawVisits,
      careProgramData,
      refetchCareProgramData,
    ],
  );

  const isReschedulingDataLoading = useMemo(
    () => isLoadingReschedulingData || isFetchingReschedulingData,
    [isLoadingReschedulingData, isFetchingReschedulingData],
  );

  return (
    <VisitInfoTypeContext.Provider value={memoizedProviderValue}>
      <AddTaskProvider
        hasPredefinedData
        tasksListData={temporaryVisitData?.tasks}
        updateTasksList={updateVisitTasksList}
      >
        <CustomDialog
          cancelButtonName="Cancel"
          cancelCallback={() => setOpenConfirmationModal(false)}
          submitButtonName="confirm"
          submitCallback={closeDateChanging}
          title="Confirmation"
          openDialog={openConfirmationModal}
        >
          <UnsavedChangesContent />
        </CustomDialog>
        {openDialogType === visitInfoTypeDialogs.visitInfoType && <VisitInfoTypeDialogWrapper />}
        {openDialogType === visitInfoTypeDialogs.careProgramVisit && (
          <CareProgramVisitPlaceholderDialogWrapper />
        )}
        {openDialogType === visitInfoTypeDialogs.visitFullDetails && <VisitDetailsWrapper />}
        {openCheckReschedulingDialog && (
          <CheckReschedulingTable
            closeRescheduling={closeRescheduling}
            confirmReschedulingVisit={confirmReschedulingVisit}
            isReschedulingDataLoading={isReschedulingDataLoading}
            openCheckReschedulingDialog={openCheckReschedulingDialog}
            reschedulingData={reschedulingData}
          />
        )}
      </AddTaskProvider>
      {children}
    </VisitInfoTypeContext.Provider>
  );
}

VisitManagementProvider.propTypes = {
  children: element,
  reFetchCalendarEvents: func,
};

export default React.memo(VisitManagementProvider);

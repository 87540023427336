import { Box, Button, Typography } from '@mui/material';
import { func, string } from 'prop-types';
import React, { useContext } from 'react';
import { VisitInfoTypeContext } from '../../../../../shared/context';
import {
  editPreferredCaregiverWrapperStyles,
  outlinedButtonStyles,
} from '../../VisitUnmarkPreferredCaregiver/styles';

export default function VisitUnmarkPreferredCaregiver({
  handleCheckAvailability = () => {},
  cancelUnmarkCaregiver = () => {},
  setShadowCaregiverId = () => {},
  setShadowCaregiverName = () => {},
  preferredCaregiverName = '',
}) {
  const { reFetchCalendarEvents, temporaryVisitData } = useContext(VisitInfoTypeContext);
  const onApplyRemoveCaregiver = () => {
    setShadowCaregiverId(null);
    setShadowCaregiverName(null);
    handleCheckAvailability(true);
    cancelUnmarkCaregiver();
  };
  return (
    <Box sx={editPreferredCaregiverWrapperStyles}>
      <Typography sx={{ lineHeight: '24px' }}>
        You are going to remove caregiver <b>{preferredCaregiverName}</b> as preferred
      </Typography>
      <Box sx={{ display: 'flex', width: '100%', gap: '12px', mt: '12px' }}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={cancelUnmarkCaregiver}
          disableElevation
          sx={outlinedButtonStyles}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onApplyRemoveCaregiver}
          disableElevation
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
}

VisitUnmarkPreferredCaregiver.propTypes = {
  applyUnmarkCaregiver: func,
  cancelUnmarkCaregiver: func,
  setPreferredCaregiverId: func,
  setPreferredCaregiverName: func,
  preferredCaregiverName: string,
};

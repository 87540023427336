import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { array, bool, func } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { COLORS } from '../../../../../../../../../shared/styles/Theme';
import {
  timeSlotAccordianDetailsStyles,
  timeSlotAccordianStyles,
  timeSlotAccordianSummaryStyles,
  timeSlotAccordianTextStyles,
  timeSlotItemStyles,
  timeSlotItemWrapperStyles,
} from './styles';

export default function SelectableTimeSlot({
  blocked = false,
  fullAvailable = false,
  onSelect = () => {},
  selected = false,
  softViolations = [],
}) {
  const [expanded, setExpanded] = useState(false);
  const semiAvailable = softViolations.length > 0;
  const colorScheme = useMemo(() => {
    if (blocked) {
      return {
        color: COLORS.black[50],
        backgroundColor: COLORS.black[50],
        cursor: 'default',
        '& .MuiTypography-root': {
          cursor: 'default',
        },
      };
    }
    if (semiAvailable && selected) {
      return {
        color: '#ffffff',
        backgroundColor: COLORS.yellow[700],
      };
    }
    if (fullAvailable && selected) {
      return {
        color: '#ffffff',
        backgroundColor: '#42c18c',
      };
    }
    if (semiAvailable && !selected) {
      return {
        color: COLORS.yellow[700],
        backgroundColor: COLORS.yellow[250],
      };
    }
    if (fullAvailable && !selected) {
      return {
        color: COLORS.green[600],
        backgroundColor: COLORS.green[200],
      };
    }
    return {
      color: COLORS.black[50],
      backgroundColor: COLORS.black[50],
    };
  }, [blocked, fullAvailable, selected, semiAvailable]);
  return (
    <Box sx={timeSlotItemWrapperStyles}>
      <Box
        sx={{ ...timeSlotItemStyles, ...colorScheme }}
        onClick={() => (!fullAvailable && !semiAvailable ? {} : onSelect())}
      >
        {fullAvailable && !semiAvailable ? (
          <Typography variant="h6" sx={timeSlotAccordianTextStyles}>
            {selected ? 'Selected' : 'Select'}
          </Typography>
        ) : semiAvailable ? (
          <Accordion
            elevation={0}
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
            sx={{ ...timeSlotAccordianStyles, ...colorScheme }}
          >
            <AccordionSummary sx={timeSlotAccordianSummaryStyles}>
              <Typography variant="h6" sx={timeSlotAccordianTextStyles}>
                {selected ? 'Selected' : 'Select'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={timeSlotAccordianDetailsStyles}>
              {softViolations.map((violation, index) => (
                <Typography variant="h6" sx={{ fontSize: '12px' }}>
                  {violation}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
}

SelectableTimeSlot.propTypes = {
  blocked: bool,
  fullAvailable: bool,
  onSelect: func,
  selected: bool,
  softViolations: array,
};

import { Box } from '@mui/material';
import { string } from 'prop-types';
import React, { useContext } from 'react';
import { VisitInfoTypeContext } from '../../../../../../../shared/context';
import StatusesCustomSelect from '../../../../../../../components/StatusesSelect';
import {
  tasksStatusesColorSchemes,
  tasksStatusesMap,
  tasksStatusesMapTwo,
} from '../../../../../../../shared/constants';

export default function StatusAction({ id = '', status = '' }) {
  const { temporaryVisitData, updateTemporaryVisitData } = useContext(VisitInfoTypeContext);

  const changeStatus = value => {
    const currentTask = temporaryVisitData?.tasks?.find(task => task.id === id);
    const currentTaskIndex = temporaryVisitData?.tasks?.findIndex(task => task.id === id);
    const newTasks = [...temporaryVisitData?.tasks];
    newTasks[currentTaskIndex] = { ...currentTask, status: tasksStatusesMap[value] };
    updateTemporaryVisitData({ tasks: newTasks });
  };

  const statusName = tasksStatusesMapTwo[status];
  return (
    <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', mt: '4px' }}>
      <StatusesCustomSelect
        colorScheme={tasksStatusesColorSchemes[status]}
        disabled={true}
        id="status"
        options={Object.keys(tasksStatusesMap)}
        isLoading={false}
        selectedValue={statusName}
        setValue={changeStatus}
      />
    </Box>
  );
}

StatusAction.propTypes = {
  id: string,
  status: string,
  tasksType: string,
};

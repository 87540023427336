import { Box } from '@mui/material';
import moment from 'moment/moment';
import React, { useCallback, useContext, useMemo } from 'react';
import ConstantTableItem from './ArrivalWindowsTableItmes/ConstantTableItem';
import SelectableTimeSlot from './ArrivalWindowsTableItmes/SelectableTimeSlot';
import { CreateOneTimeVisitContext } from '../../../../../../context';
import { H12_TIME_FORMAT } from '../../../../../../../../shared/constants';
import AffectedVisitsAccordion from '../../../../../CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/ArrivalWindowsAccordion/AffectedVisitsAccordion';

export default function AvailabilityWindowsBody() {
  const { availabilityScheduling, selectedTimeSlots, setSelectedTimeSlots } =
    useContext(CreateOneTimeVisitContext);

  const changeSlots = newTimeSlot => {
    if (!availabilityScheduling) {
      return '';
    }
    setSelectedTimeSlots(newTimeSlot);
    return '';
  };

  const availabilitiesByTimesOfDay = useMemo(() => {
    const availabilitiesMap = {
      morning: [],
      midday: [],
      afternoon: [],
    };
    if (!availabilityScheduling?.availableArrivalWindows) {
      return availabilitiesMap;
    }
    return availabilityScheduling.timeOfDays.reduce((obj, item) => {
      const availabilitiesList = availabilityScheduling?.availableArrivalWindows.filter(
        ({ arrivalStart }) =>
          moment(arrivalStart, H12_TIME_FORMAT).isBetween(
            moment(item.from, H12_TIME_FORMAT),
            moment(item.to, H12_TIME_FORMAT),
            'minutes',
            '[)',
          ),
      );
      return Object.assign(obj, {
        [item.name]: availabilitiesList,
      });
    }, {});
  }, [availabilityScheduling]);

  const checkIsTimeSlotSelected = useCallback(
    (arrivalStart, arrivalEnd) =>
      arrivalStart &&
      selectedTimeSlots?.arrivalStart &&
      arrivalStart === selectedTimeSlots?.arrivalStart &&
      arrivalEnd &&
      selectedTimeSlots?.arrivalEnd &&
      arrivalEnd === selectedTimeSlots?.arrivalEnd,
    [selectedTimeSlots],
  );

  const sortedTimeOfDays = [];
  const nameList = ['Early Morning', 'Mid Morning', 'Morning', 'Midday', 'Afternoon', 'Evening'];
  for (const name of nameList) {
    for (const timeOfDay of availabilityScheduling.timeOfDays) {
      if (timeOfDay.name === name) {
        sortedTimeOfDays.push(timeOfDay);
      }
    }
  }

  return sortedTimeOfDays.map(timeOfDay => (
    <AffectedVisitsAccordion
      key={timeOfDay.name}
      title={`${timeOfDay.name}: ${timeOfDay.from} - ${timeOfDay.to}`}
    >
      {availabilitiesByTimesOfDay[timeOfDay.name]?.map(
        ({ arrivalEnd, arrivalStart, weekdays, softViolations }) => (
          <Box key={`${arrivalEnd}-${arrivalStart}`} sx={{ display: 'flex' }}>
            {[
              `${
                arrivalStart === arrivalEnd ? `${arrivalStart}` : `${arrivalStart} - ${arrivalEnd}`
              }`,
              '0',
            ].map((item, index) =>
              !index ? (
                <ConstantTableItem key={item} isArrivalWindowItem={!index} name={item} />
              ) : (
                <SelectableTimeSlot
                  key={item}
                  blocked={!weekdays?.length}
                  fullAvailable={weekdays?.length > 0}
                  onSelect={() =>
                    changeSlots({
                      arrivalEnd,
                      arrivalStart,
                    })
                  }
                  selected={checkIsTimeSlotSelected(arrivalStart, arrivalEnd)}
                  softViolations={softViolations}
                />
              ),
            )}
          </Box>
        ),
      )}
    </AffectedVisitsAccordion>
  ));
}

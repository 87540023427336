import { Box, Button } from '@mui/material';
import { bool, func } from 'prop-types';
import React from 'react';
import { outlinedButtonStyles, setupActionsWrapperStyles } from './styles';

export default function SetupActions({
  availableToRecheck = false,
  caregiverHasBeenChanged = false,
  caregiverHasBeenRechecked = false,
  onApplyShadowCaregiver = () => {},
  onCancel = () => {},
  isSameDay = false,
}) {
  return (
    <Box sx={setupActionsWrapperStyles}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={onCancel}
        disableElevation
        sx={outlinedButtonStyles}
      >
        Cancel
      </Button>
      {!caregiverHasBeenRechecked && (
        <Button
          color="primary"
          disabled={!caregiverHasBeenChanged}
          variant="contained"
          onClick={onApplyShadowCaregiver}
          disableElevation
          sx={{ minWidth: '188px' }}
        >
          {isSameDay ? 'Apply' : 'Check Availability'}
        </Button>
      )}
      {caregiverHasBeenRechecked && (
        <Button
          color="secondary"
          disabled={!availableToRecheck}
          variant="outlined"
          onClick={onApplyShadowCaregiver}
          disableElevation
          sx={outlinedButtonStyles}
        >
          Recheck
        </Button>
      )}
    </Box>
  );
}

SetupActions.propTypes = {
  applyShadowCaregiver: func,
  availableToRecheck: bool,
  caregiverHasBeenChanged: bool,
  caregiverHasBeenRechecked: bool,
  checkAvailability: func,
  disableToApply: bool,
  onCancel: func,
};

import { Box, Button, Collapse, Typography } from '@mui/material';
import {
  useCheckRestoreCancelledVisitQuery,
  useConfirmRestoreCancelledVisitMutation,
  useRestoreCancelledVisitNonOptimizedPeriodMutation,
} from '../../../api/commonApi/api';
import { bool, func, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import AlertContext from '../../../components/Alert';
import Loader from '../../../components/Loader';
import {
  canRestoreCanceledVisitStatuses,
  notAllocatedVisitStatuses,
  onlyCancelAllowsVisitStatuses,
  unVerifyVisitStatuses,
  visitStatuses,
} from '../constants';
import {
  infoKeyField,
  infoRowWrapperStyles,
  statusButtonsWrapper,
  statusSelectorDialogLoader,
  statusWrapper,
} from '../styles';
import { getBgColor } from '../utils';
import CheckReschedulingTable from './CheckReschedulingTable';
import StatusChangingConfirmation from './StatusChangingConfirmation';
import VisitStatusSelect from './StatusSelect';
import { VisitInfoTypeContext } from '../../../shared/context';
import { api } from '../../../api/index';
import OptimizerCheck from '../../Optimizer/OptimizerCheck';
import moment from 'moment';
import { CALIFORNIA_TIME_ZONE, visitStatusesMap } from '../../../shared/constants';
import CustomDialog from '../../Dialog';
import { COLORS } from '../../../shared/styles/Theme';

export default function StatusSelectorField({
  cancelNewStatus = () => {},
  changeStatus = () => {},
  closeNewStatusConfirmation = () => {},
  date = '',
  disabledSelect = false,
  initialStatus = '',
  isFutureVisit = false,
  isNotAllocatedVisit = false,
  isUpdatingVisitStatus = false,
  needToConfirmStatus = false,
  reFetchCalendarEvents = () => {},
  scheduledVisit = false,
  visitId = '',
}) {
  const { setAlert } = useContext(AlertContext);
  const { temporaryVisitData, onUpdateVisitDetails } = useContext(VisitInfoTypeContext);
  const [openCheckReschedulingDialog, setOpenCheckReschedulingDialog] = useState(false);
  const [openCheckOptimizer, setOpenCheckOptimizer] = useState(false);
  const [openCheckOneTimeOptimizer, setOpenCheckOneTimeOptimizer] = useState(false);
  const [optimizerData, setOptimizerData] = useState({});
  const [oneTimeOptimizerData, setOneTimeOptimizerData] = useState({});
  const [careprogramChanges, setCareprogramChanges] = useState({});
  const userProfile = JSON.parse(localStorage.getItem('user'));
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);

  const {
    data: reschedulingData,
    isLoading: isLoadingReschedulingData,
    isFetching: isFetchingReschedulingData,
    error: checkVisitReschedulingError,
  } = useCheckRestoreCancelledVisitQuery(visitId, {
    refetchOnMountOrArgChange: true,
    skip: !openCheckReschedulingDialog,
  });
  const [confirmRestoreCancelledVisit, { error: confirmVisitReschedulingError }] =
    useConfirmRestoreCancelledVisitMutation();
  const [
    restoreCancelledVisitNonOptimizedPeriod,
    { error: restoreCancelledVisitNonOptimizedPeriodError },
  ] = useRestoreCancelledVisitNonOptimizedPeriodMutation();

  useEffect(() => {
    const errorData =
      checkVisitReschedulingError ||
      confirmVisitReschedulingError ||
      restoreCancelledVisitNonOptimizedPeriodError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    checkVisitReschedulingError,
    confirmVisitReschedulingError,
    restoreCancelledVisitNonOptimizedPeriodError,
    setAlert,
  ]);

  const availableVisitsOptions = useMemo(() => {
    if (isNotAllocatedVisit) {
      return notAllocatedVisitStatuses;
    }
    if (
      initialStatus?.toLowerCase() === 'canceled' &&
      userProfile?.role?.toLowerCase() === 'admin' &&
      isFutureVisit
    ) {
      return canRestoreCanceledVisitStatuses;
    }
    if (initialStatus?.toLowerCase() === 'verified' && userProfile?.roles?.includes('ADMIN')) {
      return unVerifyVisitStatuses;
    }
    if (initialStatus?.toLowerCase() !== 'verified' && (isNotAllocatedVisit || isFutureVisit)) {
      return onlyCancelAllowsVisitStatuses;
    }
    return visitStatuses;
  }, [initialStatus, isNotAllocatedVisit, isFutureVisit, userProfile]);

  const closeRescheduling = useCallback(() => {
    setOpenCheckReschedulingDialog(false);
    cancelNewStatus();
  }, [cancelNewStatus]);

  const confirmReschedulingVisit = useCallback(() => {
    if (scheduledVisit) {
      confirmRestoreCancelledVisit(reschedulingData?.requestId)
        .unwrap()
        .then(() => {
          setOpenCheckReschedulingDialog(false);
          reFetchCalendarEvents();
        });
    } else {
      restoreCancelledVisitNonOptimizedPeriod({ date, visitId })
        .unwrap()
        .then(() => {
          setOpenCheckReschedulingDialog(false);
          reFetchCalendarEvents();
        });
    }
    closeNewStatusConfirmation();
  }, [
    closeNewStatusConfirmation,
    confirmRestoreCancelledVisit,
    date,
    reFetchCalendarEvents,
    reschedulingData,
    restoreCancelledVisitNonOptimizedPeriod,
    scheduledVisit,
    visitId,
  ]);

  const fetchCareProgramData = () => {
    const payload = {
      id: temporaryVisitData.careprogramId,
    };
    return api('GET', 'crud', 'careProgram', payload);
  };

  const onConfirmNewStatus = async () => {
    setLocalLoading(true);
    if (
      initialStatus?.toLowerCase() !== 'canceled' &&
      temporaryVisitData?.status?.toLowerCase() === 'canceled'
    ) {
      if (temporaryVisitData?.oneTimeVisit) {
        if (
          moment(temporaryVisitData?.date).isSameOrBefore(moment(), 'date') &&
          initialStatus?.toLowerCase() !== 'scheduled'
        ) {
          temporaryVisitData.previousStatus = initialStatus;
        } else if (moment(temporaryVisitData?.date).isAfter(moment(), 'date')) {
          if (initialStatus?.toLowerCase() !== 'scheduled') {
            temporaryVisitData.previousStatus = initialStatus;
          }
          setLocalLoading(false);
          setOneTimeOptimizerData(temporaryVisitData);
          setOpenCheckOneTimeOptimizer(true);
          closeNewStatusConfirmation();
          return '';
        }
      } else {
        const careProgramData = await fetchCareProgramData(temporaryVisitData.careprogramId);
        if (careProgramData.error) {
          setLocalLoading(false);
          setAlert({
            careProgramData,
            type: 'error',
          });
          return;
        } else {
          const newDateChange =
            initialStatus?.toLowerCase() === 'scheduled'
              ? { canceled: true }
              : { canceled: true, previousStatus: initialStatus };
          let dateChanges = {};
          if (careProgramData?.data?.dateChanges) {
            dateChanges = { ...careProgramData?.data?.dateChanges };
          }
          const slicedDate = date.slice(0, 10);
          if (slicedDate in dateChanges) {
            dateChanges[slicedDate] = { ...dateChanges[slicedDate], ...newDateChange };
          } else {
            dateChanges[slicedDate] = newDateChange;
          }
          careProgramData.data.dateChanges = dateChanges;
          careProgramData.data.oneTimeChange = true;

          if (moment(temporaryVisitData?.date).isSameOrBefore(moment(), 'date')) {
            await submitCareProgramChanges(careProgramData?.data);
          } else {
            setLocalLoading(false);
            setCareprogramChanges(careProgramData?.data);
            setOptimizerData(temporaryVisitData);
            setOpenCheckOptimizer(true);
            closeNewStatusConfirmation();
            return '';
          }
        }
      }
    }

    if (
      initialStatus?.toLowerCase() !== 'inprogress' &&
      temporaryVisitData?.status?.toLowerCase() === 'inprogress'
    ) {
      temporaryVisitData.actualTimeStart = moment()
        .tz(CALIFORNIA_TIME_ZONE)
        .format('YYYY-MM-DDTHH:mm:ss');
      if (temporaryVisitData.actualTimeEnd) {
        temporaryVisitData.actualTimeEnd = undefined;
      }
    }

    if (
      initialStatus?.toLowerCase() !== 'completed' &&
      temporaryVisitData?.status?.toLowerCase() === 'completed'
    ) {
      temporaryVisitData.actualTimeEnd = moment()
        .tz(CALIFORNIA_TIME_ZONE)
        .format('YYYY-MM-DDTHH:mm:ss');
      temporaryVisitData.actualDuration = moment(temporaryVisitData.actualTimeEnd).diff(
        moment(temporaryVisitData.actualTimeStart),
        'minutes',
      );
    }

    setLocalLoading(false);
    oneTimeSubmitFunction(temporaryVisitData);
    closeNewStatusConfirmation();
    return '';
  };

  const isReschedulingDataLoading = useMemo(
    () => isLoadingReschedulingData || isFetchingReschedulingData,
    [isLoadingReschedulingData, isFetchingReschedulingData],
  );

  const submitCareProgramChanges = async data => {
    const response = await api('PATCH', 'crud', 'careProgram', data);
    if (response.error) {
      setAlert({
        response,
        type: 'error',
      });
    }
    setLocalLoading(false);
  };

  const submitFunction = async data => {
    setLocalLoading(true);
    await submitCareProgramChanges(careprogramChanges);
    oneTimeSubmitFunction(data);
  };

  const oneTimeSubmitFunction = async data => {
    if (data) {
      onUpdateVisitDetails(data);
    }
  };

  const handleRestoreStatus = async () => {
    setLocalLoading(true);
    setOpenRestoreDialog(false);
    if (temporaryVisitData?.oneTimeVisit) {
      temporaryVisitData.status = temporaryVisitData.previousStatus ?? visitStatusesMap.scheduled;
      if (temporaryVisitData.previousStatus) {
        delete temporaryVisitData.previousStatus;
      }
      if (moment(temporaryVisitData?.date).isAfter(moment(), 'date')) {
        setLocalLoading(false);
        setOneTimeOptimizerData(temporaryVisitData);
        setOpenCheckOneTimeOptimizer(true);
      } else {
        setLocalLoading(false);
        oneTimeSubmitFunction(temporaryVisitData);
      }
    } else {
      const careProgramData = await fetchCareProgramData(temporaryVisitData.careprogramId);
      if (careProgramData.error) {
        setAlert({
          careProgramData,
          type: 'error',
        });
        return;
      } else {
        const dateChanges = careProgramData?.data?.dateChanges;
        const dateKey = moment(temporaryVisitData?.date).format('YYYY-MM-DD');
        temporaryVisitData.status = visitStatusesMap.scheduled;
        if (dateChanges && dateChanges[dateKey] && dateChanges[dateKey].canceled === true) {
          const changesForDate = dateChanges[dateKey];
          const { canceled, previousStatus, ...restOfChanges } = changesForDate;
          temporaryVisitData.status = previousStatus ?? visitStatusesMap.scheduled;
          if (Object.keys(restOfChanges).length === 0) {
            delete dateChanges[dateKey];
          } else {
            dateChanges[dateKey] = restOfChanges;
          }
          careProgramData.data.dateChanges = dateChanges;
          careProgramData.data.oneTimeChange = true;
        }

        if (moment(temporaryVisitData?.date).isAfter(moment(), 'date')) {
          setLocalLoading(false);
          setCareprogramChanges(careProgramData?.data);
          setOptimizerData(temporaryVisitData);
          setOpenCheckOptimizer(true);
        } else {
          await submitCareProgramChanges(careProgramData?.data);
          oneTimeSubmitFunction(temporaryVisitData);
        }
      }
    }
  };

  return (
    <Box>
      {(localLoading || isUpdatingVisitStatus) && <Loader sx={statusSelectorDialogLoader} />}
      <CustomDialog
        cancelButtonName="back"
        cancelCallback={() => setOpenRestoreDialog(false)}
        openDialog={openRestoreDialog}
        submitButtonName="Confirm"
        submitCallback={handleRestoreStatus}
        title="Confirmation"
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            {`${
              temporaryVisitData?.oneTimeVisit ? 'One Time' : 'Care Program'
            } Visit will be restored. Do you want to proceed?`}
          </Typography>
        </Box>
      </CustomDialog>
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Status:</Typography>
        <Box sx={statusWrapper}>
          <Box sx={statusButtonsWrapper}>
            <VisitStatusSelect
              bgColor={getBgColor(temporaryVisitData?.status)}
              disabled={disabledSelect}
              initialVisitStatus={initialStatus}
              isLoading={false}
              options={availableVisitsOptions}
              selectedValue={temporaryVisitData?.status || ''}
              setValue={changeStatus}
            />
            {initialStatus?.toLowerCase() === 'canceled' && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenRestoreDialog(true)}
                disableElevation
              >
                Restore
              </Button>
            )}
          </Box>
          {temporaryVisitData?.status === 'Canceled' &&
            temporaryVisitData?.cancellationReasonDetails && (
              <Typography>
                Cancellation Reason: {temporaryVisitData?.cancellationReasonDetails?.name}
              </Typography>
            )}
        </Box>
      </Box>
      <Collapse in={needToConfirmStatus} timeout={300}>
        <StatusChangingConfirmation
          cancelNewStatus={cancelNewStatus}
          confirmNewStatus={onConfirmNewStatus}
          newStatus={temporaryVisitData?.status}
          newCancellationReason={temporaryVisitData?.cancellationReason}
        />
      </Collapse>
      {openCheckReschedulingDialog && (
        <CheckReschedulingTable
          closeRescheduling={closeRescheduling}
          confirmReschedulingVisit={confirmReschedulingVisit}
          isReschedulingDataLoading={isReschedulingDataLoading}
          openCheckReschedulingDialog={openCheckReschedulingDialog}
          reschedulingData={reschedulingData}
        />
      )}
      <OptimizerCheck
        data={[optimizerData]}
        dataObjName="visitChangeObj"
        submitFunction={submitFunction}
        open={openCheckOptimizer}
        openToggle={setOpenCheckOptimizer}
        cancelFunction={cancelNewStatus}
      />
      <OptimizerCheck
        data={[oneTimeOptimizerData]}
        dataObjName="visitChangeObj"
        submitFunction={oneTimeSubmitFunction}
        open={openCheckOneTimeOptimizer}
        openToggle={setOpenCheckOneTimeOptimizer}
        cancelFunction={cancelNewStatus}
      />
    </Box>
  );
}

StatusSelectorField.propTypes = {
  cancelNewStatus: func,
  changeStatus: func,
  closeNewStatusConfirmation: func,
  date: string,
  disabledSelect: bool,
  initialStatus: string,
  isFutureVisit: bool,
  isNotAllocatedVisit: bool,
  isUpdatingVisitStatus: bool,
  needToConfirmStatus: bool,
  reFetchCalendarEvents: func,
  scheduledVisit: bool,
  visitId: string,
};

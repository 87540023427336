import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetJobsByIdMutation } from '../../../../api/Scheduler/api';
import { func, instanceOf } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { useDebouncedCallback } from '../../../../shared/hooks/useDebounce';
import { getNoData } from '../../../../shared/utils/common';
import Loader from '../../../../components/Loader';
import CustomTable from '../../../../components/Table';
import CustomNavigation from '../CustomNavigation';
import SchedulingToolbar from '../SchedulingToolbar';
import Filters from './components/Filters';
import VisitVerification from './components/VisitVerification';
import {
  DEFAULT_SORT_MODAL,
  INITIAL_SCHEDULING_FILTERS,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  columns,
  statusOptions,
  violationOptions,
} from './constants';
import {
  customAccordionStyles,
  customAccordionSummaryStyles,
  detailSectionTitle,
  visitAccordionDetailsContainer,
  visitAccordionSummaryTitle,
  visitsTableStyles,
} from './styles';

function VisitsTableView({
  date = {},
  eventsArray = [],
  isDateHoliday = {},
  onNavigate = () => {},
  selectedDate = {},
  teamsFilter = [],
  setTeamFilter = () => {},
  locationsList = [],
  reFetchCalendarEvents = () => {},
  reOptimize = () => {},
}) {
  const handleStatusCondition = (visit, condition = false) => {
    const statusIncluded = ['completed', 'canceled', 'verified'].includes(
      visit.status.toLowerCase(),
    );
    return condition ? statusIncluded : !statusIncluded;
  };

  const apiRef = useGridApiRef();
  const apiRefCompleted = useGridApiRef();
  const [getJobsById, { isLoading: getJobsByIdSearching }] = useGetJobsByIdMutation();
  const [visitsFilters, setVisitsFilters] = useState(INITIAL_SCHEDULING_FILTERS);
  const [sortModel, setSortModel] = useState(DEFAULT_SORT_MODAL);
  const [verificationMode, setVerificationMode] = useState(false);
  const [showCompletedTable, setShowCompletedTable] = useState(false);
  const [tableOneVisits, setTableOneVisits] = useState([]);
  const [tableTwoVisits, setTableTwoVisits] = useState([]);
  const { onOpenVisitInfoTypeDialog } = useContext(VisitInfoTypeContext);
  const completedVisitsPresent = eventsArray.filter(x => handleStatusCondition(x, true)).length > 0;

  useEffect(() => {
    setVisitsFilters(prevValue => ({
      ...prevValue,
      status: statusOptions?.map(t => t),
      show: violationOptions?.map(t => t),
      teams: teamsFilter?.teams?.map(t => t.name),
    }));
  }, [setVisitsFilters, teamsFilter]);
  useEffect(() => {
    setSortModel(DEFAULT_SORT_MODAL);
  }, [visitsFilters]);

  const openVisitInfo = useCallback(
    visitData => {
      if (!visitData?.isShadowVisit) {
        onOpenVisitInfoTypeDialog(visitData);
      }
    },
    [onOpenVisitInfoTypeDialog],
  );

  const filterByJobID = value => {
    const jobIdSearchQuery = value?.trim()?.toLowerCase();
    const newArray = eventsArray.filter(({ externalId }) => externalId?.includes(jobIdSearchQuery));
    if (newArray.length) {
      setTableOneVisits(newArray.filter(x => handleStatusCondition(x)));
      if (completedVisitsPresent) {
        setTableTwoVisits(newArray.filter(x => handleStatusCondition(x, true)));
      }
    } else {
      getJobsById(value)
        .unwrap()
        .then(data => {
          if (data.length === 1) {
            openVisitInfo(data[0]);
          }
          setTableOneVisits(data?.filter(x => handleStatusCondition(x)));
          if (completedVisitsPresent) {
            setTableTwoVisits(data?.filter(x => handleStatusCondition(x, true)));
          }
        })
        .catch(() => {
          setTableOneVisits([]);
          if (completedVisitsPresent) {
            setTableTwoVisits([]);
          }
        });
    }
  };

  const debouncedSearch = useDebouncedCallback(e => {
    if (e.target.name === 'jobId') {
      filterByJobID(e.target.value);
    } else {
      setVisitsFilters({ ...visitsFilters, [e.target.name]: e.target.value });
    }
  }, 500);

  const filterEvents = useCallback(() => {
    const clientSearchQuery = visitsFilters.client?.trim()?.toLowerCase();
    const caregiverSearchQuery = visitsFilters.caregiver?.trim()?.toLowerCase();
    // const jobIdSearchQuery = visitsFilters.jobId?.trim()?.toLowerCase();

    const filteredEvents = eventsArray.filter(event => {
      const {
        caregiverName,
        clientName,
        status,
        teamDetails,
        violatedSoftConstraints,
        violatedHardConstraints,
      } = event;
      const isStatusMatched = visitsFilters?.status?.includes(status);
      const isTeamMatched = visitsFilters.teams?.includes(teamDetails?.name);
      const selectedViolationOptions = visitsFilters.show;

      const isNoViolationsSelected = selectedViolationOptions?.includes('No Violations');
      const isSoftConstraintsSelected = selectedViolationOptions?.includes(
        'Soft Constraints Violations',
      );
      const isHardConstraintsSelected = selectedViolationOptions?.includes(
        'Hard Constraints Violations',
      );
      const notViolatedSoftConstraint =
        violatedSoftConstraints?.length === 0 || !violatedSoftConstraints;
      const notViolatedHardConstraint =
        violatedHardConstraints?.length === 0 || !violatedHardConstraints;
      const shouldShowVisit =
        (isNoViolationsSelected && notViolatedSoftConstraint && notViolatedHardConstraint) ||
        (isSoftConstraintsSelected && violatedSoftConstraints?.length > 0) ||
        (isHardConstraintsSelected && violatedHardConstraints?.length > 0);

      if (
        visitsFilters.status?.length === 0 ||
        visitsFilters.teams?.length === 0 ||
        visitsFilters.show?.length === 0
      ) {
        return false;
      }
      return (
        (caregiverName?.toLowerCase()?.includes(caregiverSearchQuery) ||
          caregiverSearchQuery === '') &&
        (clientName?.toLowerCase()?.includes(clientSearchQuery) || clientSearchQuery === '') &&
        // (externalId?.includes(jobIdSearchQuery) || jobIdSearchQuery === '') &&
        (visitsFilters.status?.length === 0 || isStatusMatched) &&
        (visitsFilters.teams?.length === 0 || isTeamMatched) &&
        shouldShowVisit
      );
    });
    setTableOneVisits(filteredEvents.filter(x => handleStatusCondition(x)));
    if (completedVisitsPresent) {
      setTableTwoVisits(filteredEvents.filter(x => handleStatusCondition(x, true)));
    }
  }, [visitsFilters, eventsArray, completedVisitsPresent]);

  useEffect(() => {
    filterEvents();
  }, [visitsFilters, apiRef, apiRefCompleted, filterEvents, eventsArray]);

  const updateSorting = useCallback(data => setSortModel(data[0]), []);

  const visitColumns = useMemo(() => columns({ openVisitInfo }), [openVisitInfo]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          mb: '30px',
        }}
      >
        <Box sx={{ display: 'flex', width: '100%' }}>
          <CustomNavigation
            date={date}
            isDateHoliday={isDateHoliday}
            isTable
            onNavigate={onNavigate}
            reOptimize={reOptimize}
          />
        </Box>

        <SchedulingToolbar
          selectedDate={selectedDate}
          isDateHoliday={isDateHoliday}
          verificationMode={verificationMode}
          setVerificationMode={setVerificationMode}
        />
      </Box>
      <Filters
        blockedFilters={getJobsByIdSearching}
        debouncedSearch={debouncedSearch}
        filterData={visitsFilters}
        locationsList={locationsList}
        setFilterData={setVisitsFilters}
        setTeamFilter={setTeamFilter}
        statusOptions={statusOptions}
        teamsFilter={teamsFilter}
        violationOptions={violationOptions}
      />
      {(getJobsByIdSearching || !visitColumns?.length) && <Loader />}
      <Box
        sx={{
          padding: '30px 10px 0 10px',
        }}
      >
        {visitColumns?.length && (
          <CustomTable
            apiRef={apiRef}
            columns={visitColumns}
            customStyles={visitsTableStyles}
            headerHeight={44}
            pageSize={PAGE_SIZE}
            noRowsOverlay={() => getNoData({ title: 'No Visits for today' })}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            rows={tableOneVisits}
            setSortModelState={updateSorting}
            sortModel={[sortModel]}
          />
        )}
        <VisitVerification
          actualVisit={eventsArray}
          allLocations={locationsList}
          date={date}
          debouncedSearch={debouncedSearch}
          filterData={visitsFilters}
          open={verificationMode}
          setFilterData={setVisitsFilters}
          setOpen={setVerificationMode}
          setTeamFilter={setTeamFilter}
          setVerificationMode={setVerificationMode}
          statusOptions={statusOptions}
          teamsFilter={teamsFilter}
          verificationMode={verificationMode}
          violationOptions={violationOptions}
          reFetchCalendarEvents={reFetchCalendarEvents}
          openVisitInfo={openVisitInfo}
        />
      </Box>
      {completedVisitsPresent && (
        <Box sx={{ paddingBottom: '20px' }}>
          <Accordion elevation={0} expanded={showCompletedTable} sx={customAccordionStyles}>
            <AccordionSummary
              onClick={() => setShowCompletedTable(!showCompletedTable)}
              aria-controls="panel1bh-content"
              sx={customAccordionSummaryStyles}
            >
              <Box sx={visitAccordionSummaryTitle}>
                <Typography
                  sx={{ lineHeight: '32px', cursor: 'pointer', textDecoration: 'underline' }}
                  variant="h5"
                >
                  Completed and Beyond Visits
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={visitAccordionDetailsContainer}>
              <Box sx={{ ...detailSectionTitle, marginTop: '10px' }}>
                {visitColumns?.length && (
                  <CustomTable
                    apiRef={apiRefCompleted}
                    columns={visitColumns}
                    customStyles={visitsTableStyles}
                    headerHeight={44}
                    pageSize={PAGE_SIZE}
                    noRowsOverlay={() => getNoData({ title: 'No Visits for today' })}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    rows={tableTwoVisits}
                    setSortModelState={updateSorting}
                    sortModel={[sortModel]}
                  />
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </Box>
  );
}

VisitsTableView.propTypes = {
  date: instanceOf(Object),
  onNavigate: func,
  setTeamFilter: func,
  eventsArray: instanceOf(Array),
  teamsFilter: instanceOf(Object),
  locationsList: instanceOf(Array),
  selectedDate: instanceOf(Object),
  isDateHoliday: instanceOf(Object),
  reFetchCalendarEvents: func,
  reOptimize: func,
};

export default VisitsTableView;

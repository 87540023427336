import { useVerifyVisitMutation } from '../../../../../../api/Scheduler/api';
import { VerificationModeVisitNotesContext } from '../../../../../../pages/Scheduling/context';
import { bool, func, instanceOf } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { combineTeamsAndTerritory } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import FullWidthDialog from '../../../../../../components/FullWidthDialog';
import VisitVerificationCommon from './VisitVerificationCommon';
import { uniq } from 'lodash';

function VisitVerification({
  actualVisit = [],
  allLocations = [],
  date = {},
  debouncedSearch = () => {},
  filterData = {},
  teamsFilter = {},
  open = false,
  setFilterData = () => {},
  setOpen = () => {},
  setVerificationMode = () => {},
  statusOptions = [],
  verificationMode = false,
  violationOptions = [],
  setTeamFilter = () => {},
  reFetchCalendarEvents = () => {},
  openVisitInfo = () => {},
}) {
  const { setAlert } = useContext(AlertContext);
  const completedVisits = useMemo(
    () => actualVisit.filter(visit => visit.status.toLowerCase() === 'completed'),
    [actualVisit],
  );
  const visitNotesArray = completedVisits?.map(event => ({
    tasks: event?.tasks,
    caregiver: event.caregiverName,
    client: event.clientName,
    externalId: event.externalId ? event.externalId : '',
    fullVisitData: event,
    id: event.id + 1,
    teamDetails: event.teamDetails,
    type: 'visitNote',
    visitDetails: event?.visitDetails,
    visitNotes: event?.visitNotes,
  }));

  const initialPreVisitNotes = useMemo(() => {
    const notes = {};
    visitNotesArray.forEach(event => {
      notes[event.id] = {
        visitNotes: event?.visitNotes || '',
        visitDetails: event?.visitDetails || '',
      };
    });
    return notes;
  }, [visitNotesArray]);

  const [preVisitNotes, setPreVisitNotes] = useState(initialPreVisitNotes);
  const [idsFlaggedAsIncomplete, setIdsFlaggedAsIncomplete] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [chosenVisits, setChosenVisits] = useState([]);
  const [verifyVisits, { error: verifyVisitError }] = useVerifyVisitMutation();
  useEffect(() => {
    if (verifyVisitError) {
      setAlert({
        errorData: verifyVisitError,
        type: 'error',
      });
    }
  }, [verifyVisitError, setAlert]);
  const allData = completedVisits?.concat(visitNotesArray);
  const sortedAllData = allData.sort((a, b) => a.id.localeCompare(b.id));
  const allTeamsAndTerritory = combineTeamsAndTerritory(allLocations);
  const onlyTeamsArray = allTeamsAndTerritory?.filter(team => team.territory);
  const filteredTeams = useMemo(
    () =>
      onlyTeamsArray?.filter(location => filterData.teams?.some(team => location?.name === team)),
    [onlyTeamsArray, filterData],
  );

  const filteredVisits = useMemo(() => ({}), []);

  filteredTeams?.forEach(location => {
    filteredVisits[location.id] = sortedAllData.filter(
      visit =>
        (!visit?.externalId || visit?.externalId?.includes(filterData?.jobId)) &&
        visit?.teamDetails?.name === location?.name &&
        `${visit?.caregiver?.firstName} + ${visit?.caregiver?.lastName}`
          ?.toLowerCase()
          .includes(filterData?.caregiver?.toLowerCase()?.trim()) &&
        `${visit?.client?.firstName} ${visit?.client?.lastName}`
          .toLowerCase()
          .includes(filterData?.client?.toLowerCase()?.trim()),
    );
  });
  const sortedTeams = filteredTeams?.sort((a, b) => {
    const lengthA = filteredVisits[a.id]?.length || 0;
    const lengthB = filteredVisits[b.id]?.length || 0;
    return lengthB - lengthA;
  });
  useEffect(() => {
    setChosenVisits(completedVisits.filter(visit => selectionModel.includes(visit.id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionModel]);
  const onSelectVisit = useCallback(
    rowId => {
      if (selectionModel.includes(rowId)) {
        setSelectionModel(prev => prev.filter(id => id !== rowId));
      } else {
        const completedVisit = completedVisits.find(visit => visit.id === rowId);
        if (completedVisit) {
          if (
            completedVisit?.tasks?.filter(
              task =>
                task?.status?.toLowerCase() === 'todo' || task.status?.toLowerCase() === 'to do',
            )?.length > 0 ??
            false
          ) {
            setIdsFlaggedAsIncomplete(prev => uniq([...prev, rowId]));
          } else {
            setSelectionModel([...selectionModel, rowId]);
          }
        }
      }
    },
    [selectionModel, setSelectionModel, completedVisits],
  );
  const changePreVisitNotes = useCallback(
    (newValue, rowId) => {
      setPreVisitNotes(prevNotes => ({
        ...prevNotes,
        [rowId]: newValue,
      }));
    },
    [setPreVisitNotes],
  );

  const handleVerificationModeChange = useCallback(
    event => {
      setVerificationMode(event.target.checked);
    },
    [setVerificationMode],
  );

  const cancelVisitNotes = useCallback(rowId => {
    setPreVisitNotes(prevNotes => ({
      ...prevNotes,
      [rowId]: '',
    }));
    const parsedRowId = rowId.slice(0, -1);
    setChosenVisits(prevChosenVisits =>
      prevChosenVisits?.map(visit => ({
        ...visit,
        visitNotes: visit.id === parsedRowId ? '' : visit.visitNotes,
      })),
    );
    setPreVisitNotes(current => {
      const copy = { ...current };

      delete copy[rowId];

      return copy;
    });
  }, []);

  const submitVisitNote = useCallback(
    (rowId, visitType) => {
      setChosenVisits(prevChosenVisits =>
        prevChosenVisits?.map(visit => {
          const parsedId = `${visit?.id}1`;
          const matchingPreVisitNote = preVisitNotes?.[parsedId];
          return {
            ...visit,
            [visitType]: matchingPreVisitNote[visitType] || '',
          };
        }),
      );
      const updatedVisits = [];
      for (let visit in completedVisits) {
        const tempVisit = { ...completedVisits[visit] };
        if (preVisitNotes[rowId]?.visitNotes && preVisitNotes[rowId]?.visitNotes !== '') {
          tempVisit.visitNotes = preVisitNotes[rowId]?.visitNotes;
        }
        if (preVisitNotes[rowId]?.visitDetails && preVisitNotes[rowId]?.visitDetails !== '') {
          tempVisit.visitDetails = preVisitNotes[rowId]?.visitDetails;
        }
        delete tempVisit.arrivalTimeDuration;
        delete tempVisit.isDraggable;
        updatedVisits.push(tempVisit);
      }
      verifyVisits(updatedVisits);
      reFetchCalendarEvents();
    },
    [completedVisits, preVisitNotes, verifyVisits, reFetchCalendarEvents],
  );

  const handleSelectAllTable = useCallback(() => {
    if (selectAll) {
      setSelectionModel([]);
    } else {
      let completedVisitsWithPendingTasks = [];
      let completedVisitsWithoutPendingTasks = [];

      for (const completedVisit of completedVisits) {
        if (
          completedVisit?.tasks?.filter(
            task =>
              task?.status?.toLowerCase() === 'todo' || task.status?.toLowerCase() === 'to do',
          )?.length > 0
        ) {
          completedVisitsWithPendingTasks.push(completedVisit?.id);
        } else {
          completedVisitsWithoutPendingTasks.push(completedVisit?.id);
        }
      }
      setIdsFlaggedAsIncomplete(prev => uniq([...prev, ...completedVisitsWithPendingTasks]));
      setSelectionModel(completedVisitsWithoutPendingTasks);
    }

    setSelectAll(!selectAll);
  }, [selectAll, setSelectionModel, completedVisits]);

  const onSelectAllTeamVisits = useCallback(
    locationId => {
      const isSelected = selectionModel.some(
        visitId => completedVisits.find(visit => visit.id === visitId)?.team === locationId,
      );

      if (!isSelected) {
        const selectedIdsForLocation = completedVisits
          ?.filter(
            completedVisit =>
              completedVisit?.tasks?.filter(
                task =>
                  task?.status?.toLowerCase() === 'todo' || task.status?.toLowerCase() === 'to do',
              )?.length === 0 ?? true,
          )
          ?.filter(visit => visit.team === locationId)
          ?.map(visit => visit.id);

        const incompleteIdsForLocation = completedVisits
          ?.filter(
            completedVisit =>
              completedVisit?.tasks?.filter(
                task =>
                  task?.status?.toLowerCase() === 'todo' || task.status?.toLowerCase() === 'to do',
              )?.length > 0 ?? false,
          )
          ?.filter(visit => visit.team === locationId)
          ?.map(visit => visit.id);

        setIdsFlaggedAsIncomplete(prev => uniq([...prev, ...incompleteIdsForLocation]));
        setSelectionModel(prev => [...prev, ...selectedIdsForLocation]);
      } else {
        const deselectedIdsForLocation = completedVisits
          ?.filter(visit => visit.team === locationId)
          ?.map(visit => visit.id);

        setSelectionModel(prev => prev.filter(id => !deselectedIdsForLocation.includes(id)));
      }
    },
    [selectionModel, setSelectionModel, completedVisits],
  );

  const submitCallback = () => {
    setOpenConfirmationDialog(true);
    setChosenVisits(prevChosenVisits =>
      prevChosenVisits.map(visit => {
        const parsedId = `${visit?.id}1`;
        const matchingPreVisitNote = preVisitNotes?.[parsedId];

        return {
          ...visit,
          visitNotes: matchingPreVisitNote?.visitNotes || '',
          visitDetails: matchingPreVisitNote?.visitDetails || '',
        };
      }),
    );
  };
  const submitDialog = useCallback(() => {
    const updatedVisits = [];
    for (let visit in completedVisits) {
      const tempVisit = { ...completedVisits[visit] };
      tempVisit.status = 'Verified';
      delete tempVisit.arrivalTimeDuration;
      delete tempVisit.isDraggable;
      updatedVisits.push(tempVisit);
    }
    verifyVisits(updatedVisits);
    setOpenConfirmationDialog(false);
    setOpen(false);
    setChosenVisits([]);
    reFetchCalendarEvents();
  }, [verifyVisits, setOpenConfirmationDialog, setOpen, reFetchCalendarEvents, completedVisits]);

  const cancelCallback = () => {
    setOpen(false);
    setSelectionModel([]);
    setChosenVisits([]);
    setPreVisitNotes({});
    setSelectAll(false);
    setIdsFlaggedAsIncomplete([]);
  };
  let additionalVisitDetails = 0;
  let caregiverVisitNotes = 0;

  Object.keys(preVisitNotes).forEach(key => {
    if (Object.hasOwn(initialPreVisitNotes, key)) {
      additionalVisitDetails +=
        preVisitNotes[key]?.visitNotes !== initialPreVisitNotes[key]?.visitNotes ? 1 : 0;
      caregiverVisitNotes +=
        preVisitNotes[key]?.visitDetails !== initialPreVisitNotes[key]?.visitDetails ? 1 : 0;
    }
  });
  const memoizedProviderValue = useMemo(
    () => ({
      additionalVisitDetails,
      allLocations,
      cancelVisitNotes,
      caregiverVisitNotes,
      changePreVisitNotes,
      chosenVisits,
      completedVisits,
      date,
      debouncedSearch,
      filterData,
      filteredVisits,
      handleSelectAllTable,
      handleVerificationModeChange,
      onSelectAllTeamVisits,
      onSelectVisit,
      openConfirmationDialog,
      preVisitNotes,
      selectionModel,
      setFilterData,
      setOpenConfirmationDialog,
      setTeamFilter,
      sortedTeams,
      statusOptions,
      submitDialog,
      submitVisitNote,
      teamsFilter,
      verificationMode,
      violationOptions,
      idsFlaggedAsIncomplete,
    }),
    [
      additionalVisitDetails,
      allLocations,
      cancelVisitNotes,
      caregiverVisitNotes,
      changePreVisitNotes,
      chosenVisits,
      completedVisits,
      date,
      debouncedSearch,
      filterData,
      filteredVisits,
      handleSelectAllTable,
      handleVerificationModeChange,
      onSelectAllTeamVisits,
      onSelectVisit,
      openConfirmationDialog,
      preVisitNotes,
      selectionModel,
      setFilterData,
      setTeamFilter,
      sortedTeams,
      statusOptions,
      submitDialog,
      submitVisitNote,
      teamsFilter,
      verificationMode,
      violationOptions,
      idsFlaggedAsIncomplete,
    ],
  );
  return (
    <VerificationModeVisitNotesContext.Provider value={memoizedProviderValue}>
      <FullWidthDialog
        cancelButtonName="cancel"
        backButtonCallback={cancelCallback}
        cancelCallback={cancelCallback}
        submitButtonName={`Verify ${
          chosenVisits.length === 0 ? '' : chosenVisits?.length
        } visit & save notes`}
        disabledSubmit={chosenVisits.length === 0}
        submitCallback={submitCallback}
        title="Verification Mode"
        openDialog={open}
      >
        <VisitVerificationCommon openVisitInfo={openVisitInfo} />
      </FullWidthDialog>
    </VerificationModeVisitNotesContext.Provider>
  );
}

VisitVerification.propTypes = {
  actualVisit: instanceOf(Array),
  allLocations: instanceOf(Array),
  date: instanceOf(Object),
  debouncedSearch: func,
  filterData: instanceOf(Object),
  teamsFilter: instanceOf(Object),
  open: bool,
  setFilterData: func,
  setOpen: func,
  setVerificationMode: func,
  setTeamFilter: func,
  statusOptions: instanceOf(Array),
  verificationMode: bool,
  violationOptions: instanceOf(Array),
  reFetchCalendarEvents: func,
  openVisitInfo: func,
};

export default React.memo(VisitVerification);

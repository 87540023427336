import { Box, Typography } from '@mui/material';
import WarningIconYellow from '../../../../img/icons/warning-icon.svg';
import { bool, instanceOf, number } from 'prop-types';
import React, { useContext } from 'react';
import CounterBadge from '../../../../components/CounterBadge';
import { ScheduleCaregiverManagementContext } from '../../context';
import ManagementActions from '../Menu';
import CustomTooltip from '../../../../components/CustomTooltip';

export default function AdditionalInfoAndStatuses({
  caregiver = {},
  editMode = false,
  isDateHoliday = {},
  selectedDate = {},
  totalVisits = 0,
}) {
  const { addOneTimeAvailability } = useContext(ScheduleCaregiverManagementContext);

  return (
    <Box sx={{ position: 'absolute', right: '10px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {caregiver?.violatedSoftConstraints?.length > 0 && (
          <Box sx={{ display: 'flex', mr: '4px' }}>
            <CustomTooltip title={`${caregiver?.violatedSoftConstraints?.join(', ')}`}>
              <img src={WarningIconYellow} alt="warningIcon" />
            </CustomTooltip>
            <Typography>{caregiver?.violatedSoftConstraints?.length}</Typography>
          </Box>
        )}

        <CounterBadge label={totalVisits} />
        {!editMode && (
          <ManagementActions
            addOneTimeAvailability={() => addOneTimeAvailability(caregiver)}
            caregiver={caregiver}
            isDateHoliday={isDateHoliday}
            selectedDate={selectedDate}
          />
        )}
      </Box>
    </Box>
  );
}

AdditionalInfoAndStatuses.propTypes = {
  caregiver: instanceOf(Object),
  isDateHoliday: instanceOf(Object),
  selectedDate: instanceOf(Object),
  editMode: bool,
  totalVisits: number,
};

import { Box, Typography } from '@mui/material';
import RecurrencePattern from '../../../../pages/ClientDetails/views/CarePlan/components/RecurrencePattern';
import TableTaskCell from '../../../../pages/ClientDetails/views/CarePlan/components/TableTaskCell';
import TaskActions from '../../../../pages/ClientDetails/views/CarePlan/components/TaskActions';
import {
  taskPriorityCellWrapper,
  visitTypeCellWrapper,
} from '../../../../pages/ClientDetails/views/CarePlan/components/styles';
import React from 'react';
import HistoryBulletList from '../../../../components/HistoryBulletList';
import { backgroundColorSchemeMap, formatToPST } from '../../../../shared/constants';
import Badge from '../../../../components/Badge';

export const INITIAL_CARE_PLAN_FILTERS = {
  task: '',
  categoryTypes: [],
  days: [],
  timeOfDays: [],
};
export const PAGE_SIZE = 10;

export const HISTORY_UPDATE_FORMAT = 'ddd D MMM YYYY, h:mm:ss A';

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const priorityOptions = ['High', 'Medium', 'Low'];

export const viewTypeButtons = [
  { name: 'Current', infoType: 'current' },
  { name: 'History', infoType: 'history' },
];

export const sortingOptions = [
  { id: 'name', name: 'Task' },
  { id: 'category', name: 'Category' },
  { id: 'levelsOfAssistance', name: 'Level of Assistance' },
  { id: 'timeOfDays', name: 'Time of Day' },
  { id: 'visitTypes', name: 'Visit Type' },
];

export const getBadgeColorScheme = status => {
  const statusesKeys = {
    low: 'active',
    medium: 'partiallySuccessful',
    high: 'error',
    default: 'null',
  };
  const statusKey = statusesKeys[status ? status.toLowerCase() : 'default'];
  return backgroundColorSchemeMap[statusKey];
};

export const columns = editMode => [
  {
    field: 'name',
    headerName: 'Task',
    minWidth: 300,
    maxWidth: 360,
    flex: 1,
    renderCell: ({ row }) => <TableTaskCell row={row} />,
    sortable: false,
  },
  {
    field: 'details',
    headerName: 'Details',
    minWidth: 400,
    flex: 1,
    sortable: false,
  },
  {
    field: 'priority',
    headerName: 'Priority',
    minWidth: 250,
    flex: 1,
    renderCell: ({ row, value }) => (
      <Box sx={taskPriorityCellWrapper}>
        <Box sx={{ display: 'flex', height: 'min-content' }}>
          <Badge
            colorScheme={getBadgeColorScheme(row?.priority)}
            label={row?.priority ?? 'Unknown'}
          />
        </Box>
      </Box>
    ),
    sortable: false,
  },
  {
    field: '',
    headerName: 'Recurrence Pattern',
    width: 370,
    minWidth: 320,
    flex: 1,
    sortable: false,
    renderCell: ({ id, row }) => (
      <Box sx={visitTypeCellWrapper}>
        <RecurrencePattern row={row} />
        {editMode && <TaskActions row={row} />}
      </Box>
    ),
  },
];

export const historyColumns = [
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    sortable: false,
    minWidth: 280,
    renderCell: params => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{formatToPST(params.row.dateChanged)}</Typography>
      </Box>
    ),
  },
  {
    field: 'changes',
    headerName: 'Changes',
    minWidth: 740,
    sortable: false,
    renderCell: params => <HistoryBulletList changes={params.row} />,
  },
  {
    field: 'user',
    headerName: 'User',
    sortable: false,
    width: 330,
    renderCell: params => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{params.row.updatedBy}</Typography>
      </Box>
    ),
  },
];

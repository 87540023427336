import { useGetSchedulerQuery, useReloadSchedulerDataMutation } from '../../api/Scheduler/api';
import { useGetCaregiversListQuery } from '../../api/Caregivers/api';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DATE_ONLY_STANDARD_FORMAT, ISO_DATE_ONLY_FORMAT } from '../../shared/constants';
import AlertContext from '../../components/Alert';
import Loader from '../../components/Loader';
import VisitManagementProvider from '../../components/VisitInfoTypeProvider';
import Scheduling from './Scheduling';
import ScheduleCaregiverManagementProvider from './components/ScheduleCaregiverManagement';
import { LOCAL_STORAGE_KEYS, makeCaregiversVists } from './constants';
import OptimizerCheck from '../../components/Optimizer/OptimizerCheck';

export default function SchedulingPage() {
  const { setAlert } = useContext(AlertContext);
  const today = moment();
  const todayDate = today.format(ISO_DATE_ONLY_FORMAT);
  const storedDate = localStorage.getItem(LOCAL_STORAGE_KEYS.currentDate)
    ? new Date(localStorage.getItem(LOCAL_STORAGE_KEYS.currentDate))
    : null;
  const [currentDate, setCurrentDate] = useState(storedDate);
  const [caregivers, setCaregivers] = useState(null);
  const [openCheckOptimizer, setOpenCheckOptimizer] = useState(false);
  const [optimizerData, setOptimizerData] = useState({});
  const date = useMemo(
    () => (currentDate ? moment(currentDate).format(ISO_DATE_ONLY_FORMAT) : todayDate),
    [currentDate, todayDate],
  );
  const [schedulerEvents, setschedulerEvents] = useState({
    scheduleEvents: [],
    caregiversList: [],
    notAllocatedEvents: [],
    tableViewEvents: [],
  });
  const {
    data: rawVisits,
    isLoading: visitsLoading,
    isFetching: isVisitsFetching,
    refetch: reFetchVisits,
    error: visitsError,
  } = useGetSchedulerQuery(date);
  const {
    data: caregiversRaw,
    isFetching: isCaregiversFetching,
    isLoading: caregiversLoading,
    refetch: reFetchCaregivers,
    error: caregiversError,
  } = useGetCaregiversListQuery({
    filters: JSON.stringify(),
  });

  useEffect(() => {
    if (caregiversRaw) {
      const filteredCaregivers = [];
      for (let caregiver in caregiversRaw) {
        if (caregiversRaw[caregiver]?.pendingChanges?.status) {
          for (let statusPendingDate in caregiversRaw[caregiver]?.pendingChanges?.status) {
            if (
              moment(currentDate).isSameOrAfter(moment(statusPendingDate, ISO_DATE_ONLY_FORMAT))
            ) {
              if (
                caregiversRaw[caregiver].status === 'Active' &&
                caregiversRaw[caregiver]?.pendingChanges?.status?.[statusPendingDate] !== 'Inactive'
              ) {
                filteredCaregivers.push(caregiversRaw[caregiver]);
              } else if (
                caregiversRaw[caregiver].status === 'Inactive' &&
                caregiversRaw[caregiver]?.pendingChanges?.status?.[statusPendingDate] === 'Active'
              ) {
                filteredCaregivers.push(caregiversRaw[caregiver]);
              }
            } else {
              if (caregiversRaw[caregiver].status === 'Active') {
                filteredCaregivers.push(caregiversRaw[caregiver]);
              }
            }
          }
        } else if (caregiversRaw[caregiver].status === 'Active') {
          filteredCaregivers.push(caregiversRaw[caregiver]);
        }
      }
      setCaregivers(filteredCaregivers);
    }
  }, [caregiversRaw, currentDate]);

  useEffect(() => {
    if (rawVisits && caregivers) {
      const tempVisits = [...rawVisits];
      const allocatedVisits = [];
      const notAllocatedVisits = [];
      for (let visit in tempVisits) {
        if (tempVisits[visit].status !== 'Canceled'){
          if (tempVisits[visit].caregiverId && tempVisits[visit].caregiverId !== 'NoCaregiver') {
            const thisVisit = { ...tempVisits[visit] };
            thisVisit.isDraggable = false;
            allocatedVisits.push(thisVisit);
          } else {
            const thisVisit = { ...tempVisits[visit] };
            const arrivalTime = moment(
              thisVisit.arrivalTime ? thisVisit.arrivalTime : thisVisit.arrivalStart,
            );
            const actualTimeStart = thisVisit.actualTimeStart
              ? moment(thisVisit.actualTimeStart)
              : undefined;
            const actualTimeEnd = thisVisit.actualTimeEnd
              ? moment(thisVisit.actualTimeEnd)
              : undefined;
            const start = moment(thisVisit?.date)
              .set({
                hour: actualTimeStart ? actualTimeStart.hours() : arrivalTime?.hours(),
                minute: actualTimeStart ? actualTimeStart.minutes() : arrivalTime?.minutes(),
                second: 0,
                millisecond: 0,
              })
              .toDate();
            const end = actualTimeEnd
              ? actualTimeEnd.toDate()
              : moment(start).add(thisVisit.duration, 'minutes').toDate();
            thisVisit.arrivalTime = arrivalTime.format('YYYY-MM-DDTHH:mm:ss');
            thisVisit.start = start;
            thisVisit.end = end;
            thisVisit.arrivalTimeDuration = 60;
            thisVisit.resourceId = thisVisit.caregiverId;
            thisVisit.isDraggable = false;

            if (actualTimeStart && actualTimeEnd) {
              thisVisit.actualDuration = actualTimeEnd.diff(actualTimeStart, 'minutes');
            }

            notAllocatedVisits.push(thisVisit);
          }
        }
      }
      const scheduleEvents = makeCaregiversVists(allocatedVisits, caregivers);
      setschedulerEvents({
        scheduleEvents: scheduleEvents,
        caregiversList: caregivers,
        notAllocatedEvents: notAllocatedVisits,
        tableViewEvents: allocatedVisits,
      });
    }
  }, [rawVisits, caregivers]);

  const reFetchCalendarEvents = () => {
    reFetchVisits();
    reFetchCaregivers();
  };

  const [
    reloadSchedulerData,
    { isLoading: refreshSchedulingPending, error: refreshSchedulingError },
  ] = useReloadSchedulerDataMutation();

  const reOptimize = () => {
    setOptimizerData({
      date: date,
      teams:
        JSON.parse(window.localStorage.getItem('TEAMS_FILTER'))
          ?.teams?.filter(team => team?.id && !('teams' in team))
          ?.map(team => team.id)
          .filter(x => x && x.length) ?? [],
    });
    setOpenCheckOptimizer(true);
  };

  const reloadSchedulingForDate = () => {
    const data = {
      fromDate: moment(date).format(ISO_DATE_ONLY_FORMAT),
      toDate: moment(date).format(ISO_DATE_ONLY_FORMAT),
    };
    reloadSchedulerData(data)
      .unwrap()
      .then(() => window.location.reload());
  };

  useEffect(() => {
    const errorData = visitsError || caregiversError || refreshSchedulingError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [visitsError, caregiversError, refreshSchedulingError, setAlert]);

  const eventsLoading = useMemo(
    () =>
      visitsLoading ||
      caregiversLoading ||
      isVisitsFetching ||
      isCaregiversFetching ||
      refreshSchedulingPending,
    [
      refreshSchedulingPending,
      visitsLoading,
      caregiversLoading,
      isVisitsFetching,
      isCaregiversFetching,
    ],
  );

  const changeCurrentDate = useCallback(newDate => {
    setCurrentDate(newDate);
    localStorage.setItem(LOCAL_STORAGE_KEYS.currentDate, newDate);
  }, []);
  return (
    <VisitManagementProvider reFetchCalendarEvents={reFetchCalendarEvents} rawVisits={rawVisits}>
      <ScheduleCaregiverManagementProvider
        calloutDate={moment(date).format(DATE_ONLY_STANDARD_FORMAT)}
        caregiversList={schedulerEvents.caregiversList}
        reFetchCalendarEvents={reFetchCalendarEvents}
      >
        {eventsLoading && <Loader />}
        {schedulerEvents.caregiversList && schedulerEvents.scheduleEvents && (
          <Scheduling
            currentDate={currentDate}
            eventsLoading={eventsLoading}
            reOptimize={reOptimize}
            schedulerEvents={schedulerEvents}
            setCurrentDate={changeCurrentDate}
            today={today}
            todayDate={todayDate}
            reFetchCalendarEvents={reFetchCalendarEvents}
          />
        )}
        <OptimizerCheck
          data={optimizerData}
          dataObjName={'reOptimize'}
          submitFunction={reloadSchedulingForDate}
          open={openCheckOptimizer}
          openToggle={setOpenCheckOptimizer}
        />
      </ScheduleCaregiverManagementProvider>
    </VisitManagementProvider>
  );
}

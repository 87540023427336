import { Box, CircularProgress, Typography } from '@mui/material';
import hardConstraintsWarning from '../../../../../img/icons/error-icon.svg';
import WarningIconSchedule from '../../../../../img/icons/warning-icon-shcedule.svg';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import CustomDialog from '../../../../Dialog';
import { uniq } from 'lodash';

export default function AddShadowCaregiverConfirmation({
  checkPossibilityData = [],
  closeDialog = () => {},
  disabledSubmit = false,
  isLoading = false,
  openDialog = false,
  submitDialog = () => {},
  isSaving = false,
}) {
  const errorLoading = Object.keys(checkPossibilityData).includes('success');
  const hardConstraints = uniq(checkPossibilityData?.violatedConstraints ?? []);
  const softConstraints = uniq(checkPossibilityData?.violatedSoftConstraints ?? []);

  const successResult =
    hardConstraints?.length === 0 && checkPossibilityData?.visitAvailable === true;

  const successButton = 'Confirm';

  const submitAction = () => {
    if (successResult) {
      submitDialog(softConstraints);
    } else {
      closeDialog();
    }
  };

  return (
    <div>
      <CustomDialog
        cancelButtonName={'Cancel'}
        cancelCallback={closeDialog}
        disableOnCloseByClickOutside
        openDialog={openDialog}
        disabledSubmit={disabledSubmit || isLoading || errorLoading}
        submitButtonName={isSaving ? 'Saving...' : successResult ? successButton : 'OK'}
        submitCallback={submitAction}
        title="Confirmation"
      >
        <Box>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : errorLoading ? (
            <Typography sx={{ ml: '30px', maxWidth: '470px' }} variant="body1">
              An error occurred. Please try again.
            </Typography>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 28px 28px 28px',
              }}
            >
              {hardConstraints?.length > 0 && (
                <Box>
                  {hardConstraints.map(title => (
                    <Box key={title} sx={{ display: 'flex', alignItems: 'center', mb: '6px' }}>
                      <img src={hardConstraintsWarning} alt="" />
                      <Typography sx={{ ml: '6px', maxWidth: '470px' }} variant="body1">
                        {title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
              {softConstraints?.length > 0 && (
                <Box>
                  {softConstraints.map(title => (
                    <Box key={title} sx={{ display: 'flex', alignItems: 'center', mb: '6px' }}>
                      <img src={WarningIconSchedule} alt="" />
                      <Typography sx={{ ml: '6px', maxWidth: '470px' }} variant="body1">
                        {title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
              <Box>
                {successResult ? (
                  <>
                    <Typography sx={{ ml: '6px' }} variant="body1">
                      The shadow visit can be scheduled
                      {(softConstraints ?? [])?.length > 0 ? ' with the above constraints.' : '.'}
                    </Typography>
                    <Typography sx={{ ml: '6px' }} variant="body1">
                      Do you want to proceed?
                    </Typography>
                  </>
                ) : (
                  <Typography sx={{ ml: '6px' }} variant="body1">
                    The shadow visit cannot be scheduled.
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </CustomDialog>
    </div>
  );
}

AddShadowCaregiverConfirmation.propTypes = {
  openDialog: bool,
  checkPossibilityData: instanceOf(Object),
  closeDialog: func,
  isLoading: bool,
  submitDialog: func,
  disabledSubmit: bool,
  isSaving: bool,
};

export const visitTasksManagementWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  mt: '10px',
};

export const tasksManagementTableStyles = [
  {
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '400px',
      padding: '0px',
      overflowX: 'hidden',
    },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-cell': {
      padding: '26px 21px 24px 21px',
    },
    '& .MuiDataGrid-footerContainer': {
      margin: 0,
    },
    '& .MuiDataGrid-row:nth-of-type(odd)': {
      maxHeight: '178px !important',
      minHeight: '178px !important',
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
      borderTop: '1px solid #dbdaee',
      minWidth: '100%',
    },
    '& .MuiDataGrid-row': {
      borderTop: '1px solid #dbdaee',
      minWidth: '100%',
    },
    '& .MuiDataGrid-cell:first-of-type': {
      padding: '26px 21px 24px 21px',
    },
  },
];

export const taskPriorityCellWrapper = {
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
};

export const customAccordionStyles = {
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: '0px 0px 0px 20px',
    width: '100%',
  },
  '&:last-of-type, &.Mui-expanded:last-of-type': {
    borderRadius: 0,
  },
  '& .MuiCollapse-root': {
    width: 'calc(100% - 26px)',
  },
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '0px 0px 0px 20px',
};

export const customAccordionSummaryStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  cursor: 'pointer',
  width: '100%',
  '&.MuiAccordionSummary-root, &.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: '32px',
    minWidth: 'fit-content !important',
    maxWidth: 'fit-content !important',
    width: 'fit-content !important',
  },
  '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
    justifyContent: 'space-between',
    margin: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  borderRadius: '4px',
};

export const visitAccordionSummaryTitle = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '8px',
};

export const visitAccordionDetailsContainer = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

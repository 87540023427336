import { Box, CircularProgress } from '@mui/material';
import { number } from 'prop-types';
import React from 'react';

export default function Loader({ size = 40, sx = undefined }) {
  return (
    <Box
      sx={
        sx
          ? sx
          : {
              display: 'flex',
              zIndex: 1499,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }
      }
    >
      <CircularProgress size={size} />
    </Box>
  );
}

Loader.propTypes = {
  size: number,
};

import { Box, Typography } from '@mui/material';
import { instanceOf, string } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../shared/styles/Theme';
import { getIconComponent } from '../../../../../shared/utils/common';
import CustomTooltip from '../../../../../components/CustomTooltip';
import primaryCaregiverIcon from '../../../../../img/icons/primary-visit-icon.svg';
import shadowCaregiverIcon from '../../../../../img/icons/shadow-visit-black-icon.svg';
import { VISITS_VIEWS } from '../constants';

export default function VisitType({ row = {}, tabSubView = VISITS_VIEWS.notAllocated }) {
  const isShadowVisit = row && row.caregiverId?.length ? row.shadowCaregiverId?.length > 0 : false;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
      {tabSubView !== VISITS_VIEWS.notAllocated && (
        <CustomTooltip title={isShadowVisit ? 'Shadow Visit' : 'Regular Visit'}>
          <img src={isShadowVisit ? shadowCaregiverIcon : primaryCaregiverIcon} alt="arrivalIcon" />
        </CustomTooltip>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            ml: row?.shiftedBy ? '40px' : 0,
          }}
        >
          {getIconComponent(row.visitTypeDetails?.name?.toLowerCase())}
          <Typography
            variant={row?.shiftedBy ? 'body1' : 'h5'}
            sx={{
              ml: '8px',
              fontSize: '14px',
              color: COLORS.blue[700],
            }}
          >
            {row.visitTypeDetails?.name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

VisitType.propTypes = {
  row: instanceOf(Object),
  value: string,
};

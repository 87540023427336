import { Box } from '@mui/material';
import { useGetCaregiversListQuery } from '../../../../../api/Caregivers/api';
import { func, instanceOf } from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { VisitInfoTypeContext } from '../../../../../shared/context';
import AlertContext from '../../../../../components/Alert';
import Loader from '../../../../../components/Loader';
import CustomAutocomplete from '../../AutocompleteSelect';
import SetupActions from './SetupActions';
import { editPreferredCaregiverWrapperStyles } from './styles';
import moment from 'moment';

export default function VisitShadowCaregiverSetup({
  handleCheckAvailability = () => {},
  setEditingShadowCaregiver = () => {},
  setShadowCaregiverId = () => {},
  setShadowCaregiverName = () => {},
  shadowCaregiverName = '',
  shadowCaregiverId = '',
  onCancel = () => {},
}) {
  const { setAlert } = useContext(AlertContext);
  const { initialVisitData, temporaryVisitData } = useContext(VisitInfoTypeContext);
  const [availableToRecheck, setAvailableToRecheck] = useState(false);
  const [shadowCaregiversList, setShadowCaregiversList] = useState([]);
  const [caregiverHasBeenRechecked, setCaregiverHasBeenRechecked] = useState(false);

  const {
    data: caregivers,
    error: caregiversListError,
    isLoading: isCaregiversListLoading,
  } = useGetCaregiversListQuery(
    {
      team: temporaryVisitData?.team,
      active: true,
      date: temporaryVisitData?.date,
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    const errorData = caregiversListError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [caregiversListError, setAlert]);

  const onApplyShadowCaregiver = () => {
    setEditingShadowCaregiver(false);
    handleCheckAvailability();
  };

  useEffect(() => {
    if (caregivers) {
      const tempShadowCaregiversList = [];
      for (let cg in caregivers) {
        if (
          caregivers[cg].id !== temporaryVisitData.caregiverId &&
          caregivers[cg].status === 'Active' &&
          (caregivers[cg]?.primaryTeams?.includes(temporaryVisitData.team) ||
            caregivers[cg]?.secondaryTeams?.includes(temporaryVisitData.team))
        ) {
          tempShadowCaregiversList.push({
            id: caregivers[cg].id,
            name: `${caregivers[cg].firstName} ${caregivers[cg].lastName}`,
          });
        }
      }
      setShadowCaregiversList(tempShadowCaregiversList);
    }
    // eslint-disable-next-line
  }, [caregivers]);

  const selectCaregiver = (_, value) => {
    if (!value) {
      return;
    }
    const selectedCaregiver = caregivers.find(({ id }) => id === value?.id);
    setShadowCaregiverId(selectedCaregiver.id);
    setShadowCaregiverName(`${selectedCaregiver.firstName} ${selectedCaregiver.lastName}`);
    if (caregiverHasBeenRechecked) {
      setAvailableToRecheck(true);
    }
  };

  const cancelShadowCaregiverEditing = () => {
    setCaregiverHasBeenRechecked(false);
    onCancel();
  };

  const caregiverHasBeenChanged = useMemo(
    () =>
      shadowCaregiverId?.length
        ? initialVisitData?.shadowCaregiverId !== shadowCaregiverId
        : !!initialVisitData?.shadowCaregiverId,
    [initialVisitData, shadowCaregiverId],
  );

  const isSameDay = moment(temporaryVisitData?.date).isSame(moment(), 'date');

  return (
    <Box sx={editPreferredCaregiverWrapperStyles}>
      {isCaregiversListLoading && <Loader />}
      <Box sx={{ display: 'flex', width: '100%', gap: '12px' }}>
        <CustomAutocomplete
          id="shadow-caregiver"
          changeValue={selectCaregiver}
          optionsList={shadowCaregiversList}
          label="Shadow Caregiver"
          selectedValue={shadowCaregiverName}
        />
        <SetupActions
          availableToRecheck={availableToRecheck}
          caregiverHasBeenChanged={caregiverHasBeenChanged}
          caregiverHasBeenRechecked={caregiverHasBeenRechecked}
          onApplyShadowCaregiver={onApplyShadowCaregiver}
          onCancel={cancelShadowCaregiverEditing}
          isSameDay={isSameDay}
        />
      </Box>
    </Box>
  );
}

VisitShadowCaregiverSetup.propTypes = {
  applyShadowCaregiver: func,
  setEditingShadowCaregiver: func,
  setShadowCaregiverId: func,
  setShadowCaregiverName: func,
  shadowCaregiverName: instanceOf(String),
  shadowCaregiverId: instanceOf(String),
  onCancel: func,
};

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { H12_TIME_FORMAT, H24_TIME_FORMAT } from '../../../../../../shared/constants';

/* Don't delete because logic can be useful */

export const checkIsPeriodsAreCollapsed = ({
  currentProgram,
  updatedCareProgram,
  startTime,
  endTime,
}) => {
  const comparedPrograms = updatedCareProgram.filter(
    item =>
      item.id !== currentProgram.id && currentProgram.days.some(day => item.days.indexOf(day) >= 0),
  );
  return comparedPrograms.some(({ arrivalEnd, arrivalStart, duration }) => {
    const convertedArrivalEnd = moment(arrivalEnd, H12_TIME_FORMAT).add(duration, 'minutes');
    const convertedArrivalStart = moment(arrivalStart, H12_TIME_FORMAT);
    return (
      startTime.isBetween(convertedArrivalStart, convertedArrivalEnd, 'minutes', []) ||
      endTime.isBetween(convertedArrivalStart, convertedArrivalEnd, 'minutes', []) ||
      convertedArrivalStart.isBetween(startTime, endTime, 'minutes', []) ||
      convertedArrivalEnd.isBetween(startTime, endTime, 'minutes', [])
    );
  });
};

export const checkIsDataHasBeenChanged = ({ initialData, updatedData }) => {
  const dataHasBeenChanged = false;
  if (initialData.length !== updatedData.length) {
    return true;
  } else {
    if (initialData.length > 0) {
      for (let visit in updatedData) {
        const initVisit = initialData.find(
          thisInitVisit => updatedData[visit].id === thisInitVisit.id,
        );
        if (initVisit) {
          if (
            initVisit.arrivalEnd !== updatedData[visit].arrivalEnd ||
            initVisit.arrivalStart !== updatedData[visit].arrivalStart ||
            initVisit.effectiveDateEnd !== updatedData[visit].effectiveDateEnd ||
            initVisit.effectiveDateStart !== updatedData[visit].effectiveDateStart ||
            initVisit.timesOfDays !== updatedData[visit].timesOfDays ||
            initVisit.visitType !== updatedData[visit].visitType ||
            initVisit.duration !== updatedData[visit].duration ||
            initVisit.days !== updatedData[visit].days
          ) {
            return true;
          }
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }
  return dataHasBeenChanged;
};

export const checkIsCreateVisitStepConfirmButtonDisabled = data => {
  const { alternativeArrivalWindows, selectedTimeSlots, step, visitData } = data;
  if (step === 0) {
    const { arrivalWindowDuration, days, duration, recurrence, timesOfDays, visitType } =
      visitData || {};
    return (
      !recurrence ||
      !arrivalWindowDuration ||
      !timesOfDays?.length ||
      !days ||
      !Object.keys(days).length ||
      !duration ||
      !visitType
    );
  }
  if (step === 1) {
    return (
      selectedTimeSlots === null ||
      Object.values(selectedTimeSlots)?.length !== Object.keys(visitData?.days).length
    );
  }
  if (step === 2) {
    return (
      !alternativeArrivalWindows ||
      !!alternativeArrivalWindows?.unAvailableSchedule?.filter(visit => !visit.cancelled).length
    );
  }
  return false;
};

export const getNewVisitsList = ({
  selectedTimeSlots,
  updatedCareProgram,
  visitData,
  effectiveDate,
  clientId,
}) => {
  const thisVisitData = { ...visitData };
  const days = {};
  for (const day in selectedTimeSlots) {
    const start = moment(selectedTimeSlots[day].arrivalStart, H12_TIME_FORMAT).format(
      H24_TIME_FORMAT,
    );
    const end = moment(selectedTimeSlots[day].arrivalEnd, H12_TIME_FORMAT).format(H24_TIME_FORMAT);
    days[day] = { arrivalStart: start, arrivalEnd: end };
  }
  const newUpdatedCareProgram = [...updatedCareProgram];
  thisVisitData.effectiveDateStart = effectiveDate.format("YYYY-MM-DDTHH:mm:ss");
  thisVisitData.days = days;
  if (!thisVisitData.id) {
    thisVisitData.clientId = clientId;
    thisVisitData.changesType = 'add';
    thisVisitData.id = uuidv4();
    newUpdatedCareProgram.push(thisVisitData);
  } else {
    thisVisitData.changesType = 'new';
    thisVisitData.dateCreated = moment().toISOString();
    const latestVisits = newUpdatedCareProgram
      .filter(visit => visit.id === thisVisitData.id)
      .filter(visit => !visit.effectiveDateEnd);
    let latestIndex = null;
    if (latestVisits.length) {
      latestIndex = newUpdatedCareProgram.indexOf(latestVisits[0]);
    }
    if (newUpdatedCareProgram[latestIndex] !== thisVisitData) {
      newUpdatedCareProgram[latestIndex] = {
        ...newUpdatedCareProgram[latestIndex],
        changesType: 'existing',
      };
      newUpdatedCareProgram.push(thisVisitData);
    }
  }
  return newUpdatedCareProgram;
};

import { Box, Typography } from '@mui/material';
import hardConstraintsWarning from '../../../../img/icons/error-icon.svg';
import { bool, instanceOf, string } from 'prop-types';
import React from 'react';
import { notAllocateDetailsWrapper, notAllocatedBox, notAllocatedExpanded } from '../styles';

export default function NotAllocatedHeaderComponent({
  caregiver = {},
  editMode = false,
  minHeight = '',
}) {
  const headerStyles = notAllocatedBox(minHeight);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box className="CaregiversBox" sx={headerStyles}>
        <Box sx={notAllocatedExpanded}>
          <img style={{ width: '16x', height: '16px' }} src={hardConstraintsWarning} alt="" />
          <Typography sx={{ ml: '6px', fontWeight: 600 }} variant="body1">
            {caregiver.title}
          </Typography>
        </Box>
      </Box>
      {!editMode && (
        <Box className="expandedHeader" sx={notAllocateDetailsWrapper}>
          <Typography sx={{ ml: '6px', fontWeight: 600, mb: '16px' }} variant="body2">
            {caregiver.description}
          </Typography>
          <Typography sx={{ ml: '6px', fontWeight: 600 }} variant="body2">
            {caregiver.text}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

NotAllocatedHeaderComponent.propTypes = {
  caregiver: instanceOf(Object),
  editMode: bool,
  minHeight: string,
};

import { Box } from '@mui/material';
import React, { useContext } from 'react';
import LocationsFilter from '../../../components/FiltersComponents/LocationFilter';
import SearchFilter from '../../../components/FiltersComponents/Search';
import SelectWithCounter from '../../../components/FiltersComponents/Select';
import { CAREGIVERS_STATUSES, CaregiversFilters } from '../constants';
import { TEAM_STATUSES } from '../../../shared/constants';

export default function Filters() {
  const {
    changeStatusFilters,
    changeTeamFilters,
    teamFilters,
    caregiversFilters,
    debouncedSearch,
    isLoading,
    isFetching,
    locationsList,
    setCaregiversFilters,
    isLocationsLoading,
  } = useContext(CaregiversFilters);
  return (
    <Box sx={{ display: 'flex', mb: '25px', gap: '20px' }}>
      <SearchFilter
        changeValue={debouncedSearch}
        name="caregiverName"
        placeholder="Name"
        initialValue={caregiversFilters?.caregiverName}
      />
      <SelectWithCounter
        filterItems={caregiversFilters?.status.map(status => ({ id: status, name: status }))}
        id="status"
        isLoading={isLoading || isFetching}
        isMultiselect
        options={CAREGIVERS_STATUSES}
        placeHolder="Status"
        setFilters={changeStatusFilters}
        showFiltersCount
      />
      <LocationsFilter
        locationsList={locationsList}
        setFilters={setCaregiversFilters}
        filters={caregiversFilters}
        isLoading={isLocationsLoading}
        isCaregiverOrClient
      />
      <SelectWithCounter
        filterItems={teamFilters}
        id="teamStatus"
        isLoading={isLoading || isFetching}
        isMultiselect
        options={TEAM_STATUSES}
        placeHolder="Team Status"
        setFilters={changeTeamFilters}
        showFiltersCount
      />
    </Box>
  );
}

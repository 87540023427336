import { Box, Typography } from '@mui/material';
import hardConstraintsWarning from '../../../../img/icons/error-icon.svg';
import warningIconYellow from '../../../../img/icons/warning-icon.svg';
import { bool, instanceOf } from 'prop-types';
import React from 'react';
import { violationTooltipComponent } from '../../../../shared/utils/common';
import { violatedSoftConstraintsStyles } from '../../styles';
import EventCardInfo from './EventCardInfo';
import { eventCardInfoWrapper, showTeamStyles } from './styles';

export default function CaregiverCalendarEventMainInfoCard({
  arrivalEnd = {},
  arrivalStart = {},
  checkIfShowArrival = false,
  event = {},
  hovered = false,
}) {
  return (
    <Box sx={{ height: '100%' }} className="event-before-box">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: '5px 14px',
          height: '100%',
        }}
      >
        <Box sx={eventCardInfoWrapper}>
          <EventCardInfo
            arrivalEnd={arrivalEnd}
            arrivalStart={arrivalStart}
            checkIfShowArrival={checkIfShowArrival}
            eventData={event}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mt: event.duration < 45 ? '-20px' : '0',
          }}
        >
          {event?.violatedHardConstraints?.length > 0 && (
            <Box sx={violatedSoftConstraintsStyles}>
              {violationTooltipComponent({
                violations: event.violatedHardConstraints,
                icon: hardConstraintsWarning,
                status: event.status,
                violationType: 'hard',
              })}
            </Box>
          )}
          {event?.violatedSoftConstraints?.length > 0 && (
            <Box sx={violatedSoftConstraintsStyles}>
              {violationTooltipComponent({
                violations: event.violatedSoftConstraints,
                icon: warningIconYellow,
                status: event.status,
                violationType: 'soft',
              })}
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          ...showTeamStyles,
          background: hovered && event?.teamDetails?.color?.color,
          height: hovered ? '24px' : '3px',
        }}
      >
        {hovered && (
          <Typography
            sx={{
              color: event?.teamDetails?.color?.fontColor,
            }}
          >
            {event?.teamDetails?.name}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

CaregiverCalendarEventMainInfoCard.propTypes = {
  arrivalEnd: instanceOf(Object),
  arrivalStart: instanceOf(Object),
  checkIfShowArrival: bool,
  event: instanceOf(Object),
  hovered: bool,
};

import moment from 'moment';
import {
  H12_TIME_FORMAT,
  MAX_SCHEDULER_WORKING_HOUR,
  MIN_SCHEDULER_WORKING_HOUR,
  visitStatusesMap,
} from '../../shared/constants';
import { convertUtcToTeamTimeZoneMomentObject } from '../../shared/utils/common';
import { pluralize } from '../../shared/utils/pluralize';

export const LOCAL_STORAGE_KEYS = {
  currentDate: 'CURRENT_DATE',
  scrollPosition: 'SCROLL_POSITION',
  temporaryScrollPosition: 'TEMPORARY_SCROLL_POSITION',
  teamsFilter: 'TEAMS_FILTER',
  timeValuesList: 'TIME_VALUES_LIST',
  oldStatus: 'OLD_STATUS',
};

export const TIME_CONTENT_HEIGHT_EXPANDED = 'calc(100vh - 345px)';
export const TIME_CONTENT_HEIGHT = 'calc(100vh - 250px)';
export const PREFERRED_TITLE = `The caregiver you are about to assign does not match the client's preferred caregiver choice for the visit.`;
export const HIDE_HEADER_HEIGHT_EXPANDED = '257px';
export const HIDE_HEADER_HEIGHT = '102px';
export const SHOW_DETAILS_BUTTON_HEIGHT_COLLAPSE = '280px';
export const SHOW_DETAILS_BUTTON_HEIGHT_EXPAND = '188px';
export const DEFAULT_HEADER_HEIGHT = 139;
export const EXPANDED_HEADER_HEIGHT = 250;

export const makeCaregiversVists = visits => {
  const filteredVisits = visits;
  const newVisits = filteredVisits.map(visit => {
    const actualTimeStart = visit.actualTimeStart ? moment(visit.actualTimeStart) : undefined;
    const actualTimeEnd = visit.actualTimeEnd ? moment(visit.actualTimeEnd) : undefined;
    return {
      ...visit,
      resourceId: visit.caregiverId,
      start: new Date(
        visit.actualTimeStart
          ? visit.actualTimeStart
          : visit.arrivalTime
          ? visit.arrivalTime
          : visit.arrivalStart,
      ),
      end: visit.actualTimeEnd
        ? new Date(visit.actualTimeEnd)
        : new Date(
            moment(visit.actualTimeStart ? visit.actualTimeStart : visit.arrivalTime)
              .add(visit.duration, 'minutes')
              .format('YYYY-MM-DDTHH:mm:ss'),
          ),
      arrivalTimeDuration: moment(visit.arrivalEnd).diff(visit.arrivalStart, 'minutes'),
      travelTime: visit.roadTimeInMinutes,
      timeDifferenceBetweenStartAndArrivalStart: 0,
      timeDifferenceBetweenStartAndArrivalEnd: 60,
      ...(visit.actualTimeStart &&
        visit.actualTimeEnd && { actualDuration: actualTimeEnd.diff(actualTimeStart, 'minutes') }),
    };
  });
  const originalVisits = visits.filter(visit => visit.status !== 'Canceled');
  for (let visit in originalVisits) {
    if (originalVisits[visit]?.shadowCaregiverId) {
      const actualTimeStart = visit.actualTimeStart ? moment(visit.actualTimeStart) : undefined;
      const actualTimeEnd = visit.actualTimeEnd ? moment(visit.actualTimeEnd) : undefined;
      const tempVisit = {
        ...originalVisits[visit],
        resourceId: originalVisits[visit].shadowCaregiverId,
        start: new Date(
          originalVisits[visit].actualTimeStart
            ? originalVisits[visit].actualTimeStart
            : originalVisits[visit].arrivalTime
            ? originalVisits[visit].arrivalTime
            : originalVisits[visit].arrivalStart,
        ),
        end: originalVisits[visit].actualTimeEnd
          ? new Date(originalVisits[visit].actualTimeEnd)
          : new Date(
              moment(
                originalVisits[visit].actualTimeStart
                  ? originalVisits[visit].actualTimeStart
                  : originalVisits[visit].arrivalTime,
              )
                .add(originalVisits[visit].duration, 'minutes')
                .format('YYYY-MM-DDTHH:mm:ss'),
            ),
        arrivalTimeDuration: moment(originalVisits[visit].arrivalEnd).diff(
          originalVisits[visit].arrivalStart,
          'minutes',
        ),
        travelTime: originalVisits[visit].roadTimeInMinutes,
        timeDifferenceBetweenStartAndArrivalStart: 0,
        timeDifferenceBetweenStartAndArrivalEnd: 60,
        ...(originalVisits[visit].actualTimeStart &&
          originalVisits[visit].actualTimeEnd && {
            actualDuration: actualTimeEnd?.diff(actualTimeStart, 'minutes'),
          }),
      };
      newVisits.push(tempVisit);
    }
  }
  return newVisits;
};

export const checkIfDraggable = visit => {
  let isDraggable = false;
  if (
    !visit.shadowCaregiverId &&
    [
      visitStatusesMap.allocated,
      visitStatusesMap.cannotBeAllocated,
      visitStatusesMap.scheduled,
      visitStatusesMap.enRoute,
      visitStatusesMap.onSite,
    ].includes(visit.status)
  ) {
    isDraggable = true;
  }
  return isDraggable;
};
export const detailsButtons = [
  { id: 'Calendar', name: 'Calendar', infoType: 'calendar' },
  { id: 'Visits', name: 'Visits', infoType: 'visits' },
  { id: 'PTO & Callouts', name: 'PTO & Callouts', infoType: 'time-off-requests' },
];
export const truncateName = (str, maxLength, number) =>
  str?.length > maxLength ? `${str.substring(0, number)}...` : str;

export const formattedTime = time => {
  const duration = moment.duration(time, 'minutes');
  const hours = duration.hours();
  const minutes = duration.minutes();
  if (hours <= 0) {
    return `${minutes}min `;
  }
  if (hours >= 1 && minutes === 0) {
    return `${pluralize(hours, 'hour')}`;
  }
  if (hours >= 1 && minutes > 0) {
    return `${pluralize(hours, 'hour')} ${minutes} min`;
  }
  return '';
};

export const notAllocatedDescription = {
  id: 'NoCaregiver',
  title: 'Not Allocated Visits',
  text: 'Please adjust them manually.',
  description:
    'The visits in this column failed to get allocated to the Caregivers by the automated Scheduling Engine due to the hard constraints violation.',
};

export const updateNotAllocatedVisits = (data, otherEventsLength) =>
  data?.notAllocatedVisits?.map((ev, ind) => {
    const arrivalStart = convertUtcToTeamTimeZoneMomentObject(ev?.arrivalStart);
    const arrivalEnd = convertUtcToTeamTimeZoneMomentObject(ev?.arrivalEnd);

    const timeDifferenceBetweenStartAndArrivalStart = arrivalEnd?.diff(arrivalStart, 'minutes');
    const arrivalTimeEnds = moment(ev.date)
      .set({
        hour: arrivalEnd?.hours(),
        minute: arrivalEnd?.minutes(),
        second: 0,
        millisecond: 0,
      })
      .toDate();
    const start = moment(ev.date)
      .set({
        hour: arrivalEnd?.hours(),
        minute: arrivalEnd?.minutes(),
        second: 0,
        millisecond: 0,
      })
      .toDate();
    const arrivalTimeStart = moment(ev.date)
      .set({
        hour: arrivalEnd?.hours(),
        minute: arrivalEnd?.minutes(),
        second: 0,
        millisecond: 0,
      })
      .subtract(timeDifferenceBetweenStartAndArrivalStart, 'minutes')
      .toDate();
    const arrivalTimeDuration = moment
      .duration(moment(arrivalTimeEnds).diff(moment(arrivalTimeStart)))
      .asMinutes();
    const end = moment(start).add(ev.duration, 'minutes').toDate();
    const type = ev?.visitType?.toLowerCase();
    const team = ev?.team?.name;
    const title = ev?.visitType;
    const violatedHardConstraints = ev?.violatedHardConstraints;
    const withArrival =
      timeDifferenceBetweenStartAndArrivalStart === 0 ? 'withoutArrival' : 'withArrival';
    return {
      timeDifferenceBetweenStartAndArrivalStart,
      withArrival,
      duration: ev?.duration,
      client: ev?.client,
      resourceId: 1,
      arrivalTimeStart,
      arrivalTimeEnds,
      violatedHardConstraints,
      // status: 'Not Allocated',
      eventId: otherEventsLength + ind + 1,
      status: 'Scheduled',
      end,
      start,
      team,
      type,
      title,
      isDraggable: false,
      arrivalTimeDuration,
      region: ev?.team,
      id: ev.id,
      repeat: ev?.recurrence,
    };
  });

export const addArrivalEvents = ({ eventsList, filteredCaregivers, currentDate }) => {
  const finalEvents = [];
  for (let event in eventsList) {
    const thisEvent = eventsList[event];
    if (
      thisEvent.status !== 'Scheduled' &&
      thisEvent.status !== 'NotAllocated' &&
      thisEvent.status !== 'Lunch Break'
    ) {
      thisEvent.isDraggable = false;
    } else if (moment(currentDate).isBefore(moment(), 'date')) {
      thisEvent.isDraggable = false;
    } else if (thisEvent?.shadowCaregiverId) {
      thisEvent.isDraggable = false;
    } else {
      thisEvent.isDraggable = true;
    }
    finalEvents.push(thisEvent);
  }
  return finalEvents;
};

export const addResourceIds = (data, visits, caregivers) => {
  let resourceIdCounter = 2;
  if (!data) {
    return null;
  }

  const updatedData = JSON.parse(JSON.stringify(data));

  updatedData?.caregivers?.forEach(caregiver => {
    // eslint-disable-next-line no-plusplus
    const resourceId = resourceIdCounter++;
    // eslint-disable-next-line no-param-reassign
    caregiver.caregiver.resourceId = resourceId;
    // eslint-disable-next-line no-param-reassign
    caregiver.events = caregiver?.events?.map(event => ({
      ...event,
      resourceId,
      availabilities: caregiver?.caregiver?.availabilities,
      isShadowVisit: event.shadowCaregiver && caregiver.caregiver.id === event.shadowCaregiver.id,
    }));
  });
  return updatedData;
};
export const getAllEvents = data =>
  data?.caregivers
    ?.flatMap(caregiver => caregiver?.events)
    .map(eventData => ({
      ...eventData,
      arrivalTime: eventData?.arrivalTime
        ? convertUtcToTeamTimeZoneMomentObject(eventData?.arrivalTime).format(H12_TIME_FORMAT)
        : null,
      arrivalStart: eventData?.arrivalStart
        ? convertUtcToTeamTimeZoneMomentObject(eventData?.arrivalStart).format(H12_TIME_FORMAT)
        : null,
      arrivalEnd: eventData?.arrivalEnd
        ? convertUtcToTeamTimeZoneMomentObject(eventData?.arrivalEnd).format(H12_TIME_FORMAT)
        : null,
      actualTimeStart:
        eventData?.actualTimeStart && eventData?.actualTimeStart !== 'NOT STARTED'
          ? convertUtcToTeamTimeZoneMomentObject(eventData?.actualTimeStart).format(H12_TIME_FORMAT)
          : null,
      actualTimeEnd:
        eventData?.actualTimeEnd && eventData?.actualTimeEnd !== 'NOT ENDED'
          ? convertUtcToTeamTimeZoneMomentObject(eventData?.actualTimeEnd).format(H12_TIME_FORMAT)
          : null,
    })) || [];

export const onlyCaregivers = data => {
  const transformedData = [];
  const uniqueCaregivers = new Set();
  data?.primaryTeams?.forEach(team => {
    const teamId = team?.id;
    const teamName = team?.name;
    // const teamCaregivers = data.caregivers.filter(
    //   (caregiver) => caregiver?.caregiver?.teams[0]?.id === teamId,
    // );

    const primaryTeamCaregivers = data.caregivers.filter(caregiver =>
      caregiver?.caregiver?.teams?.some(t => t?.id === teamId),
    );

    const secondaryTeamCaregivers = data.caregivers.filter(
      caregiver =>
        caregiver?.secondaryTeams?.some(t => t?.id === teamId) &&
        !caregiver?.caregiver?.teams?.some(t => t?.id === teamId),
    );
    const allTeamCaregivers = [...primaryTeamCaregivers, ...secondaryTeamCaregivers];
    allTeamCaregivers.forEach(caregiver => {
      const orderValues = caregiver?.primaryTeams.map(caregiverTeam => caregiverTeam?.order);
      const territoryOrder =
        caregiver?.primaryTeams[0]?.territory?.order ||
        caregiver?.secondaryTeams[0]?.territory?.order ||
        0;

      const minOrder = Math.min(...orderValues);
      const caregiverId = caregiver?.caregiver?.id;
      if (!uniqueCaregivers.has(caregiverId) && caregiver.caregiver.status === 'Active') {
        uniqueCaregivers.add(caregiverId);
        const caregiversOrder = caregiver?.caregiverOrder;
        const violates30hoursAWeekRule = caregiver?.caregiver?.violates30hoursAWeekRule;
        const violates50PercentADayRule = caregiver?.caregiver?.violates50PercentADayRule;
        const visitCount = caregiver?.events?.filter(ev => ev.title !== 'Lunch break').length || 0;
        const scheduledMinutes = caregiver?.caregiver?.scheduledMinutes || 0;
        const utilizedMinutes = caregiver?.caregiver?.utilizedMinutes || 0;
        const utilizationRate = caregiver?.caregiver?.utilizationRate || 0;
        const utilizedMinutesWithTravelTime =
          caregiver?.caregiver?.utilizedMinutesWithTravelTime || 0;
        const utilizationRateWithTravelTime =
          caregiver?.caregiver?.utilizationRateWithTravelTime || 0;
        const expectedTravelTime = caregiver?.caregiver?.travelTimeMinutes || 0;
        const resourceId = caregiver?.caregiver?.resourceId || 0;
        const caregiverName = `${caregiver?.caregiver?.firstName} ${caregiver?.caregiver?.lastName}`;
        const availabilities = caregiver.caregiver.availabilities || [];

        const mergeAvailabilities = () => {
          availabilities.sort(
            (a, b) => moment(a.startTime, 'h:mm A') - moment(b.startTime, 'h:mm A'),
          );

          const mergedAvailabilities = [];

          let currentStart = null;
          let currentEnd = null;

          // eslint-disable-next-line no-restricted-syntax
          for (const availability of availabilities) {
            const startTime = moment(availability.startTime, 'h:mm A');
            const endTime = moment(availability.endTime, 'h:mm A');

            if (currentEnd && startTime.isSameOrBefore(currentEnd)) {
              currentEnd = moment.max(currentEnd, endTime);
            } else {
              if (currentStart !== null) {
                mergedAvailabilities.push({
                  startTime: currentStart.format('h:mm A'),
                  endTime: currentEnd.format('h:mm A'),
                });
              }
              currentStart = startTime;
              currentEnd = endTime;
            }
          }

          if (currentStart !== null) {
            mergedAvailabilities.push({
              startTime: currentStart.format('h:mm A'),
              endTime: currentEnd.format('h:mm A'),
            });
          }

          return mergedAvailabilities;
        };
        const mergedAvailabilities = mergeAvailabilities(availabilities);
        const otherTeams = allTeamCaregivers
          ?.filter(otherCaregiver => otherCaregiver?.caregiver?.id === caregiver?.caregiver?.id)
          ?.map(otherCaregiver => otherCaregiver?.secondaryTeams)
          ?.flat()
          ?.map(t => t);
        const primaryTeams = allTeamCaregivers
          .filter(otherCaregiver => otherCaregiver?.caregiver?.id === caregiver?.caregiver?.id)
          ?.map(otherCaregiver => otherCaregiver?.primaryTeams)
          ?.flat()
          ?.map(t => t);
        const otherTeamsNames = otherTeams?.map(loc => loc?.name);
        const primaryTeamsArray = primaryTeams?.map(loc => loc?.name);
        const primaryAndSecondaryTeams = [...otherTeamsNames, ...primaryTeamsArray];
        const allTeamsValue = [...otherTeams, ...primaryTeams];

        const violatedSoftConstraints = caregiver?.caregiver?.violatedSoftConstraints || [];
        const violatedHardConstraints = caregiver?.caregiver?.violatedHardConstraints || [];
        const parsedCaregiverData = {
          location: teamName,
          team: teamName,
          teamId,
          otherTeams,
          visitCount,
          scheduledMinutes,
          caregiversOrder,
          utilizedMinutes,
          utilizationRate,
          utilizedMinutesWithTravelTime,
          utilizationRateWithTravelTime,
          expectedTravelTime,
          resourceId,
          primaryAndSecondaryTeams,
          allTeamsValue,
          availabilities: mergedAvailabilities,
          minOrder,
          territoryOrder,
          violatedSoftConstraints,
          violatedHardConstraints,
          violates30hoursAWeekRule,
          violates50PercentADayRule,
          firstName: caregiver?.caregiver?.firstName,
          lastName: caregiver?.caregiver?.lastName,
          caregiver: {
            name: caregiverName,
            id: caregiver?.caregiver?.id,
          },
          teams: caregiver.caregiver.teams,
          region: {
            id: caregiver?.caregiver?.teams[0]?.id,
            name: caregiver?.caregiver?.teams[0]?.name,
          },
        };
        transformedData.push(parsedCaregiverData);
      }
    });
  });
  transformedData.sort((a, b) => {
    if (a.territoryOrder !== b.territoryOrder) {
      return a.territoryOrder - b.territoryOrder;
    }
    return a.minOrder - b.minOrder;
  });
  return transformedData;
};

export const eventsForTableView = data => {
  const eventsArray = [];
  data?.caregivers?.forEach(item => {
    const { caregiver: parentCaregiver, events, primaryTeams } = item;
    if (events && events.length > 0) {
      events
        .filter(
          eventData =>
            !(
              parentCaregiver?.id === eventData?.shadowCaregiver?.id && !!eventData?.shadowCaregiver
            ),
        )
        .forEach(event => {
          const {
            actualDuration,
            actualTimeEnd,
            actualTimeStart,
            arrivalEnd,
            arrivalStart,
            arrivalTime,
            cancellationReason,
            careProgramTasks,
            caregiver,
            client,
            date,
            duration,
            externalId,
            id,
            preferredCaregiver,
            recurrence,
            roadTimeInMinutes,
            shadowCaregiver,
            shadowRoadTimeInMinutes,
            shadowStatus,
            status,
            team,
            violatedHardConstraints,
            violatedSoftConstraints,
            visitDetails,
            visitNotes,
            visitTasks,
            visitType,
          } = event;
          if (event.visitType === 'Lunch break') {
            return;
          }
          eventsArray.push({
            actualDuration,
            actualTimeEnd,
            actualTimeStart,
            arrivalEnd,
            arrivalStart,
            arrivalTime,
            cancellationReason,
            careProgramTasks,
            caregiver,
            client,
            date,
            duration,
            externalId,
            id,
            preferredCaregiver,
            primaryTeams,
            recurrence,
            region: team,
            roadTimeInMinutes,
            shadowCaregiver,
            shadowRoadTimeInMinutes,
            shadowStatus,
            status,
            violatedHardConstraints,
            violatedSoftConstraints,
            visitDetails,
            visitNotes,
            visitTasks,
            visitType,
          });
        });
    }
  });
  const notAllocatedEventsArray = data?.notAllocatedVisits || [];
  const notAllocatedEvents = notAllocatedEventsArray?.map(event => {
    const {
      arrivalEnd,
      arrivalStart,
      arrivalTime,
      caregiver,
      client,
      date,
      duration,
      id,
      preferredGender,
      team,
      violatedHardConstraints,
      visitType,
    } = event;
    return {
      arrivalEnd,
      arrivalStart,
      arrivalTime,
      caregiver: caregiver || null,
      client,
      date,
      duration,
      id,
      preferredGender,
      region: team,
      status: 'Scheduled',
      violatedHardConstraints,
      visitType,
    };
  });
  const cancelledVisitsArray = data?.cancelledVisits || [];
  const cancelledEvents = cancelledVisitsArray?.map(event => {
    const {
      arrivalEnd,
      arrivalStart,
      arrivalTime,
      caregiver,
      client,
      date,
      duration,
      id,
      preferredGender,
      status,
      team,
      violatedHardConstraints,
      visitType,
    } = event;
    return {
      arrivalEnd,
      arrivalStart,
      arrivalTime,
      caregiver: caregiver || null,
      client,
      date,
      duration,
      id,
      preferredGender,
      region: team,
      status,
      violatedHardConstraints,
      visitType,
    };
  });

  const concatCancelledAndNotAllocatedEvents = notAllocatedEvents?.concat(cancelledEvents);
  return eventsArray?.concat(
    concatCancelledAndNotAllocatedEvents.filter(a => eventsArray.every(b => b.id !== a.id)),
  );
};

export const findFirstAndLastAvailability = caregivers => {
  const firstAndLast = {
    first: MIN_SCHEDULER_WORKING_HOUR,
    last: MAX_SCHEDULER_WORKING_HOUR,
  };

  let tempFirst = 23;
  let tempLast = 0;
  if (caregivers?.length > 0) {
    for (let caregiver in caregivers) {
      for (let availabilityDay in caregivers[caregiver].availabilities) {
        const startTime = moment(
          caregivers[caregiver].availabilities[availabilityDay].startTime,
          'h:mm:ss',
        );
        const stopTime = moment(
          caregivers[caregiver].availabilities[availabilityDay].endTime,
          'h:mm:ss',
        );

        if (startTime.hour() < tempFirst) {
          let firstHour = startTime.hour() - 1;
          if (firstHour < 0) {
            firstHour = 0;
          }
          firstAndLast.first = firstHour;
          tempFirst = firstHour;
        }

        if (stopTime.hour() > tempLast) {
          firstAndLast.last = stopTime.hour() + 1;
          tempLast = stopTime.hour() + 1;
        }
      }
    }
  }
  return firstAndLast;
};

export const findMinimumStartAvailability = caregivers => {
  if (caregivers?.length === 0) {
    return MIN_SCHEDULER_WORKING_HOUR;
  }

  const minMoment = caregivers
    ?.map(av => av?.availabilities)
    ?.flat()
    ?.map(availability => moment(availability?.startTime, 'h:mm A'));
  if (!minMoment?.length) {
    return MIN_SCHEDULER_WORKING_HOUR;
  }
  const minHour = minMoment?.reduce((min, current) =>
    current?.hour() < min?.hour() ? current : min,
  );

  return minHour?.hour() === 0 ? 1 : minHour.hour();
};
export const findMaximumEndAvailability = caregivers => {
  if (caregivers?.length === 0) {
    return MAX_SCHEDULER_WORKING_HOUR;
  }

  const maxMoment = caregivers
    ?.map(av => av?.availabilities)
    ?.flat()
    ?.map(availability => moment(availability?.endTime, 'h:mm A'));

  if (!maxMoment?.length) {
    return MAX_SCHEDULER_WORKING_HOUR;
  }
  const maxHour = maxMoment?.reduce((max, current) =>
    current?.hour() > max?.hour() ? current : max,
  );

  return maxHour?.hour();
};

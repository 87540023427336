import moment from 'moment';

export const minHour = events =>
  events?.reduce((min, item) => {
    const arrivalStart = item?.arrivalTimeStart?.toString() ?? item?.start?.toString();
    const timeParts = arrivalStart?.split(' ');
    const hours = parseInt(timeParts[4], 10);
    const isPM = timeParts[1].includes('PM');
    const hours24 = isPM ? hours + 12 : hours;
    return Math.min(min, hours24);
  }, 24);

export const maxHour = events =>
  events?.reduce((max, item) => {
    const arrivalEnd = item?.end?.toString();
    const timeParts = arrivalEnd?.split(' ');
    const hours = parseInt(timeParts[4], 10);
    const isPM = timeParts[1].includes('PM');
    const hours24 = isPM ? hours + 12 : hours;
    const duration = parseInt(item.duration, 10);
    const maxWithDuration = hours24 + Math.floor(duration / 60);
    return Math.max(max, maxWithDuration);
  }, 0);

export const checkIsCreateVisitStepConfirmButtonDisabled = data => {
  const { selectedTimeSlots, step, stepDataLoading, visitData } = data;
  if (!visitData) {
    return true;
  }
  if (step === 0) {
    const { date, duration, visitType, arrivalWindowDuration, timesOfDays } = visitData || {};
    let disable = true;
    if (
      timesOfDays?.length &&
      arrivalWindowDuration &&
      date?.length &&
      duration &&
      visitType &&
      (!visitData.isExactTime ||
        moment(visitData.arrivalEnd).isSameOrBefore(visitData.arrivalStart))
    ) {
      disable = false;
    }
    return disable;
  }
  if (step === 1) {
    return stepDataLoading || !selectedTimeSlots;
  }
  if (step === 2) {
    return stepDataLoading;
  }
  return false;
};

import {
  useAddOneTimeAvailabilityMutation,
  useGetAllCaregiverAvailabilitiesQuery,
} from '../../../../../api/Scheduler/api';
import moment from 'moment';
import { ScheduleCaregiverManagementContext } from '../../../../../pages/Scheduling/context';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DATE_ONLY_STANDARD_FORMAT } from '../../../../../shared/constants';
import AlertContext from '../../../../../components/Alert';
import CustomDialog from '../../../../../components/Dialog';
import SettingsView from './components/SettingsView';
import OptimizerCheck from '../../../../../components/Optimizer/OptimizerCheck';
import { v4 as uuidv4 } from 'uuid';

export default function AddOneTimeAvailability() {
  const { setAlert } = useContext(AlertContext);
  const [hasErrors, setHasErrors] = useState(false);
  const [oneTimeAvailabilityData, setOneTimeAvailabilityData] = useState();
  const [checkData, setCheckData] = useState(null);
  const [openCheckOptomizer, setOpenCheckOptomizer] = useState(false);
  const { calloutDate, closeOneTimeAvailability, showOneTimeAvailabilityData, reFetchCalendarEvents } =
    useContext(ScheduleCaregiverManagementContext);
  
  const { data: availabilitiesData, error: availabilitiesDataError } =
    useGetAllCaregiverAvailabilitiesQuery(
      {
        caregiver: showOneTimeAvailabilityData?.caregiverId,
      },
      {
        refetchOnMountOrArgChange: true,
      },
    );

  const [addOneTimeAvailability, { error: addOneTimeAvailabilityError, isLoading }] =
    useAddOneTimeAvailabilityMutation();

  useEffect(() => {
    const errorData = availabilitiesDataError || addOneTimeAvailabilityError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [setAlert, availabilitiesDataError, addOneTimeAvailabilityError]);

  useEffect(() => {
    if (availabilitiesData) {
      const currentDate = moment(calloutDate, DATE_ONLY_STANDARD_FORMAT).format("YYYY-MM-DD");

      const filteredAvailabilities = Object.values(availabilitiesData?.availabilities).filter(
        (availability) => {
          return availability.effectiveStartDate === currentDate;
        }
      );
      let filteredOneTimeAvailabilities = []
      if (availabilitiesData?.oneTimeAvailabilities){
        filteredOneTimeAvailabilities = Object.values(availabilitiesData?.oneTimeAvailabilities).filter(
          (oneTimeAvailability) => {
            return oneTimeAvailability.effectiveStartDate === currentDate;
          }
        );
      }
      const filteredOneTimeAvailabilitiesFinal = []
      for (let filteredOneTimeAvailability in filteredOneTimeAvailabilities){
        filteredOneTimeAvailabilitiesFinal.push({
          ...filteredOneTimeAvailabilities[filteredOneTimeAvailability],
          temporaryId: uuidv4()
        })
      }
      
      setOneTimeAvailabilityData({
        ...availabilitiesData,
        oneTimeAvailabilities: filteredOneTimeAvailabilitiesFinal,
        availabilities: filteredAvailabilities
      });
    }
  }, [availabilitiesData, setOneTimeAvailabilityData, calloutDate]);

  const isRequiredFieldsNotFilled = useMemo(
    () =>
      !oneTimeAvailabilityData?.oneTimeAvailabilities?.length ||
      oneTimeAvailabilityData?.oneTimeAvailabilities?.some(
        (period) => !period.startTime || !period.endTime,
      ),
    [oneTimeAvailabilityData],
  );

  const closeDialog = useCallback(() => {
    closeOneTimeAvailability();
  }, [closeOneTimeAvailability]);

  const openOptimizer = async () => {
    if (!moment(calloutDate).isSameOrBefore(moment(), 'day')) {
      setOpenCheckOptomizer(true);
    }
    const parsedOneTimeAvailability =
      oneTimeAvailabilityData?.oneTimeAvailabilities.map(
        ({ effectiveStartDate, endTime, startTime }) => ({
          effectiveStartDate,
          endTime,
          startTime,
        }),
      );

    const requestData = {
      ...oneTimeAvailabilityData,
      oneTimeAvailabilities: parsedOneTimeAvailability,
      availabilities: availabilitiesData.availabilities
    };
    setCheckData({date: moment(calloutDate, DATE_ONLY_STANDARD_FORMAT).format("YYYY-MM-DD"), caregiverObj: requestData});
    if (moment(calloutDate).isSameOrBefore(moment(), 'day')) {
      await addOneTimeAvailability(requestData);
      closeOneTimeAvailability();
      reFetchCalendarEvents();
    } else {
      setOpenCheckOptomizer(true);
    }
  }

  const submitOneTimeAvailability = async () => {
    await addOneTimeAvailability(checkData);
    closeOneTimeAvailability();
    reFetchCalendarEvents();
  };

  return (
    <CustomDialog
      cancelButtonName="Cancel"
      cancelCallback={closeDialog}
      customStyles={{ maxWidth: '600px' }}
      disableOnCloseByClickOutside
      disabledSubmit={isRequiredFieldsNotFilled || hasErrors || isLoading}
      openDialog={!!showOneTimeAvailabilityData}
      submitButtonName={isLoading ? 'Saving...' : 'Confirm'}
      submitCallback={openOptimizer}
      title="One Time Availability"
    >
      <SettingsView
        date={calloutDate}
        oneTimeAvailabilityData={oneTimeAvailabilityData}
        setHasErrors={setHasErrors}
        setOneTimeAvailabilityData={setOneTimeAvailabilityData}
        isLoading={isLoading}
      />
      <OptimizerCheck
        data={[checkData]}
        dataObjName="caregiverScheduleObj"
        submitFunction={submitOneTimeAvailability}
        open={openCheckOptomizer}
        openToggle={setOpenCheckOptomizer}
      />
    </CustomDialog>
  );
}

import { Box, Typography } from '@mui/material';
import EditIcon from '../../../../../img/icons/edit-icon.svg';
import DeleteIcon from '../../../../../img/icons/iconButtons/delete-icon.svg';
import { AddTaskContext, CarePlanContext } from '../../../../../pages/ClientDetails/context';
import { instanceOf } from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { COLORS } from '../../../../../shared/styles/Theme';
import CustomDialog from '../../../../../components/Dialog';
import TableRowActions from './TableRowActions';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function TaskActions({ row = '' }) {
  const { localCarePlanTasks, setLocalCarePlanTasks, deleteCarePlanTask } =
    useContext(CarePlanContext);
  const { editTask } = useContext(AddTaskContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  //TODO: Uncomment when completing: https://app.clickup.com/t/8698d2504
  // const swapTasks = (target, currentItem) => {
  //   const copiedTasks = [...localCarePlanTasks];
  //   const currentIndex = localCarePlanTasks.findIndex(({ id }) => id === currentItem);
  //   if (currentIndex === -1) {
  //     return true;
  //   }
  //   const targetIndex = currentIndex + target;
  //   [copiedTasks[targetIndex], copiedTasks[currentIndex]] = [
  //     copiedTasks[currentIndex],
  //     copiedTasks[targetIndex],
  //   ];
  //   const taskToSave = [
  //     ...copiedTasks.filter(({ priority }) => priority === 'High'),
  //     ...copiedTasks.filter(({ priority }) => priority === 'Medium'),
  //     ...copiedTasks.filter(({ priority }) => priority === 'Low'),
  //   ];
  //   setLocalCarePlanTasks(taskToSave);
  //   return true;
  // };

  const cantReplaceNextTask = useMemo(() => {
    const currentTaskIndex = localCarePlanTasks.findIndex(({ id }) => id === row?.id);
    const nextTask = localCarePlanTasks[currentTaskIndex + 1];
    if (!nextTask) {
      return true;
    } else {
      if (
        (nextTask.priority === 'Medium' && row?.priority === 'High') ||
        (nextTask.priority === 'Low' && row?.priority === 'High') ||
        (nextTask.priority === 'Low' && row?.priority === 'Medium')
      ) {
        return true;
      }
      return false;
    }
  }, [localCarePlanTasks, row]);

  const cantReplacePreviousTask = useMemo(() => {
    const currentTaskIndex = localCarePlanTasks.findIndex(({ id }) => id === row?.id);
    if (currentTaskIndex < 1) {
      return true;
    }
    const previousTask = localCarePlanTasks[currentTaskIndex - 1];
    if (!previousTask) {
      return true;
    } else {
      if (
        (previousTask.priority === 'High' && row?.priority === 'Medium') ||
        (previousTask.priority === 'High' && row?.priority === 'Low') ||
        (previousTask.priority === 'Medium' && row?.priority === 'Low')
      ) {
        return true;
      }
      return false;
    }
  }, [localCarePlanTasks, row]);

  const actions = useMemo(() => {
    return [
      {
        icon: <img src={EditIcon} alt="Edit" />,
        key: 'Edit',
        name: 'Edit visit',
        callback: () => {
          editTask(row?.id);
        },
      },
      {
        icon: <img src={DeleteIcon} alt="Edit" />,
        key: 'Delete',
        name: 'Delete visit',
        callback: () => {
          setOpenDeleteDialog(true);
        },
      },
      //TODO: Uncomment when completing: https://app.clickup.com/t/8698d2504
      // {
      //   callback: () => swapTasks(1, row?.id),
      //   disabled: cantReplaceNextTask,
      //   icon: <ArrowDownwardIcon sx={{ fill: COLORS.blue[300], fontSize: '20px' }} />,
      //   label: 'Move down',
      // },
      // {
      //   callback: () => swapTasks(-1, row?.id),
      //   disabled: cantReplacePreviousTask,
      //   icon: <ArrowUpwardIcon sx={{ fill: COLORS.blue[300], fontSize: '20px' }} />,
      //   label: 'Move up',
      // },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cantReplaceNextTask, cantReplacePreviousTask, row?.id, editTask]);

  const deleteTask = useCallback(() => {
    const newTask = localCarePlanTasks.find(task => task.id === row?.id);
    const taskId = newTask ? { id: newTask.id } : null;
    deleteCarePlanTask({
      ...taskId,
    });
    setOpenDeleteDialog(false);
  }, [deleteCarePlanTask, localCarePlanTasks, row]);

  const carePlanTask = useMemo(
    () => localCarePlanTasks.find(task => task.id === row?.id)?.name || '',
    [localCarePlanTasks, row],
  );

  return (
    <div>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenDeleteDialog(false)}
        submitButtonName="confirm"
        submitCallback={deleteTask}
        title="Confirmation"
        openDialog={openDeleteDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            {`The task "${carePlanTask}" will be removed. Do you want to proceed?`}
          </Typography>
        </Box>
      </CustomDialog>
      <TableRowActions menuItems={actions} />
    </div>
  );
}

TaskActions.propTypes = {
  row: instanceOf(Object),
};

export default React.memo(TaskActions);

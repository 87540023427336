import errorIcon from '../../../../../../../img/icons/error-outlined.svg';
import successIcon from '../../../../../../../img/icons/success-outlined.svg';
import React from 'react';
import { COLORS } from '../../../../../../../shared/styles/Theme';
import { v4 as uuidv4 } from 'uuid';

export const MAX_LONG_TEXT_DESCRIPTION_FIELD = 4000;

export const personalInformationFieldsMap = [
  { name: 'Client', key: 'client' },
  { name: 'Location', key: 'address' },
];

export const createVisitSummaryFields = {
  datesColumn: [
    { name: 'Client', key: 'client' },
    { name: 'Address', key: 'address' },
    { name: 'Visit Type', key: 'visitType' },
  ],
  secondColumn: [
    { name: 'Duration', key: 'duration' },
    { name: 'Selected Time Slot', key: 'selectedTimeSlotsText' },
  ],
};

export const createVisitObject = visit => {
  const newId = uuidv4();
  return {
    id: newId,
    clientId: visit.clientId,
    dateCreated: visit.dateCreated,
    preferredLanguages: [],
    preferredSkills: [],
    visitType: visit.visitType,
    duration: visit.duration,
    address: visit.address,
    team: visit.team,
    territory: visit.territory,
    createdBy: 'system',
    active: 'True',
    tasks: [],
    date: visit.date,
    arrivalStart: visit.arrivalStart,
    arrivalEnd: visit.arrivalEnd,
    oneTimeVisit: true,
    careprogramId: newId,
    paddingTime: 0,
    tasks: [],
    violatedSoftConstraints: [],
    version: 1,
    clientName: visit.clientName,
    status: 'Scheduling',
    caregiverId: 'NoCaregiver',
    caregiverName: 'No Caregiver',
    violatedHardConstraints: ['Visit Unallocated'],
    roadTimeInMinutes: 0,
    devInfo: [],
  };
};

export const availabilityData = {
  availableSchedule: [
    {
      arrivalStart: '12:00 PM',
      arrivalEnd: '1:00 PM',
      availableCaregivers: [
        {
          id: '26cb0fe5-49de-3022-b0bf-d5327aff115c',
          firstName: 'Camryn',
          lastName: 'Howe',
        },
        {
          id: '26cb0fe5-49de-4112-b0bf-d5327aff115c',
          firstName: 'Krish',
          lastName: 'Mccarthy',
        },
      ],
      date: '09/05/2023 00:00:00',
      endDate: '',
      exactTime: null,
    },
    {
      arrivalStart: '11:00 AM',
      arrivalEnd: '12:00 PM',
      availableCaregivers: [
        {
          id: '10aa0fe5-49de-4112-b0bf-d5327aff115c',
          firstName: 'Paityn',
          lastName: 'Golden',
        },
        {
          id: '26cb0fe5-49de-4112-c9aa-d5327aff550c',
          firstName: 'Emery',
          lastName: 'Jackson',
        },
        {
          id: '26cb0fe5-49de-0000-b0bf-d5327aff115c',
          firstName: 'Sarah',
          lastName: 'Blanchard',
        },
        {
          id: '26cb0fe5-49de-9911-b0bf-d5327aff115c',
          firstName: 'Desmond',
          lastName: 'Newman',
        },
      ],
      date: '09/06/2023 00:00:00',
      endDate: '',
      exactTime: null,
    },
  ],
  unAvailableSchedule: [
    {
      arrivalStart: '9:00 AM',
      arrivalEnd: '10:00 AM',
      date: '09/04/2023 00:00:00',
      endDate: '',
      exactTime: null,
      availableWindows: [
        {
          arrivalStart: '8:00 AM',
          arrivalEnd: '9:00 AM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          arrivalStart: '8:15 AM',
          arrivalEnd: '9:15 AM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          arrivalStart: '8:30 AM',
          arrivalEnd: '9:30 AM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          arrivalStart: '9:45 AM',
          arrivalEnd: '10:45 AM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          arrivalStart: '10:00 AM',
          arrivalEnd: '11:00 AM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          arrivalStart: '10:15 AM',
          arrivalEnd: '11:15 AM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
      ],
      otherDates: [
        {
          date: '09/08/2023 00:00:00',
          arrivalStart: '9:00 AM',
          arrivalEnd: '10:00 AM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          date: '09/09/2023 00:00:00',
          arrivalStart: '9:00 AM',
          arrivalEnd: '10:00 AM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
      ],
    },
    {
      arrivalStart: '2:00 PM',
      arrivalEnd: '2:30 PM',
      date: '09/10/2023 00:00:00',
      endDate: '',
      exactTime: null,
      availableWindows: [
        {
          arrivalStart: '1:00 PM',
          arrivalEnd: '1:30 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          arrivalStart: '1:15 PM',
          arrivalEnd: '1:45 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          arrivalStart: '1:30 PM',
          arrivalEnd: '2:00 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          arrivalStart: '2:45 PM',
          arrivalEnd: '3:15 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          arrivalStart: '3:00 PM',
          arrivalEnd: '3:30 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          arrivalStart: '3:15 PM',
          arrivalEnd: '3:45 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
      ],
      otherDates: [
        {
          date: '09/01/2023 00:00:00',
          arrivalStart: '2:00 PM',
          arrivalEnd: '2:30 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          date: '09/03/2023 00:00:00',
          arrivalStart: '2:00 PM',
          arrivalEnd: '2:30 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
      ],
    },
    {
      arrivalStart: '6:00 PM',
      arrivalEnd: '6:30 PM',
      date: '09/03/2023 00:00:00',
      endDate: '',
      exactTime: null,
      availableWindows: [
        {
          arrivalStart: '5:00 PM',
          arrivalEnd: '5:30 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          arrivalStart: '5:15 PM',
          arrivalEnd: '5:45 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          arrivalStart: '5:30 PM',
          arrivalEnd: '6:00 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          arrivalStart: '6:45 PM',
          arrivalEnd: '7:15 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          arrivalStart: '7:00 PM',
          arrivalEnd: '7:30 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          arrivalStart: '7:15 PM',
          arrivalEnd: '7:45 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
      ],
      otherDates: [
        {
          date: '09/02/2023 00:00:00',
          arrivalStart: '6:00 PM',
          arrivalEnd: '6:30 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
        {
          date: '09/04/2023 00:00:00',
          arrivalStart: '6:00 PM',
          arrivalEnd: '6:30 PM',
          availableCaregivers: [
            {
              id: '10aa0fe5-10g1-4311-b0bf-d5327aff115c',
              firstName: 'Macy',
              lastName: 'Webster',
            },
            {
              id: '10aa0fe5-49de-3301-b0bf-d5327aff115c',
              firstName: 'Pierre',
              lastName: 'Mcconnell',
            },
            {
              id: '10aa0fe5-49de-2211-b0bf-d5327aff115c',
              firstName: 'Desmond',
              lastName: 'Newman',
            },
          ],
        },
      ],
    },
  ],
};

export const checkingResultsColumns = [
  {
    bgColor: COLORS.green[200],
    color: COLORS.green[600],
    icon: <img src={successIcon} alt="success icon" />,
    getTitle(number, total) {
      return `[${number}/${total}] ${total > 1 ? 'visits' : 'visit'} can be scheduled`;
    },
    type: 'success',
    dataKey: 'availableSchedule',
  },
  {
    bgColor: COLORS.red[200],
    color: COLORS.red[600],
    icon: <img src={errorIcon} alt="error icon" />,
    getTitle(number, total) {
      return `Action required: [${number}/${total}] ${
        total > 1 ? 'visits' : 'visit'
      } can not be scheduled`;
    },
    type: 'error',
    dataKey: 'unAvailableSchedule',
  },
];

export const getBlockedReason = ({ pastDate, holiday, turnOfDay }) => {
  if (pastDate) {
    return 'Past date unavailable';
  }
  if (holiday) {
    return 'Unavailable due holiday';
  }
  if (turnOfDay) {
    return 'Unavailable due Scheduling Engine';
  }
  return null;
};

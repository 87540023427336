import { Box, IconButton } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import CustomTooltip from '../../../../../../components/CustomTooltip';
import { actionsButtonStyles, tableRowActionsWrapper } from './styles';

function TableRowActions({ menuItems = [] }) {
  return (
    <Box sx={tableRowActionsWrapper}>
      {menuItems.map(({ callback, icon, key, name, disabled }) => (
        <CustomTooltip key={key} title={name}>
          <IconButton disabled={disabled} disableRipple onClick={callback} sx={actionsButtonStyles}>
            {icon}
          </IconButton>
        </CustomTooltip>
      ))}
    </Box>
  );
}

TableRowActions.propTypes = {
  menuItems: instanceOf(Array),
};

export default React.memo(TableRowActions);

import { Box, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import PreferredIcon from '../../../../img/icons/preferred-icon.svg';
import { bool, string } from 'prop-types';
import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { COLORS } from '../../../../shared/styles/Theme';

export default function LinkItem({
  link = '',
  name = '',
}) {
  const { closeDialog } = useContext(VisitInfoTypeContext);
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        gap: '5px',
        '& img': { width: '12px' },
      }}
    >
      <Link
        component={RouterLink}
        to={link}
        onClick={() => closeDialog()}
        underline="none"
      >
        <Typography
          sx={{
            color: COLORS.blue[700],
            cursor: 'pointer',
          }}
          variant="body1"
        >
          {name}
        </Typography>
      </Link>
    </Box>
  );
}

LinkItem.propTypes = {
  isPreferredCaregiver: bool,
  link: string,
  name: string,
};

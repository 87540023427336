import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { constraintsIconsTypes } from '../../constants';
import AdditionalVisitDetails from '../AdditionalVisitDetails';
import CaregiverVisitNotes from '../CaregiverVisitNotes';
import ConstraintsAlert from '../ConstraintsAlert';
import VisitInfoFields from '../VisitInfoFields/VisitInfoFields';

export default function VisitInfoTypeContent() {
  const { visitInfoData } = useContext(VisitInfoTypeContext);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {visitInfoData?.violatedHardConstraints?.length > 0 && (
        <ConstraintsAlert
          alertData={{
            reasons: visitInfoData?.violatedHardConstraints,
            title: 'Hard Constraints',
            type: 'error',
          }}
          iconType={constraintsIconsTypes.triangle}
        />
      )}
      {visitInfoData?.violatedSoftConstraints?.length > 0 && (
        <ConstraintsAlert
          alertData={{
            reasons: visitInfoData?.violatedSoftConstraints,
            title: 'Soft Constraints',
            type: 'error',
          }}
          iconType={constraintsIconsTypes.triangle}
        />
      )}
      {visitInfoData?.violatedSoftShadowConstraints?.length > 0 && (
        <ConstraintsAlert
          alertData={{
            reasons: visitInfoData?.violatedSoftShadowConstraints,
            title: 'Shadow Visit Soft Constraints',
            type: 'error',
          }}
          iconType={constraintsIconsTypes.triangle}
        />
      )}
      <VisitInfoFields />
      {!!visitInfoData?.visitDetails?.length && (
        <AdditionalVisitDetails notes={visitInfoData?.visitDetails} />
      )}
      {!!visitInfoData?.visitNotes?.length && (
        <CaregiverVisitNotes notes={visitInfoData?.visitNotes} />
      )}
    </Box>
  );
}

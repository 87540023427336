import { Typography } from '@mui/material';
import moment from 'moment';
import {
  H24_TIME_FORMAT,
  ISO_DATE_ONLY_FORMAT,
  SHORT_DATE_FORMAT,
} from '../../../../../../../../../shared/constants';
import { COLORS } from '../../../../../../../../../shared/styles/Theme';

export const dateHandler = dateString => {
  if (dateString.includes('AM') || dateString.includes('PM')) {
    return dateString;
  } else {
    return moment(dateString, H24_TIME_FORMAT).format('hh:mm A');
  }
};

export const getSummaryData = data => {
  const { effectiveDate, userData, visitData } = data;
  const arrivalWindow = {};

  const initialVisit = data?.initialCareProgramData?.find(x => x.id === visitData?.id);

  const oldArrivalWindowKeys = Object.keys(initialVisit?.days ?? {});
  if (oldArrivalWindowKeys.length && initialVisit?.days[oldArrivalWindowKeys[0]]?.arrivalStart) {
    const oldArrivalWindow = initialVisit?.days[oldArrivalWindowKeys[0]];
    arrivalWindow.oldWindow = `${dateHandler(oldArrivalWindow?.arrivalStart)} - ${dateHandler(
      oldArrivalWindow?.arrivalEnd,
    )}`;
  }

  const newArrivalWindowKeys = Object.keys(data.selectedTimeSlots ?? {});
  if (
    newArrivalWindowKeys.length &&
    data.selectedTimeSlots[newArrivalWindowKeys[0]]?.arrivalStart
  ) {
    const newArrivalWindow = data.selectedTimeSlots[newArrivalWindowKeys[0]];
    arrivalWindow.newWindow = `${dateHandler(newArrivalWindow?.arrivalStart)} - ${dateHandler(
      newArrivalWindow?.arrivalEnd,
    )}`;
  }

  let duration = {
    newDuration: visitData?.duration ?? 0,
  };
  let visitType = {
    newVisitType: visitData?.visitTypeDetails?.name ?? 'Unknown',
  };

  if (initialVisit) {
    if (initialVisit.duration !== duration.newDuration) {
      duration = { ...duration, oldDuration: initialVisit.duration };
    }
    if (initialVisit.visitTypeDetails?.name !== visitType.newVisitType) {
      visitType = { ...visitType, oldVisitType: initialVisit.visitTypeDetails?.name };
    }
  }

  return {
    client: `${userData?.firstName} ${userData?.lastName}`,
    address: `${userData?.street} ${userData?.city}, ${userData?.state}, ${userData?.zipCode}, ${userData?.country}`,
    recurrence: visitData?.recurrence,
    effectiveDate: moment(effectiveDate, ISO_DATE_ONLY_FORMAT).format(SHORT_DATE_FORMAT),
    arrivalWindow,
    duration,
    visitType,
  };
};

export const getSummaryChangesTypography = (data, oldKey, newKey) => {
  return (
    <Typography
      variant="body1"
      sx={{
        color: COLORS.blue[900],
        mb: '16px',
        lineHeight: '20px',
        '& .initial-data-value': {
          textDecoration: data[`${newKey}`] ? 'line-through' : 'none',
          color: data[`${newKey}`] ? '#C7CCD6' : 'inherit',
          pr: '5px',
        },
        '& .selected-data-value': {
          fontStyle: data[`${newKey}`] ? 'italic' : 'normal',
        },
      }}
    >
      <span className="initial-data-value">
        {!data[`${oldKey}`] && !data[`${newKey}`]
          ? 'N/A'
          : data[`${oldKey}`] && data[`${oldKey}`] !== data[`${newKey}`]
          ? `${data[`${oldKey}`]}`
          : ''}
      </span>
      <span className="selected-data-value">
        {data[`${newKey}`] ? `${data[`${oldKey}`] ? ' ' : ''}${data[`${newKey}`]}` : ``}
      </span>
    </Typography>
  );
};

import { Box, Link, Typography } from '@mui/material';
import moment from 'moment';
import { H12_TIME_FORMAT } from '../../../../shared/constants';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { COLORS } from '../../../../shared/styles/Theme';
import {
  comparingFullNames,
  getIconComponent,
  getLargeSizeStatusComponent,
} from '../../../../shared/utils/common';
import VisitCaregiverInfo from './components/VisitCaregiverInfo';
import VisitIcons from './components/VisitIcons';

// import TableViewActions from './components/TableViewActions';

export const DEFAULT_SORT_MODAL = { field: 'arrivalTime', sort: 'asc' };

export const INITIAL_SCHEDULING_FILTERS = {
  client: '',
  caregiver: '',
  jobId: '',
  teams: [],
  status: [],
  show: [],
};
export const violationOptions = [
  'No Violations',
  'Soft Constraints Violations',
  'Hard Constraints Violations',
];
export const statusOptions = [
  'Scheduled',
  'Verified',
  'Completed',
  'OnSite',
  'EnRoute',
  'InProgress',
  'Canceled',
];
const PAGE_SIZE = 20;

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

const comparingScheduledTime = (first, second) => {
  const firstTime = moment(first);
  const secondTime = moment(second);

  if (firstTime.isBefore(secondTime)) {
    return -1;
  }
  if (firstTime.isAfter(secondTime)) {
    return 1;
  }
  return 0;
};

const columns = ({ openVisitInfo = () => {} }) => [
  {
    field: 'icons',
    headerName: '',
    maxWidth: 90,
    sortable: false,
    align: 'right',
    renderCell: ({ row }) => <VisitIcons visitData={row} />,
  },
  {
    field: 'jobId',
    headerName: 'Job ID',
    minWidth: 110,
    renderCell: ({ row }) => (
      <Typography
        variant="body1"
        sx={{
          color: COLORS.blue[700],
          fontWeight: '600',
          fontSize: '14px',
          cursor: 'pointer',
        }}
        onClick={() => openVisitInfo(row)}
      >
        {row.jobId}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'arrivalTime',
    headerName: 'Scheduled Time',
    minWidth: 175,
    renderCell: ({ row }) => (
      <Typography>{moment(row?.arrivalTime).format(H12_TIME_FORMAT)}</Typography>
    ),
    sortComparator: (v1, v2) => comparingScheduledTime(v1, v2),
  },
  {
    field: 'arrivalWindow',
    headerName: 'Arrival Window',
    minWidth: 155,
    maxWidth: 220,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <Typography>
        {row?.arrivalStart !== row?.arrivalEnd
          ? `${moment(row?.arrivalStart).format(H12_TIME_FORMAT)} - ${moment(row.arrivalEnd).format(
              H12_TIME_FORMAT,
            )}`
          : moment(row?.arrivalStart).format(H12_TIME_FORMAT)}
      </Typography>
    ),
  },
  {
    field: 'client',
    headerName: 'Client',
    minWidth: 150,
    flex: 1,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/clients/${row?.clientId}`} underline="none">
        <Typography
          variant="body1"
          sx={{
            color: COLORS.blue[700],
            fontWeight: '600',
            fontSize: '14px',
            cursor: 'pointer',
          }}
        >
          {row?.clientName}
        </Typography>
      </Link>
    ),
    sortComparator: (v1, v2) => comparingFullNames(v1, v2),
  },
  {
    field: 'visitType',
    headerName: 'Visit Type',
    minWidth: 170,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', p: '6px 0' }}>
        <Typography sx={{ mr: '6px' }}>
          {getIconComponent(row?.visitTypeDetails?.name?.toLowerCase())}
        </Typography>
        <Typography variant="body1" sx={{ color: COLORS.blue[700] }}>
          {row?.visitTypeDetails?.name ? row?.visitTypeDetails?.name : '-'}
        </Typography>
      </Box>
    ),
  },
  {
    field: 'duration',
    headerName: 'Duration',
    minWidth: 100,
    renderCell: ({ row }) => <Typography>{row?.duration} min</Typography>,
  },
  {
    field: 'team',
    headerName: 'Team',
    minWidth: 155,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            background: row?.teamDetails?.color?.color,
            width: '16px',
            height: '16px',
            borderRadius: '3px',
            mr: '6px',
          }}
        />
        <Typography>{row?.teamDetails?.name}</Typography>
      </Box>
    ),
  },
  {
    field: 'caregiver',
    headerName: 'Caregiver',
    minWidth: 150,
    flex: 1,
    renderCell: ({ row }) => <VisitCaregiverInfo visitData={row} />,
    sortComparator: (v1, v2) => comparingFullNames(v1, v2),
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 145,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {getLargeSizeStatusComponent(row?.status)}
        {/* <TableViewActions row={row} /> */}
      </Box>
    ),
  },
];

export { columns, PAGE_SIZE, PAGE_SIZE_OPTIONS };

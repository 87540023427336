import { Box } from '@mui/material';
import { useGetCaregiversListQuery } from '../../../../../api/Caregivers/api';
import { func, string } from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { VisitInfoTypeContext } from '../../../../../shared/context';
import AlertContext from '../../../../../components/Alert';
import Loader from '../../../../../components/Loader';
import CustomAutocomplete from '../../AutocompleteSelect';
import SetupActions from './SetupActions';
import { editPreferredCaregiverWrapperStyles } from './styles';
import moment from 'moment';

export default function VisitPreferredCaregiverSetup({
  handleCheckAvailability = () => {},
  setEditingPreferredCaregiver = () => {},
  setPreferredCaregiverId = () => {},
  setPreferredCaregiverName = () => {},
  preferredCaregiverName = '',
  preferredCaregiverId = '',
  onCancel = () => {},
}) {
  const [availableToRecheck, setAvailableToRecheck] = useState(false);
  const [caregiverAvailabilityChecked, setCaregiverAvailabilityChecked] = useState(false);
  const [PreferredCaregiversList, setPreferredCaregiversList] = useState([]);

  const { initialVisitData, temporaryVisitData } = useContext(VisitInfoTypeContext);

  const { setAlert } = useContext(AlertContext);
  const {
    data: caregivers,
    error,
    isLoading: isCaregiversListLoading,
  } = useGetCaregiversListQuery(
    {
      team: temporaryVisitData?.team,
      active: true,
      date: temporaryVisitData?.date,
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  useEffect(() => {
    if (caregivers) {
      const tempPreferredCaregiversList = [];
      for (let cg in caregivers) {
        if (
          caregivers[cg].id !== temporaryVisitData.shadowCaregiverId &&
          caregivers[cg].status === 'Active' &&
          (caregivers[cg]?.primaryTeams?.includes(temporaryVisitData.team) ||
            caregivers[cg]?.secondaryTeams?.includes(temporaryVisitData.team))
        ) {
          tempPreferredCaregiversList.push({
            id: caregivers[cg].id,
            name: `${caregivers[cg].firstName} ${caregivers[cg].lastName}`,
          });
        }
      }
      setPreferredCaregiversList(tempPreferredCaregiversList);
    }
    // eslint-disable-next-line
  }, [caregivers]);

  const selectCaregiver = (_, value) => {
    if (!value) {
      return;
    }
    const selectedCaregiver = caregivers.find(({ id }) => id === value?.id);
    setPreferredCaregiverId(selectedCaregiver.id);
    setPreferredCaregiverName(`${selectedCaregiver.firstName} ${selectedCaregiver.lastName}`);
    if (caregiverAvailabilityChecked) {
      setAvailableToRecheck(true);
    }
  };

  const cancelPrimaryCaregiverEditing = () => {
    setCaregiverAvailabilityChecked(false);
    onCancel();
  };

  const caregiverHasBeenChanged = useMemo(
    () =>
      !moment().isSame(moment(temporaryVisitData?.date), 'day') &&
      (preferredCaregiverId?.length
        ? initialVisitData?.caregiverId !== preferredCaregiverId
        : !!initialVisitData?.caregiverId),
    [initialVisitData, temporaryVisitData, preferredCaregiverId],
  );

  const preferredCaregiverTheSameAsPrimary = useMemo(
    () => preferredCaregiverId === temporaryVisitData?.caregiverId,
    [preferredCaregiverId, temporaryVisitData?.caregiverId],
  );

  const onApplyShadowCaregiver = () => {
    setEditingPreferredCaregiver(false);
    handleCheckAvailability();
  };

  return (
    <Box sx={editPreferredCaregiverWrapperStyles}>
      {isCaregiversListLoading && <Loader />}
      <Box sx={{ display: 'flex', width: '100%', gap: '12px' }}>
        <CustomAutocomplete
          id="caregiver"
          changeValue={selectCaregiver}
          optionsList={PreferredCaregiversList}
          label="Primary Caregiver"
          selectedValue={preferredCaregiverName}
          showAdditionalIcon={temporaryVisitData?.isPreferredCaregiver}
        />
        <SetupActions
          availableToRecheck={availableToRecheck}
          caregiverAvailabilityChecked={caregiverAvailabilityChecked}
          caregiverHasBeenChanged={caregiverHasBeenChanged}
          handleCheckAvailability={onApplyShadowCaregiver}
          onCancel={cancelPrimaryCaregiverEditing}
          preferredCaregiverTheSameAsPrimary={preferredCaregiverTheSameAsPrimary}
        />
      </Box>
    </Box>
  );
}

VisitPreferredCaregiverSetup.propTypes = {
  handleCheckAvailability: func,
  setEditingPreferredCaregiver: func,
  setPreferredCaregiverId: func,
  setPreferredCaregiverName: func,
  preferredCaregiverName: string,
  preferredCaregiverId: string,
  onCancel: func,
};

import { Box, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import arrivalWhiteIcon from '../../../../../img/icons/arrival-window-visit-white.svg';
import arrivalIcon from '../../../../../img/icons/arrival-window-visit.svg';
import preferredCaregiverIcon from '../../../../../img/icons/preferred-icon.svg';
import primaryCaregiverIcon from '../../../../../img/icons/primary-visit-icon.svg';
import moment from 'moment/moment';
import { bool, instanceOf } from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { visitStatusesMap } from '../../../../../shared/constants';
import { COLORS } from '../../../../../shared/styles/Theme';
import { getStatusComponent } from '../../../../../shared/utils/common';
import CustomTooltip from '../../../../../components/CustomTooltip';
import { eventCardInfo, eventCardInfoTitle } from './style';

export default function EventCardInfo({
  checkIfShowArrival = false,
  eventData = {},
  visitWithArrivalWindow = false,
}) {
  const isOnSiteStatus = eventData.status === visitStatusesMap.onSite;
  const isVerified = eventData.status === visitStatusesMap.verified;

  const fontColor =
    !eventData.isShadowVisit && (isOnSiteStatus || isVerified) ? '#ffffff' : COLORS.blue[700];

  const isSetTime = moment(eventData.arrivalStart).isSame(moment(eventData.arrivalEnd));

  const visitType = eventData?.visitTypeDetails?.name;
  const hourValue = eventData?.duration ? Math.floor(eventData.duration / 60) : 0;
  const minuteValue = eventData?.duration ? eventData.duration % 60 : 0;
  const visitTypeValue = `${visitType ? `${visitType} ` : ''}(${
    hourValue > 0 ? `${hourValue}h${minuteValue > 0 ? ' ' : ''}` : ''
  }${minuteValue > 0 ? `${minuteValue}m` : ''})`;

  const arrivalTimeComponent = (
    <Box sx={{ display: 'flex' }}>
      <img
        src={!eventData.isShadowVisit && isOnSiteStatus ? arrivalWhiteIcon : arrivalIcon}
        alt="arrivalIcon"
      />
      <Typography
        sx={{
          ml: '6px',
          color: fontColor,
        }}
        variant="body2"
      >
        {visitWithArrivalWindow
          ? `${moment(eventData.arrivalStart).format('h:mm A')}`
          : `${moment(eventData.arrivalStart).format('h:mm A')} - ${moment(
              eventData.arrivalEnd,
            ).format('h:mm A')}`}
      </Typography>
    </Box>
  );

  const visitDetailComponent = (title, value) => (
    <Box sx={{ display: 'flex' }}>
      <Typography sx={{ color: fontColor }} variant="body2">
        <span style={{ fontWeight: 700 }}>{title}:</span> {value}
      </Typography>
    </Box>
  );

  const isLunchBreak = eventData?.status === 'Lunch Break';

  return eventData.caregiverId ? (
    <Box sx={eventCardInfo}>
      <Box sx={eventCardInfoTitle}>
        <Link component={RouterLink} to={`/caregivers/${eventData.caregiverId}`} underline="none">
          <Typography
            variant="body1"
            sx={{
              color: isOnSiteStatus || isVerified ? '#ffffff' : COLORS.blue[700],
              fontWeight: '600',
              fontSize: '14px',
            }}
          >
            {eventData.caregiverName}
          </Typography>
        </Link>
        {eventData?.preferredCaregiver &&
          eventData?.caregiver?.id === eventData?.preferredCaregiver?.id && (
            <CustomTooltip title="Preferred Caregiver">
              <img src={preferredCaregiverIcon} alt="arrivalIcon" />
            </CustomTooltip>
          )}
        {eventData?.shadowCaregiver && (
          <CustomTooltip title="Primary Caregiver">
            <img src={primaryCaregiverIcon} alt="arrivalIcon" />
          </CustomTooltip>
        )}
      </Box>
      <Box sx={{ display: 'flex', marginBottom: '2px' }}>
        {getStatusComponent(eventData.isShadowVisit ? eventData.shadowStatus : eventData.status)}
      </Box>
      {checkIfShowArrival && arrivalTimeComponent}
      {!isLunchBreak && (
        <>
          {visitDetailComponent('Team', eventData?.teamDetails?.name ?? 'Unknown')}
          {visitDetailComponent('Recurrence', eventData?.oneTimeVisit ? 'One Time' : 'Recurring')}
          {visitDetailComponent('Arrival Type', isSetTime ? 'Set Time' : 'Window')}
          {visitDetailComponent('Visit Type', visitTypeValue)}
        </>
      )}
    </Box>
  ) : (
    <Typography variant="body1" sx={{ color: COLORS.blue[900], fontWeight: 600 }}>
      Not allocated
    </Typography>
  );
}

EventCardInfo.propTypes = {
  checkIfShowArrival: bool,
  eventData: instanceOf(Object),
  visitWithArrivalWindow: bool,
};

import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import repeatedVisit from '../../../../img/icons/visitTypes/repeatedVisit.svg';
import { bool, func } from 'prop-types';
import React, { useContext } from 'react';
import { visitTypeIcons } from '../../../../shared/constants';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { COLORS } from '../../../../shared/styles/Theme';
import JobIdInfo from '../JobIdInfo';
import VisitCost from '../VisitCost';
import { closeButtonStyles, titleHeaderStyles, titleWrapperStyles } from './styles';

export default function Title({ closeDialog = () => {}, isLoading = false }) {
  const { temporaryVisitData } = useContext(VisitInfoTypeContext);
  return (
    <DialogTitle
      sx={{
        width: '100%',
        padding: '24px 28px 24px 28px',
        opacity: isLoading ? 0.1 : 1,
      }}
    >
      <Box sx={titleHeaderStyles}>
        <Box sx={titleWrapperStyles}>
          <Typography variant="h4" noWrap sx={{ lineHeight: '24px' }}>
            {temporaryVisitData?.visitTypeDetails?.name}
          </Typography>
          {temporaryVisitData?.repeated && <img src={repeatedVisit} alt="" />}
          <img
            src={visitTypeIcons[temporaryVisitData?.visitTypeDetails?.name?.toLowerCase()]}
            alt="visit type icon"
          />
          <Divider orientation="vertical" flexItem sx={{ color: COLORS.blue[200] }} />
          <VisitCost cost={temporaryVisitData?.visitCost} />
          <Divider orientation="vertical" flexItem sx={{ color: COLORS.blue[200] }} />
          <JobIdInfo jobId={temporaryVisitData?.jobId} />
        </Box>
        <IconButton onClick={() => closeDialog(null)} sx={closeButtonStyles}>
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
  );
}

Title.propTypes = {
  closeDialog: func,
  isLoading: bool,
};

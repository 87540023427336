import { Box, Typography } from '@mui/material';
import React from 'react';

function SuccessResult({ checkPossibilityData, isLunchBreak = false, shadowVisitCheck = false }) {
  return (
    <Box>
      {checkPossibilityData.visitAvailable ? (
        <>
          <Typography sx={{ ml: '6px' }} variant="body1">
            The {isLunchBreak ? 'lunch break' : shadowVisitCheck ? 'shadow visit' : 'visit'} can be{' '}
            {shadowVisitCheck ? 'scheduled' : 'rescheduled'}
            {(checkPossibilityData.violatedConstraints ?? [])?.length > 0
              ? ' with the above constraints.'
              : '.'}
          </Typography>
          <Typography sx={{ ml: '6px' }} variant="body1">
            Do you want to proceed?
          </Typography>
        </>
      ) : (
        <Typography sx={{ ml: '6px' }} variant="body1">
          The {isLunchBreak ? 'lunch break' : shadowVisitCheck ? 'shadow visit' : 'visit'} cannot be
          rescheduled.
        </Typography>
      )}
    </Box>
  );
}

export default SuccessResult;

import { useGetHolidaysQuery } from '../../../../api/Administration/api';
import { useGetClientsCalendarQuery, useGetUserDataByIdQuery } from '../../../../api/Clients/api';
import { useGetCareProgramTableQuery } from '../../../../api/CareProgram/api';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { ISO_DATE_ONLY_FORMAT, visitStatusesMap } from '../../../../shared/constants';
import { checkIfIsHoliday } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import Loader from '../../../../components/Loader';
import VisitManagementProvider from '../../../../components/VisitInfoTypeProvider/VisitManagementProvider';
import ClientsCalendarsView from './ClientsCalendarsView';
import { ClientsCalendarHeader } from './components/ClientsCalendarHeader';
import CreateOneTimeVisitProvider from './components/CreateVisitProvider/CreateOneTimeVisitProvider';
import './index.css';
import { v4 as uuidv4 } from 'uuid';

export default function ClientsCalendar() {
  const { setAlert } = useContext(AlertContext);
  const [calendarDate, setCalendarDate] = useState(null);
  const [unoptimizedCalendarVisits, setUnoptimizedCalendarVisits] = useState([]);
  const [unoptimizedDate, setUnoptimizedDate] = useState();
  const [isOptimizedPeriod, setIsOptimizedPeriod] = useState(true);
  const [manualLoading, setManualLoading] = useState(false);
  const { id } = useParams();
  const today = moment();
  const mondayDate = today.startOf('isoWeek').format(ISO_DATE_ONLY_FORMAT);
  const formattedDate = calendarDate
    ? moment(calendarDate)?.startOf('isoWeek').format(ISO_DATE_ONLY_FORMAT)
    : null;
  const {
    data: clientEvents,
    refetch: refetchCalendarData,
    error: getClientsCalendarError,
    isLoading: isLoadingCalendar,
    isFetching: isFetchingCalendar,
  } = useGetClientsCalendarQuery(
    {
      clientId: id,
      date: formattedDate || mondayDate,
    },
    { refetchOnMountOrArgChange: true },
  );
  const { data: holidays, error: getHolidaysError } = useGetHolidaysQuery();

  const {
    data: careProgramData,
    isLoading: isCareProgramLoading,
    error: getCareProgramTableError,
    refetch: refetchCareProgramData,
    isFetching: isCareProgramFetching,
  } = useGetCareProgramTableQuery(id);

  const {
    data: originalClientData,
    isLoading: isDataLoading,
    error: userDataError,
  } = useGetUserDataByIdQuery(id);

  useEffect(() => {
    const today = moment().weekday();
    const daysToAdd = 14 - today;
    setUnoptimizedDate(moment().add(daysToAdd, 'days'));
  }, []);

  useEffect(() => {
    if (careProgramData && originalClientData && (formattedDate || mondayDate)) {
      const initialDate = formattedDate ?? mondayDate;
      const momentInitialDate = moment(initialDate);
      const dateList = [0, 1, 2, 3, 4].map(x => momentInitialDate.clone().add(x, 'days'));
      let visitsFromCareProgram = [];
      for (const dateOfWeek of dateList) {
        const filteredCarePrograms = careProgramData.filter(careProgram => {
          const daysOfWeek = Object.keys(careProgram.days);
          return daysOfWeek.includes(`${dateOfWeek.weekday()}`);
        });
        if (filteredCarePrograms.length) {
          for (const filteredCareProgram of filteredCarePrograms) {
            const dateOfWeekTimes = filteredCareProgram.days[dateOfWeek.weekday()];
            const visitArrivalStartArr = dateOfWeekTimes.arrivalStart
              .split(':')
              .map(x => Number(x));
            const visitArrivalEndArr = dateOfWeekTimes.arrivalEnd.split(':').map(x => Number(x));
            const arrivalStart = dateOfWeek
              .clone()
              .set({
                hours: visitArrivalStartArr[0],
                minutes: visitArrivalStartArr[1],
                seconds: 0,
              })
              .format('YYYY-MM-DDTHH:mm:ss');
            const arrivalEnd = dateOfWeek
              .clone()
              .set({ hours: visitArrivalEndArr[0], minutes: visitArrivalEndArr[1], seconds: 0 })
              .format('YYYY-MM-DDTHH:mm:ss');

            const isoDate = dateOfWeek.clone().format(ISO_DATE_ONLY_FORMAT);
            const dateChanges = filteredCareProgram.dateChanges
              ? filteredCareProgram.dateChanges[isoDate]
              : undefined;
            let statusToUse = visitStatusesMap.notAllocated;
            if (dateChanges) {
              if (dateChanges.canceled === true) {
                statusToUse = visitStatusesMap.canceled;
              }
            }

            visitsFromCareProgram.push({
              careprogramId: filteredCareProgram.id,
              status: statusToUse,
              visitType: filteredCareProgram.visitType,
              date: dateOfWeek.clone().format('YYYY-MM-DDTHH:mm:ss'),
              active: 'True',
              territory: originalClientData.territory,
              team: originalClientData.team,
              id: uuidv4(),
              duration: filteredCareProgram.duration,
              clientName: `${originalClientData.firstName} ${originalClientData.lastName}`,
              arrivalStart: arrivalStart,
              arrivalTime: arrivalStart,
              paddingTime: 0,
              arrivalEnd: arrivalEnd,
              tasks: [],
              caregiverName: '',
              caregiverId: 'NoCaregiver',
              violatedSoftConstraints: [],
              address: [
                originalClientData.street,
                originalClientData.city,
                originalClientData.state,
                originalClientData.zipCode,
                originalClientData.country,
              ]
                .filter(x => x?.length)
                .join(', '),
              roadTimeInMinutes: 0,
              clientId: filteredCareProgram.clientId,
              preferredSkills: [],
              violatedHardConstraints: [],
              preferredLanguages: [],
              teamDetails: originalClientData.teamDetails,
              territoryName: originalClientData.territoryName,
              taskDetails: [],
              visitTypeDetails: filteredCareProgram.visitTypeDetails,
              isExactTime: arrivalStart === arrivalEnd,
            });
          }
        }
      }
      setUnoptimizedCalendarVisits(visitsFromCareProgram);
    }
  }, [careProgramData, formattedDate, mondayDate, originalClientData]);

  useEffect(() => {
    const errorData =
      getHolidaysError || getClientsCalendarError || getCareProgramTableError || userDataError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    getHolidaysError,
    getClientsCalendarError,
    getCareProgramTableError,
    userDataError,
    setAlert,
  ]);

  const parsedEvents = useMemo(() => {
    const isNotOptimizedPeriod =
      unoptimizedDate &&
      unoptimizedCalendarVisits &&
      moment(formattedDate ?? mondayDate).isSameOrAfter(moment(unoptimizedDate), 'date')
        ? true
        : false;

    setIsOptimizedPeriod(!isNotOptimizedPeriod);

    const dataToMap = isNotOptimizedPeriod ? unoptimizedCalendarVisits : clientEvents;

    return (
      dataToMap?.map(clientEvent => {
        const thisVisit = { ...clientEvent };
        const arrivalTime = moment(
          thisVisit.arrivalTime ? thisVisit.arrivalTime : thisVisit.arrivalStart,
        );
        const actualTimeStart = thisVisit.actualTimeStart
          ? moment(thisVisit.actualTimeStart)
          : undefined;
        const actualTimeEnd = thisVisit.actualTimeEnd ? moment(thisVisit.actualTimeEnd) : undefined;
        const start = moment(thisVisit?.date)
          .set({
            hour: actualTimeStart ? actualTimeStart.hours() : arrivalTime?.hours(),
            minute: actualTimeStart ? actualTimeStart.minutes() : arrivalTime?.minutes(),
            second: 0,
            millisecond: 0,
          })
          .toDate();
        const end = actualTimeEnd
          ? actualTimeEnd.toDate()
          : moment(start).add(thisVisit.duration, 'minutes').toDate();
        return {
          ...thisVisit,
          arrivalTime: arrivalTime.format('YYYY-MM-DDTHH:mm:ss'),
          start,
          end,
          arrivalTimeDuration: 60,
          ...(actualTimeStart &&
            actualTimeEnd && { actualDuration: actualTimeEnd.diff(actualTimeStart, 'minutes') }),
        };
      }) ?? []
    );
  }, [unoptimizedDate, unoptimizedCalendarVisits, formattedDate, mondayDate, clientEvents]);

  useEffect(() => {
    const now = new Date();
    setTimeout(() => {
      const currentTimeIndicator = document.querySelector('.rbc-current-time-indicator');

      currentTimeIndicator?.classList.add('custom-time-indicator');
      const currentTimeIndicator1 = document.querySelector('.custom-time-indicator');
      const currentTime = now.toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
      });
      currentTimeIndicator1?.setAttribute('data-current-time', currentTime);
    }, 0);
  }, [parsedEvents]);

  const calendarHeader = ({ date }) => {
    const isDateHoliday = checkIfIsHoliday(date, holidays, calendarDate);
    return (
      <ClientsCalendarHeader
        date={date}
        holiday={isDateHoliday?.name ? date : null}
        holidayName={isDateHoliday?.name || ''}
      />
    );
  };

  const dayPropGetter = useCallback(
    date => {
      const isDateHoliday = checkIfIsHoliday(date, holidays, calendarDate);

      const dayProps = {};

      if (isDateHoliday) {
        dayProps.className = 'holiday';
      }
      return dayProps;
    },
    [holidays, calendarDate],
  );
  return (
    <CreateOneTimeVisitProvider
      getCalendarData={refetchCalendarData}
      setManualLoading={setManualLoading}
    >
      <VisitManagementProvider
        reFetchCalendarEvents={refetchCalendarData}
        refetchCareProgramData={refetchCareProgramData}
      >
        {isLoadingCalendar ||
        isCareProgramLoading ||
        isCareProgramFetching ||
        isDataLoading ||
        isFetchingCalendar ||
        manualLoading ? (
          <Loader />
        ) : (
          <ClientsCalendarsView
            calendarHeader={calendarHeader}
            calendarDate={calendarDate}
            dayPropGetter={dayPropGetter}
            clientEvents={parsedEvents}
            setCalendarDate={setCalendarDate}
            isOptimizedPeriod={isOptimizedPeriod}
            careProgramData={careProgramData}
          />
        )}
      </VisitManagementProvider>
    </CreateOneTimeVisitProvider>
  );
}

import { TabContext, TabPanel } from '@mui/lab';
import { Box } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ReportingTitle from './components/Title';
import WeeklySchedulingReport from './views/WeeklySchedulingReport/WeeklySchedulingReport';
import moment from 'moment';

export default function Reporting() {
  const { tab } = useParams();
  const navigate = useNavigate();
  const [reportingWeekStartDate, setReportingWeekStartDate] = useState(
    moment().clone().startOf('isoweek'),
  );

  const handleChange = (_, newValue) => {
    navigate(
      newValue === 'weekly-scheduling-report'
        ? `/reporting/${newValue}/not-allocated`
        : `/reporting/${newValue}`,
    );
  };

  const reportingTabs = useMemo(
    () => [
      {
        title: 'Weekly Scheduling Report',
        key: 'weekly-scheduling-report',
        child: (
          <WeeklySchedulingReport
            reportingWeekStartDate={reportingWeekStartDate}
            setReportingWeekStartDate={setReportingWeekStartDate}
          />
        ),
      },
      // TODO: Develop Report History?
      // {
      //   title: 'Visit History Report',
      //   key: 'visit-history-report',
      //   child: <ReportHistory />,
      // },
    ],
    [reportingWeekStartDate],
  );

  return (
    <Box
      sx={{
        width: '100%',
        typography: 'body1',
        maxHeight: 'calc(100vh - 180px)',
        pr: '20px',
      }}
    >
      <TabContext value={tab}>
        <ReportingTitle
          handleChange={handleChange}
          reportingTabs={reportingTabs}
          reportingWeekStartDate={reportingWeekStartDate}
        />
        <Box>
          {reportingTabs?.map(({ key, child }) => (
            <TabPanel key={key} sx={{ padding: 0 }} value={key}>
              {child}
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </Box>
  );
}

import { Box } from '@mui/material';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import React, { useContext, useEffect } from 'react';
import NumericInput from '../../../../../components/NumericInput';
import ErrorAlert from '../../../../../components/ErrorAlert';

export default function GuaranteedHours() {
  const { profileData, setProfileData, guaranteedHoursError, setGuaranteedHoursError } =
    useContext(CaregiverProfileContext);

  const handleHourChange = value => {
    setProfileData({ ...profileData, guaranteedHours: value ? Number(value) : 0 });
  };

  useEffect(() => {
    if (profileData) {
      if (!profileData.guaranteedHours && profileData.guaranteedHours !== 0) {
        handleHourChange(0);
      } else if (profileData?.guaranteedHours > 120) {
        setGuaranteedHoursError('Guaranteed hours cannot exceed 120');
      } else {
        setGuaranteedHoursError('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  return (
    <Box
      sx={{
        maxWidth: '250px',
        minWidth: '250px',
      }}
    >
      <NumericInput
        changeValue={handleHourChange}
        isRequired
        label="Guaranteed Hours"
        oneTimeVisit
        step={1}
        value={profileData?.guaranteedHours ?? 0}
        max={120}
      />
      {guaranteedHoursError && (
        <ErrorAlert error={guaranteedHoursError} customStyles={{ marginTop: '10px' }} />
      )}
    </Box>
  );
}

import { Box, Typography } from '@mui/material';
import CrossedOutCell from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/CrossedOutCell';
import { instanceOf } from 'prop-types';
import React from 'react';
import { durationAndEffectiveDates } from './styles';
import { dateHandler } from '../utils';

export default function ArrivalWindow({ row = {} }) {
  const actualArrivalWindowKeys = Object.keys(row.days ?? {});
  let actualWindow = '';
  if (actualArrivalWindowKeys.length) {
    const actualArrivalWindow = row.days[actualArrivalWindowKeys[0]];
    actualWindow =
      actualArrivalWindow?.arrivalStart === actualArrivalWindow?.arrivalEnd
        ? `${dateHandler(actualArrivalWindow?.arrivalStart)}`
        : `${dateHandler(actualArrivalWindow?.arrivalStart)} - ${dateHandler(
            actualArrivalWindow?.arrivalEnd,
          )}`;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'start' }}>
      <Box sx={durationAndEffectiveDates}>
        {row.arrivalWindow?.oldWindow &&
          row.arrivalWindow?.oldWindow !== row.arrivalWindow?.newWindow && (
            <CrossedOutCell>
              <Typography component="span" sx={{ width: 'max-content' }}>
                {row.arrivalWindow?.oldWindow}
              </Typography>
            </CrossedOutCell>
          )}
        <Typography
          sx={{
            width: 'max-content',
            fontWeight: row.oldDuration || row.oldDuration === 0 ? 600 : 400,
          }}
        >
          {row.arrivalWindow?.newWindow ? row.arrivalWindow?.newWindow : actualWindow}
        </Typography>
      </Box>
    </Box>
  );
}

ArrivalWindow.propTypes = {
  row: instanceOf(Object),
};

import { Box, IconButton } from '@mui/material';
import EditIcon from '../../../../../img/icons/edit-icon.svg';
import CancelIcon from '../../../../../img/icons/iconButtons/cancel-icon.svg';
import DeleteIcon from '../../../../../img/icons/iconButtons/delete-icon.svg';
import { timeOfRequestActionsButtonStyles,  timeOfRequestActionsButtonStylesText} from '../../../../../pages/CaregiverDetails/view/PtoAndCallouts/styles';
import { EditCareProgramContext } from '../../../../../pages/ClientDetails/context';
import { instanceOf } from 'prop-types';
import React, { useContext } from 'react';
import CustomTooltip from '../../../../../components/CustomTooltip';
import { careProgramVisitActionsWrapper } from './CareProgramManagementProvider/components/styles';

function VisitActions({ visit = {}, visitEditTypes = {} }) {
  const {
    setEditedVisitData,
    showRemoveProgramVisit,
    showRemoveEndDate,
    showCancelChanges,
    updatedCareProgram,
  } = useContext(EditCareProgramContext);

  const hasOtherVisit = updatedCareProgram.some(
    item => item.id === visit.id && item.updateId !== visit.updateId,
  );
  const canBeCanceled =
    visit.changesType === 'new' ||
    visit.changesType === 'add' ||
    (visit.changesType === 'old' && !visit.effectiveDateEnd && hasOtherVisit);
  const canBeEdited =
    (visit.changesType === 'old' && !visit.effectiveDateEnd && !hasOtherVisit) ||
    visit.changesType === 'add' || (visit.changesType === 'edit' && !visit.effectiveDateEnd && !hasOtherVisit);
  const canBeDeleted = 
  (visit.changesType === 'old' && !visit.effectiveDateEnd && !hasOtherVisit) || 
  visit.changesType === 'new' || (visit.changesType === 'edit' && !visit.effectiveDateEnd && !hasOtherVisit);

  const canEndDateBeDeleted = (visit.changesType === 'old' && visit.effectiveDateEnd && !hasOtherVisit) || visit.changesType === 'delete';

  return (
    <Box sx={careProgramVisitActionsWrapper}>
      {canBeEdited && (
        <CustomTooltip title="Edit visit">
          <span>
            <IconButton
              aria-label="Edit visit"
              disabled={!!visit.effectiveDateEnd}
              disableRipple
              onClick={() => {
                setEditedVisitData(visit);
              }}
              sx={timeOfRequestActionsButtonStyles}
            >
              <img src={EditIcon} alt="Edit" />
            </IconButton>
          </span>
        </CustomTooltip>
      )}
      {canBeCanceled && (
        <CustomTooltip title="Cancel Changes">
          <span>
            <IconButton
              aria-label="Cancel Changes"
              disableFocusRipple
              disableRipple
              onClick={() => showCancelChanges(visit)}
              sx={timeOfRequestActionsButtonStyles}
            >
              <img src={CancelIcon} alt="Cancel" />
            </IconButton>
          </span>
        </CustomTooltip>
      )}
      {canBeDeleted && (
        <CustomTooltip title="Delete visit">
          <span>
            <IconButton
              aria-label="Delete visit"
              disabled={!!visit.effectiveDateEnd}
              disableRipple
              onClick={() => showRemoveProgramVisit(visit)}
              sx={timeOfRequestActionsButtonStyles}
            >
              <img src={DeleteIcon} alt="Delete" />
            </IconButton>
          </span>
        </CustomTooltip>
      )}
      {/* {canEndDateBeDeleted && (
        <CustomTooltip title="Edit End Date">
          <span>
            <IconButton
              aria-label="Edit End Date"
              onClick={() => editEndDate(visit)}
              sx={timeOfRequestActionsButtonStylesText}
            >
              Edit End Date
            </IconButton>
          </span>
        </CustomTooltip>
      )} */}
      {canEndDateBeDeleted && (
        <CustomTooltip title="Delete End Date">
          <span>
            <IconButton
              aria-label="Delete End Date"
              onClick={() => showRemoveEndDate(visit)}
              sx={timeOfRequestActionsButtonStylesText}
            >
              Remove End Date
            </IconButton>
          </span>
        </CustomTooltip>
      )}
    </Box>
  );
}

VisitActions.propTypes = {
  visit: instanceOf(Object),
  visitEditTypes: instanceOf(Object),
};

export default React.memo(VisitActions);

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, IconButton, Typography } from '@mui/material';
import DeleteIcon from '../../../../img/icons/iconButtons/delete-icon.svg';
import OutlinedPreferredIcon from '../../../../img/icons/preferred-icon-outlined.svg';
import { bool, func, object, string } from 'prop-types';
import React, { useContext, useState } from 'react';
import AlertContext from '../../../../components/Alert';
import Loader from '../../../../components/Loader';
import {
  customOutlinedButton,
  infoFieldWrapper,
  infoKeyField,
  infoRowEditableInfoWrapper,
  infoRowWrapperStyles,
} from '../../styles';
import EditIconButton from '../EditIconButton';
import LinkItem from '../VisitInfoFields/LinkItem';
import {
  VisitPreferredCaregiverSetup,
  VisitUnmarkPreferredCaregiver,
} from './VisitPreferedCaregiverSetup';
import moment from 'moment';
import OptimizerCheck from '../../../Optimizer/OptimizerCheck';
import { api } from '../../../../api/';
import { VisitInfoTypeContext } from '../../../../shared/context';

export default function VisitPrimaryCaregiver({
  caregiverName = '',
  caregiverId = '',
  preferredCaregiverName = '',
  preferredCaregiverId = '',
  enableToEditPrimaryCaregiver = false,
  visitInfoData = {},
  onUpdateVisitDetails = () => {},
}) {
  const { setAlert } = useContext(AlertContext);
  const [optimizerData, setOptimizerData] = useState({});
  const [openCheckOptimizer, setOpenCheckOptimizer] = useState(false);
  const [thisPreferredCaregiverId, setThisPreferredCaregiverId] = useState(
    preferredCaregiverId ? preferredCaregiverId : caregiverId,
  );
  const [thisPreferredCaregiverName, setThisPreferredCaregiverName] = useState(
    preferredCaregiverName ? preferredCaregiverName : caregiverName,
  );
  const [editingVisit, setEditingVisit] = useState(false);
  const [editingCareprogram, setEditingCareprogram] = useState(false);
  const [editingPreferredCaregiver, setEditingPreferredCaregiver] = useState(false);
  const [removingPreferredCaregiver, setRemovingPreferredCaregiver] = useState(null);

  const { reFetchCalendarEvents } = useContext(VisitInfoTypeContext);

  const submitFunction = async () => {
    await updateCareProgram();
    reFetchCalendarEvents();
  };

  const updateVisit = async tempVisitData => {
    const apiData = await api('PATCH', 'crud', 'visits', tempVisitData);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    }
    setEditingVisit(false);
  };

  const updateCareProgram = async () => {
    const thisDate = moment(visitInfoData?.date).format('YYYY-MM-DD');
    const payload = {
      id: visitInfoData?.careprogramId,
    };
    const apiData = await api('GET', 'crud', 'careprogram', payload);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      if (apiData?.data) {
        const thisCareProgram = apiData?.data;
        if (
          thisCareProgram?.dateChanges &&
          Object.keys(thisCareProgram?.dateChanges).includes(thisDate)
        ) {
          thisCareProgram.dateChanges[thisDate].preferredCaregiverId = thisPreferredCaregiverId;
        } else {
          thisCareProgram.dateChanges = {
            ...thisCareProgram.dateChanges,
            [thisDate]: {
              preferredCaregiverId: thisPreferredCaregiverId,
            },
          };
        }
        const apiDataPatch = await api('PATCH', 'crud', 'careprogram', thisCareProgram);
        if (apiDataPatch.error) {
          setAlert({
            apiData: apiDataPatch,
            type: 'error',
          });
        }
      }
    }
    setEditingCareprogram(false);
  };

  const handleCheckAvailability = async remove => {
    const tempVisitData = {
      ...visitInfoData,
      preferredCaregiverId: thisPreferredCaregiverId,
      preferredCaregiverName: preferredCaregiverName,
    };
    if (preferredCaregiverId === null || remove) {
      delete tempVisitData.preferredCaregiverId;
      delete tempVisitData.preferredCaregiverName;
    }
    setEditingVisit(false);
    if (moment(visitInfoData?.date).isAfter(moment(), 'date')) {
      setEditingCareprogram(true);
      setOptimizerData(tempVisitData);
      setOpenCheckOptimizer(true);
    } else if (moment(visitInfoData?.date).isSame(moment(), 'date')) {
      await updateVisit(tempVisitData);
      await updateCareProgram();
      reFetchCalendarEvents();
    }
    setEditingVisit(false);
  };

  const onCancel = () => {
    setThisPreferredCaregiverId(null);
    setThisPreferredCaregiverName(null);
    setOptimizerData({});
    setEditingPreferredCaregiver(false);
    setEditingCareprogram(false);
  };

  return (
    <Box>
      {editingVisit && editingCareprogram && <Loader />}
      {!editingVisit && !editingCareprogram && (
        <Box sx={{ ...infoRowWrapperStyles, alignItems: 'start' }}>
          <Typography sx={infoKeyField}>
            {preferredCaregiverId ? 'Preferred' : 'Primary'} Caregiver:
          </Typography>
          <Box sx={infoFieldWrapper}>
            <Box sx={infoRowEditableInfoWrapper}>
              {caregiverId !== 'NoCaregiver' && caregiverName ? (
                <LinkItem link={`/caregivers/${caregiverId}`} name={thisPreferredCaregiverName} />
              ) : (
                <Typography>-</Typography>
              )}
              {enableToEditPrimaryCaregiver && !!preferredCaregiverId && (
                <Box sx={{ display: 'flex', gap: '12px' }}>
                  <EditIconButton
                    onEdit={setEditingPreferredCaregiver}
                    tooltip="Edit preferred caregiver"
                  />
                  <IconButton
                    aria-label="delete"
                    disableRipple
                    onClick={setRemovingPreferredCaregiver}
                    sx={{ p: 0 }}
                  >
                    <img src={DeleteIcon} alt="Delete" />
                  </IconButton>
                </Box>
              )}
            </Box>
            {enableToEditPrimaryCaregiver && !preferredCaregiverId && (
              <Button
                color="secondary"
                disabled={!enableToEditPrimaryCaregiver}
                disableElevation
                onClick={setEditingPreferredCaregiver}
                startIcon={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                    <AddIcon sx={{ fontSize: '14px' }} />
                    <img src={OutlinedPreferredIcon} alt="preferred-icon" />
                  </Box>
                }
                sx={customOutlinedButton}
                variant="outlined"
              >
                Preferred caregiver
              </Button>
            )}
          </Box>
        </Box>
      )}
      {removingPreferredCaregiver && (
        <VisitUnmarkPreferredCaregiver
          handleCheckAvailability={handleCheckAvailability}
          cancelUnmarkCaregiver={() => setRemovingPreferredCaregiver(false)}
          setThisPreferredCaregiverId={setThisPreferredCaregiverId}
          setThisPreferredCaregiverName={setThisPreferredCaregiverName}
          preferredCaregiverName={preferredCaregiverName}
        />
      )}
      {editingPreferredCaregiver && (
        <VisitPreferredCaregiverSetup
          handleCheckAvailability={handleCheckAvailability}
          setEditingPreferredCaregiver={setEditingPreferredCaregiver}
          setPreferredCaregiverId={setThisPreferredCaregiverId}
          setPreferredCaregiverName={setThisPreferredCaregiverName}
          preferredCaregiverName={thisPreferredCaregiverName}
          preferredCaregiverId={thisPreferredCaregiverId}
          onCancel={onCancel}
        />
      )}
      <OptimizerCheck
        data={[optimizerData]}
        dataObjName="visitChangeObj"
        submitFunction={submitFunction}
        open={openCheckOptimizer}
        openToggle={setOpenCheckOptimizer}
        cancelFunction={onCancel}
        handleCheckAvailability={handleCheckAvailability}
      />
    </Box>
  );
}

VisitPrimaryCaregiver.propTypes = {
  caregiverName: string,
  caregiverId: string,
  preferredCaregiverName: string,
  preferredCaregiverId: string,
  enableToEditPrimaryCaregiver: bool,
  visitInfoData: object,
  onUpdateVisitDetail: func,
};

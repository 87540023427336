import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { AddTaskContext, CarePlanContext } from '../../../../../../pages/ClientDetails/context';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import ButtonsGroup from '../../../../../../components/ButtonsGroup';
import { viewTypeButtons } from '../../constants';

export default function Header() {
  const { id, tabSubView } = useParams();
  const navigate = useNavigate();
  const { addNewTask } = useContext(AddTaskContext);
  const { taskOrderChanged, editMode, setEditMode, handleCancelEditMode } =
    useContext(CarePlanContext);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexShrink: 0,
        marginBottom: '20px',
      }}
    >
      <ButtonsGroup
        buttons={viewTypeButtons}
        changeSelected={value => navigate(`/clients/${id}/care-plan/${value}`)}
        selected={tabSubView}
      />
      {tabSubView === 'current' &&
        (editMode ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              color="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => addNewTask()}
              sx={{ ml: '28px' }}
              disableElevation
            >
              Task
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleCancelEditMode}
              sx={{ ml: '28px' }}
              disableElevation
            >
              Cancel
            </Button>

            {/* TODO: Uncomment when completing: https://app.clickup.com/t/8698d2504 */}
            {/* <Button
              color="primary"
              variant="contained"
              // TODO: Add api call which updates the order of these tasks
              onClick={() => null}
              sx={{ ml: '28px' }}
              disableElevation
              disabled={!taskOrderChanged}
            >
              Save Task Order
            </Button> */}
          </Box>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={() => setEditMode(true)}
            sx={{ ml: '28px' }}
            disableElevation
          >
            Edit Mode
          </Button>
        ))}
    </Box>
  );
}

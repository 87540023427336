import { COLORS } from '../../../shared/styles/Theme';

export const detailsWrapper = {
  padding: '0 10px 10px 16px',
  border: `1px solid ${COLORS.blue[200]}`,
  borderTop: 'none',
  background: COLORS.blue[50],
};

export const notAllocateDetailsWrapper = {
  minHeight: '181px',
  whiteSpace: 'normal',
  textAlign: 'start',
  padding: '10px 10px 10px 10px',
  border: `1px solid ${COLORS.blue[200]}`,
  borderTop: 'none',
  backgroundColor: '#F7F9FF',
  mt: '-30px',
};

export const detailsInfoKeysStyles = {
  fontSize: '12px',
  minWidth: '140px',
  textAlign: 'start',
};

export const caregiverInfoWrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginLeft: '8px',
  flexGrow: 1,
};

export const chipArrivalStartStyle = {
  position: 'absolute',
  top: '-7px',
  right: '-61px',
  zIndex: 1003,
  padding: ' 3px 6px 2px 6px',
  borderRadius: '30px',
  background: COLORS.brown[600],
  color: '#fff',
  fontSize: '11px',
};

export const chipArrivalWindowStyle = {
  position: 'absolute',
  right: '-98px',
  width: '92px',
  zIndex: 1003,
  padding: '4px 6px',
  borderRadius: '4px',
  background: '#fff',
  boxShadow: '0px 0px 12px 0px #2C2D2E29',
};

export const customSwitcher = {
  '& .MuiSwitch-thumb': {
    background: '#fff',
  },
  '& .MuiSwitch-track': {
    background: COLORS.blue[20],
  },
  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    background: COLORS.green[500],
    opacity: 1,
  },
};

export const schedulingToolbarWrapper = {
  display: 'flex',
  justifyContent: 'flex-end',
  height: '40px',
  mb: '20px',
  gap: 3,
};

export const schedulingToolbarTableWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '40px',
  gap: '20px',
  pr: '20px',
  mb: '20px',
};

export const editModeWrapper = {
  position: 'absolute',
  right: '-8px',
  zIndex: 10,
  width: '100%',
  padding: '0 24px',
};

export const customNavigationWrapper = {
  display: 'flex',
  minWidth: '370px',
  alignItems: 'center',
  width: 'fit-content',
  zIndex: 100,
};

const customSelectStyles = {
  minWidth: '100px',
  flex: '1 0 0',
  fontSize: '15px',
  height: '42px',
  color: `${COLORS.blue[500]}`,
  borderRadius: '8px',
  '&.MuiSvgIcon-root': {
    fill: `${COLORS.blue[300]}`,
    width: '20px',
    height: '20px',
    top: 11,
  },
  '&.MuiOutlinedInput-root': {
    border: 'none',
    backgroundColor: COLORS.blue[50],
    '.MuiOutlinedInput-input': {
      padding: '10px 12px',
      paddingRight: '28px',
    },
    fieldset: {
      border: 'none',
    },
  },
  '&.MuiOutlinedInput-root.has-items': {
    backgroundColor: COLORS.yellow[200],
    color: COLORS.blue[900],
  },
};

const customSelectItem = {
  display: 'flex',
  fontSize: '15px',
  color: `${COLORS.blue[900]}`,
  backgroundColor: '#ffffff',
  '&.select-all-item': {
    borderBottom: `1px solid ${COLORS.blue[200]}`,
    paddingBottom: '8px',
  },
  '&:hover, &:active, &:focus, ': {
    backgroundColor: '#ffffff',
  },
  '&:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
  },
  '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:active,  &.Mui-selected:focus': {
    backgroundColor: '#ffffff',
  },
};

const violatedSoftConstraintsStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  pr: '6px',
  mt: '-27px',
  mb: '7px',
};

export const notAllocatedBox = minHeight => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '15px',
  height: '100%',
  minHeight: minHeight,
  backgroundColor: '#F7F9FF',
});

export const notAllocatedExpanded = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#F7F9FF',
};

export const editScheduleHolidayWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  left: '110px',
  mt: '16px',
};

export const dragAndDropSuccessAlertStyles = {
  position: 'absolute',
  zIndex: 10000,
  right: '50%',
  left: '80%',
  top: '14px',
  minWidth: '300px',
  fontSize: '12px',
  boxShadow: '0px 0px 12px rgba(44, 45, 46, 0.16)',
};

export const editContentWrapper = {
  padding: '16px 10px',
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
};

export { customSelectItem, customSelectStyles, violatedSoftConstraintsStyles };

export const expandButtonStyles = {
  display: 'flex',
  gap: 0.5,
  padding: '0 8px',
  background: COLORS.brown[600],
  borderRadius: '50px',
  alignItems: 'center',
  color: '#fff',
  cursor: 'pointer',
};
export const optimizeSchedulingStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  mr: '16px',
};
export const optimizeButtonStyles = {
  height: '40px',
  padding: '8px 12px',
  fontSize: '12px',
  borderRadius: '6px',
  color: COLORS.blue[500],
  '& .MuiButton-startIcon': {
    mr: '6px',
  },
  ':hover': {
    backgroundColor: 'transparent',
  },
};
export const selectTabStyles = {
  maxWidth: '200px',
  '& .animated-chosen-item ': {
    display: 'flex',
    alignItems: 'center',
    mt: '2px',
  },
};

import { COLORS } from '../../../../../../../../shared/styles/Theme';

export const recurrentAvailabilityWrapper = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ecebf5',
  border: `1px solid ${COLORS.blue[200]}`,
  borderRadius: '8px',
  overflow: 'hidden',
};

export const getRecurrentAvailabilityContentWrapperStyles = visitSummaryHeight => ({
  overflow: 'auto',
  maxHeight: `calc(100vh - 350px - ${visitSummaryHeight}px)`,
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
});

export const checkAvailabilitySchedulePatternWrapperStyles = {
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  gap: '26px',
  pb: '16px',
  mb: '16px',
};

export const refreshButton = {
  marginTop: '32px',
};

export const checkAvailabilityWrapperStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '40px',
  width: '100%',
  margin: 'auto',
};

import { Box, Typography } from '@mui/material';
import warningIcon from '../../../../../../../../img/icons/warning-icon.svg';
import { bool, func, object, string } from 'prop-types';
import React from 'react';
import CustomDialogAlert from '../../../../../../../../components/CustomDialogAlert';
import ErrorAlert from '../../../../../../../../components/ErrorAlert';
import CustomSelect from '../../../../../../../../components/Select';
import { sortBy } from 'lodash';
import moment from 'moment';
import { arrivalWindowDurationOptions } from '../../../../../CareProgram/constants';

function ArrivalSlots({
  isSelectedTimeOutOfRange = false,
  pastTimeError = null,
  filtersData = {},
  changeTypeOfDay = () => {},
  changeArrivalWindowDuration = () => {},
  visitParams = {},
}) {
  const displayedTimesOfDay = sortBy(filtersData?.timeOfDays, timeOfDay =>
    moment(timeOfDay.from, 'HH:mm').toDate(),
  ).map(timeOfDay => {
    const fromTime = moment(timeOfDay.from, 'HH:mm');
    const toTime = moment(timeOfDay.to, 'HH:mm');

    return {
      ...timeOfDay,
      name: `${timeOfDay.name} (${fromTime.format('ha')} - ${toTime.format('ha')})`,
    };
  });
  const updateTimeOfDay = inputValue => {
    const configDataChosen = filtersData?.timeOfDays?.filter(
      timeOfDay =>
        inputValue.value?.includes(timeOfDay.id) ||
        inputValue.value?.filter(inputValueEntry => inputValueEntry?.id === timeOfDay.id).length >
          0,
    );
    changeTypeOfDay({ field: inputValue.field, value: configDataChosen });
  };
  return (
    <>
      <CustomDialogAlert
        customStyles={{ mt: isSelectedTimeOutOfRange ? '12px' : '0px' }}
        icon={warningIcon}
        showAlert={isSelectedTimeOutOfRange}
        title="Out of business hours"
        type="warning"
      />
      {pastTimeError && (
        <>
          <ErrorAlert error={pastTimeError} />
          <Box sx={{ width: '100%', height: '12px' }} />
        </>
      )}
      <Box sx={{ display: 'flex', gap: '20px' }}>
        <CustomSelect
          hasSelectAllOption
          id="timeOfDays"
          isMultiselect
          isRequired
          options={displayedTimesOfDay}
          placeHolder="Time of Day"
          selectedValue={visitParams?.timesOfDaysDetails}
          setValue={updateTimeOfDay}
        />
        <CustomSelect
          endIcon={
            <Typography sx={{ mr: '32px' }} variant="body1">
              min
            </Typography>
          }
          id="arrivalWindowDuration"
          isRequired
          options={arrivalWindowDurationOptions}
          placeHolder="Arrival Window Duration"
          selectedValue={String(visitParams?.arrivalWindowDuration)}
          setValue={changeArrivalWindowDuration}
          withIcon
        />
      </Box>
    </>
  );
}

ArrivalSlots.propTypes = {
  arrivalStart: string,
  changeExactTime: func,
  isSelectedTimeOutOfRange: bool,
  pastTimeError: string,
  filtersData: object,
  changeTypeOfDay: func,
  changeArrivalWindowDuration: func,
  visitParams: object,
};

export default React.memo(ArrivalSlots);

import { Box } from '@mui/material';
import { bool, func, instanceOf } from 'prop-types';
import React, { useContext, useRef } from 'react';
import { DEFAULT_MAX_WORKING_HOUR, DEFAULT_MIN_WORKING_HOUR } from '../../../../shared/constants';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { getEventBoxClass, isWeekend } from '../../../../shared/utils/common';
import DefaultFiveDaysCalendar from '../../../../components/Calendar';
import SevenDaysCalendar from '../../../../components/SevenDaysCalendar';
import ClientCalendarEvent from './components/ClientCalendarEvent';
import ClientCalendarToolbar from './components/ClientCalendarToolbar';
import SevenDaysCalendarToolbar from './components/SevenDaysCalendarToolbar';
import { maxHour, minHour } from './utils';
import moment from 'moment';

export default function ClientsCalendarsView({
  calendarHeader = () => {},
  calendarDate = null,
  clientEvents = [],
  dayPropGetter = {},
  setCalendarDate = () => {},
  isOptimizedPeriod = true,
  careProgramData = [],
}) {
  const eventWrapperRef = useRef();
  const weekendEvents = clientEvents?.some(event => isWeekend(event.start));
  const { onOpenVisitInfoTypeDialog, onOpenCareProgramVisitPlaceholderDialog } =
    useContext(VisitInfoTypeContext) || {};

  const onSelectEvent = data => {
    if (isOptimizedPeriod) {
      onOpenVisitInfoTypeDialog(data);
    } else {
      onOpenCareProgramVisitPlaceholderDialog(data, careProgramData);
    }
  };

  const getMinHour = () => (minHour(clientEvents) ? minHour(clientEvents) - 1 : 0);

  return (
    <Box sx={{ height: 'calc(100vh - 200px)' }} ref={eventWrapperRef}>
      {weekendEvents ? (
        <SevenDaysCalendar
          className="client-seven-days-calendar"
          customToolbar={SevenDaysCalendarToolbar}
          dateHeaderWrapper={calendarHeader}
          dayPropGetter={dayPropGetter}
          eventComponent={ClientCalendarEvent}
          eventStyleGetter={getEventBoxClass}
          events={clientEvents}
          date={calendarDate}
          firstVisit={clientEvents?.length > 0 ? getMinHour() : DEFAULT_MIN_WORKING_HOUR}
          lastVisit={
            clientEvents?.length > 0 ? maxHour(clientEvents) + 1 : DEFAULT_MAX_WORKING_HOUR
          }
          onNavigate={date => setCalendarDate(date)}
          onSelectEvent={onSelectEvent}
          toolbar
        />
      ) : (
        <DefaultFiveDaysCalendar
          className="client-five-days-calendar"
          customToolbar={ClientCalendarToolbar}
          date={calendarDate}
          dateHeaderWrapper={calendarHeader}
          eventComponent={ClientCalendarEvent}
          eventStyleGetter={getEventBoxClass}
          dayPropGetter={dayPropGetter}
          events={clientEvents}
          firstVisit={7}
          lastVisit={20}
          onNavigate={date => setCalendarDate(date)}
          onSelectEvent={onSelectEvent}
          toolbar
          slotPropGetter={date => {
            let slotProps = {};
            const slotTime = moment(date);
            const shiftsStart = moment(date).set({
              hour: 7,
              minute: 0,
              second: 0,
              millisecond: 0,
            });
            const shiftsEnd = moment(date).set({
              hour: 20,
              minute: 59,
              second: 59,
              millisecond: 0,
            });
            if (slotTime.isBefore(shiftsStart) || slotTime.isAfter(shiftsEnd)) {
              slotProps.style = {
                background: '#000',
              };
            }
            return slotProps;
          }}
        />
      )}
    </Box>
  );
}

ClientsCalendarsView.propTypes = {
  calendarHeader: func,
  calendarDate: instanceOf(Date),
  clientEvents: instanceOf(Array),
  dayPropGetter: instanceOf(Object),
  setCalendarDate: func,
  isOptimizedPeriod: bool,
  careProgramData: instanceOf(Array),
};

import { Box, Typography } from '@mui/material';
import CrossedOutCell from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/CrossedOutCell';
import { instanceOf } from 'prop-types';
import React from 'react';
import { getIconComponent } from '../../../../../../../../../../shared/utils/common';
import { customCellWrapper } from './styles';

export default function VisitType({ row = {} }) {
  return (
    <Box sx={customCellWrapper}>
      {row.oldVisitType && row.oldVisitType !== row?.visitTypeDetails?.name && (
        <CrossedOutCell>
          {getIconComponent(row.oldVisitType.toLowerCase())}
          <Typography variant="h5" sx={{ ml: '8px', fontWeight: 400 }}>
            {row.oldVisitType}
          </Typography>
        </CrossedOutCell>
      )}
      <Box sx={{ display: 'flex' }}>
        {getIconComponent(row?.visitTypeDetails?.name)}
        <Typography variant="h5" sx={{ ml: '8px' }}>
          {row?.visitTypeDetails?.name}
        </Typography>
      </Box>
    </Box>
  );
}

VisitType.propTypes = {
  row: instanceOf(Object),
};

import { Box, Typography } from '@mui/material';
import { ClientProfilesContext } from '../../../../../pages/ClientDetails/context';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../../../../shared/styles/Theme';
import CustomSelect from '../../../../../components/Select';
import { additionalSelectStyles } from '../styles';
import { sortBy } from 'lodash';

export default function BlockedCaregiversSelector() {
  const { clientData, setClientData, caregivers } = useContext(ClientProfilesContext);
  const [tempProfileData, setTempProfileData] = useState({
    blockedCaregivers: clientData.blockedCaregivers,
    blockedCaregiversNames: clientData.blockedCaregiversNames,
  });

  const onSelectChange = value => {
    const caregiverDetails = [];
    for (let caregiver in value.value) {
      const caregiverDetail = caregivers.find(
        thisCaregiver => thisCaregiver.id === value.value[caregiver],
      );
      caregiverDetails.push(caregiverDetail);
    }
    const blockedCaregivers = caregiverDetails.map(caregiver => caregiver.id);
    const blockedCaregiversNames = caregiverDetails.map(
      caregiver => `${caregiver.firstName} ${caregiver.lastName}`,
    );
    const newProfileData = {
      blockedCaregivers: blockedCaregivers,
      blockedCaregiversNames: blockedCaregiversNames,
    };
    setTempProfileData(newProfileData);
  };

  const onClose = () => {
    const newProfileData = {
      ...clientData,
      ...tempProfileData,
    };
    setClientData(newProfileData);
  };

  const filteredCaregivers = useMemo(() => {
    return caregivers?.length
      ? caregivers.filter(
          caregiver =>
            caregiver?.primaryTeams?.includes(clientData?.team) ||
            caregiver?.secondaryTeams?.includes(clientData?.team),
        )
      : [];
  }, [caregivers, clientData.team]);

  useEffect(() => {
    setTempProfileData({
      blockedCaregivers: clientData.blockedCaregivers,
      blockedCaregiversNames: clientData.blockedCaregiversNames,
    });
  }, [clientData]);

  return (
    <Box>
      <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
        {caregivers?.length ? (
          <CustomSelect
            addStyles={additionalSelectStyles}
            id="blockedCaregivers"
            placeHolder="Blocked caregivers"
            options={sortBy(filteredCaregivers, ['firstName']).map(caregiver => ({
              id: caregiver.id,
              name: `${caregiver.firstName} ${caregiver.lastName}`,
            }))}
            isLoading={false}
            isMultiselect
            selectedValue={tempProfileData?.blockedCaregivers?.map(caregiverId => ({
              id: caregiverId,
            }))}
            setValue={onSelectChange}
            onClose={onClose}
          />
        ) : (
          <Typography
            sx={{ color: COLORS.blue[500], fontStyle: 'italic' }}
          >{`No caregivers with a client team ${clientData?.team?.name}`}</Typography>
        )}
      </Box>
    </Box>
  );
}

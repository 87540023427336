import { Box, Collapse, Typography } from '@mui/material';
import { bool, func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { infoKeyField, infoRowEditableInfoWrapper, infoRowWrapperStyles } from '../styles';
import EditIconButton from './EditIconButton';
import VisitDateAndArrivalTimeSetup from './VisitDateAndArrivalTimeSetup';
import moment from 'moment';
import { H12_TIME_FORMAT } from '../../../shared/constants';

export default function DateAndArrivalTimeFields({
  editArrivalTime = false,
  enableToEditScheduledTime = false,
  isExactTime = false,
  setEditArrivalTime = () => {},
  scheduledValue = '',
}) {
  const [scheduledTime, setScheduledTime] = useState(null);

  useEffect(() => {
    if (scheduledValue) {
      setScheduledTime(moment(scheduledValue).format(H12_TIME_FORMAT));
    }
  }, [scheduledValue]);

  return (
    <Box>
      {!editArrivalTime && (
        <Box sx={infoRowWrapperStyles}>
          <Typography sx={infoKeyField}>
            {isExactTime ? 'Scheduled Time:' : 'Scheduled Arrival:'}
          </Typography>
          <Box sx={infoRowEditableInfoWrapper}>
            <Typography variant="body1" component="span">
              {scheduledTime}
            </Typography>
            {enableToEditScheduledTime && (
              <EditIconButton onEdit={() => setEditArrivalTime(true)} tooltip="Edit arrival time" />
            )}
          </Box>
        </Box>
      )}
      <Collapse in={editArrivalTime} timeout={300}>
        <VisitDateAndArrivalTimeSetup cancelEditing={() => setEditArrivalTime(false)} />
      </Collapse>
    </Box>
  );
}

DateAndArrivalTimeFields.propTypes = {
  editArrivalTime: bool,
  enableToEditScheduledTime: bool,
  isExactTime: bool,
  setEditArrivalTime: func,
  scheduledValue: string,
};

import { Box, Dialog, DialogContent } from '@mui/material';
import { bool, element, func, string } from 'prop-types';
import React, { createElement } from 'react';
import { TransitionSlide } from '../../../../shared/constants';
import { emptyPageWrapper, visitInfoWrapper } from '../../styles';
import Actions from './Actions';
import Title from './Title';
import Loader from '../../../Loader';

export default function CareProgramVisitPlaceholderDialog({
  cancelCallback = () => {},
  openDialog = false,
  updateVisit = () => {},
  localLoading = false,
  visitStatus = '',
  children = createElement('div'),
}) {
  return (
    <Dialog
      disableEscapeKeyDown
      keepMounted
      open={!!openDialog}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '680px',
        },
      }}
      TransitionComponent={TransitionSlide}
    >
      {localLoading && (
        <Box sx={emptyPageWrapper}>
          <Loader />
        </Box>
      )}
      <Title closeDialog={cancelCallback} />
      <DialogContent sx={visitInfoWrapper}>{children}</DialogContent>
      <Actions closeDialog={cancelCallback} updateVisit={updateVisit} visitStatus={visitStatus} />
    </Dialog>
  );
}

CareProgramVisitPlaceholderDialog.propTypes = {
  cancelCallback: func,
  openDialog: bool,
  updateVisit: func,
  localLoading: bool,
  visitStatus: string,
  children: element,
};

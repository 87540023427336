import { Box, Typography } from '@mui/material';
import hardConstraintsWarning from '../../../../img/icons/error-icon.svg';
import warningIconYellow from '../../../../img/icons/warning-icon.svg';
import { bool, instanceOf } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { violationTooltipComponent } from '../../../../shared/utils/common';
import { showTeamStyles, violatedSoftConstraintsStyles, visitEventInfoWrapper } from '../../styles';
import EventCardInfo from './EventCardInfo';
import {
  hardConstraintNotOnDemandReasons,
  hardConstraintReasons,
  softConstraintReasons,
} from '../../../../pages/Reporting/views/WeeklySchedulingReport/constants';

export default function SchedulingEventMainInfoCard({
  arrivalEnd = {},
  arrivalStart = {},
  checkIfShowArrival = false,
  event = {},
  hovered = false,
}) {
  const [violatedHardConstraints, setViolatedHardConstraints] = useState([]);
  const [violatedSoftConstraints, setViolatedSoftConstraints] = useState([]);

  useEffect(() => {
    const tempViolatedSoftConstraints =
      event?.violatedSoftConstraints?.filter(
        constraint =>
          constraint?.length &&
          softConstraintReasons.filter(reason => constraint.toLowerCase().startsWith(reason))
            .length > 0,
      ) ?? [];

    const tempViolatedHardConstraints =
      event?.violatedHardConstraints?.filter(
        constraint =>
          constraint?.length &&
          [
            hardConstraintReasons,
            ...(event?.visitTypeDetails?.name !== 'On Demand'
              ? hardConstraintNotOnDemandReasons
              : []),
          ].filter(reason => constraint.toLowerCase().startsWith(reason)).length > 0,
      ) ?? [];

    setViolatedSoftConstraints(tempViolatedSoftConstraints);
    setViolatedHardConstraints(tempViolatedHardConstraints);
  }, [event]);

  return (
    <Box sx={{ height: '100%', overflow: 'hidden' }} className="event-before-box">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: '5px 14px',
          height: '100%',
        }}
      >
        <Box sx={visitEventInfoWrapper}>
          <EventCardInfo
            arrivalStart={arrivalStart}
            arrivalEnd={arrivalEnd}
            checkIfShowArrival={checkIfShowArrival}
            eventData={event}
          />
        </Box>
        <Box
          className="violations-icons"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mt: event.duration < 45 ? '-20px' : '0',
          }}
        >
          {violatedHardConstraints.length > 0 && (
            <Box sx={violatedSoftConstraintsStyles}>
              {violationTooltipComponent({
                violations: violatedHardConstraints,
                icon: hardConstraintsWarning,
                status: event.status,
                violationType: 'hard',
              })}
            </Box>
          )}
          {violatedSoftConstraints.length > 0 && (
            <Box sx={violatedSoftConstraintsStyles}>
              {violationTooltipComponent({
                violations: violatedSoftConstraints,
                icon: warningIconYellow,
                status: event.status,
                violationType: 'soft',
              })}
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          ...showTeamStyles,
          background: hovered && event?.teamDetails?.color?.color,
          height: hovered ? '24px' : '3px',
        }}
      >
        {hovered && (
          <Typography
            sx={{
              color: event?.teamDetails?.color?.fontColor,
            }}
          >
            {event?.teamDetails?.name}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

SchedulingEventMainInfoCard.propTypes = {
  arrivalEnd: instanceOf(Object),
  arrivalStart: instanceOf(Object),
  checkIfShowArrival: bool,
  event: instanceOf(Object),
  hovered: bool,
};

import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { H12_TIME_FORMAT, SHORT_DATE_FORMAT } from '../../../../shared/constants';
import {
  infoFieldWrapper,
  infoKeyField,
  infoRowEditableInfoWrapper,
  infoRowWrapperStyles,
} from '../../styles';
import { object } from 'prop-types';
import { getLargeSizeStatusComponent } from '../../../../shared/utils/common';

export default function CareProgramVisitPlaceholderDialogContent({ visitInfoData = {} }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Primary Caregiver:</Typography>
        <Box sx={infoFieldWrapper}>
          <Box sx={infoRowEditableInfoWrapper}>
            <Typography>-</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Status:</Typography>
        <Box sx={infoFieldWrapper}>
          <Box sx={infoRowEditableInfoWrapper}>
            {getLargeSizeStatusComponent(visitInfoData?.status)}
          </Box>
        </Box>
      </Box>
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Scheduled Date</Typography>
        <Box sx={infoRowEditableInfoWrapper}>
          <Typography variant="body1" component="span">
            {moment(visitInfoData?.date).format(SHORT_DATE_FORMAT)}
          </Typography>
        </Box>
      </Box>
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>
          {visitInfoData?.isExactTime ? 'Scheduled Time:' : 'Scheduled Arrival:'}
        </Typography>
        <Box sx={infoRowEditableInfoWrapper}>
          <Typography variant="body1" component="span">
            {moment(visitInfoData?.arrivalTime).format(H12_TIME_FORMAT)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

CareProgramVisitPlaceholderDialogContent.propTypes = {
  visitInfoData: object,
};

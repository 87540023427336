import { Box, Checkbox, Typography } from '@mui/material';
import {
  VerificationModeVisitNotesContext,
  VerificationTasksContext,
} from '../../../../../../pages/Scheduling/context';
import { instanceOf } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TASKS_TYPES } from '../../../../../../shared/constants';
import AlertContext from '../../../../../../components/Alert';
import Loader from '../../../../../../components/Loader';
import VisitNotes from './VisitNotes/VisitNotes';
import VisitTasksManagement from './VisitTasksManagement';
import { pendingTasksErrorText } from './styles';
import { useGetConfigQuery } from '../../../../../../api/Config/api';
import { COLORS } from '../../../../../../shared/styles/Theme';

const visitNotesFields = [
  {
    key: 'visitNotes',
    title: 'Caregiver Visit Notes',
    updatedFieldKey: 'visitNotes',
    fieldKey: 'visitDetails',
    oldValueKey: 'visitDetails',
  },
];

function VisitRow({ row = {} }) {
  const { setAlert } = useContext(AlertContext);
  const [temporaryVisitData, setTemporaryVisitData] = useState(false);
  const [showedIncompleteTasksType, setShowedIncompleteTasksType] = useState(
    TASKS_TYPES.careProgramTasks,
  );
  const [showedCompletedTasksType, setShowedCompletedTasksType] = useState(
    TASKS_TYPES.careProgramTasks,
  );
  const [showedIncompleteTasks, setShowedIncompleteTasks] = useState([]);
  const [showedCompletedTasks, setShowedCompletedTasks] = useState([]);
  const {
    cancelVisitNotes,
    changePreVisitNotes,
    onSelectVisit,
    preVisitNotes,
    selectionModel,
    submitVisitNote,
    idsFlaggedAsIncomplete,
  } = useContext(VerificationModeVisitNotesContext);

  const {
    data: configData,
    isLoading: isLoadingConfigs,
    error: configError,
  } = useGetConfigQuery(['categoryTypes'], {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (configError) {
      setAlert({
        errorData: configError,
        type: 'error',
      });
    }
  }, [configError, setAlert]);

  const parsedRowId = `${row.id}1`;

  const changeNotes = ({ value, updatedField, field, oldValue }) => {
    const updatedNotes = {
      [field]: oldValue,
      [updatedField]: value,
    };
    changePreVisitNotes(updatedNotes, row.id);
  };

  useEffect(() => {
    if (row && row?.fullVisitData) {
      setTemporaryVisitData(row.fullVisitData);
    }
  }, [row]);

  const updateTemporaryVisitData = useCallback(
    value => {
      setTemporaryVisitData(prevState => ({ ...prevState, ...value }));
    },
    [setTemporaryVisitData],
  );

  const memoizedProviderValue = useMemo(
    () => ({
      temporaryVisitData,
      updateTemporaryVisitData,
    }),
    [temporaryVisitData, updateTemporaryVisitData],
  );

  const displayError = useMemo(
    () =>
      (row?.tasks?.filter(
        task => task?.status?.toLowerCase() === 'todo' || task.status?.toLowerCase() === 'to do',
      )?.length > 0 ??
        false) &&
      (idsFlaggedAsIncomplete?.filter(idFlaggedAsIncomplete =>
        row?.id?.startsWith(idFlaggedAsIncomplete),
      )?.length > 0 ??
        false),
    [idsFlaggedAsIncomplete, row],
  );

  const incompleteTasks = useMemo(() => {
    return (
      row?.tasks?.filter(
        task => task?.status?.toLowerCase() === 'todo' || task.status?.toLowerCase() === 'to do',
      ) ?? []
    );
  }, [row?.tasks]);

  const completedTasks = useMemo(() => {
    return (
      row?.tasks?.filter(
        task => task?.status?.toLowerCase() !== 'todo' && task.status?.toLowerCase() !== 'to do',
      ) ?? []
    );
  }, [row?.tasks]);

  return (
    <VerificationTasksContext.Provider value={memoizedProviderValue}>
      <Box sx={{ display: 'flex', flex: '1 0 0', pb: '16px' }}>
        {isLoadingConfigs && <Loader />}
        {row.type !== 'visitNote' ? (
          <Checkbox
            checked={selectionModel?.includes(row?.id)}
            onChange={() => onSelectVisit(row.id)}
          />
        ) : (
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                maxWidth: '100%',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              {displayError && (
                <Typography variant="body2" sx={pendingTasksErrorText}>
                  Visit cannot be verified until tasks are no longer in a pending state
                </Typography>
              )}
              {visitNotesFields.map(({ key, title, updatedFieldKey, fieldKey, oldValueKey }) => (
                <VisitNotes
                  key={key}
                  cancelCallback={() => cancelVisitNotes(row?.id)}
                  changeDetails={e =>
                    changeNotes({
                      value: e.target?.value,
                      updatedField: updatedFieldKey,
                      field: fieldKey,
                      oldValue: preVisitNotes?.[row.id]?.[oldValueKey],
                    })
                  }
                  initialVisitNotes={preVisitNotes?.[parsedRowId]?.[key] || row?.[key]}
                  label={title}
                  maxLength={4000}
                  submitCallback={() => submitVisitNote(row?.id, key)}
                  textFieldId={row?.id}
                  textFieldValue={preVisitNotes?.[parsedRowId]?.[key] || row?.[key] || ''}
                  disabled
                />
              ))}
              {incompleteTasks.length > 0 && (
                <VisitTasksManagement
                  key={'incomplete'}
                  showedTasksType={showedIncompleteTasksType}
                  setShowedTasksType={setShowedIncompleteTasksType}
                  showedTasks={showedIncompleteTasks}
                  setShowedTasks={setShowedIncompleteTasks}
                  titleInsert={`${incompleteTasks.length} Incomplete Task${
                    incompleteTasks.length > 1 ? 's' : ''
                  }`}
                  temporaryVisitData={temporaryVisitData}
                  configData={configData}
                  titleColor={COLORS.red[600]}
                />
              )}
              {completedTasks.length > 0 && (
                <VisitTasksManagement
                  key={'completed'}
                  showedTasksType={showedCompletedTasksType}
                  setShowedTasksType={setShowedCompletedTasksType}
                  showedTasks={showedCompletedTasks}
                  setShowedTasks={setShowedCompletedTasks}
                  titleInsert={`${completedTasks.length} Completed Task${
                    completedTasks.length > 1 ? 's' : ''
                  }`}
                  temporaryVisitData={temporaryVisitData}
                  configData={configData}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </VerificationTasksContext.Provider>
  );
}

VisitRow.propTypes = {
  row: instanceOf(Object),
};

export default React.memo(VisitRow);

import moment from 'moment/moment';
import { getStatusClassName } from '../../shared/utils/common';
import { checkIfDraggable } from './constants';

export const getDndEventBoxClass = event => {
  const classNameData = { className: 'dnd-event' };
  if (event?.title === 'arrival') {
    classNameData.className = classNameData.className.concat(
      ` arrival ${event?.resourceId} ${event?.clientName} ${event?.team} arrival${event?.eventId}`,
    );
  }
  if (event?.title === 'arrivalTime') {
    classNameData.className = classNameData.className.concat(
      ` arrivalTime  arrivalTime${event?.id}   ${event?.resourceId}`,
    );
  }

  if (event?.visitTypeDetails?.name === 'Lunch Break') {
    classNameData.className = classNameData.className.concat(` lunchBreak`);
  }

  if (event?.type === 'PTO') {
    classNameData.className = classNameData.className.concat(` ptoCalendarEvent`);
  }

  if (event?.caregiverId && event?.caregiverId === 'NoCaregiver') {
    classNameData.className = classNameData.className.concat(' not-allocated');
  }

  if (!checkIfDraggable(event) || event.type === 'PTO') {
    classNameData.className = classNameData.className.concat(' non-draggable');
  }
  classNameData.className = getStatusClassName(classNameData.className, event);
  return classNameData;
};

export const calculateCalendarVerticalScrollPosition = ({ currentDate, firstVisit, top }) => {
  const scrollMinutes = Math.floor(top * (60 / 160));
  const startOfDay = moment(firstVisit, 'HH:mm');
  const end = moment(startOfDay).add(scrollMinutes, 'minutes');
  return moment(currentDate)
    .set({
      hour: end?.hours(),
      minute: end?.minutes(),
      second: 0,
      millisecond: 0,
    })
    .toDate();
};

export const getCaregiverConstraints = (caregiver, eventsList) => {
  const tempEventList = [...eventsList];
  const tempCaregiver = { ...caregiver };
  const caregiverEvents = tempEventList.filter(event => event.caregiverId === tempCaregiver.id);
  for (const event of caregiverEvents) {
    const knownHardConstraints = [];
    if (event.violatedHardConstraints?.length) {
      for (const constraint of knownHardConstraints) {
        if (
          event.violatedHardConstraints.includes(constraint) &&
          !tempCaregiver.violatedHardConstraints?.includes(constraint)
        ) {
          if (tempCaregiver.violatedHardConstraints) {
            tempCaregiver.violatedHardConstraints.push(constraint);
          } else {
            tempCaregiver.violatedHardConstraints = [constraint];
          }
        }
      }
    }
    const knownSoftConstraints = [
      'No Lunch Break',
      'Caregiver In Overtime',
      'Caregiver Scheduled For Less Then 4 hours',
      'Caregiver utilization rate is only',
      "Caregiver's travel time accounts is",
    ];
    if (event.violatedSoftConstraints?.length) {
      for (const constraint of knownSoftConstraints) {
        if (
          (event.violatedSoftConstraints.includes(constraint) ||
            event.violatedSoftConstraints.find(field => field.startsWith(constraint))) &&
          !tempCaregiver.violatedSoftConstraints?.includes(constraint)
        ) {
          if (tempCaregiver.violatedSoftConstraints) {
            tempCaregiver.violatedSoftConstraints.push(
              event.violatedSoftConstraints.find(field => field.startsWith(constraint)),
            );
          } else {
            tempCaregiver.violatedSoftConstraints = [constraint];
          }
        }
      }
    }
  }
  return tempCaregiver;
};

const getAvailabilityWithOneTime = (caregiver, currentDate, day) => {
  let thisAvailability = { ...caregiver?.availabilities };
  if (caregiver?.pendingChanges?.availabilities) {
    for (let availDate in caregiver?.pendingChanges?.availabilities) {
      const availDateMoment = moment(availDate, 'YYYY-MM-DD');
      if (availDateMoment <= moment(currentDate)) {
        thisAvailability = { ...caregiver?.pendingChanges?.availabilities[availDate] };
      }
    }
  }
  let oneTimeAvailabilities = [];
  if (caregiver?.oneTimeAvailabilities) {
    oneTimeAvailabilities = caregiver?.oneTimeAvailabilities?.filter(oneTimeAvailability =>
      moment(oneTimeAvailability.effectiveStartDate).isSame(moment(currentDate), 'day'),
    );
  }
  if (thisAvailability[day]) {
    thisAvailability[day] = [...thisAvailability[day], ...oneTimeAvailabilities];
  } else {
    thisAvailability[day] = [...oneTimeAvailabilities];
  }
  return thisAvailability;
};

const getAvailAbilityWindows = (caregiver, currentDate) => {
  const day = String(moment(currentDate).day() - 1);
  const availabilityWithOneTime = getAvailabilityWithOneTime(caregiver, currentDate, day);
  const availabilityWindows = [];
  if (day in availabilityWithOneTime) {
    for (let availabilityIndex in availabilityWithOneTime[day]) {
      const availability = availabilityWithOneTime[day][availabilityIndex];
      const currentDateFormated = moment(currentDate).format('YYYY-MM-DD');
      const start = moment(
        currentDateFormated + 'T' + availability?.startTime,
        'YYYY-MM-DDTHH:mm:ss',
      );
      const end = moment(currentDateFormated + 'T' + availability?.endTime, 'YYYY-MM-DDTHH:mm:ss');
      let shouldAppend = true;
      for (let windowIndex in availabilityWindows) {
        const window = availabilityWindows[windowIndex];
        if (start <= window['start'] && end >= window['start'] && end <= window['end']) {
          window['start'] = start;
          shouldAppend = false;
        }
        if (end >= window['end'] && start >= window['start'] && start <= window['end']) {
          window['end'] = end;
          shouldAppend = false;
        }
        if (start <= window['start'] && end >= window['end']) {
          window['start'] = start;
          window['end'] = end;
          shouldAppend = false;
        } else if (start >= window['start'] && end <= window['end']) {
          shouldAppend = false;
        }
      }
      if (shouldAppend) {
        availabilityWindows.push({ start: start, end: end });
      }
    }
  }
  return availabilityWindows;
};

const getScheduledMinutes = (caregiverEvents, caregiver, currentDate) => {
  const availabilityWindows = getAvailAbilityWindows(caregiver, currentDate);
  const windowVisits = [];
  const windowWorkedMinutes = [];
  for (let availabilityWindowIndex in availabilityWindows) {
    const availabilityWindow = availabilityWindows[availabilityWindowIndex];
    windowVisits.push([]);
    for (let visit in caregiverEvents) {
      const arrivalTime = moment(caregiverEvents[visit]['arrivalTime'], 'YYYY-MM-DDTHH:mm:ss');
      if (
        arrivalTime.isSameOrAfter(availabilityWindow['start']) &&
        arrivalTime.isSameOrBefore(availabilityWindow['end'])
      ) {
        windowVisits[availabilityWindowIndex].push(caregiverEvents[visit]);
      }
    }
  }
  for (let theseVisitsIndex in windowVisits) {
    if (windowVisits[theseVisitsIndex].length > 0) {
      const theseVisits = windowVisits[theseVisitsIndex].sort((a, b) =>
        moment(a['arrivalTime']).diff(moment(b['arrivalTime'])),
      );
      const firstVisit = moment(theseVisits[0].arrivalTime, 'YYYY-MM-DDTHH:mm:ss');
      const lastVisit = moment(
        theseVisits[theseVisits.length - 1].arrivalTime,
        'YYYY-MM-DDTHH:mm:ss',
      ).add(theseVisits[theseVisits.length - 1].duration, 'minutes');
      const thisMinutesWorked = lastVisit.diff(firstVisit, 'minutes');
      windowWorkedMinutes.push(thisMinutesWorked);
    } else {
      windowWorkedMinutes.push(0);
    }
  }
  let minutesWorked = 0;
  windowWorkedMinutes.forEach(minutes => {
    minutesWorked += minutes;
  });
  return minutesWorked;
};

export const calculateResourceStatistics = (
  caregiver,
  eventsList,
  currentDate,
  allTimeOffRequests,
) => {
  if (caregiver.id !== 'NoCaregiver') {
    const caregiverPto = allTimeOffRequests?.filter(
      pto => pto.caregiverId === caregiver.id && pto.status === 'Approved',
    );
    const currentDay = new Date(currentDate).getDay();
    const availabilities = caregiver.availabilities?.[currentDay - 1];
    let availableMinutes = 0;
    let scheduledMinutes = 0;
    let availabilityPtoConflictsPresent = false;
    let totalPTOMinutes = 0;
    for (let availability in availabilities) {
      let caregiverShiftStart = moment(currentDate).set({
        hours: Number(availabilities[availability].startTime.substring(0, 2)),
        minutes: 0,
        seconds: 0,
      });
      let caregiverShiftEnd = moment(currentDate).set({
        hours: Number(availabilities[availability].endTime.substring(0, 2)),
        minutes: 0,
        seconds: 0,
      });
      const overLappingPtoStart = caregiverPto?.find(
        pto =>
          moment(pto.from).isBefore(caregiverShiftStart, 'minutes') &&
          moment(pto.to).isAfter(caregiverShiftStart, 'minutes'),
      );
      const overLappingPtoEnd = caregiverPto?.find(
        pto =>
          moment(pto.from).isBefore(caregiverShiftEnd, 'minutes') &&
          moment(pto.to).isAfter(caregiverShiftEnd, 'minutes'),
      );
      const overLappingPto = caregiverPto?.find(
        pto =>
          moment(pto.from).isBefore(caregiverShiftStart, 'minutes') &&
          moment(pto.to).isAfter(caregiverShiftEnd, 'minutes'),
      );
      if (overLappingPtoStart && !overLappingPto) {
        caregiverShiftStart = moment(overLappingPtoStart?.to);
        availabilityPtoConflictsPresent = true;
      } else if (overLappingPtoEnd && !overLappingPto) {
        caregiverShiftEnd = moment(overLappingPtoEnd?.from);
        availabilityPtoConflictsPresent = true;
      }
      let ptoMinutes = 0;
      const availabilityPtoConflicts =
        caregiverPto?.filter(
          pto =>
            moment(pto.from).isSameOrAfter(caregiverShiftStart, 'minutes') &&
            moment(pto.from).isBefore(caregiverShiftEnd, 'minutes') &&
            moment(pto.to).isAfter(caregiverShiftStart, 'minutes') &&
            moment(pto.to).isSameOrBefore(caregiverShiftEnd, 'minutes'),
        ) ?? [];
      if (availabilityPtoConflicts?.length) {
        availabilityPtoConflictsPresent = true;
      }
      for (const availabilityPtoConflict of availabilityPtoConflicts) {
        const thisPtoMinutes = moment(availabilityPtoConflict.to).diff(
          availabilityPtoConflict.from,
          'minutes',
        );
        if (thisPtoMinutes) {
          ptoMinutes += thisPtoMinutes;
        }
      }
      if (!overLappingPto) {
        const thisAvailabiliyMinutes = caregiverShiftEnd.diff(caregiverShiftStart, 'minutes');
        availableMinutes += thisAvailabiliyMinutes;
      } else {
        availabilityPtoConflictsPresent = true;
      }
      totalPTOMinutes += ptoMinutes;
    }

    const caregiverEvents = eventsList.filter(
      event =>
        (event.caregiverId === caregiver.id || event.shadowCaregiverId === caregiver.id) &&
        event.status !== 'Lunch Break',
    );
    // caregiverEvents.sort((a, b) => moment(a.start).diff(moment(b.start)));
    // let firstVisit = null;
    // let lastVisit = null;
    // if (caregiverEvents.length) {
    //   firstVisit = caregiverEvents[0];
    //   lastVisit = caregiverEvents[caregiverEvents.length - 1];
    // }
    // if (firstVisit && lastVisit) {
    //   const start = moment(firstVisit.start);
    //   const end = moment(lastVisit.end);
    //   scheduledMinutes = end.diff(start, 'minutes');
    // }

    scheduledMinutes = getScheduledMinutes(caregiverEvents, caregiver, currentDate);

    const lunchBreaks = eventsList.filter(
      event => event.status === 'Lunch Break' && event.caregiverId === caregiver.id,
    );

    let totalMinutesBooked = caregiverEvents.reduce((acc, event) => acc + event.duration, 0);
    scheduledMinutes -= lunchBreaks.length * 30 + totalPTOMinutes;
    const totalTravelTime = caregiverEvents.reduce((acc, event) => acc + event.travelTime, 0);
    let guaranteedMinutes = availabilityPtoConflictsPresent ? 0 : 420;

    if (guaranteedMinutes > 0) {
      if (availableMinutes >= 420 && availableMinutes < 660) {
        guaranteedMinutes = 300;
      } else if (availableMinutes < 420 && availableMinutes > 0) {
        guaranteedMinutes = 180;
      } else if (availableMinutes <= 0) {
        guaranteedMinutes = 0;
      }
    }

    let unutilizedMinutes = guaranteedMinutes - scheduledMinutes;
    if (unutilizedMinutes < 0) {
      unutilizedMinutes = 0;
    }

    const hoursAvailable = Math.floor(availableMinutes / 60);
    const minutesAvailable = availableMinutes % 60;
    const hoursScheduled = Math.floor(scheduledMinutes / 60);
    const minutesScheduled = scheduledMinutes % 60;
    const hoursGuaranteed = Math.floor(guaranteedMinutes / 60);
    const minutesGuaranteed = guaranteedMinutes % 60;
    const hoursBooked = Math.floor(totalMinutesBooked / 60);
    const minutesBooked = totalMinutesBooked % 60;
    const hoursUnutilized = Math.floor(unutilizedMinutes / 60);
    const minutesUnutilized = unutilizedMinutes % 60;
    const hoursBookedWithTravelTime = Math.floor((totalMinutesBooked + totalTravelTime) / 60);
    const minutesBookedWithTravelTime = parseInt(
      ((totalMinutesBooked + totalTravelTime) / 60 - hoursBookedWithTravelTime) * 60,
    );
    const hoursTravelTime = Math.floor(totalTravelTime / 60);
    const minutesTravelTime = parseInt((totalTravelTime / 60 - hoursTravelTime) * 60);

    const utilizationRate = (
      scheduledMinutes > 0 ? (scheduledMinutes / availableMinutes) * 100 : 0
    ).toFixed(2);

    const billableRate = (
      scheduledMinutes > 0 ? (totalMinutesBooked / scheduledMinutes) * 100 : 0
    ).toFixed(2);

    const billableRateWithTravelTime = (
      scheduledMinutes > 0 ? ((totalMinutesBooked + totalTravelTime) / scheduledMinutes) * 100 : 0
    ).toFixed(2);

    const guaranteedRate = (
      scheduledMinutes > 0 ? (scheduledMinutes / guaranteedMinutes) * 100 : 0
    ).toFixed(2);

    return {
      hoursAvailable,
      minutesAvailable,
      hoursScheduled,
      minutesScheduled,
      scheduledTotalMinutes: scheduledMinutes,
      hoursGuaranteed,
      minutesGuaranteed,
      guaranteedTotalMinutes: guaranteedMinutes,
      hoursBooked,
      minutesBooked,
      hoursUnutilized,
      minutesUnutilized,
      unutilizedTotalMinutes: unutilizedMinutes,
      hoursTravelTime,
      minutesTravelTime,
      hoursBookedWithTravelTime,
      minutesBookedWithTravelTime,
      utilizationRate,
      billableRate,
      billableRateWithTravelTime,
      guaranteedRate,
      totalVisits: caregiverEvents.length,
    };
  }
};

export const calculateRateColor = (fieldValue, lowLevel, alertLevel) => {
  const value = Number(fieldValue ?? 0);
  if (value < lowLevel) {
    return '#FF3B30';
  }
  if (value < alertLevel) {
    return '#FF9C28';
  }
  return '#09AF00';
};

export const calculateHourColor = (hours, mins, lowLevel, alertLevel) => {
  const totalMinutes = hours * 60 + mins;
  if (totalMinutes >= alertLevel) {
    return '#FF3B30';
  }
  if (totalMinutes >= lowLevel) {
    return '#FF9C28';
  }
  return '#09AF00';
};

export const getHourString = (hours, mins) => {
  if (hours && mins) {
    return `${hours}h ${mins}min`;
  }
  if (!hours && mins) {
    return `${mins}min`;
  }
  if (hours && !mins) {
    return `${hours}h`;
  }
  if (hours === 0 && hours === 0) {
    return '0';
  }
  return 'N/A';
};

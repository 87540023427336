import { Box, Stack } from '@mui/material';
import { useGetWeeklyReportQuery } from '../../../../api/Reporting/api';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ISO_DATE_ONLY_FORMAT } from '../../../../shared/constants';
import AlertContext from '../../../../components/Alert';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import Loader from '../../../../components/Loader';
import VisitManagementProvider from '../../../../components/VisitInfoTypeProvider/VisitManagementProvider';
import ReportStatistics from './components/ReportStatistics';
import ReportingWeekDates from './components/ReportingWeek';
import VisitsList from './components/VisitsList';
import {
  caregiverConstraintReasons,
  hardConstraintNotOnDemandReasons,
  hardConstraintReasons,
  processAllocatedVisit,
  processUnallocatedVisit,
  softConstraintReasons,
  unallocatedConstraintReasons,
  viewTypeButtons,
} from './constants';
import { tablesWrapper } from './styles';
import { func, object } from 'prop-types';
import { useGetTeamsQuery, useGetTerritoryQuery } from '../../../../api/Administration/api';
import LocationsFilter from '../../../../components/FiltersComponents/LocationFilter';
import { combineTeamsAndTerritory } from '../../../../shared/utils/common';

function WeeklySchedulingReport({
  reportingWeekStartDate = moment(),
  setReportingWeekStartDate = () => {},
}) {
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const { tabSubView } = useParams();
  const [reportFilters, setReportFilters] = useState({ teams: [] });

  const {
    data: weeklyReportData,
    error,
    isLoading,
    isFetching,
    refetch: refetchWeeklyReport,
  } = useGetWeeklyReportQuery(
    {
      dateFrom: reportingWeekStartDate.format(ISO_DATE_ONLY_FORMAT),
      dateTo: reportingWeekStartDate.clone().endOf('isoweek').format(ISO_DATE_ONLY_FORMAT),
    },
    { refetchOnMountOrArgChange: true },
  );
  const {
    data: territories,
    error: territoriesError,
    isLoading: isTerritoriesLoading,
  } = useGetTerritoryQuery();

  const { data: teams, error: teamsError, isLoading: istTeamsLoading } = useGetTeamsQuery();

  useEffect(() => {
    if (error || territoriesError || teamsError) {
      setAlert({
        errorData: error ?? territoriesError ?? teamsError,
        type: 'error',
      });
    }
  }, [error, territoriesError, teamsError, setAlert]);

  const [visitsList, setVisitsList] = useState([]);
  const [visitsData, setVisitsData] = useState([]);
  const [locationsList, setLocationList] = useState([]);

  const changeTeamsFilters = value => {
    if (value?.teams) {
      setReportFilters(value);
    }
  };

  useEffect(() => {
    const startOfWeek = reportingWeekStartDate;
    const endOfWeek = reportingWeekStartDate.clone().endOf('isoweek');
    if (weeklyReportData) {
      const tempVisitsData = [];

      weeklyReportData.forEach(item => {
        const itemDate = moment(item.date);
        if (
          (item.status === 'NotAllocated' ||
            item.violatedHardConstraints?.length > 0 ||
            item.violatedSoftConstraints?.length > 0) &&
          itemDate.isBetween(startOfWeek, endOfWeek, 'date', '[]')
        ) {
          tempVisitsData.push(
            item.status === 'NotAllocated'
              ? processUnallocatedVisit(item)
              : processAllocatedVisit(item),
          );
        }
      });

      setVisitsData(tempVisitsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeklyReportData, reportingWeekStartDate]);

  useEffect(() => {
    if (territories?.length > 0 && teams?.length > 0) {
      const tempLocations = [];
      territories.forEach(territory => {
        const territoryIsActive = !territory.status || territory.status.toLowerCase() === 'active';

        if (territoryIsActive) {
          let thisTeams = [];
          teams.forEach(team => {
            const teamIsActive = !team.status || team.status.toLowerCase() === 'active';
            if (team.territory === territory.id && teamIsActive) {
              thisTeams.push(team);
            }
          });
          if (thisTeams.length > 0) {
            tempLocations.push({
              ...territory,
              teams: thisTeams,
            });
          }
        }
      });
      setLocationList(tempLocations);
      if (!reportFilters?.teams?.length) {
        const allTeamsValue = combineTeamsAndTerritory(
          tempLocations.filter(x => x?.name !== 'ProdTestTerritory'),
        );
        setReportFilters({
          teams: allTeamsValue,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [territories, teams]);

  useEffect(() => {
    let filteredVisits = visitsData.filter(
      visit => reportFilters?.teams?.findIndex(team => team.id === visit.team) > -1,
    );

    const handleFilterVisits = (visits, reasons, notOnDemandReasons = []) => {
      return visits.filter(visit => {
        const actualReasons = [
          ...reasons,
          ...(visit?.visitTypeDetails?.name !== 'On Demand' ? notOnDemandReasons : []),
        ];
        return (
          visit.violatedSoftConstraints.filter(
            constraint =>
              constraint?.length &&
              actualReasons.filter(reason => constraint.toLowerCase().startsWith(reason)).length >
                0,
          ).length > 0 ||
          visit.violatedHardConstraints.filter(
            constraint =>
              constraint?.length &&
              actualReasons.filter(reason => constraint.toLowerCase().startsWith(reason)).length >
                0,
          ).length > 0
        );
      });
    };

    if (tabSubView?.length) {
      switch (tabSubView) {
        case 'not-allocated':
          filteredVisits = handleFilterVisits(filteredVisits, unallocatedConstraintReasons);
          break;
        case 'hard-constraints':
          filteredVisits = handleFilterVisits(
            filteredVisits,
            hardConstraintReasons,
            hardConstraintNotOnDemandReasons,
          );
          break;
        case 'soft-constraints':
          filteredVisits = handleFilterVisits(filteredVisits, softConstraintReasons);
          break;
        case 'caregiver-constraints':
          filteredVisits = handleFilterVisits(filteredVisits, caregiverConstraintReasons);
          break;
        default:
          break;
      }
    }

    setVisitsList(filteredVisits);
  }, [visitsData, tabSubView, reportFilters]);

  const changeSubView = value => navigate(`/reporting/weekly-scheduling-report/${value}`);

  return (
    <VisitManagementProvider reFetchCalendarEvents={refetchWeeklyReport}>
      <Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: '20px',
          }}
        >
          {(isLoading || isFetching || isTerritoriesLoading || istTeamsLoading) && <Loader />}
          <ButtonsGroup
            buttons={viewTypeButtons}
            changeSelected={changeSubView}
            selected={tabSubView}
          />
          <Box sx={{ display: 'flex', gap: '20px' }}>
            <ReportingWeekDates
              date={reportingWeekStartDate}
              setNewDate={setReportingWeekStartDate}
            />
            <LocationsFilter
              locationsList={locationsList}
              setFilters={changeTeamsFilters}
              filters={reportFilters}
              isLoading={isTerritoriesLoading || istTeamsLoading}
            />
          </Box>
        </Box>
        {/* {TODO statistics will be implemented later} */}
        {!tabSubView && <ReportStatistics />}
        <Box sx={tablesWrapper}>
          <VisitsList visitsList={visitsList} tabSubView={tabSubView} />
        </Box>
      </Stack>
    </VisitManagementProvider>
  );
}

WeeklySchedulingReport.propTypes = {
  reportingWeekStartDate: object,
  setReportingWeekStartDate: func,
};
export default WeeklySchedulingReport;

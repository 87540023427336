import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Box } from '@mui/material';
import { bool, func, instanceOf } from 'prop-types';
import React, { useState } from 'react';
import CounterBadge from '../../../components/CounterBadge';
import LocationsModal from './LocationsModal';
import { customButtonFilter } from './styles';

const teamCount = territories =>
  territories?.reduce((acc, territory) => acc + territory.teams.length, 0);

export default function LocationsFilter({
  filters = {},
  isCaregiverOrClient = false,
  isLoading = false,
  locationsList = [],
  setFilters = () => {},
  setFiltersValue = () => {},
}) {
  const [openFilter, setOpenFilter] = useState(false);

  const closeDialog = () => setOpenFilter(false);
  const filtersCounts = filters?.teams?.filter(t => t.territory);
  const allTeamCount = teamCount(locationsList);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 0 0' }}>
      <LocationsModal
        allTeamCount={allTeamCount}
        closeDialog={closeDialog}
        filters={filters}
        isCaregiverOrClient={isCaregiverOrClient}
        locationsList={locationsList}
        openFilter={openFilter}
        setFilters={setFilters}
        setFiltersValue={setFiltersValue}
      />
      <Box
        className={filters?.teams?.length ? 'has-selected-items' : ''}
        sx={{ ...customButtonFilter }}
        variant="contained"
        onClick={() => (isLoading ? {} : setOpenFilter(true))}
      >
        <span>Teams & Territories</span>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!!filters?.teams?.length && (
            <CounterBadge
              label={
                allTeamCount === filtersCounts?.length
                  ? 'ALL'
                  : `${filtersCounts?.length}/${allTeamCount}`
              }
            />
          )}
          <LocationOnOutlinedIcon />
        </Box>
      </Box>
    </Box>
  );
}

LocationsFilter.propTypes = {
  filters: instanceOf(Object),
  isCaregiverOrClient: bool,
  isLoading: bool,
  locationsList: instanceOf(Array),
  setFilters: func,
  setFiltersValue: func,
};

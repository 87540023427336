import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { EditCareProgramContext } from '../../../../../../../pages/ClientDetails/context';
import {
  UPDATE_PROGRAM_POPUPS,
  VISITS_CHANGES_TYPES,
} from '../../../../../../../pages/ClientDetails/views/CareProgram/constants';
import React, { useCallback, useContext, useMemo } from 'react';
import { ISO_DATE_ONLY_FORMAT } from '../../../../../../../shared/constants';
import CustomDialog from '../../../../../../../components/Dialog';
import SelectDate from './SelectDate';

function SmallDialogs() {
  const {
    closeDialog,
    effectiveDate,
    innerDialogType,
    removedVisit,
    setEffectiveDate,
    setRemovedVisit,
    setUpdatedCareProgram,
    showAddNewProgramVisit,
    showProgramVisitsView,
    updatedCareProgram,
  } = useContext(EditCareProgramContext);

  const removeVisit = useCallback(() => {
    const newProgramList = [...updatedCareProgram];
    const programIndex = newProgramList.findIndex(item => item.updateId === removedVisit.updateId);
    const otherProgramIndex = newProgramList.findIndex(item => item.id === removedVisit.id && item.updateId !== removedVisit.updateId);
    newProgramList[programIndex] = {
      ...newProgramList[programIndex],
      effectiveDateEnd: effectiveDate.format("YYYY-MM-DDTHH:mm:ss"),
      changesType: VISITS_CHANGES_TYPES.delete,
    };
    if (otherProgramIndex !== -1 && newProgramList[otherProgramIndex].effectiveDateEnd === newProgramList[programIndex].effectiveDateEnd) {
      delete newProgramList[programIndex]
    }
    setUpdatedCareProgram(newProgramList);
    closeDialog();
    setRemovedVisit(null);
  }, [
    closeDialog,
    effectiveDate,
    removedVisit,
    setRemovedVisit,
    setUpdatedCareProgram,
    updatedCareProgram,
  ]);

  const hasFeaturedVisits = updatedCareProgram.some(visit =>
    moment(visit.effectiveDateStart, ISO_DATE_ONLY_FORMAT).isAfter(moment()),
  );

  const removeEndDate = useCallback(() => {
    const newProgramList = [...updatedCareProgram];
    const programIndex = newProgramList.findIndex(item => item.id === removedVisit.id);
    newProgramList[programIndex] = {
      ...removedVisit,
      changesType: VISITS_CHANGES_TYPES.edit,
    };
    delete newProgramList[programIndex].effectiveDateEnd;
    setUpdatedCareProgram(newProgramList);
    closeDialog();
    setRemovedVisit(null);
  }, [closeDialog, removedVisit, setUpdatedCareProgram, updatedCareProgram, setRemovedVisit]);

  const cancelChanges = useCallback(() => {
    const newProgramList = [...updatedCareProgram];
    const programIndex = newProgramList.findIndex(item => item.updateId === removedVisit.updateId);
    const otherProgramIndex = newProgramList.findIndex(item => item.id === removedVisit.id && item.updateId !== removedVisit.updateId);
    newProgramList[otherProgramIndex].changesType = 'old';
    delete newProgramList[otherProgramIndex].effectiveDateEnd;
    const removedNewProgramList = newProgramList.filter((item) => item.updateId !== newProgramList[programIndex].updateId);
    setUpdatedCareProgram(removedNewProgramList);
    closeDialog();
    setRemovedVisit(null);
  }, [
    closeDialog,
    removedVisit,
    setRemovedVisit,
    setUpdatedCareProgram,
    updatedCareProgram
  ]);

  const getDialogs = useMemo(
    () => [
      {
        cancelCallback: closeDialog,
        child: (
          <SelectDate
            date={effectiveDate}
            selectDate={setEffectiveDate}
            showWarning
            warningTitle={
              hasFeaturedVisits ? 'New effective date will be applied to all visits' : null
            }
          />
        ),
        disabledSubmit: !effectiveDate,
        isOpen: innerDialogType === UPDATE_PROGRAM_POPUPS.effectiveDate,
        submitButtonName: 'proceed',
        submitCallback: updatedCareProgram.length ? showProgramVisitsView : showAddNewProgramVisit,
        title: 'Set Effective Date',
      },
      {
        cancelCallback: closeDialog,
        child: (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0 28px 28px 28px',
            }}
          >
            <Typography variant="body1" sx={{ mb: '24px' }}>
              The visit will be removed. Do you want to proceed?
            </Typography>
          </Box>
        ),
        isOpen: innerDialogType === UPDATE_PROGRAM_POPUPS.removeProgram,
        submitButtonName: 'Remove visit',
        submitCallback: removeVisit,
        title: 'Confirmation',
      },
      {
        cancelCallback: closeDialog,
        child: (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0 28px 28px 28px',
            }}
          >
            <Typography variant="body1" sx={{ mb: '24px' }}>
              The end date will be removed. Do you want to proceed?
            </Typography>
          </Box>
        ),
        isOpen: innerDialogType === UPDATE_PROGRAM_POPUPS.removeEndDate,
        submitButtonName: 'Remove End Date',
        submitCallback: removeEndDate,
        title: 'Confirmation',
      },
      {
        cancelCallback: closeDialog,
        child: (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0 28px 28px 28px',
            }}
          >
            <Typography variant="body1" sx={{ mb: '24px' }}>
              The changes to this Care Program will be canceled. Do you want to proceed?
            </Typography>
          </Box>
        ),
        isOpen: innerDialogType === UPDATE_PROGRAM_POPUPS.cancelChanges,
        submitButtonName: 'Cancel Changes',
        submitCallback: cancelChanges,
        title: 'Confirmation',
      },
    ],
    [
      closeDialog,
      effectiveDate,
      hasFeaturedVisits,
      innerDialogType,
      removeVisit,
      setEffectiveDate,
      showAddNewProgramVisit,
      showProgramVisitsView,
      updatedCareProgram.length,
      cancelChanges,
      removeEndDate,
    ],
  );
  return getDialogs?.map(
    ({
      cancelCallback,
      child,
      disabledSubmit,
      isOpen,
      submitButtonName,
      submitCallback,
      title,
    }, index) => (
      <CustomDialog
        key={index}
        cancelButtonName="cancel"
        cancelCallback={cancelCallback}
        disabledSubmit={disabledSubmit}
        slotProps={{
          backdrop: {
            style: { backgroundColor: 'rgba(27, 29, 38, 0.6)' },
          },
        }}
        submitButtonName={submitButtonName}
        submitCallback={submitCallback}
        title={title}
        openDialog={isOpen}
      >
        {child}
      </CustomDialog>
    ),
  );
}

export default React.memo(SmallDialogs);

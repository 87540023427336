import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment';
import { array, bool, func, instanceOf, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  CALIFORNIA_TIME_ZONE,
  CONVERT_TO_UTC_FORMAT,
  H12_TIME_FORMAT,
} from '../../../../../../../../shared/constants';
import AlertContext from '../../../../../../../../components/Alert';
import MultilineInput from '../../../../../../../../components/Inputs/MultilineInput';
import Loader from '../../../../../../../../components/Loader';
import DatesCalendar from '../DatesCalendar';
import { MAX_LONG_TEXT_DESCRIPTION_FIELD } from '../constants';
import { visitParametersWrapper } from '../styles';
import ArrivalSlots from './ArrivalSlots';
import ClientData from './ClientData';
import StepTitle from './StepTitle';
import VisitTypeBlock from './VisitTypeBlock';
import { useGetConfigQuery } from '../../../../../../../../api/Config/api';
import { useParams } from 'react-router';
import { useGetUserDataByIdQuery } from '../../../../../../../../api/Clients/api';
import { v4 as uuidv4 } from 'uuid';
import { sortObjectTimeOfDays } from '../../../../../../../../shared/utils/common';
import { uniq } from 'lodash';

function VisitParameters({
  isSelectedTimeOutOfRange = false,
  pastTimeError = null,
  setPastTimeError = () => {},
  setVisitParams = () => {},
  visitParams = {},
  selectedDates = [],
  setSelectedDates = () => {},
}) {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [configs, setConfigs] = useState(null);
  const [showVisitNotes, setShowVisitNotes] = useState(
    visitParams?.visitDetails?.length > 0 ?? false,
  );
  const {
    data: userData,
    isLoading: isLoadingUserData,
    error: getUserDataError,
  } = useGetUserDataByIdQuery(id);
  const {
    data: configData,
    isLoading: isLoadingConfigs,
    error: getConfigError,
  } = useGetConfigQuery(['timeOfDays', 'visitTypes']);

  useEffect(() => {
    if (userData) {
      const id = uuidv4();
      const tempVisitParams = { ...visitParams };
      tempVisitParams.id = id;
      tempVisitParams.oneTimeVisit = true;
      tempVisitParams.address = `${userData.street}, ${userData.city}, ${userData.state}, ${userData.zipCode}, ${userData.country}`;
      tempVisitParams.careprogramId = id;
      tempVisitParams.team = userData.team;
      tempVisitParams.territory = userData.territory;
      tempVisitParams.clientId = userData.id;
      tempVisitParams.clientName = `${userData.firstName} ${userData.lastName}`;
      setVisitParams({ ...tempVisitParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (getConfigError || getUserDataError) {
      const errorData = getConfigError || getUserDataError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getConfigError, getUserDataError, setAlert]);

  const checkPastTimeError = useCallback(
    date => {
      const californiaCurrentTime = moment().tz(CALIFORNIA_TIME_ZONE);
      const californiaCurrentDateSelected = moment(date).tz(CALIFORNIA_TIME_ZONE, true);

      if (
        moment(californiaCurrentDateSelected).isSameOrBefore(
          californiaCurrentTime.add(10, 'minutes'),
          'minutes',
        )
      ) {
        setPastTimeError(
          'You cannot create a visit starting less than 10 min of the current time (CA timezone)',
        );
      } else {
        setPastTimeError(null);
      }
    },
    [setPastTimeError],
  );

  const changeDays = useCallback(
    (date, add) => {
      const tempDates = uniq(
        [...selectedDates, date].sort((a, b) => new Date(a).getTime() - new Date(b).getTime()),
      ).filter(x => (add === true ? x : x !== date));
      if (tempDates.length) {
        const newArrivalStart = visitParams?.arrivalStart?.length
          ? moment(
              `${tempDates[0].substring(0, 10)} ${moment(visitParams.arrivalStart).format(
                H12_TIME_FORMAT,
              )}`,
              CONVERT_TO_UTC_FORMAT,
            ).format('YYYY-MM-DDTHH:mm:ss')
          : undefined;
        const newArrivalEnd = visitParams?.arrivalEnd?.length
          ? moment(
              `${tempDates[0].substring(0, 10)} ${moment(visitParams.arrivalEnd).format(
                H12_TIME_FORMAT,
              )}`,
              CONVERT_TO_UTC_FORMAT,
            ).format('YYYY-MM-DDTHH:mm:ss')
          : undefined;
        setVisitParams(prevValue => ({
          ...prevValue,
          date: tempDates[0],
          arrivalStart: newArrivalStart,
          arrivalEnd: newArrivalEnd,
          arrivalTime: newArrivalStart,
        }));
        setSelectedDates(tempDates);
        if (newArrivalStart) {
          checkPastTimeError(newArrivalStart);
        }
      }
    },
    [
      checkPastTimeError,
      selectedDates,
      setSelectedDates,
      setVisitParams,
      visitParams.arrivalEnd,
      visitParams.arrivalStart,
    ],
  );

  const onSelectChange = params => {
    if (params.field === 'arrivalWindowDuration') {
      setVisitParams({
        ...visitParams,
        arrivalWindowDuration: params.name,
        isExactTime: params.name === '0',
      });
      return '';
    }
    if (params.field === 'visitType') {
      const selectedValue = configData?.visitTypes?.find(type => type.id === params.id);
      setVisitParams(prevValue => ({
        ...prevValue,
        visitType: selectedValue?.id,
        visitTypeDetails: selectedValue,
        duration: selectedValue?.duration,
      }));
    } else if (params.field === 'genderPreference') {
      setVisitParams(prevValue => ({
        ...prevValue,
        genderPreference: configData?.genders?.find(type => type.id === params.id).id,
      }));
    } else if (params.field === 'preferredSkills') {
      setVisitParams(prevValue => ({
        ...prevValue,
        preferredSkills: params.value,
      }));
    } else if (params.field === 'preferredLanguages') {
      setVisitParams(prevValue => ({
        ...prevValue,
        preferredLanguages: params.value,
      }));
    } else if (params.field === 'timeOfDays') {
      const timesOfDaysDetails = [];
      for (let value in params.value) {
        if (typeof params.value[value] === 'string') {
          timesOfDaysDetails.push(
            configs.timeOfDays.filter(day => params.value[value] === day.id)[0],
          );
        } else if (typeof params.value[value] === 'object') {
          timesOfDaysDetails.push(params.value[value]);
        }
      }
      const timesOfDayIds = timesOfDaysDetails.map(skill => skill.id);
      setVisitParams({
        ...visitParams,
        timesOfDays: timesOfDayIds,
        timesOfDaysDetails: timesOfDaysDetails,
      });
      return;
    }
  };

  const changeDuration = value => {
    setVisitParams({ ...visitParams, duration: parseInt(value) });
  };

  useEffect(() => {
    if (configData) {
      const tempConfigData = { ...configData };

      let tempTimeOfDays = [];
      if (tempConfigData.timeOfDays) {
        tempTimeOfDays = sortObjectTimeOfDays(tempConfigData);
      }

      setConfigs({ ...tempConfigData, timeOfDays: tempTimeOfDays });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData]);

  const changeVisitDetails = e => {
    if (e.target.value.length <= MAX_LONG_TEXT_DESCRIPTION_FIELD) {
      setVisitParams({ ...visitParams, visitDetails: e.target.value });
    }
  };

  if (isLoadingConfigs || isLoadingUserData) {
    return <Loader />;
  }

  return (
    <Box sx={visitParametersWrapper}>
      <StepTitle />
      <ClientData userData={userData} clientName={visitParams?.clientName} />
      <Box sx={{ width: '100%', height: '8px' }} />
      <DatesCalendar handleSelectDate={changeDays} selectedDates={selectedDates} />
      <Box sx={{ width: '100%', height: '24px' }} />
      <VisitTypeBlock
        changeDuration={changeDuration}
        changeVisitType={onSelectChange}
        filtersData={configData}
        visitParams={visitParams}
      />
      <Box sx={{ width: '100%', height: '24px' }} />
      <ArrivalSlots
        isSelectedTimeOutOfRange={isSelectedTimeOutOfRange}
        pastTimeError={pastTimeError}
        filtersData={configData}
        changeTypeOfDay={onSelectChange}
        changeArrivalWindowDuration={onSelectChange}
        visitParams={visitParams}
      />
      <Box sx={{ width: '100%', height: '12px' }} />
      <FormControlLabel
        sx={{ marginBottom: '12px', maxWidth: '200px' }}
        control={<Checkbox checked={showVisitNotes} />}
        onChange={() => {
          if (showVisitNotes) {
            setVisitParams(prev => ({ ...prev, visitDetails: undefined }));
          }
          setShowVisitNotes(!showVisitNotes);
        }}
        label="Additional Visit Details"
      />
      {showVisitNotes && (
        <MultilineInput
          changeDetails={changeVisitDetails}
          id="visitDetails"
          label="Additional Visit Details"
          maxLength={MAX_LONG_TEXT_DESCRIPTION_FIELD}
          value={visitParams.visitDetails}
          showHelperText
        />
      )}
      <Box sx={{ width: '100%', height: '15px' }} />
    </Box>
  );
}

VisitParameters.propTypes = {
  isSelectedTimeOutOfRange: bool,
  pastTimeError: string,
  setPastTimeError: func,
  setVisitParams: func,
  visitParams: instanceOf(Object),
  selectedDates: array,
  setSelectedDates: func,
};

export default React.memo(VisitParameters);

import { Box, Button, Typography } from '@mui/material';
import { func, string } from 'prop-types';
import React, { useContext } from 'react';
import { VisitInfoTypeContext } from '../../../../../shared/context';
import {
  editPreferredCaregiverWrapperStyles,
  outlinedButtonStyles,
} from '../../VisitUnmarkPreferredCaregiver/styles';

export default function VisitUnmarkShadowCaregiver({
  handleCheckAvailability = () => {},
  closeUnmarkCaregiver = () => {},
  setShadowCaregiverId = () => {},
  setShadowCaregiverName = () => {},
  shadowCaregiverName = '',
}) {
  const onApplyRemoveCaregiver = () => {
    setShadowCaregiverId(null);
    setShadowCaregiverName(null);
    handleCheckAvailability(true)
    closeUnmarkCaregiver();
  };
  return (
    <Box sx={editPreferredCaregiverWrapperStyles}>
      <Typography sx={{ lineHeight: '24px' }}>
        You are going to remove caregiver <b>{shadowCaregiverName}</b> as shadow
      </Typography>
      <Box sx={{ display: 'flex', width: '100%', gap: '12px', mt: '12px' }}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={closeUnmarkCaregiver}
          disableElevation
          sx={outlinedButtonStyles}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onApplyRemoveCaregiver}
          disableElevation
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
}

VisitUnmarkShadowCaregiver.propTypes = {
  applyUnmarkCaregiver: func,
  closeUnmarkCaregiver: func,
  setShadowCaregiverId: func,
  setShadowCaregiverName: func,
  shadowCaregiverName: string,
};

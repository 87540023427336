import { Box, IconButton, Typography } from '@mui/material';
import DeleteIcon from '../../../../img/icons/iconButtons/delete-icon.svg';
import { bool, func, object, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import AlertContext from '../../../../components/Alert';
import Loader from '../../../../components/Loader';
import {
  infoFieldWrapper,
  infoKeyField,
  infoRowEditableInfoWrapper,
  infoRowWrapperStyles,
} from '../../styles';
import EditIconButton from '../EditIconButton';
import LinkItem from '../VisitInfoFields/LinkItem';
import AddShadowCaregiverButton from './AddShadowCaregiverButton';
import { VisitShadowCaregiverSetup, VisitUnmarkShadowCaregiver } from './VisitShadowCaregiverSetup';
import moment from 'moment';
import OptimizerCheck from '../../../Optimizer/OptimizerCheck';
import { api } from '../../../../api/';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { useCheckDragAndDropPossibilityMutation } from '../../../../api/Scheduler/api';
import { uniq } from 'lodash';
import AddShadowCaregiverConfirmation from './AddShadowCaregiverConfirmation/AddShadowCaregiverConfirmation';

export default function VisitShadowCaregiver({
  caregiverName = '',
  caregiverId = '',
  enableToEditShadowCaregiver = false,
  visitInfoData = {},
  onUpdateVisitDetails = () => {},
}) {
  const { setAlert } = useContext(AlertContext);
  const [optimizerData, setOptimizerData] = useState({});
  const [openCheckOptimizer, setOpenCheckOptimizer] = useState(false);
  const [shadowCaregiverId, setShadowCaregiverId] = useState(caregiverId);
  const [shadowCaregiverName, setShadowCaregiverName] = useState(caregiverName);
  const [editingVisit, setEditingVisit] = useState(false);
  const [editingCareprogram, setEditingCareprogram] = useState(false);
  const [editingShadowCaregiver, setEditingShadowCaregiver] = useState(false);
  const [removingShadowCaregiver, setRemovingShadowCaregiver] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const { reFetchCalendarEvents } = useContext(VisitInfoTypeContext);

  const submitFunction = async () => {
    await updateCareProgram();
    reFetchCalendarEvents();
  };

  const [
    checkDragAndDropPossibility,
    { data: checkPossibilityData, isLoading, error: checkDragAndDropPossibilityError },
  ] = useCheckDragAndDropPossibilityMutation();

  useEffect(() => {
    if (checkDragAndDropPossibilityError) {
      const errorData = checkDragAndDropPossibilityError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [checkDragAndDropPossibilityError, setAlert]);

  const updateCareProgram = async () => {
    const thisDate = moment(visitInfoData?.date).format('YYYY-MM-DD');
    const payload = {
      id: visitInfoData?.careprogramId,
    };
    const apiData = await api('GET', 'crud', 'careprogram', payload);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      if (apiData?.data) {
        const thisCareProgram = apiData?.data;
        if (
          thisCareProgram?.dateChanges &&
          Object.keys(thisCareProgram?.dateChanges).includes(thisDate)
        ) {
          thisCareProgram.dateChanges[thisDate].shadowCaregiverId = shadowCaregiverId;
        } else {
          thisCareProgram.dateChanges = {
            ...thisCareProgram.dateChanges,
            [thisDate]: {
              shadowCaregiverId: shadowCaregiverId,
            },
          };
        }
        const apiDataPatch = await api('PATCH', 'crud', 'careprogram', thisCareProgram);
        if (apiDataPatch.error) {
          setAlert({
            apiData: apiDataPatch,
            type: 'error',
          });
        }
      }
    }
    setEditingCareprogram(false);
  };

  const handleCheckAvailability = async remove => {
    let tempVisitData = {
      ...visitInfoData,
      shadowCaregiverId: shadowCaregiverId,
      shadowCaregiverName: shadowCaregiverName,
    };
    if (shadowCaregiverId === null || remove) {
      delete tempVisitData.shadowCaregiverId;
      delete tempVisitData.shadowCaregiverName;
      if (tempVisitData.violatedSoftShadowConstraints) {
        delete tempVisitData.violatedSoftShadowConstraints;
      }
    }
    setEditingVisit(true);
    if (moment(tempVisitData?.date).isAfter(moment(), 'date')) {
      setEditingCareprogram(true);
      setOptimizerData(tempVisitData);
      setOpenCheckOptimizer(true);
    } else if (moment(tempVisitData?.date).isSame(moment(), 'date')) {
      if (shadowCaregiverId === null || remove) {
        await handleSaveChangesWithoutOptimizer(tempVisitData);
      } else {
        setEditingCareprogram(true);
        await checkDragAndDropPossibility({
          caregiverId: tempVisitData.shadowCaregiverId,
          visit: {
            ...tempVisitData,
            caregiverId: shadowCaregiverId,
            caregiverName: shadowCaregiverName,
          },
          action: 'visitCheck',
        });
        setConfirmationDialogOpen(true);
        setEditingCareprogram(false);
      }
    }
    setEditingVisit(false);
  };

  const handleSubmitShadowCaregiver = async violatedSoftConstraints => {
    setEditingCareprogram(true);
    setConfirmationDialogOpen(false);
    const tempVisitData = {
      ...visitInfoData,
      shadowCaregiverId: shadowCaregiverId,
      shadowCaregiverName: shadowCaregiverName,
      violatedSoftShadowConstraints: uniq([...(violatedSoftConstraints ?? [])]),
    };
    if (shadowCaregiverId === null) {
      delete tempVisitData.shadowCaregiverId;
      delete tempVisitData.shadowCaregiverName;
      delete tempVisitData.violatedSoftShadowConstraints;
    }
    await handleSaveChangesWithoutOptimizer(tempVisitData);
  };

  const onCancel = () => {
    setShadowCaregiverId(null);
    setShadowCaregiverName(null);
    setOptimizerData({});
    setEditingShadowCaregiver(false);
    setEditingCareprogram(false);
  };

  const handleSaveChangesWithoutOptimizer = async data => {
    setEditingCareprogram(false);
    await onUpdateVisitDetails(data);
    await updateCareProgram();
    await reFetchCalendarEvents();
  };

  return (
    <Box>
      {(editingVisit || isLoading) && editingCareprogram && <Loader />}
      {!(editingVisit || isLoading) && !editingCareprogram && (
        <Box sx={{ ...infoRowWrapperStyles, alignItems: 'start' }}>
          <Typography sx={infoKeyField}>Shadow Caregiver:</Typography>
          <Box sx={infoFieldWrapper}>
            <Box sx={infoRowEditableInfoWrapper}>
              {caregiverName ? (
                <LinkItem link={`/caregivers/${caregiverId}`} name={caregiverName} />
              ) : (
                <Typography>-</Typography>
              )}
              {enableToEditShadowCaregiver && caregiverId && (
                <Box sx={{ display: 'flex', gap: '12px' }}>
                  <EditIconButton
                    onEdit={setEditingShadowCaregiver}
                    tooltip="Edit shadow caregiver"
                  />
                  <IconButton
                    aria-label="delete"
                    disableRipple
                    onClick={setRemovingShadowCaregiver}
                    sx={{ p: 0 }}
                  >
                    <img src={DeleteIcon} alt="Delete" />
                  </IconButton>
                </Box>
              )}
            </Box>
            <AddShadowCaregiverButton
              enableToEditShadowCaregiver={enableToEditShadowCaregiver}
              hasShadowCaregiver={!!caregiverId}
              onEditShadowCaregiver={setEditingShadowCaregiver}
            />
          </Box>
        </Box>
      )}
      {removingShadowCaregiver && (
        <VisitUnmarkShadowCaregiver
          handleCheckAvailability={handleCheckAvailability}
          closeUnmarkCaregiver={() => setRemovingShadowCaregiver(false)}
          setShadowCaregiverId={setShadowCaregiverId}
          setShadowCaregiverName={setShadowCaregiverName}
          shadowCaregiverName={shadowCaregiverName}
        />
      )}
      {editingShadowCaregiver && (
        <VisitShadowCaregiverSetup
          handleCheckAvailability={handleCheckAvailability}
          setEditingShadowCaregiver={setEditingShadowCaregiver}
          setShadowCaregiverId={setShadowCaregiverId}
          setShadowCaregiverName={setShadowCaregiverName}
          shadowCaregiverName={shadowCaregiverName}
          shadowCaregiverId={shadowCaregiverId}
          onCancel={onCancel}
        />
      )}
      <OptimizerCheck
        data={[optimizerData]}
        dataObjName="visitChangeObj"
        submitFunction={submitFunction}
        open={openCheckOptimizer}
        openToggle={setOpenCheckOptimizer}
        cancelFunction={onCancel}
      />
      <AddShadowCaregiverConfirmation
        checkPossibilityData={checkPossibilityData}
        closeDialog={() => {
          onCancel();
          setConfirmationDialogOpen(false);
        }}
        disabledSubmit={isLoading || !checkPossibilityData || editingCareprogram}
        isLoading={isLoading || !checkPossibilityData || editingCareprogram}
        openDialog={confirmationDialogOpen}
        submitDialog={handleSubmitShadowCaregiver}
        isSaving={editingCareprogram}
      />
    </Box>
  );
}

VisitShadowCaregiver.propTypes = {
  caregiverName: string,
  caregiverId: string,
  enableToEditShadowCaregiver: bool,
  visitInfoData: object,
  onUpdateVisitDetail: func,
};

import { Box } from '@mui/material';
import CustomBreadcrumbs from '../../../../../../../pages/ClientDetails/components/Breadcrumbs';
import CustomStepper from '../../../../../../../pages/ClientDetails/components/CustomStepper';
import {
  ClientDetailsContext,
  CreateOneTimeVisitContext,
} from '../../../../../../../pages/ClientDetails/context';
import { createOneTimeVisitSteps } from '../../../../../../../pages/ClientDetails/views/Calendar/constants';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ErrorAlert from '../../../../../../../components/ErrorAlert';
import VisitParameters from './VisitParameters';
import { createOneTimeVisitMultistepHeader, createOneTimeVisitMultistepWrapper } from './styles';
import RecurrentAvailability from './RecurrentAvailability/RecurrentAvailability';
import VisitSummary from './VisitSummary/VisitSummary';
import { useGetUserDataByIdQuery } from '../../../../../../../api/Clients/api';
import { useParams } from 'react-router';
import AlertContext from '../../../../../../../components/Alert';
import { getSummaryData } from './utils';
import CheckAvailability from './CheckAvailability/CheckAvailability';

export default function CreateOneTimeVisitMultistep() {
  const { id } = useParams();
  const [errorData, setErrorData] = useState(null);
  const [temporaryVisitData, setTemporaryVisitData] = useState({});
  const [visitSummaryHeight, setVisitSummaryHeight] = useState(0);
  const { setAlert } = useContext(AlertContext);
  const visitSummaryRef = useRef(null);

  const {
    closeDialog,
    completedSteps,
    isSelectedTimeOutOfRange,
    pastTimeError,
    setPastTimeError,
    setStep,
    setStepDataLoading,
    setVisitParams,
    step,
    stepDataLoading,
    visitParams,
    handleChangesAdded,
    selectedDates,
    setSelectedDates,
    availabilityScheduling,
    setAvailabilityScheduling,
    setCompletedSteps,
    selectedTimeSlots,
    activeDates,
    setActiveDates,
    setSelectedTimeSlots,
    submitStep,
    setManualLoading,
    getCalendarData,
    handleCancelFlow,
  } = useContext(CreateOneTimeVisitContext);

  useEffect(() => {
    if (visitParams) {
      setTemporaryVisitData(visitParams);
    }
  }, [visitParams]);

  const { resetToInitialView } = useContext(ClientDetailsContext);

  const { data: userData, error: getUserDataByIdError } = useGetUserDataByIdQuery(id);

  useEffect(() => {
    if (getUserDataByIdError) {
      setAlert({
        errorData: getUserDataByIdError,
        type: 'error',
      });
    }
  }, [getUserDataByIdError, setAlert]);

  const getStepView = () => {
    if (step === 0) {
      return (
        <VisitParameters
          isSelectedTimeOutOfRange={isSelectedTimeOutOfRange}
          pastTimeError={pastTimeError}
          setPastTimeError={setPastTimeError}
          setVisitParams={setVisitParams}
          visitParams={visitParams}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
        />
      );
    }
    if (step === 1) {
      return (
        <RecurrentAvailability
          availabilityScheduling={availabilityScheduling}
          clearSelectedTimeSlots={() => setSelectedTimeSlots(null)}
          errorData={errorData}
          setAvailabilityScheduling={setAvailabilityScheduling}
          setErrorData={setErrorData}
          setStepDataLoading={setStepDataLoading}
          setTemporaryVisitData={setTemporaryVisitData}
          setVisitData={setVisitParams}
          temporaryVisitData={temporaryVisitData}
          visitData={visitParams}
          visitSummaryHeight={visitSummaryHeight}
          setCompletedSteps={setCompletedSteps}
          selectedDates={selectedDates}
          activeDates={activeDates}
          setActiveDates={setActiveDates}
          setSelectedTimeSlots={setSelectedTimeSlots}
        />
      );
    }
    if (step === 2) {
      return (
        <CheckAvailability
          setStepDataLoading={setStepDataLoading}
          submitStep={submitStep}
          resetToInitialView={resetToInitialView}
          handleCancelFlow={handleCancelFlow}
          selectedTimeSlots={selectedTimeSlots}
          activeDates={activeDates}
          visitParams={visitParams}
          getCalendarData={getCalendarData}
          setManualLoading={setManualLoading}
        />
      );
    }
    return false;
  };

  return (
    <Box sx={createOneTimeVisitMultistepWrapper}>
      <Box sx={createOneTimeVisitMultistepHeader}>
        <CustomBreadcrumbs
          closeDialog={closeDialog}
          resetToInitialView={resetToInitialView}
          title="Add Visit"
          handleChangesAdded={handleChangesAdded}
        />
        <CustomStepper
          changeStep={setStep}
          completedSteps={completedSteps}
          currentStep={step}
          disabled={stepDataLoading}
          steps={createOneTimeVisitSteps}
          disabledStepNavigation={step > 1}
        />
      </Box>
      {!!errorData && (
        <Box sx={{ maxWidth: '500px', margin: 'auto' }}>
          <ErrorAlert error={errorData} closeError={() => setErrorData(null)} />
        </Box>
      )}
      {step > 0 && (
        <VisitSummary
          data={getSummaryData({
            selectedTimeSlots,
            userData,
            visitParams,
          })}
          setVisitSummaryHeight={setVisitSummaryHeight}
          visitSummaryRef={visitSummaryRef}
          visitData={visitParams}
        />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>{getStepView()}</Box>
    </Box>
  );
}

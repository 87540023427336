import { Box, Button } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useMemo } from 'react';
import { tasksTypeButtons, visitStatusesMap } from '../../../../shared/constants';
import { getNoData } from '../../../../shared/utils/common';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import CustomTable from '../../../../components/Table';
import {
  disabledButton,
  tasksManagementTableStyles,
  visitTasksManagementWrapper,
} from '../VisitDetails/styles';
import { getTasksColumns } from './constants';

function VisitTasksManagement({
  addVisitTask = () => {},
  notAllocated = false,
  setShowedTasksType = () => {},
  showedTasksType = '',
  tasks = [],
  title = '',
  visitStatus = '',
  isLoadingConfigs = false,
}) {
  const apiRef = useGridApiRef();

  const tableStyles = useMemo(() => tasksManagementTableStyles(!tasks?.length), [tasks]);

  const disableToAddTask = useMemo(
    () =>
      notAllocated ||
      ![
        visitStatusesMap.scheduled,
        visitStatusesMap.enRoute,
        visitStatusesMap.onSite,
        visitStatusesMap.completed,
        visitStatusesMap.inProgress,
      ].includes(visitStatus),
    [notAllocated, visitStatus],
  );

  const tasksColumns = useMemo(
    () => getTasksColumns(showedTasksType, tasks),
    [tasks, showedTasksType],
  );

  return (
    <Box sx={visitTasksManagementWrapper}>
      <Box sx={{ display: 'flex', gap: '24px', justifyContent: 'space-between' }}>
        <ButtonsGroup
          buttons={tasksTypeButtons}
          changeSelected={value => setShowedTasksType(value)}
          selected={showedTasksType}
        />
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => addVisitTask(title)}
          disableElevation
          disabled={!!disableToAddTask}
          sx={disabledButton}
        >
          Add task
        </Button>
      </Box>
      <CustomTable
        apiRef={apiRef}
        columns={tasksColumns}
        customStyles={tableStyles}
        headerHeight={34}
        noRowsOverlay={() => getNoData({ title: 'No tasks yet' })}
        onRowClick={() => {}}
        rows={tasks}
        withoutPagination
        isLoading={isLoadingConfigs}
      />
    </Box>
  );
}

VisitTasksManagement.propTypes = {
  addVisitTask: func,
  notAllocated: bool,
  setShowedTasksType: func,
  showedTasksType: string,
  tasks: instanceOf(Array),
  title: string,
  visitStatus: string,
  isLoadingConfigs: bool,
};

export default React.memo(VisitTasksManagement);

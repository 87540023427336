import { Box, Divider, Typography } from '@mui/material';
import { bool, string } from 'prop-types';
import React, { useMemo } from 'react';
import { getConstantTableItemStyles } from './styles';
import { COLORS } from '../../../../../../../../../shared/styles/Theme';

export default function ConstantTableItem({
  isArrivalWindowItem = false,
  isHeader = false,
  name = '',
  showDivider = false,
}) {
  const itemStyles = useMemo(
    () => getConstantTableItemStyles(isArrivalWindowItem),
    [isArrivalWindowItem],
  );

  return (
    <Box
      sx={{
        ...itemStyles,
        height: isHeader ? '44px' : '54px',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: '14px',
          fontWeight: isHeader ? '700' : '400',
          pl: '16px',
        }}
      >
        {name}
      </Typography>
      {showDivider && (
        <Divider
          orientation="vertical"
          sx={{
            height: '22px',
            color: COLORS.blue[200],
          }}
        />
      )}
    </Box>
  );
}

ConstantTableItem.propTypes = {
  isArrivalWindowItem: bool,
  isHeader: bool,
  name: string,
  showDivider: bool,
};

import moment from 'moment';
import {
  ISO_DATE_ONLY_FORMAT,
  SHORT_DATE_FORMAT,
} from '../../../../../../../../../../shared/constants';
import { summaryPreViewButtons } from './constants';

export const getTabsData = updatedCareProgram => {
  const allData = {
    summaryData: null,
    currentVersion: null,
    futureVersion: null,
  };

  const currentVersionDate = updatedCareProgram[0]?.effectiveDateStart;
  const currentVersion = moment(currentVersionDate, ISO_DATE_ONLY_FORMAT).isSameOrBefore(moment())
    ? updatedCareProgram.filter(
        visit =>
          moment(visit.effectiveDateStart, ISO_DATE_ONLY_FORMAT).isSameOrBefore(moment(), 'date') &&
          (!visit.effectiveDateEnd ||
            moment(visit.effectiveDateEnd, ISO_DATE_ONLY_FORMAT).isAfter(moment(), 'date')),
      )
    : null;

  const futureVersionDate = updatedCareProgram.find(visit =>
    moment(visit?.effectiveDateStart, ISO_DATE_ONLY_FORMAT).isAfter(moment(), 'date'),
  )?.effectiveDateStart;

  const futureVersion = futureVersionDate
    ? updatedCareProgram.filter(
        visit =>
          !visit.effectiveDateEnd ||
          moment(visit.effectiveDateEnd, ISO_DATE_ONLY_FORMAT).isAfter(
            moment(futureVersionDate),
            'date',
          ),
      )
    : null;

  const addedVisits = updatedCareProgram
    ?.filter(visit => visit.changesType === 'add')
    ?.map((visitData, index) => ({ ...visitData }));

  const editedVisits = updatedCareProgram
    ?.filter(visit => visit.changesType === 'new' || visit.changesType === 'edit')
    ?.map((visitData, index) => ({ ...visitData }));

  const deletedVisits = updatedCareProgram
    ?.filter(visit => visit.changesType === 'delete')
    ?.map((visitData, index) => ({ ...visitData }));

  allData.summaryData = {
    addedVisits,
    editedVisits,
    deletedVisits,
  };

  const currentEventsWithoutDuplicates = currentVersion
    ? [...new Set(currentVersion?.map(({ effectiveDateStart }) => effectiveDateStart))]
    : [];

  return {
    ...allData,
    currentVersion: currentVersion
      ? {
          date: currentEventsWithoutDuplicates.length > 1 ? null : currentVersionDate,
          visits: currentVersion,
        }
      : null,
    futureVersion: futureVersion ? { date: futureVersionDate, visits: futureVersion } : null,
  };
};

export const getSummaryTabs = tabsData => {
  if (!tabsData.currentVersion) {
    const buttons = summaryPreViewButtons.filter(button => button.infoType !== 'currentVersion');
    return buttons?.map(button =>
      button.infoType === 'futureVersion'
        ? {
            ...button,
            subName: `Starting ${moment(tabsData?.futureVersion?.date).format(SHORT_DATE_FORMAT)}`,
          }
        : button,
    );
  }
  if (!tabsData.futureVersion) {
    const buttons = summaryPreViewButtons.filter(button => button.infoType !== 'futureVersion');
    return buttons?.map(button =>
      button.infoType === 'currentVersion' && tabsData.currentVersion.date
        ? {
            ...button,
            subName: `Started ${moment(tabsData.currentVersion.date).format(SHORT_DATE_FORMAT)}`,
          }
        : button,
    );
  }
  return summaryPreViewButtons.map(button => {
    if (button.infoType === 'currentVersion' && tabsData.currentVersion.date) {
      return {
        ...button,
        subName: `Started ${moment(tabsData.currentVersion.date).format(SHORT_DATE_FORMAT)}`,
      };
    }
    if (button.infoType === 'futureVersion') {
      return {
        ...button,
        subName: `Starting ${moment(tabsData?.futureVersion?.date).format(SHORT_DATE_FORMAT)}`,
      };
    }
    return button;
  });
};

import errorIcon from '../../../../../../../../../img/icons/error-outlined.svg';
import successIcon from '../../../../../../../../../img/icons/success-outlined.svg';
import React from 'react';
import { COLORS } from '../../../../../../../../../shared/styles/Theme';

export const MAX_LONG_TEXT_DESCRIPTION_FIELD = 4000;

export const personalInformationFieldsMap = [
  { name: 'Client', key: 'client' },
  { name: 'Location', key: 'address' },
];

export const createVisitSummaryFields = {
  datesColumn: [
    { name: 'Client', key: 'client' },
    { name: 'Address', key: 'address' },
    { name: 'Visit Type', key: 'visitType' },
  ],
  secondColumn: [
    { name: 'Duration', key: 'duration' },
    { name: 'Recurrence', key: 'recurrence' },
    { name: 'Effective Date', key: 'effectiveDate' },
  ],
};

export const visitRepeatItems = [
  { name: 'Daily', id: 'Daily' },
  { name: 'Weekly', id: 'Weekly' },
  { name: 'Monthly', id: 'Monthly' },
];

export const checkingResultsColumns = [
  {
    bgColor: COLORS.green[200],
    color: COLORS.green[600],
    icon: <img src={successIcon} alt="success icon" />,
    getTitle(number, total) {
      return `[${number}/${total}] ${total > 1 ? 'visits' : 'visit'} can be scheduled`;
    },
    type: 'success',
    dataKey: 'availableSchedule',
  },
  {
    bgColor: COLORS.red[200],
    color: COLORS.red[600],
    icon: <img src={errorIcon} alt="error icon" />,
    getTitle(number, total) {
      return `Action required: [${number}/${total}] ${
        total > 1 ? 'visits' : 'visit'
      } can not be scheduled`;
    },
    type: 'error',
    dataKey: 'unAvailableSchedule',
  },
];

import { EditCareProgramContext } from '../../../../../../pages/ClientDetails/context';
import React, { useContext, useEffect } from 'react';
import CareProgramTable from '../../components/CareProgramTable';
import { useGetUserDataByIdQuery } from '../../../../../../api/Clients/api';
import { useParams } from 'react-router';
import AlertContext from '../../../../../../components/Alert';

function CareProgramTableView() {
  const { initialCareProgramData } = useContext(EditCareProgramContext);
  const { id } = useParams();
  const { data: clientData, error } = useGetUserDataByIdQuery(id);

  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  return <CareProgramTable careProgramVisits={initialCareProgramData} clientData={clientData} />;
}

export default React.memo(CareProgramTableView);

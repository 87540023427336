import { Box, Link, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import hardConstraintsWarning from '../../../../img/icons/error-icon.svg';
import softConstraintsWarning from '../../../../img/icons/warning-icon.svg';
import moment from 'moment/moment';
import React from 'react';
import { H12_TIME_FORMAT, SHORT_DATE_FORMAT } from '../../../../shared/constants';
import { COLORS } from '../../../../shared/styles/Theme';
import VisitCaregiverInfo from './components/VisitCaregiversInfo';
import VisitType from './components/VisitType';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Link as RouterLink } from 'react-router-dom';

export const DEFAULT_SORT_MODAL = { field: 'date', sort: 'asc' };

export const VISITS_STATUSES_TYPES = [
  { key: 'upcoming', title: 'Upcoming' },
  { key: 'past', title: 'Past' },
];

export const NO_DATA_TITLES = {
  'not-allocated': 'There are no visits which are not allocated this week',
  'hard-constraints': 'There are no visits with hard constraint violations this week',
  'soft-constraints': 'There are no visits with soft constraint violations this week',
  'caregiver-constraints': 'There are no visits with caregiver violations this week',
};

export const PAGE_SIZE = 20;

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const VISITS_VIEWS = {
  notAllocated: 'not-allocated',
  softConstraints: 'soft-constraints',
  hardConstraints: 'hard-constraints',
  caregiverConstraints: 'caregiver-constraints',
};

export const viewTypeButtons = [
  {
    name: 'Not Allocated',
    infoType: VISITS_VIEWS.notAllocated,
    icon: hardConstraintsWarning,
  },
  {
    name: 'Critical Violations',
    infoType: VISITS_VIEWS.hardConstraints,
    icon: hardConstraintsWarning,
  },
  {
    name: 'Minor Violations',
    infoType: VISITS_VIEWS.softConstraints,
    icon: softConstraintsWarning,
  },
  {
    name: 'Caregiver Violations',
    infoType: VISITS_VIEWS.caregiverConstraints,
    icon: hardConstraintsWarning,
  },
];

const comparingVisitDates = (first, second) => {
  const firstTime = moment(first);
  const secondTime = moment(second);
  if (firstTime.isBefore(secondTime)) {
    return -1;
  }
  if (firstTime.isAfter(secondTime)) {
    return 1;
  }
  return 0;
};

const tableCellViolationList = violationsToDisplay => {
  return (
    <Box>
      {violationsToDisplay.map((t, index) => (
        <List
          // eslint-disable-next-line react/no-array-index-key
          key={`${t}+ ${index}`}
          style={{
            marginBottom: index === violationsToDisplay.length - 1 ? '0' : '12px',
          }}
        >
          <ListItem sx={{ padding: '6px', fontSize: '14px' }}>
            <ListItemIcon sx={{ minWidth: '16px' }}>
              <FiberManualRecordIcon
                sx={{ color: COLORS.blue[700], width: '8px', height: '8px' }}
              />
            </ListItemIcon>
            {t}
          </ListItem>
        </List>
      ))}
    </Box>
  );
};

export const visitViolationReportingColumns = tabSubView => [
  {
    field: 'date',
    headerName: 'Date',
    colSpan: 1,
    width: 160,
    renderCell: ({ row }) => <Typography>{moment(row.date).format(SHORT_DATE_FORMAT)}</Typography>,
    sortComparator: (v1, v2) => comparingVisitDates(v1, v2),
  },
  {
    field: 'clientName',
    flex: 1,
    headerName: 'Client',
    minWidth: 180,
    sortable: false,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/clients/${row?.clientId}`} underline="none">
        <Typography
          variant="body1"
          sx={{ color: COLORS.blue[700], fontWeight: '600', fontSize: '14px', cursor: 'pointer' }}
        >
          {row?.clientName}
        </Typography>
      </Link>
    ),
  },
  {
    field: 'team',
    headerName: 'Team',
    sortable: false,
    width: 150,
    flex: 1,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            background: row.teamDetails?.color?.color,
            width: '16px',
            height: '16px',
            borderRadius: '3px',
            mr: '6px',
          }}
        />
        <Typography>{row?.teamDetails?.name ?? 'N/A'}</Typography>
      </Box>
    ),
  },
  {
    field: 'visitType',
    headerName: 'Visit Type',
    flex: 1,
    colSpan: 1,
    minWidth: 240,
    sortable: false,
    renderCell: ({ row }) => <VisitType row={row} tabSubView={tabSubView} />,
  },
  {
    field: 'arrivalStart',
    flex: 1,
    headerName: 'Scheduled Arrival Window',
    colSpan: 1,
    minWidth: 220,
    sortable: false,
    renderCell: ({ row }) => (
      <Typography>
        {row?.arrivalStart !== row?.arrivalEnd
          ? `${moment(row?.arrivalStart).format(H12_TIME_FORMAT)} - ${moment(
              row?.arrivalEnd,
            ).format(H12_TIME_FORMAT)}`
          : moment(row?.arrivalStart).format(H12_TIME_FORMAT)}
      </Typography>
    ),
  },
  ...(tabSubView === VISITS_VIEWS.notAllocated
    ? []
    : [
        {
          field: 'violations',
          flex: 1,
          headerName: 'Violations',
          minWidth: 400,
          sortable: false,
          renderCell: ({ row }) => {
            const violationReasons =
              tabSubView === VISITS_VIEWS.hardConstraints
                ? [
                    ...hardConstraintReasons,
                    ...(row?.visitTypeDetails?.name !== 'On Demand'
                      ? hardConstraintNotOnDemandReasons
                      : []),
                  ]
                : tabSubView === VISITS_VIEWS.softConstraints
                ? softConstraintReasons
                : unallocatedConstraintReasons;

            const violations = [
              ...(row?.violatedSoftConstraints ?? []),
              ...(row?.violatedHardConstraints ?? []),
            ];

            const violationsToDisplay = violations.filter(
              constraint =>
                constraint?.length &&
                violationReasons.filter(reason => constraint.toLowerCase().startsWith(reason))
                  .length > 0,
            );

            return tableCellViolationList(violationsToDisplay);
          },
        },
      ]),
];

export const caregiverViolationReportingColumns = [
  {
    field: 'date',
    headerName: 'Date',
    colSpan: 1,
    width: 160,
    renderCell: ({ row }) => <Typography>{moment(row.date).format(SHORT_DATE_FORMAT)}</Typography>,
    sortComparator: (v1, v2) => comparingVisitDates(v1, v2),
  },
  {
    field: 'caregiverId',
    headerName: 'Caregivers',
    minWidth: 250,
    sortable: false,
    renderCell: ({ row }) => <VisitCaregiverInfo visitData={row} />,
  },
  {
    field: 'violations',
    flex: 1,
    headerName: 'Violations',
    minWidth: 400,
    sortable: false,
    renderCell: ({ row }) => {
      const violations = [
        ...(row?.violatedSoftConstraints ?? []),
        ...(row?.violatedHardConstraints ?? []),
      ];

      const violationsToDisplay = violations.filter(
        constraint =>
          constraint?.length &&
          caregiverConstraintReasons.filter(reason => constraint.toLowerCase().startsWith(reason))
            .length > 0,
      );

      return tableCellViolationList(violationsToDisplay);
    },
  },
];

export const processUnallocatedVisit = visit => {
  const thisVisit = { ...visit };
  const arrivalTime = moment(thisVisit['arrivalStart']);
  const start = moment(thisVisit?.date)
    .set({
      hour: arrivalTime?.hours(),
      minute: arrivalTime?.minutes(),
      second: 0,
      millisecond: 0,
    })
    .toDate();
  const end = moment(start).add(thisVisit.duration, 'minutes').toDate();
  thisVisit.arrivalTime = arrivalTime.format('YYYY-MM-DDTHH:mm:ss');
  thisVisit.start = start;
  thisVisit.end = end;
  thisVisit.arrivalTimeDuration = 60;

  return thisVisit;
};

export const processAllocatedVisit = visit => {
  return {
    ...visit,
    start: new Date(visit.arrivalTime),
    end: new Date(
      moment(visit.arrivalTime).add(visit.duration, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
    ),
    arrivalTimeDuration: moment(visit.arrivalEnd).diff(visit.arrivalStart, 'minutes'),
    travelTime: 20,
    timeDifferenceBetweenStartAndArrivalStart: 0,
    timeDifferenceBetweenStartAndArrivalEnd: 60,
  };
};

export const unallocatedConstraintReasons = ['visit unallocated'];
export const hardConstraintReasons = [
  'visit is outside of caregiver availability.',
  'visit overlaps with another visit',
  "visit is not in caregiver's teams",
  'visit is outside of arrival window',
];
export const hardConstraintNotOnDemandReasons = ['caregiver has not seen client'];
export const softConstraintReasons = ['preferred caregiver not available', 'visit preference:'];
export const caregiverConstraintReasons = [
  'caregiver in overtime',
  'no lunch break',
  'caregiver scheduled for less then 4 hours',
  'caregiver utilization rate is only',
  "caregiver's travel time accounts is",
];

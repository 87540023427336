import { Box, Button, DialogActions, Typography } from '@mui/material';
import { func, string } from 'prop-types';
import React, { useState } from 'react';
import { COLORS } from '../../../../shared/styles/Theme';
import { disabledButtonStyles, styledCancelButton } from './styles';
import CustomDialog from '../../../Dialog';
import { visitStatusesMap } from '../../../../shared/constants';

export default function Actions({
  closeDialog = () => {},
  updateVisit = () => {},
  visitStatus = '',
}) {
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);

  const isCancelled = visitStatus === visitStatusesMap.canceled;

  const handleSubmit = async () => {
    if (isCancelled) {
      setOpenRestoreDialog(false);
    } else {
      setOpenCancelDialog(false);
    }
    updateVisit(!isCancelled);
  };

  return (
    <DialogActions
      sx={{
        justifyContent: 'flex-end',
        width: '100%',
        padding: '12px 20px',
        borderTop: `1px solid ${COLORS.blue[200]}`,
      }}
    >
      <CustomDialog
        cancelButtonName="back"
        cancelCallback={() => setOpenCancelDialog(false)}
        openDialog={openCancelDialog}
        submitButtonName="Confirm"
        submitCallback={handleSubmit}
        title="Confirmation"
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            Care Program Visit will be Canceled. Do you want to proceed?
          </Typography>
        </Box>
      </CustomDialog>
      <CustomDialog
        cancelButtonName="back"
        cancelCallback={() => setOpenRestoreDialog(false)}
        openDialog={openRestoreDialog}
        submitButtonName="Confirm"
        submitCallback={handleSubmit}
        title="Confirmation"
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            Care Program Visit will be restored and Not Allocated. Do you want to proceed?
          </Typography>
        </Box>
      </CustomDialog>
      <Box sx={{ display: 'flex', gap: '12px' }}>
        {isCancelled ? (
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpenRestoreDialog(true)}
            disableElevation
          >
            Restore Visit
          </Button>
        ) : (
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => setOpenCancelDialog(true)}
            disableElevation
            sx={styledCancelButton}
          >
            Cancel Visit
          </Button>
        )}
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => closeDialog()}
          disableElevation
          sx={disabledButtonStyles}
        >
          Close
        </Button>
      </Box>
    </DialogActions>
  );
}

Actions.propTypes = {
  closeDialog: func,
  updateVisit: func,
  visitStatus: string,
};

import { Accordion, AccordionSummary, Box, Typography } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ISO_DATE_ONLY_FORMAT } from '../../../../shared/constants';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { infoKeyField, infoRowWrapperStyles } from '../../styles';
import { checkEnableToEditFields } from '../../utils';
import ActualTimeFields from '../ActualTimeFields';
import DateAndArrivalTimeFields from '../DateAndArrivalTimeFields';
import StatusSelectorField from '../StatusSelectorField';
import VisitPrimaryCaregiver from '../VisitPrimaryCaregiver';
import VisitShadowCaregiver from '../VisitShadowCaregiver';
import LinkItem from './LinkItem';
import DurationComponent from './DurationComponent/DurationComponent';

export default function VisitInfoFields() {
  const [editActualTime, setEditActualTime] = useState(false);
  const [needToConfirmStatus, setNeedToConfirmStatus] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const {
    reFetchCalendarEvents,
    updateTemporaryVisitData,
    isUpdatingVisitStatus,
    editArrivalTime,
    setEditArrivalTime,
    visitInfoData,
    temporaryVisitData,
    onUpdateVisitDetails,
  } = useContext(VisitInfoTypeContext);

  const changeStatus = useCallback(
    value => {
      const newData = { status: value };
      updateTemporaryVisitData(newData);
      setNeedToConfirmStatus(true);
    },
    [updateTemporaryVisitData],
  );

  const cancelNewStatus = () => {
    setNeedToConfirmStatus(false);
    updateTemporaryVisitData({
      status: visitInfoData.status,
      actualTimeStart: visitInfoData.actualTimeStart,
      actualTimeEnd: visitInfoData.actualTimeEnd,
      cancellationReason: visitInfoData.cancellationReason,
    });
  };

  const closeNewStatusConfirmation = useCallback(() => setNeedToConfirmStatus(false), []);

  const isNotAllocatedVisit = visitInfoData?.status?.toLowerCase() === 'notallocated';

  const enableFieldsToEditData = useMemo(
    () =>
      checkEnableToEditFields({
        editActualTime,
        editArrivalTime,
        isNotAllocatedVisit,
        needToConfirmStatus,
        visitStatus: visitInfoData.status,
        visitDate: visitInfoData.date,
      }),
    [
      editActualTime,
      editArrivalTime,
      isNotAllocatedVisit,
      needToConfirmStatus,
      visitInfoData.date,
      visitInfoData.status,
    ],
  );
  const isFutureVisit = moment(visitInfoData?.date, ISO_DATE_ONLY_FORMAT).isAfter(moment(), 'day');

  const isOutOfOptimizedPeriodVisit = moment(visitInfoData?.date, ISO_DATE_ONLY_FORMAT).isAfter(
    moment().clone().startOf('week').add(13, 'days'),
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Client:</Typography>
        <LinkItem
          link={`/clients/${visitInfoData.clientId}` || ''}
          name={visitInfoData.clientName || ''}
        />
      </Box>
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Location:</Typography>
        <Typography variant="body1">{visitInfoData?.address}</Typography>
      </Box>
      <VisitPrimaryCaregiver
        caregiverName={visitInfoData?.caregiverName}
        caregiverId={visitInfoData?.caregiverId}
        preferredCaregiverName={visitInfoData?.preferredCaregiverName}
        preferredCaregiverId={visitInfoData?.preferredCaregiverId}
        enableToEditPrimaryCaregiver={
          enableFieldsToEditData.isPrimaryCaregiverAvailableToEdit && !isOutOfOptimizedPeriodVisit
        }
        visitInfoData={visitInfoData}
        onUpdateVisitDetails={onUpdateVisitDetails}
      />
      <VisitShadowCaregiver
        caregiverName={visitInfoData?.shadowCaregiverName}
        caregiverId={visitInfoData?.shadowCaregiverId}
        enableToEditShadowCaregiver={
          enableFieldsToEditData.isShadowCaregiverAvailableToEdit && !isOutOfOptimizedPeriodVisit
        }
        visitInfoData={visitInfoData}
        onUpdateVisitDetails={onUpdateVisitDetails}
      />
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Team:</Typography>
        <Typography variant="body1">{visitInfoData?.teamDetails?.name || ''}</Typography>
      </Box>
      <StatusSelectorField
        cancelNewStatus={cancelNewStatus}
        closeNewStatusConfirmation={closeNewStatusConfirmation}
        changeStatus={changeStatus}
        date={visitInfoData?.date}
        disabledSelect={
          (visitInfoData?.shadowCaregiverId &&
            visitInfoData?.caregiverId === visitInfoData?.shadowCaregiverId) ||
          enableFieldsToEditData.isStatusDisabledToEdit ||
          visitInfoData?.status?.toLowerCase() === 'canceled'
        }
        isUpdatingVisitStatus={isUpdatingVisitStatus}
        isFutureVisit={isFutureVisit}
        isNotAllocatedVisit={isNotAllocatedVisit}
        initialStatus={
          visitInfoData?.shadowCaregiverId &&
          visitInfoData?.caregiverId === visitInfoData?.shadowCaregiverId
            ? visitInfoData?.shadowStatus
            : visitInfoData?.status
        }
        needToConfirmStatus={needToConfirmStatus}
        reFetchCalendarEvents={reFetchCalendarEvents}
        scheduledVisit={!!visitInfoData?.externalId}
        temporaryStatus={temporaryVisitData?.status}
        visitId={visitInfoData?.id}
      />
      <DateAndArrivalTimeFields
        editArrivalTime={editArrivalTime}
        enableToEditScheduledTime={
          enableFieldsToEditData.isScheduledTimeAvailableToEdit && !isOutOfOptimizedPeriodVisit
        }
        isExactTime={visitInfoData?.isExactTime}
        setEditArrivalTime={setEditArrivalTime}
        scheduledValue={visitInfoData?.arrivalTime}
      />
      <ActualTimeFields
        actualTimeEnd={visitInfoData?.actualTimeEnd ?? undefined}
        actualTimeStart={visitInfoData?.actualTimeStart ?? undefined}
        editActualTime={editActualTime}
        enableToEditActualTime={
          enableFieldsToEditData.isActualTimeAvailableToEdit && !isOutOfOptimizedPeriodVisit
        }
        temporaryVisitData={visitInfoData}
        setEditActualTime={setEditActualTime}
      />
      <DurationComponent
        visitInfoData={visitInfoData}
        reFetchCalendarEvents={reFetchCalendarEvents}
        temporaryVisitData={temporaryVisitData}
        actualVisitData={visitInfoData}
        updateTemporaryVisitData={updateTemporaryVisitData}
        onUpdateVisitDetails={onUpdateVisitDetails}
      />
      <Accordion elevation={0} expanded={expanded}>
        <AccordionSummary onClick={() => setExpanded(!expanded)}>
          <Box>
            <Typography>Dev Info</Typography>
          </Box>
        </AccordionSummary>
        <Box>
          <Typography> Booked Debug Routes: </Typography>
          {visitInfoData?.devInfo &&
            visitInfoData?.devInfo.map((info, index) => (
              <Typography key={index}>&nbsp;&nbsp;&nbsp;&nbsp;{info}</Typography>
            ))}
          <Typography> Visit Id: {visitInfoData?.id}</Typography>
          <Typography> Client Id: {visitInfoData?.clientId}</Typography>
          <Typography> Caregiver Id: {visitInfoData?.caregiverId}</Typography>
          <Typography> Prefered Caregiver Id: {visitInfoData?.preferredCaregiverId}</Typography>
          <Typography> Shadow Caregiver Id: {visitInfoData?.shadowCaregiverId}</Typography>
          <Typography> Team Id: {visitInfoData?.team}</Typography>
          <Typography> Care Program Id: {visitInfoData?.careprogramId}</Typography>
          <Typography> Not Seen Caregivers: </Typography>
          {visitInfoData?.notSeenCaregivers &&
            visitInfoData?.notSeenCaregivers.map((cg, index) => (
              <Typography key={index}>&nbsp;&nbsp;&nbsp;&nbsp;{cg}</Typography>
            ))}
        </Box>
      </Accordion>
    </Box>
  );
}

import { Box, Button, FormControlLabel, Switch } from '@mui/material';
import AddTaskAlert from '../../../../../../pages/ClientDetails/components/CreateTask/components/AddTaskAlert';
import { bool, func, instanceOf } from 'prop-types';
import React, { useMemo, useState } from 'react';
import {
  MAX_TASK_DETAILS_FIELD_LENGTH,
  WORKING_WEEK_LENGTH,
  WEEK_DAYS,
} from '../../../../../../shared/constants';
import CustomInput from '../../../../../../components/Inputs';
import MultilineInput from '../../../../../../components/Inputs/MultilineInput';
import { TaskItemMultiselect } from '../../../../../../components/MultiselectWithBadge';
import CustomSelect from '../../../../../../components/Select';
import { taskInitialState } from '../../constants';
import CustomAutocomplete from '../Autocomplete';
import CustomCheckbox from '../Checkbox/Checkbox';
import ContentWrapper from './Wrapper';
import { customSwitcher } from './styles';
import { sortObjectTimeOfDays } from '../../../../../../shared/utils/common';
import { sortBy } from 'lodash';
import { priorityOptions } from '../../../../views/CarePlan/constants';

export default function Content({
  disabledDays = {},
  loader = {},
  configData = {},
  hasPredefinedData = false,
  hasTheSameTask = false,
  setTaskData = () => {},
  taskData = {},
}) {
  const [customVisitSetup, setCustomVisitSetup] = useState(
    hasPredefinedData ||
      taskData?.days?.length > 0 ||
      taskData?.timeOfDays?.length > 0 ||
      taskData?.visitTypes?.length > 0,
  );
  const [oldTaskData, setOldTaskData] = useState(taskInitialState);

  const handleChangeTask = (_, value) =>
    setTaskData({ ...taskData, name: value ? value.name : '' });

  const onSelectChange = params => {
    if (params.field === 'categoryTypes' || params.field === 'levelOfAssistance') {
      setTaskData({
        ...taskData,
        name: params.field === 'categoryTypes' && !taskData.isCustom ? '' : taskData.name,
        [params.field]: params.id,
      });
    } else {
      setTaskData({
        ...taskData,
        [params.field]: params.value ?? params.id,
      });
    }
  };

  const changeCustomTaskName = e => setTaskData({ ...taskData, [e.target.id]: e.target.value });

  const changeDetails = e => {
    if (e.target.value.length <= MAX_TASK_DETAILS_FIELD_LENGTH) {
      setTaskData({ ...taskData, [e.target.id]: e.target.value });
    }
  };

  const isStepAvailable = step => {
    if (step === 1) {
      return (
        !taskData.isCustom ||
        !!(taskData?.name.length && taskData?.categoryTypes.length) ||
        hasPredefinedData
      );
    }
    if (step === 2) {
      return (
        !taskData.isCustom ||
        !!(
          taskData?.name.length &&
          taskData?.categoryTypes.length &&
          taskData?.levelOfAssistance.length
        ) ||
        hasPredefinedData
      );
    }
    return true;
  };

  const selectCustomTask = e => {
    setTaskData({
      ...(hasPredefinedData ? taskData : taskInitialState),
      isCustom: e.target.checked,
    });
    setCustomVisitSetup(hasPredefinedData);
    if (!taskData.isCustom) {
      setOldTaskData(taskData);
    }
    if (taskData.isCustom) {
      setTaskData(oldTaskData);
    }
  };

  const isResetRecurrencePatternAvailable = useMemo(
    () =>
      Object.keys(taskData).some(
        fieldName => taskData[fieldName] && taskData[fieldName].length !== 0,
      ),
    [taskData],
  );

  const resetRecurrencePattern = () => {
    setTaskData({ ...taskData, days: [], timeOfDays: [], visitTypes: [] });
  };

  const sortedLevelsOfAssistance = configData?.levelsOfAssistance?.map(levelOfAssistance => ({
    ...levelOfAssistance,
    sortIndex: ['Prompt', 'Standby Assist', 'Partial Assist', 'Perform'].findIndex(
      x => x === levelOfAssistance.name,
    ),
  }));

  return (
    <ContentWrapper>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        {hasTheSameTask && <AddTaskAlert />}
        <FormControlLabel
          control={
            <Switch
              sx={customSwitcher}
              checked={taskData.isCustom}
              color="secondary"
              onChange={e => selectCustomTask(e)}
            />
          }
          label={taskData.isCustom ? 'Custom Task' : 'Standard Task'}
        />
        {!taskData.isCustom && (
          <CustomSelect
            id="categoryTypes"
            isLoading={loader}
            isMultiselect={false}
            options={configData?.categoryTypes?.map(cat => ({ id: cat.id, name: cat.name }))}
            placeHolder="Category"
            selectedValue={taskData?.categoryTypes}
            setValue={onSelectChange}
          />
        )}
        {taskData.isCustom ? (
          <CustomInput
            changeTaskName={changeCustomTaskName}
            id="name"
            label="Task"
            value={taskData?.name}
          />
        ) : (
          <CustomAutocomplete
            id="name"
            changeValue={handleChangeTask}
            options={sortBy(
              configData.taskTypes.filter(t => t.category === taskData.categoryTypes),
              ['name'],
            )}
            selectedValue={taskData?.name || ''}
          />
        )}
        {taskData.isCustom && (
          <CustomSelect
            id="categoryTypes"
            isLoading={loader}
            isMultiselect={false}
            options={configData?.categoryTypes.map(cat => ({ id: cat.id, name: cat.name }))}
            placeHolder="Category"
            selectedValue={taskData?.categoryTypes}
            setValue={onSelectChange}
          />
        )}
        {isStepAvailable(1) && (
          <CustomSelect
            id="levelOfAssistance"
            isLoading={loader}
            isMultiselect={false}
            options={sortBy(sortedLevelsOfAssistance ?? [], ['sortIndex'])?.map(lev => ({
              id: lev.id,
              name: lev.name,
            }))}
            placeHolder="Level Of Assistance"
            selectedValue={taskData?.levelOfAssistance}
            setValue={onSelectChange}
          />
        )}
        {isStepAvailable(1) && (
          <CustomSelect
            id="priority"
            isLoading={loader}
            isMultiselect={false}
            options={priorityOptions?.map(priority => ({
              id: priority,
              name: priority,
            }))}
            placeHolder="Priority"
            selectedValue={taskData?.priority}
            setValue={onSelectChange}
          />
        )}
        {isStepAvailable(2) && (
          <>
            <MultilineInput
              changeDetails={changeDetails}
              id="details"
              label="Details"
              maxLength={MAX_TASK_DETAILS_FIELD_LENGTH}
              value={taskData.details}
              showHelperText
            />
            <CustomCheckbox
              checked={customVisitSetup}
              isDisabled={hasPredefinedData}
              label="Define Recurrence Pattern"
              onValueChange={() => setCustomVisitSetup(prevState => !prevState)}
            />
            {customVisitSetup && (
              <TaskItemMultiselect
                additionalStyles={{ width: '100%' }}
                disabled={hasPredefinedData}
                hasSelectAllOption
                id="visitTypes"
                isMultiselect
                options={configData?.visitTypes?.map(vis => ({ id: vis.id, name: vis.name }))}
                placeHolder={
                  taskData?.visitTypes?.length ? 'Visit type' : 'All Visit Types by default'
                }
                selectedValue={taskData?.visitTypes || []}
                setValue={onSelectChange}
              />
            )}
            {customVisitSetup && (
              <TaskItemMultiselect
                additionalStyles={{ width: '100%' }}
                disabled={hasPredefinedData}
                disabledDays={disabledDays}
                hasSelectAllOption
                id="days"
                isMultiselect
                options={WEEK_DAYS.slice(0, WORKING_WEEK_LENGTH)?.map(day => ({
                  id: day.id,
                  name: day.name,
                }))}
                placeHolder={taskData?.days?.length ? 'Days' : 'All Days by default'}
                selectedValue={taskData.days || []}
                setValue={onSelectChange}
              />
            )}
            {customVisitSetup && (
              <TaskItemMultiselect
                additionalStyles={{ width: '100%' }}
                disabled={hasPredefinedData}
                hasSelectAllOption
                id="timeOfDays"
                isMultiselect
                options={sortObjectTimeOfDays(configData)}
                placeHolder={
                  taskData?.timeOfDays?.length ? 'Time Of Day' : 'All Times Of Day by default'
                }
                selectedValue={taskData?.timeOfDays || []}
                setValue={onSelectChange}
              />
            )}
            {customVisitSetup && (
              <Button
                color="secondary"
                disableElevation
                disabled={!isResetRecurrencePatternAvailable}
                onClick={resetRecurrencePattern}
                sx={{ width: '220px', fontSize: '11px', padding: '8px 12px' }}
                variant="outlined"
              >
                reset recurrence pattern
              </Button>
            )}
          </>
        )}
      </Box>
    </ContentWrapper>
  );
}

Content.propTypes = {
  disabledDays: instanceOf(Object),
  hasPredefinedData: bool,
  hasTheSameTask: bool,
  setTaskData: func,
  taskData: instanceOf(Object),
};

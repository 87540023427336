import { COLORS } from '../../../../../../shared/styles/Theme';

export const visitCaregiversWrapper = {
  display: 'flex',
  flexDirection: 'column',
};

export const visitCaregiverInfo = {
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  '& img': { width: '14px', height: '14px' },
};

export const caregiverNameStyles = {
  color: COLORS.blue[700],
  fontWeight: '600',
  fontSize: '14px',
  cursor: 'pointer',
};

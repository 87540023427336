import { Box, Typography } from '@mui/material';
import { instanceOf, string } from 'prop-types';
import React from 'react';
import { detailsInfoKeysStyles, detailsWrapper } from '../styles';
import { calculateHourColor, calculateRateColor, getHourString } from '../../utils';
import { sortBy } from 'lodash';

export default function DetailsWrapper({
  resourceStatistics = {},
  caregiver = {},
  minExpandedHeight = '',
}) {
  return (
    <Box
      className="expandedHeader"
      sx={{
        ...detailsWrapper,
        minHeight: `${Number(minExpandedHeight.split('p')[0]) - 40.89}px`,
      }}
    >
      <Box sx={{ display: 'flex', mb: '2px' }}>
        <Typography sx={{ ...detailsInfoKeysStyles }}>Primary Teams:</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          {caregiver.primaryTeamsDetails?.length ? (
            sortBy(caregiver.primaryTeamsDetails, ['name'])?.map(teamDetail => (
              <Typography sx={{ fontSize: '12px' }}>{teamDetail?.name}</Typography>
            ))
          ) : (
            <Typography sx={{ fontSize: '12px', fontStyle: 'italic' }}>No Team</Typography>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', mb: '2px' }}>
        <Typography sx={{ ...detailsInfoKeysStyles }}>Secondary Teams:</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          {caregiver.secondaryTeamsDetails?.length ? (
            sortBy(caregiver.secondaryTeamsDetails, ['name'])?.map(teamDetail => (
              <Typography sx={{ fontSize: '12px' }}>{teamDetail?.name}</Typography>
            ))
          ) : (
            <Typography sx={{ fontSize: '12px', fontStyle: 'italic' }}>No Team</Typography>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', mb: '2px' }}>
        <Typography sx={{ ...detailsInfoKeysStyles }}>Scheduled Hours:</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography
            sx={{
              fontSize: '12px',
              color: calculateHourColor(
                resourceStatistics?.hoursScheduled,
                resourceStatistics?.minutesScheduled,
                480,
                480,
              ),
            }}
          >
            {getHourString(
              resourceStatistics?.hoursScheduled,
              resourceStatistics?.minutesScheduled,
            )}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', mb: '2px' }}>
        <Typography sx={{ ...detailsInfoKeysStyles }}>Guaranteed Hours:</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography
            sx={{
              fontSize: '12px',
              color: calculateHourColor(
                resourceStatistics?.hoursGuaranteed,
                resourceStatistics?.minutesGuaranteed,
                9999,
                9999,
              ),
            }}
          >
            {getHourString(
              resourceStatistics?.hoursGuaranteed,
              resourceStatistics?.minutesGuaranteed,
            )}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', mb: '2px' }}>
        <Typography sx={{ ...detailsInfoKeysStyles }}>Billable Hours:</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography
            sx={{
              fontSize: '12px',
              color: calculateHourColor(
                resourceStatistics?.hoursBooked,
                resourceStatistics?.minutesBooked,
                resourceStatistics?.scheduledTotalMinutes * 0.7,
                resourceStatistics?.scheduledTotalMinutes * 0.5,
              ),
            }}
          >
            {getHourString(resourceStatistics?.hoursBooked, resourceStatistics?.minutesBooked)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', mb: '2px' }}>
        <Typography sx={{ ...detailsInfoKeysStyles }}>Unutilized Hours:</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography
            sx={{
              fontSize: '12px',
              color: calculateHourColor(
                resourceStatistics?.hoursUnutilized,
                resourceStatistics?.minutesUnutilized,
                resourceStatistics.guaranteedTotalMinutes * 0.16,
                resourceStatistics.guaranteedTotalMinutes * 0.31,
              ),
            }}
          >
            {getHourString(
              resourceStatistics?.hoursUnutilized,
              resourceStatistics?.minutesUnutilized,
            )}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', mb: '2px' }}>
        <Typography sx={{ ...detailsInfoKeysStyles }}>Travel Time:</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography
            sx={{
              fontSize: '12px',
              color: calculateHourColor(
                resourceStatistics?.hoursTravelTime,
                resourceStatistics?.minutesTravelTime,
                resourceStatistics?.scheduledTotalMinutes * 0.3,
                resourceStatistics?.scheduledTotalMinutes * 0.2,
              ),
            }}
          >
            {getHourString(
              resourceStatistics?.hoursTravelTime,
              resourceStatistics?.minutesTravelTime,
            )}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', mb: '2px' }}>
        <Typography sx={{ ...detailsInfoKeysStyles }}> Utilization %: </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            color: calculateRateColor(resourceStatistics?.billableRate, 60, 75),
          }}
        >
          {resourceStatistics?.billableRate}%
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', mb: '2px' }}>
        <Typography sx={{ ...detailsInfoKeysStyles }}>Utilization w/ Travel:</Typography>
        <Typography
          sx={{
            fontSize: '12px',
            color: calculateRateColor(resourceStatistics?.billableRateWithTravelTime, 60, 75),
          }}
        >
          {resourceStatistics?.billableRateWithTravelTime}%
        </Typography>
      </Box>
    </Box>
  );
}

DetailsWrapper.propTypes = {
  resourceStatistics: instanceOf(Object),
  minExpandedHeight: string,
};

import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Alert, AlertTitle, Grow, Typography } from '@mui/material';
import { node } from 'prop-types';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import Information from '../../components/Alert/Information';
import CustomDialog from '../Dialog';
import ErrorStacktrace from './ErrorStacktrace';
import { commonAlertWrapper } from './styles';

const AlertContext = createContext(null);

function AlertProvider({ children = <div /> }) {
  const [alertData, setAlertData] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    let tableListTimer = null;
    if (
      showAlert &&
      (alertData?.type === 'success' ||
        (alertData?.type === 'error' && alertData.bypassCustomModal))
    ) {
      const delay = 4;
      tableListTimer = setTimeout(() => {
        setShowAlert(false);
      }, delay * 1000);
    }

    return () => {
      clearTimeout(tableListTimer);
    };
  }, [alertData?.bypassCustomModal, alertData?.type, showAlert]);

  const setAlert = data => {
    setAlertData(data);
    setShowAlert(true);
  };

  const memoizedProviderValue = useMemo(
    () => ({
      setAlert,
    }),
    [],
  );
  const message =
    alertData?.errorData?.data?.message ||
    alertData?.errorData?.message ||
    alertData?.errorData?.message?.errorMsg ||
    'Something went wrong';
  const stacktrace =
    alertData?.errorData?.data?.stacktrace ||
    alertData?.errorData?.stacktrace ||
    'Something went wrong';
  const traceId = alertData?.errorData?.data?.traceId || alertData?.errorData?.traceId || null;
  const error = alertData?.errorData?.data?.error || alertData?.errorData?.error;
  const getComponent = () => {
    if (alertData?.type === 'error' && !alertData?.bypassCustomModal) {
      return error === 'RequestEngineLimiterExceededException' ? (
        <CustomDialog
          cancelCallback={() => setShowAlert(false)}
          submitButtonName="OK"
          submitCallback={() => setShowAlert(false)}
          title="Notification"
          titleIcon={<NotificationsOutlinedIcon />}
          openDialog={showAlert && !!alertData}
        >
          <Information informationData={message} />
        </CustomDialog>
      ) : (
        <CustomDialog
          cancelCallback={() => setShowAlert(false)}
          submitButtonName="OK"
          submitCallback={() => setShowAlert(false)}
          title="Something Went Wrong"
          openDialog={showAlert && !!alertData}
        >
          <ErrorStacktrace message={message} stacktrace={stacktrace} traceId={traceId} />
        </CustomDialog>
      );
    }
    return (
      <Grow in={showAlert && !!alertData}>
        <Alert
          sx={commonAlertWrapper}
          severity={alertData?.type || 'error'}
          onClose={() => setShowAlert(false)}
        >
          <AlertTitle sx={{ fontSize: '16px', mt: 0 }}>
            {alertData?.type?.toUpperCase() || 'Error'}
          </AlertTitle>
          {alertData?.message && <Typography>{alertData?.message}</Typography>}
        </Alert>
      </Grow>
    );
  };
  return (
    <AlertContext.Provider value={memoizedProviderValue}>
      {getComponent()}
      {children}
    </AlertContext.Provider>
  );
}

export { AlertProvider };
export default AlertContext;

AlertProvider.propTypes = {
  children: node,
};

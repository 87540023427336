export const getCreateVisitConfirmButtonName = (step, oneTimeVisit = false, dataLength = 1) => {
  return step === 0
    ? 'check availability'
    : step === 1
    ? oneTimeVisit
      ? `create visit${dataLength > 1 ? 's' : ''}!`
      : 'next step'
    : 'create visit!';
};

export const parseClientBlockedCaregiversResponse = response => {
  const responseData = response?.dailyAffections;
  if (!response?.dailyAffections) {
    return [];
  }

  const dates = Object.keys(responseData);
  let datesWithNotAllocatedVisits = 0;
  const parsedDates = dates.reduce((obj, currentDate) => {
    if (
      responseData[currentDate].changedVisits.length > 0 ||
      responseData[currentDate].notAllocatedVisits.length > 0
    ) {
      Object.assign(obj, { [currentDate]: responseData[currentDate] });
      datesWithNotAllocatedVisits +=
        responseData[currentDate].notAllocatedVisits.length > 0 ? 1 : 0;
    }
    return obj;
  }, {});

  return {
    dates: Object.keys(parsedDates),
    datesWithNotAllocatedVisits,
    requestId: response?.requestId,
    visitsList: parsedDates,
  };
};

import { Box, Typography } from '@mui/material';
import moment from 'moment/moment';
import React, { useContext, useState } from 'react';
import {
  backgroundColorSchemeMap,
  DATE_ONLY_STANDARD_FORMAT,
} from '../../../../../shared/constants';
import Badge from '../../../../../components/Badge';
import { CaregiverDetailsContext, CaregiverProfileContext } from '../../../context';
import { CAREGIVER_STATUSES } from '../constants';
import EffectiveDateDialog from './EffectiveDateModal';
import StatusesCustomSelect from './Select';

export default function StatusSelector() {
  const { profileData, setProfileData, caregiverProfile } = useContext(CaregiverProfileContext);
  const { isEdit } = useContext(CaregiverDetailsContext);
  const [date, setDate] = useState(
    moment().add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
  );
  const [openEffectiveDateDialog, setOpenEffectiveDateDialog] = useState(false);

  const updateProfileData = newStatus => {
    let newProfileData = { ...profileData };
    if (newStatus === 'Active') {
      newProfileData = { ...newProfileData, status: newStatus };
    } else {
      const statusChanges = { ...profileData?.pendingChanges?.statusChanges } || {};
      statusChanges[moment(date).add(1, 'days').format('YYYY-MM-DD')] = newStatus;
      const pendingChanges = {
        ...profileData?.pendingChanges,
        status: statusChanges,
      };
      newProfileData = { ...newProfileData, pendingChanges: pendingChanges };
    }
    setProfileData({ ...newProfileData });
  };

  const changeStatus = value => {
    if (value === 'Inactive') {
      setOpenEffectiveDateDialog(true);
    } else {
      updateProfileData('Active');
    }
  };

  const submitConfirmationDateDialog = () => {
    updateProfileData('Inactive');
    setOpenEffectiveDateDialog(false);
  };

  const closeEffectiveDateDialog = () => {
    setOpenEffectiveDateDialog(false);
    setDate(moment());
  };

  return (
    <Box sx={{ minWidth: '250px' }}>
      <Box
        sx={{
          display: 'flex',
          minWidth: '250px',
          flexDirection: 'column',
        }}
      >
        {isEdit ? (
          <StatusesCustomSelect
            colorScheme={backgroundColorSchemeMap[caregiverProfile?.status?.toLowerCase()]}
            disabled={false}
            id="status"
            options={CAREGIVER_STATUSES?.map(option => option.name)}
            isLoading={false}
            selectedValue={caregiverProfile?.status}
            setValue={changeStatus}
          />
        ) : (
          <Badge
            colorScheme={backgroundColorSchemeMap[profileData?.status?.toLowerCase()]}
            label={profileData?.status}
          />
        )}
        {profileData?.pendingChanges?.status && (
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', marginTop: '10px' }}>
            {Object.keys(profileData?.pendingChanges?.status).map(date => (
              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'row' }} key={date}>
                <Typography variant="body1">
                  {moment(date).format(DATE_ONLY_STANDARD_FORMAT)}:
                </Typography>
                <Typography sx={{ marginRight: '2px' }} variant="body1">
                  {profileData?.pendingChanges.status[date]}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        <EffectiveDateDialog
          openEffectiveDateDialog={openEffectiveDateDialog}
          closeEffectiveDateDialog={closeEffectiveDateDialog}
          submitEffectiveDateDialog={submitConfirmationDateDialog}
          date={date}
          selectDate={newValue => setDate(newValue)}
        />
      </Box>
    </Box>
  );
}

import { COLORS } from '../../../../shared/styles/Theme';

export const getTimeOffRequestsTableStyles = isEmpty => [
  {
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: 'calc(100vh - 360px)',
      padding: isEmpty ? '32px 0' : '1px',
      overflowX: 'auto',
    },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-cell': {
      fontSize: '15px',
      alignItems: 'start',
      padding: '46px 21px 46px 21px',
      '&:last-child': {
        maxWidth: '100% !important',
        minWidth: '475px !important',
      },
    },
  },
];

export const timeOfRequestActionsButtonStyles = {
  width: '40px',
  height: '40px',
  border: `1px solid ${COLORS.blue[200]}`,
  borderRadius: '10px',
  '&.Mui-disabled': {
    opacity: 0.6,
  },
  '&:hover': {
    backgroundColor: COLORS.blue[50],
  },
  opacity: 1,
  cursor: 'pointer',
};

export const timeOfRequestActionsButtonStylesText = {
  width: '100%',
  height: '40px',
  border: `1px solid ${COLORS.blue[200]}`,
  borderRadius: '10px',
  '&.Mui-disabled': {
    opacity: 0.6,
  },
  '&:hover': {
    backgroundColor: COLORS.blue[50],
  },
  opacity: 1,
  cursor: 'pointer',
  fontSize: '12px',
  color: COLORS.red[500],
};

export const lastUpdateCellWrapper = {
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'start',
  gap: '40px',
  '& .MuiChip-root': {
    maxHeight: '24px',
  },
};

export const continueButtonStyles = {
  color: COLORS.blue[500],
  '&.MuiButton-root.Mui-disabled': {
    backgroundColor: 'transparent',
    color: COLORS.blue[300],
    fontSize: '13px',
  },
};

export const rejectButtonStyles = { color: COLORS.red[500], fontSize: '13px' };

export const badgeAndActionsCellStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  height: '100%',
};

import { Box, Button, Collapse, Typography } from '@mui/material';
import {
  durationComponentWrapper,
  infoKeyField,
  infoRowEditableInfoWrapper,
  infoRowWrapperStyles,
  inputContainer,
  outlinedButtonStyles,
  setupActionsWrapperStyles,
} from './styles';
import EditIconButton from '../../EditIconButton';
import { useContext, useState } from 'react';
import NumericInput from '../../../../NumericInput';
import OptimizerCheck from '../../../../Optimizer/OptimizerCheck';
import moment from 'moment';
import { api } from '../../../../../api/';
import AlertContext from '../../../../Alert';

export default function DurationComponent({
  visitInfoData,
  reFetchCalendarEvents,
  temporaryVisitData,
  actualVisitData,
  updateTemporaryVisitData,
  onUpdateVisitDetails,
}) {
  const [editing, setEditing] = useState(false);
  const [optimizerData, setOptimizerData] = useState({});
  const [openCheckOptimizer, setOpenCheckOptimizer] = useState(false);

  const { setAlert } = useContext(AlertContext);

  const updateDuration = value => {
    updateTemporaryVisitData({ duration: Number(value) });
  };

  const submitFunction = async () => {
    await updateCareProgram();
    setEditing(false);
    reFetchCalendarEvents();
  };

  const handleCancel = () => {
    updateTemporaryVisitData({ duration: actualVisitData?.duration });
    setEditing(false);
  };

  const updateCareProgram = async () => {
    const thisDate = moment(temporaryVisitData?.date).format('YYYY-MM-DD');
    const payload = {
      id: temporaryVisitData?.careprogramId,
    };
    const apiData = await api('GET', 'crud', 'careprogram', payload);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      if (apiData?.data) {
        const thisCareProgram = apiData?.data
        if (thisCareProgram?.dateChanges && Object.keys(thisCareProgram?.dateChanges).includes(thisDate)) {
          thisCareProgram.dateChanges[thisDate].duration = temporaryVisitData?.duration;
        } else {
          thisCareProgram.dateChanges = {
            ...thisCareProgram.dateChanges,
            [thisDate]: {
              duration: temporaryVisitData?.duration,
            },
          };
        }
        thisCareProgram.oneTimeChange = true;
        const apiDataPatch = await api('PATCH', 'crud', 'careprogram', thisCareProgram);
        if (apiDataPatch.error) {
          setAlert({
            apiData: apiDataPatch,
            type: 'error',
          });
        }
      }
    }

  };

  const handleApply = async () => {
    if (moment(actualVisitData?.date).isAfter(moment(), 'date')) {
      setOptimizerData(temporaryVisitData);
      setOpenCheckOptimizer(true);
    } else if (moment(actualVisitData?.date).isSame(moment(), 'date')) {
      await onUpdateVisitDetails(temporaryVisitData);
      await updateCareProgram();
      setEditing(false);
      reFetchCalendarEvents();
    }
  };

  const hourValue = temporaryVisitData?.duration ? Math.floor(temporaryVisitData.duration / 60) : 0;
  const minuteValue = temporaryVisitData?.duration ? temporaryVisitData.duration % 60 : 0;

  const handleHourChange = value => {
    updateDuration(minuteValue + parseInt(value) * 60);
  };

  const handleMinuteChange = value => {
    updateDuration(hourValue * 60 + parseInt(value));
  };

  return (
    <Box {...(editing && { sx: durationComponentWrapper })}>
      {!editing && (
        <Box sx={infoRowWrapperStyles}>
          <Typography sx={infoKeyField}>
            {visitInfoData?.actualDuration ? 'Actual' : 'Scheduled'} Duration:
          </Typography>
          <Box sx={infoRowEditableInfoWrapper}>
            <Typography variant="body1">
              {visitInfoData?.actualDuration ?? visitInfoData?.duration}
            </Typography>
            {moment(visitInfoData?.date).isSameOrAfter(moment(), 'date') &&
              !visitInfoData?.actualDuration && (
                <EditIconButton onEdit={() => setEditing(true)} tooltip="Edit actual start time" />
              )}
          </Box>
        </Box>
      )}
      <Collapse in={editing} timeout={300}>
        <Box sx={inputContainer}>
          <NumericInput
            changeValue={handleHourChange}
            isRequired
            label="Scheduled Hours"
            oneTimeVisit
            step={1}
            value={hourValue}
            additionalStyles={{ width: '120px' }}
          />
          <NumericInput
            changeValue={handleMinuteChange}
            isRequired
            label="Scheduled Minutes"
            oneTimeVisit
            step={5}
            value={minuteValue}
            additionalStyles={{ width: '120px' }}
          />
        </Box>
        <Box sx={setupActionsWrapperStyles}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleCancel}
            disableElevation
            sx={outlinedButtonStyles}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!temporaryVisitData?.duration}
            variant="contained"
            onClick={handleApply}
            disableElevation
          >
            Apply
          </Button>
        </Box>
        <OptimizerCheck
          data={[optimizerData]}
          dataObjName="visitChangeObj"
          submitFunction={submitFunction}
          open={openCheckOptimizer}
          openToggle={setOpenCheckOptimizer}
          cancelFunction={() => setOptimizerData({})}
        />
      </Collapse>
    </Box>
  );
}

import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { bool, func } from 'prop-types';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import ButtonsGroup from '../../../../../components/ButtonsGroup';
import { viewTypeButtons } from '../constants';

export default function ViewActions({ addRequest = () => {}, disabled = false }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { tabSubView } = useParams();

  const changeSubView = value => {
    navigate(`/caregivers/${id}/pto-callouts/${value}`);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: '24px',
      }}
    >
      <ButtonsGroup
        buttons={viewTypeButtons}
        changeSelected={changeSubView}
        selected={tabSubView}
        disabled={disabled}
      />
      <Button
        color="secondary"
        variant="outlined"
        onClick={addRequest}
        startIcon={<AddIcon />}
        disableElevation
        disabled={disabled}
      >
        Add time-off request
      </Button>
    </Box>
  );
}

ViewActions.propTypes = {
  addRequest: func,
  disabled: bool,
};

import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { SHORT_DATE_FORMAT, formatToPST } from '../../../../shared/constants';
import { checkEqualArrays } from '../../../../shared/utils/common';
import HistoryBulletList from '../../../../components/HistoryBulletList';

export const INTERRUPTIBLE_TASK_KEY = 'registeredInterruptableTask';

export const CAREGIVER_STATUSES = [
  { id: 'active', name: 'Active' },
  { id: 'inactive', name: 'Inactive' },
];

export const CAREGIVER_STATUS_ACTION_TITLES = {
  deactivate: 'Deactivate Caregiver',
  reActivate: 'Reactivate Caregiver',
};

export const userInfoFieldsMap = [
  { name: 'Status', type: 'badge', key: 'status' },
  { name: 'Address', type: 'address', key: 'all' },
  { name: 'Primary Teams', type: 'primaryTeams', key: 'primaryTeamsDetails' },
  { name: 'Secondary Teams', type: 'secondaryTeams', key: 'secondaryTeamsDetails' },
  { name: 'Guaranteed Hours', type: 'numeric', key: 'guaranteedHours' },
];

export const PAGE_SIZE = 10;

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const detailsButtons = [
  { name: 'Current', infoType: 'current' },
  { name: 'History', infoType: 'history' },
];

export const initialState = {
  gender: '',
  type: '',
  primaryTeams: [],
  secondaryTeams: [],
  skills: [],
  languages: [],
  status: '',
};
export const historyColumns = [
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    sortable: false,
    minWidth: 280,
    renderCell: params => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{formatToPST(params.row.dateChanged)}</Typography>
      </Box>
    ),
  },
  {
    field: 'changes',
    headerName: 'Changes',
    minWidth: 740,
    sortable: false,
    renderCell: params => <HistoryBulletList changes={params.row} />,
  },
  {
    field: 'user',
    headerName: 'User',
    sortable: false,
    minWidth: 330,
    renderCell: params => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{params.row.updatedBy}</Typography>
      </Box>
    ),
  },
];

export const restructureTeams = obj => {
  if (!obj) {
    return {};
  }
  const { currentTeams, futureTeams, ...rest } = obj;
  const primaryChanged =
    futureTeams?.primaryTeams &&
    !checkEqualArrays(futureTeams?.primaryTeams, currentTeams?.primaryTeams);
  const secondaryChanged =
    futureTeams?.secondaryTeams &&
    !checkEqualArrays(futureTeams?.secondaryTeams, currentTeams?.secondaryTeams);
  return {
    ...rest,
    primaryTeams: currentTeams?.primaryTeams || [],
    secondaryTeams: currentTeams?.secondaryTeams || [],
    futurePrimaryTeams: futureTeams?.primaryTeams,
    futureSecondaryTeams: futureTeams?.secondaryTeams,
    effectiveDateStart: moment(futureTeams?.effectiveDateStart).format(SHORT_DATE_FORMAT),
    secondaryChanged,
    primaryChanged,
  };
};

export const getChangedTeams = (oldTeams, newTeams) => {
  const oldTeamIds = new Set(oldTeams?.map(team => team?.id));
  const newTeamIds = new Set(newTeams?.map(team => team?.id));

  const removedTeams = newTeams?.filter(team => !oldTeamIds?.has(team?.id));
  const addedTeams = oldTeams?.filter(team => !newTeamIds?.has(team?.id));

  return { addedTeams, removedTeams };
};
export const allocatedVisits = [
  {
    visitId: '225eadee-219f-4106-af7b-2f2a73607bfa',
    visitType: {
      name: 'Daily Boost',
      description: 'Daily boost',
    },
    duration: 70,
    arrivalStart: '10/27/2023 13:45:00',
    arrivalEnd: '10/27/2023 14:15:00',
    caregiver: {
      id: '26cb0fe5-49de-4822-b0bf-d5327aff115c',
      firstName: 'Toni Marie',
      lastName: 'Jones',
    },
    repeat: 'Weekly',
    team: 'East OC',
    travelTime: 20,
  },
  {
    visitId: 'df990cd2-1785-414d-bb6b-62476390ded2',
    visitType: {
      name: 'Full Service',
      description: 'Full service',
    },
    duration: 90,
    arrivalStart: '10/23/2023 16:00:00',
    arrivalEnd: '10/23/2023 16:30:00',
    caregiver: {
      id: '26cb0fe5-49de-4822-b0bf-d5327aff115c',
      firstName: 'Toni Marie',
      lastName: 'Jones',
    },
    repeat: 'Weekly',
    team: 'East OC',
    travelTime: 20,
  },
  {
    visitId: 'df990cd2-1785-414d-bb6b-62476390eee2',
    visitType: {
      name: 'Daily Boost',
      description: 'Daily boost',
    },
    duration: 90,
    arrivalStart: '10/27/2023 16:00:00',
    arrivalEnd: '10/27/2023 16:30:00',
    caregiver: {
      id: 'd8d58f9e-1c92-4332-b8e5-4e30d534af45',
      firstName: 'Divan',
      lastName: 'Pieterse',
    },
    repeat: 'Weekly',
    team: 'East OC',
    travelTime: 20,
  },
  {
    visitId: '608d8015-1d2d-4f0f-9c30-254c8b719a61',
    visitType: {
      name: 'Essential care',
      description: 'Essential care',
    },
    duration: 25,
    arrivalStart: '10/24/2023 13:00:00',
    arrivalEnd: '10/24/2023 13:30:00',
    caregiver: {
      id: 'd8d58f9e-1c92-4332-b8e5-4e30d534af45',
      firstName: 'Divan',
      lastName: 'Pieterse',
    },
    repeat: 'Weekly',
    team: 'East OC',
    travelTime: 20,
  },
  {
    visitId: '1caaf774-d7b5-45f5-9d42-48f403d53332',
    visitType: {
      name: 'Extended care',
      description: 'Extended care',
    },
    duration: 25,
    arrivalStart: '10/23/2023 11:00:00',
    arrivalEnd: '10/23/2023 11:00:00',
    caregiver: {
      id: '1d39bb63-f7b0-4036-b4cb-6ea93baf380f',
      firstName: 'Janneman',
      lastName: 'DeVries',
    },
    repeat: 'One time',
    team: 'East OC',
    travelTime: 20,
  },
  {
    visitId: '81047ea7-89a6-4909-a635-87e8eeffe55c',
    visitType: {
      name: 'Quick Assist',
      description: 'Quick assist',
    },
    duration: 25,
    arrivalStart: '10/23/2023 16:00:00',
    arrivalEnd: '10/23/2023 16:30:00',
    caregiver: {
      id: '80a0c4b5-d4d2-491e-b0a3-82abe2b477f9',
      firstName: 'Jakobus',
      lastName: 'Nelson',
    },
    repeat: 'One time',
    team: 'East OC',
    travelTime: 20,
  },
  {
    visitId: '27d2901a-a19c-4177-bbcb-86223b982e12',
    visitType: {
      name: 'Quick Assist',
      description: 'Quick assist',
    },
    duration: 25,
    arrivalStart: '10/26/2023 10:00:00',
    arrivalEnd: '10/26/2023 10:00:00',
    caregiver: {
      id: '80a0c4b5-d4d2-491e-b0a3-82abe2b477f9',
      firstName: 'Jakobus',
      lastName: 'Nelson',
    },
    repeat: 'One time',
    team: 'East OC',
    travelTime: 20,
  },
  {
    visitId: '4bf663d9-c626-4214-ab98-9dfa02eea526',
    visitType: {
      name: 'Quick Assist',
      description: 'Quick assist',
    },
    duration: 25,
    arrivalStart: '10/26/2023 10:00:00',
    arrivalEnd: '10/26/2023 10:00:00',
    caregiver: {
      id: '80a0c4b5-d4d2-491e-b0a3-82abe2b477f9',
      firstName: 'Jakobus',
      lastName: 'Nelson',
    },
    repeat: 'One time',
    team: 'East OC',
    travelTime: 20,
  },
];

import moment from 'moment';
import { TimeOffRequestsContext } from '../../../../../../pages/CaregiverDetails/context';
import { func, instanceOf } from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import Badge from '../../../../../../components/Badge';
import CustomDialog from '../../../../../../components/Dialog';
import { timeOffRequestTypes } from '../../constants';
import {
  checkIsTimeOffRequestChanged,
  checkRequiredFieldsFilled,
  getBadgeColorScheme,
} from '../../utils';
import SettingsView from './components/SettingsView';
import { timeOffRequestInitialState } from './constants';

export default function CreatePtoAndCallouts({
  caregiverData = {},
  startCheckOptimizer,
  setCancelButtonName,
  setSubmitButtonName,
  setCancelButtonStatus,
  confirmRequestStatusChanges,
}) {
  const {
    closeAddRequestDialog,
    editedRequestData,
    setEditedRequestData,
    creatingRequestInitialData,
    openAddRequestDialogType,
  } = useContext(TimeOffRequestsContext);
  const [requestData, setRequestData] = useState(
    editedRequestData || { ...timeOffRequestInitialState, caregiverId: caregiverData.id },
  );
  const [error, setError] = useState(null);

  const isDataChanged = useMemo(
    () => checkIsTimeOffRequestChanged(creatingRequestInitialData, requestData),
    [creatingRequestInitialData, requestData],
  );

  const isRequiredFieldsFilled = useMemo(
    () => checkRequiredFieldsFilled(requestData),
    [requestData],
  );

  const closeDialog = useCallback(() => {
    closeAddRequestDialog();
    setEditedRequestData(undefined);
    setRequestData(timeOffRequestInitialState);
    setError(null);
  }, [closeAddRequestDialog, setEditedRequestData]);

  const checkTimeOffRequestPossibility = () => {
    var startDate = moment(requestData.requestDateStart);
    const startHours = moment(requestData.requestTimeStart).hours();
    const startMins = moment(requestData.requestTimeStart).minutes();
    startDate.set({ hour: startHours, minute: startMins });
    if (requestData?.allDay) {
      startDate.set({ hour: 0, minute: 0 });
    }

    var endDate = moment(requestData.requestDateEnd);
    const endHours = moment(requestData.requestTimeEnd).hours();
    const endMins = moment(requestData.requestTimeEnd).minutes();
    endDate.set({ hour: endHours, minute: endMins });
    if (requestData?.allDay) {
      endDate.set({ hour: 23, minute: 59 });
    }

    delete requestData.requestDateStart;
    delete requestData.requestTimeStart;
    delete requestData.requestDateEnd;
    delete requestData.requestTimeEnd;

    requestData.from = startDate.format('YYYY-MM-DDTHH:mm:ss');
    requestData.to = endDate.format('YYYY-MM-DDTHH:mm:ss');
    if (editedRequestData) {
      setCancelButtonName('Set To Pending');
      setSubmitButtonName('Approve');
      setCancelButtonStatus('Pending');
      startCheckOptimizer(requestData);
    } else {
      setCancelButtonName('Cancel');
      setSubmitButtonName('Set To Pending');
      setCancelButtonStatus('Pending');
      confirmRequestStatusChanges([requestData]);
    }
  };

  const isStartDateExpired = useMemo(
    () => moment(requestData?.requestDateStart).isBefore(moment(), 'date'),
    [requestData?.requestDateStart],
  );

  return (
    <>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={closeDialog}
        disableOnCloseByClickOutside
        disabledSubmit={!isDataChanged || !isRequiredFieldsFilled || !!error || isStartDateExpired}
        isLoading={false}
        openDialog={openAddRequestDialogType === timeOffRequestTypes.settingParams}
        submitButtonName={requestData?.id ? 'Edit Request' : 'Add Request'}
        submitCallback={checkTimeOffRequestPossibility}
        title={requestData?.id ? 'Edit Time-Off Request' : 'Add Time-Off Request'}
        titleBadge={
          requestData?.status ? (
            <Badge
              colorScheme={getBadgeColorScheme(requestData?.status)}
              label={requestData?.status}
            />
          ) : null
        }
      >
        <SettingsView
          error={error}
          isStartDateExpired={isStartDateExpired}
          requestData={requestData}
          setError={setError}
          setRequestData={setRequestData}
        />
      </CustomDialog>
    </>
  );
}

CreatePtoAndCallouts.propTypes = {
  caregiverData: instanceOf(Object),
  startCheckOptimizer: func,
  confirmRequestStatusChanges: func,
};

import moment from 'moment-timezone';
import {
  DATE_ONLY_STANDARD_FORMAT,
  FULL_DATE_H24_STANDARD_FORMAT,
  H12_TIME_FORMAT,
  H24_TIME_FORMAT,
  SHORT_DATE_FORMAT,
  backgroundColorSchemeMap,
} from '../../../../shared/constants';
import { convertUtcToTeamTimeZoneMomentObject } from '../../../../shared/utils/common';
import {
  addTimeOffRequestRequiredFields,
  timeOffRequestInitialState,
} from './components/CreatePtoAndCallouts/constants';

const getFormattedDate = ({ date, time, initialTime, requestData }) =>
  `${date.format(DATE_ONLY_STANDARD_FORMAT)} ${
    requestData?.allDay ? initialTime : time.format(H24_TIME_FORMAT)
  }`;

export const formingDataForTimeOffRequestCreation = requestData => ({
  id: requestData?.id || null,
  requestStart: getFormattedDate({
    date: requestData.requestDateStart,
    time: requestData.requestTimeStart,
    initialTime: '00:00:00',
    requestData,
  }),
  requestEnd: getFormattedDate({
    date: requestData.requestDateEnd,
    time: requestData.requestTimeEnd,
    initialTime: '23:59:59',
    requestData,
  }),
  allDay: requestData.allDay,
  requestType: requestData.requestType,
  details: requestData.details,
});

export const parseTimeOffRequestsData = data =>
  data
    ? data?.map(requestData => ({
        ...requestData,
        createdDate: requestData?.createdDate
          ? convertUtcToTeamTimeZoneMomentObject(requestData?.createdDate)
          : null,
        requestDateEnd: convertUtcToTeamTimeZoneMomentObject(requestData?.requestEnd),
        requestDateStart: convertUtcToTeamTimeZoneMomentObject(requestData?.requestStart),
        requestTimeEnd: requestData?.allDay
          ? null
          : convertUtcToTeamTimeZoneMomentObject(requestData?.requestEnd),
        requestTimeStart: requestData?.allDay
          ? null
          : convertUtcToTeamTimeZoneMomentObject(requestData?.requestStart),
      }))
    : [];

export const checkIsTimeOffRequestChanged = (creatingRequestInitialData, requestData) => {
  const parsedNewData = {
    ...requestData,
    ...(requestData?.allDay
      ? {
          requestTimeEnd: moment(requestData?.requestTimeEnd, H12_TIME_FORMAT),
          requestTimeStart: moment(requestData?.requestTimeStart, H12_TIME_FORMAT),
        }
      : {}),
  };

  if (requestData.id) {
    const parsedInitialData = {
      ...creatingRequestInitialData,
      ...(creatingRequestInitialData?.allDay
        ? {
            requestTimeEnd: moment(creatingRequestInitialData?.requestTimeEnd, H12_TIME_FORMAT),
            requestTimeStart: moment(creatingRequestInitialData?.requestTimeStart, H12_TIME_FORMAT),
          }
        : {}),
    };
    return JSON.stringify(parsedInitialData) !== JSON.stringify(parsedNewData);
  }
  return JSON.stringify(timeOffRequestInitialState) !== JSON.stringify(parsedNewData);
};

export const checkRequiredFieldsFilled = requestData => {
  const requiredFields = addTimeOffRequestRequiredFields[requestData?.allDay ? 'allDay' : 'period'];
  return !requiredFields.some(item => !requestData[item] || requestData[item].length < 1);
};

export const parseNewRequestData = requestData => ({
  ...requestData,
  ...(requestData?.allDay
    ? {
        requestEnd: moment(requestData?.requestDateEnd).format(FULL_DATE_H24_STANDARD_FORMAT),
        requestStart: moment(requestData?.requestDateStart).format(FULL_DATE_H24_STANDARD_FORMAT),
      }
    : {
        requestEnd: moment(
          `${moment(requestData?.requestDateEnd).format(SHORT_DATE_FORMAT)} ${moment(
            requestData?.requestTimeEnd,
          ).format(H12_TIME_FORMAT)}`,
        ).format(FULL_DATE_H24_STANDARD_FORMAT),
        requestStart: moment(
          `${moment(requestData?.requestDateStart).format(SHORT_DATE_FORMAT)} ${moment(
            requestData?.requestTimeStart,
          ).format(H12_TIME_FORMAT)}`,
        ).format(FULL_DATE_H24_STANDARD_FORMAT),
      }),
});

export const getBadgeColorScheme = status => {
  const statusesKeys = {
    approved: 'active',
    pending: 'partiallySuccessful',
    rejected: 'error',
  };
  const statusKey = statusesKeys[status?.toLowerCase()];
  return backgroundColorSchemeMap[statusKey];
};
